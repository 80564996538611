import React from "react";
import CustomModal from "../../../../../modal/CustomModal";
import ModalHeadWithMSGLink from "../../../../../modal/ModalHeadWithMSGLink";
import Loader from "../../../../layout/Loader";
import Div from "../../../../../tags/element/Div";
import Separator from "../../../../Separator";
import Card from "../../../../card";
import SimpleHeader from "../../../../card/header/SimpleHeader";
import FlexRow from "../../../../../tags/layout/flex/public/FlexRow";
import ProductImageList from "./ProductImageList";
import SellerAddImageInput from "../../AddImage";

export default function ProductImageModal({
  isVisible,
  onHid,
  handleImageClick,
  getImages,
}) {
  return (
    <CustomModal
      onHid={onHid}
      isVisible={isVisible}
      head={
        <ModalHeadWithMSGLink
          onClick={onHid}
          msg={"Annuler"}
          title={"images"}
        />
      }
    >
      {getImages.loading ? (
        <Loader />
      ) : (
        <Div className="padding-1-em">
          <SellerAddImageInput getImages={getImages} />
          <Separator />
          <Card header={<SimpleHeader title={"Vos images"} />}>
            <FlexRow flexWrap="wrap" flexGap="1">
              <ProductImageList
                handleClick={handleImageClick}
                images={getImages.response}
              />
            </FlexRow>
          </Card>
        </Div>
      )}
    </CustomModal>
  );
}
