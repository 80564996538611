import React from "react";
import useGetTagSession from "../../../hook/useGetTagSession";
import Section from "../../tags/element/Section";
import Ul from "../../tags/element/Ul";
import MapList from "../MapList";
import Tag from "../Tag";
import TagSkeleton from "../TagSkeleton";
import Card from "../card";

export function TagSectionContainer({ children }) {
  return (
    <Section className="home__tag__section">
      <Card>
        <Ul className="tag__suggestion__list">{children}</Ul>
      </Card>
    </Section>
  );
}

const renderTagItem = (item) => <Tag data={item} key={item.id} />;

export default function TagSection() {
  const tagSession = useGetTagSession();

  if (tagSession.isEmpty) {
    return (
      <TagSectionContainer>
        <TagSkeleton />
      </TagSectionContainer>
    );
  }

  return (
    <TagSectionContainer>
      <MapList data={tagSession.tags} renderItem={renderTagItem} />
    </TagSectionContainer>
  );
}
