import React from "react";
import Div from "../../tags/element/Div";

export default function PaddingContainer({
  children,
  isCard: initial = false,
  className,
  ...props
}) {
  const className_ = ["container-padding", className].filter(Boolean).join(" ");
  return (
    <Div
      {...props}
      className={className_ + (initial ? " container-padding-card" : "")}
    >
      {children}
    </Div>
  );
}
