/* eslint-disable react/no-unescaped-entities */
import React from "react";
import MapList from "../../../components/public/MapList";
import CenterByMaxWidth from "../../../components/public/layout/CenterByMaxWidth";
import RequestManager from "../../../components/public/layout/RequestManager";
import Div from "../../../components/tags/element/Div";
import H1 from "../../../components/tags/element/H1";
import H2 from "../../../components/tags/element/H2";
import H3 from "../../../components/tags/element/H3";
import P from "../../../components/tags/element/P";
import useFetch from "../../../hook/useFetch";
import { useMetadata } from "../../../hook/useMetadata";
import { usePageAnalize } from "../../../hook/usePageAnalize";
import { useScrollTop } from "../../../hook/useScroolTop";

export default function TermOfUse() {
  useScrollTop();
  useMetadata("p=term_of_use");
  const getData = useFetch("/api/term-of-use");
  usePageAnalize();

  if (getData.loading || getData.error)
    return <RequestManager request={getData} />;

  const renderItem = (item) => {
    let Tag;

    if (item.type === "p") {
      Tag = P;
    } else if (item.type === "h1") {
      Tag = H1;
    } else if (item.type === "h2") {
      Tag = H2;
    } else if (item.type === "h3") {
      Tag = H3;
    }

    return <Tag>{item.content}</Tag>;
  };

  return (
    <CenterByMaxWidth maxWidth={"800px"}>
      <Div className="term-of-use">
        <MapList data={getData.response.data} renderItem={renderItem} />
      </Div>
    </CenterByMaxWidth>
  );
}
