import React from "react";
import FlexRow from "../../tags/layout/flex/public/FlexRow";
import Div from "../../tags/element/Div";
import H3 from "../../tags/element/H3";
import { Link } from "react-router-dom";
import FlexColumn from "../../tags/layout/flex/public/FlexColumn";
import ArrowLineRight from "../ico/ArrowLineRight";

export default function ProfileCardItem({
  label,
  value,
  to,
  ico = true,
  ...props
}) {
  return (
    <Link {...props} className="profile-card-item" to={to}>
      <FlexRow justifyContent="between" alignItems="center">
        <FlexColumn>
          {label && <H3>{label}</H3>}
          <Div>{value}</Div>
        </FlexColumn>
        {ico && <ArrowLineRight height={22} />}
      </FlexRow>
    </Link>
  );
}
