import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useGoBack = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [canGoBack, setCanGoBack] = useState(false);

  useEffect(() => {
    setCanGoBack(window.history.length > 1);
  }, [location]);

  const handleGoBack = () => {
    if (canGoBack) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };

  return handleGoBack;
};
