import React from "react";
import Div from "../tags/element/Div";

export default function Skeleton({
  children,
  height,
  width,
  className,
  style,
  ...props
}) {
  const className_ = ["skeleton skeleton-animate", className]
    .filter(Boolean)
    .join(" ");
  return (
    <Div
      style={{ height: height, width: width, ...style }}
      className={className_}
      {...props}
    >
      {children}
    </Div>
  );
}
