import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import CustomModal from '../../../components/modal/CustomModal'
import GradientButton from '../../../components/public/GradientButton'
import RequestManager from '../../../components/public/layout/RequestManager'
import MapList from '../../../components/public/MapList'
import ModalWithStickyButtonFooter from '../../../components/public/ModalWithStickyButtonFooter'
import Product from '../../../components/public/product'
import Skeleton from '../../../components/public/Skeleton'
import Button from '../../../components/tags/element/Button'
import Div from '../../../components/tags/element/Div'
import Form from '../../../components/tags/element/Form'
import Img from '../../../components/tags/element/Img'
import Input from '../../../components/tags/element/Input'
import P from '../../../components/tags/element/P'
import FlexColumn from '../../../components/tags/layout/flex/public/FlexColumn'
import FlexRow from '../../../components/tags/layout/flex/public/FlexRow'
import { useBoolean } from '../../../hook/useBoolean'
import useFetch from '../../../hook/useFetch'
import { useForm } from '../../../hook/useForm'
import useIntersectionObserver from '../../../hook/useIntersectionObserver'
import { formImageUrlForDebug } from '../../../modules/utils'



function ProductItem({ product, onSelect, isSelected }) {
  const [isChecked, setIsChecked] = useState(isSelected);

  useEffect(() => {
    onSelect?.(product.id, isChecked); // use optional chaining for clarity
  }, [isChecked, onSelect, product.id]);

  const toggleSelection = () => setIsChecked((prev) => !prev);

  return (
    <Div onClick={toggleSelection} className={`product-item ${isChecked ? "selected" : ""}`}>
      <FlexRow className="product-item-row" flexGap="1" justifyContent="between">
        <FlexRow className="product-info" flexGap="1" alignItems="center">
          <Input
            checked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
            className="product-checkbox"
            type="checkbox"
            onClick={(e) => e.stopPropagation()} // Prevent parent click
          />
          <Img className="product-image" src={formImageUrlForDebug(product.image_1.image)} height="50" width="50" />
          <FlexColumn className="product-details">
            <h3 className="product-title">{product.title}</h3>
            <P className="product-price">{product.price.formatted_price} FCFA</P>
          </FlexColumn>
        </FlexRow>
        <Button onClick={(e) => {e.stopPropagation(); alert("Preview")}} className="product-view-button">
          Voir
        </Button>
      </FlexRow>
    </Div>
  );
}




function EditCardProductModal({ manager, productIds, onSelect }) {
  const { id } = useParams();
  const [products, setProducts] = useState([]);
  const { response: productList, fetchData } = useFetch(`/api/admin/products`);
  const editCardForm = useForm(`/api/admin/cards/${id}/product/update`, undefined, [id], false);
  const [selectedProductIds, setSelectedProductIds] = useState(productIds);
  const { value: hasNextPage, toFalse: disableNextPage } = useBoolean(true);

  const observer = useIntersectionObserver(([entry]) => {
    if (entry.isIntersecting) {
      if (productList) {
        if (productList.next) {
          fetchData({ newUrl: productList.next, response: productList });
        } else {
          disableNextPage();
        }
      } 
    } 
  }, undefined, [productList]);

  useEffect(() => {
    editCardForm.setFomProps({ products: selectedProductIds });
  }, [selectedProductIds]);

  const handleProductSelect = useCallback((id, isSelected) => {
    setSelectedProductIds((prevIds) =>
      isSelected ? [...prevIds, id] : prevIds.filter((productId) => productId !== id)
    );
  }, []);

  useEffect(() => {
    if (productList?.results) {
      setProducts((prev) => [...prev, ...productList.results]);
    }
  }, [productList]);

  useEffect(() => {
    if (editCardForm.formResponse?.is_valid) {
      onSelect?.();
      manager.toFalse();
    }
  }, [editCardForm.formResponse, onSelect, manager]);

  return (
    <CustomModal isVisible={manager.value} className="edit-card-product-modal" title="Modifier la card" onHid={manager.toFalse}>
      <Div className="padding-1-em">
        <Input className="product-search" placeholder="Rechercher un produit" />
      </Div>
      <FlexColumn>
        <MapList
          useId
          data={products}
          renderItem={(product) => (
            <ProductItem product={product} isSelected={selectedProductIds.includes(product.id)} onSelect={handleProductSelect} />
          )}
        />
        {hasNextPage && (
          <div ref={observer.ref} className="padding-1-em">
            <Skeleton width="100%" height="2em" />
          </div>
        )}
      </FlexColumn>
      <ModalWithStickyButtonFooter style={{ margin: 0 }}>
        <Form method="POST" onSubmit={editCardForm.handleSubmit}>
          <GradientButton type={"button"}>Continuer ({selectedProductIds.length >= 1 && selectedProductIds.length})</GradientButton>
        </Form>
      </ModalWithStickyButtonFooter>
    </CustomModal>
  );
}


export default function AdminCardItemProduct() {
  const { id } = useParams();
  const { value: isEditModalOpen, toTrue: openEditModal, toFalse: closeEditModal } = useBoolean(false);
  const [productIds, setProductIds] = useState([]);
  const { response: cardData, fetchData, loading, error } = useFetch(`/api/cards/${id}`);

  useEffect(() => {
    if (cardData) {
      const products = cardData[0].products.map((product) => product.id);
      setProductIds(products);
    }
  }, [cardData]);

  const handleProductSelect = useCallback(() => {
    fetchData({ loading: true });
  }, [fetchData]);

  if (loading || error) return <RequestManager request={{ loading, error, fetchData }} />;

  return (
    <>
      <Button onClick={openEditModal}>Modifier</Button>
      <Div className="slider-children-container-grid s-c-c-g">
        <MapList data={cardData[0].products} renderItem={(product) => <Product key={product.id} data={product} />} />
      </Div>
      {isEditModalOpen && (
        <EditCardProductModal productIds={productIds} onSelect={handleProductSelect} manager={{ value: isEditModalOpen, toFalse: closeEditModal }} />
      )}
    </>
  );
}
