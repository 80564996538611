import { useEffect } from "react";

const useDetachNavbar = (detach) => {
  useEffect(() => {
    if (detach) {
      document.body.classList.add("navbar-static");
    } else {
      document.body.classList.remove("navbar-static");
    }
    return () => {
      document.body.classList.remove("navbar-static");
    };
  }, [detach]);
};

export default useDetachNavbar;
