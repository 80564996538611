import React from "react";
import Button from "../tags/element/Button";
import { Link } from "react-router-dom";
import Div from "../tags/element/Div";
import Span from "../tags/element/Span";
import { useVibrate } from "../../hook/useVibrate";

export default function GradientButton({
  onClick,
  type,
  gray = false,
  children,
  isLoading = false,
  style,
  gradient = true,
  ...props
}) {
  const vibrate = useVibrate();
  var className = gradient ? "button gradient-button" : "button";
  props.className && (className += ` ${props.className}`);
  className = gray ? (className += " gray") : className;

  const handleClick = (e) => {
    vibrate();
    onClick && onClick(e);
  };

  const editChildren = isLoading ? (
    <Div className="loader-container" {...props}>
      <Span className="main-loader" style={{height: "25px", width: "25px", ...style}}></Span>
    </Div>
  ) : (
    children
  );

  return type === "button" ? (
    <Button onClick={handleClick} {...props} className={className}>
      {editChildren}
    </Button>
  ) : (
    <Link onClick={handleClick} {...props} className={className}>
      {editChildren}
    </Link>
  );
}
