import React, { useEffect, useState } from "react";
import SearchIco from "../../../components/public/navbar/ico/SearchIco";
import Button from "../../../components/tags/element/Button";
import Form from "../../../components/tags/element/Form";
import FlexRow from "../../../components/tags/layout/flex/public/FlexRow";
import { useFocus } from "../../../hook/useFocus";
import useSearch from "../../../hook/useSearch";

const SearchInputSection = ({ pageAnalize }) => {
  const [inputRef] = useFocus();
  const [isBlurred, setIsBlurred] = useState(false);
  const search = useSearch({
    onSucces: (q) => {
      pageAnalize.createPageAction("SEARCH_FORM_SUBMIT", q);
    },
  });
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (inputRef.current) inputRef.current.focus();
    }, 1);

    const onBlurHandler = () => {
      clearInterval(intervalId);
      setIsBlurred(true);
      inputRef.current &&
        inputRef.current.removeEventListener("blur", onBlurHandler);
    };

    inputRef.current.addEventListener("blur", onBlurHandler);
    return () => clearInterval(intervalId);
  }, [inputRef]);

  return (
    <Form method="get" onSubmit={search.handleSubmit}>
      <FlexRow
        flexGap="1"
        alignItems="center"
        justifyContent="between"
        className="search__input__section"
      >
        <FlexRow
          className={`search__input__section-search-input-group ${isBlurred ? "blurred" : ""}`}
        >
          <Button type="submit">
            <SearchIco width={20} height={20} />
          </Button>
          <input
            ref={inputRef}
            className={`flex-grow-1 ${isBlurred ? "blurred-input" : ""}`}
            type="text"
            placeholder="Rechercher un produit"
            name="q"
          />
        </FlexRow>
      </FlexRow>
    </Form>
  );
};

export default SearchInputSection;
