import React from 'react'
import CardRender from '../../../components/public/card/body/CardRender'
import { useParams } from 'react-router-dom'

export default function DetailsSimilarProductsCard() {
  const { id, slug } = useParams()

  return (
    <CardRender
      url={`/api/products/${id}/${slug}/similar-products`}
      skeletonNumber={3}
    />
  )
}
