import React, { useEffect, useState } from "react";
import Div from "../../../components/tags/element/Div";
import Img from "../../../components/tags/element/Img";
import FlexRow from "../../../components/tags/layout/flex/public/FlexRow";
import {
  formImageUrlForDebug,
  formatUrlForDebug,
} from "../../../modules/utils";
import FlexColumn from "../../../components/tags/layout/flex/public/FlexColumn";
import MapList from "../../../components/public/MapList";

function DetailsImageListItem({ image, onSelectedChange }) {
  const imageUrl = formatUrlForDebug(image.image);
  return (
    <Div onClick={() => onSelectedChange(imageUrl)}>
      <Img src={imageUrl} alt={image.id} />
    </Div>
  );
}

function DetailsImgList({ images, onSelectedChange }) {
  const renderItem = (item) =>
    item && (
      <DetailsImageListItem onSelectedChange={onSelectedChange} image={item} />
    );
  return (
    <FlexRow className="details-img-list-container">
      <MapList data={images} renderItem={renderItem} />
    </FlexRow>
  );
}

export default function DetailsImageSection({ data }) {
  const container_image = formImageUrlForDebug(data.image_1.image);
  const [selectedImage, setSeletedImage] = useState(container_image);

  useEffect(() => {
    setSeletedImage(container_image);
  }, [data, container_image]);

  return (
    <Div className="details-banner-container">
      <Div className="details-banner-item">
        <FlexColumn
          alignItems="center"
          className="details-main-container"
          justifyContent="between"
        >
          <Div className="details-img-div">
            <Img src={selectedImage} />
          </Div>
          <DetailsImgList
            onSelectedChange={setSeletedImage}
            images={[data.image_1, data.image_2, data.image_3]}
          />
        </FlexColumn>
      </Div>
      <Img src={selectedImage} className="details-img-absolut-banner" />
    </Div>
  );
}
