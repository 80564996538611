import React from "react";
import DashborMimidCardInfo from "./card/DashborMimidCardInfo";
import DefaultDashbordCardListItem from "./card/DefaultDashbordCardListItem";
import UserSvg from "../../navbar/ico/UserSvg";
import Div from "../../../tags/element/Div";
import SearchIco from "../../navbar/ico/SearchIco";
import ShopIco from "../../ico/ShopIco";
import FlexColumn from "../../../tags/layout/flex/public/FlexColumn";
import Img from "../../../tags/element/Img";
import { Link } from "react-router-dom";
import {
  formImageUrlForDebug,
  orderStatusName,
} from "../../../../modules/utils";
import FlexRow from "../../../tags/layout/flex/public/FlexRow";
import Span from "../../../tags/element/Span";
import Button from "../../../tags/element/Button";
import { toSellerOrderDetail } from "../../../../constant";
import UserAvatar from "../../layout/UserAvatar";
import { concatenateClass } from "../../../../tools/utils";
import H2 from "../../../tags/element/H2";
import H3 from "../../../tags/element/H3";
import P from "../../../tags/element/P";
import ChevronRight from "../../ico/ChevronRight";

export const miniCardInfoItem = (item) => (
  <DashborMimidCardInfo title={item.name} value={item.value} />
);
export const visitorDataItem = (item) => {
  return (
    <DefaultDashbordCardListItem
      left={<UserSvg width={16} />}
      middle={
        <Link to={"/admin/visitor/" + item.id}>
          <FlexColumn>
            <Div>{item.id}</Div>
            <Div className="admin-order-data-item">
              <Div>{item.date}</Div>
              <Div style={{ marginTop: ".2em" }}>{item.session}</Div>
            </Div>
          </FlexColumn>
        </Link>
      }
    />
  );
};

export const sessionDataItem = (item) => {
  return (
    <DefaultDashbordCardListItem
      left={<UserSvg width={16} />}
      middle={
        <Link to={"/admin/visitor/" + item.value + "/session/:id"}>
          <FlexColumn>
            <Div>{item.id}</Div>
            <Div className="admin-order-data-item">
              <Div>{item.pages.length} page(s)</Div>
              <Div style={{ marginTop: ".2em" }}>{item.page}</Div>
            </Div>
          </FlexColumn>
        </Link>
      }
    />
  );
};

export const searchDataItem = (item) => (
  <DefaultDashbordCardListItem left={<SearchIco width={12} />} middle={item} />
);

export const countryDataItem = (item, index) => (
  <DefaultDashbordCardListItem left={index + 1} middle={item} />
);
export const regionDataItem = (item, index) => (
  <DefaultDashbordCardListItem left={index + 1} middle={item} />
);

export const usersDataItem = (item) => {
  return (
    <DefaultDashbordCardListItem
      left={<UserSvg width={16} />}
      middle={
        <FlexColumn>
          <Div>{item.fullName}</Div>
          <Div className="admin-order-data-item">
            <Div style={{ marginTop: ".2em" }}>{item.dateJoined}</Div>
          </Div>
        </FlexColumn>
      }
    />
  );
};

export const becomeSellerDataItem = (item) => (
  <DefaultDashbordCardListItem left={<ShopIco width={16} />} middle={item} />
);

export const orderDataItem = (item) => {
  return (
    <Link to={"/admin/order/" + item.id}>
      <DefaultDashbordCardListItem
        left={<UserSvg width={16} />}
        middle={
          <FlexColumn>
            <Div>{item.full_name}</Div>
            <Div className="admin-order-data-item">
              <Div>{item.shipping_address}</Div>
              <Div>{item.phone}</Div>
              <Div style={{ marginTop: ".2em" }}>{item.total} FCFA</Div>
              <Div style={{ marginTop: ".2em" }}>{item.added_date}</Div>
            </Div>
          </FlexColumn>
        }
        right={
          <Div className={"order-status-info delivery " + item.order_status}>
            {orderStatusName(item.order_status)}
          </Div>
        }
      />
    </Link>
  );
};

export const productDataItem = (item) => {
  return (
    <DefaultDashbordCardListItem
      left={
        <Img
          style={{ borderRadius: "50%" }}
          width="30"
          height="30"
          src={formImageUrlForDebug(item.image_1.image)}
        />
      }
      middle={
        <FlexColumn>
          <Div>{item.title}</Div>
          <Div className="admin-order-data-item">
            <Div>{item.price.formatted_price} FCFA</Div>
            <Div style={{ marginTop: ".2em" }}>{item.added_date}</Div>
          </Div>
        </FlexColumn>
      }
    />
  );
};

export const topPageItem = (item, i) => {
  return <DefaultDashbordCardListItem left={i + 1} middle={item} />;
};

export const ProductImageItem = ({ item, onDelete }) => {
  return (
    <FlexRow flexGap="1">
      <Img src={formImageUrlForDebug(item.image)} />
      <Div className="seller-image__render-item__container">
        <Div className="seller-image-label seller-image__name">
          <Span>{item.name}</Span>
        </Div>
        <Div className="seller-image-label seller-image__size">
          <Span>{item.size} - </Span>
          <Span>{item.added_date}</Span>
        </Div>
        {item.total_used <= 0 ? (
          <Button
            onClick={() => onDelete && onDelete(item.id)}
            className="delete"
          >
            Supprimer
          </Button>
        ) : (
          <Button className="used">Image utilisé</Button>
        )}
      </Div>
    </FlexRow>
  );
};

export const OrderItem = ({ item, profile = false }) => {
  return (
    <Link
      to={profile ? `/profile/orders/${item.id}` : toSellerOrderDetail(item.id)}
    >
      <FlexRow flexGap="1">
        <Div className="flex-shrink-0">
          <UserAvatar label={item.full_name} size={40} />
        </Div>
        <Div className="seller-image__render-item__container">
          <Div className="seller-image-label seller-image__name">
            <Span>{item.full_name}</Span>
          </Div>
          <Div className="seller-image-label seller-image__size">
            <Span></Span>
            <Span>{item.shipping_address}</Span>
          </Div>
          <Div className="seller-image-label seller-image__size">
            <Span></Span>
            <Span>{item.total} FCFA</Span>
          </Div>
          <Div className="seller-image-label seller-image__size">
            <Span></Span>
            <Span>{item.added_date}</Span>
          </Div>
          <Button
            className={concatenateClass([
              "order-status-info delivery",
              item.order_status,
            ])}
          >
            {orderStatusName(item.order_status)}
          </Button>
        </Div>
      </FlexRow>
    </Link>
  );
};

export const UserItem = (item) => (
  <Link to={`/admin/user/${item.id}`}>
    <FlexRow>
      <UserAvatar color={item.profile_color} label={item.full_name} />
      <FlexColumn>
        <H2>{item.full_name}</H2>
        <Div>
          <Span>{item.username}</Span>
        </Div>
        <Div>
          <Span>{item.date_joined}</Span>
        </Div>
      </FlexColumn>
    </FlexRow>
  </Link>
);

export const ShopItem = (item) => (
  <Link to={`/admin/shop/${item.id}`}>
    <FlexRow>
      <UserAvatar color={item.user.profile_color} label={item.user.full_name} />
      <FlexColumn>
        <H2>{item.name}</H2>
        <H3>{item.user.full_name}</H3>
        <Div>
          <Span>{item.email}</Span>
        </Div>
        <Div>
          <Span>{item.category}</Span>
        </Div>
        <Div>
          <Span>{item.added_date}</Span>
        </Div>
      </FlexColumn>
    </FlexRow>
  </Link>
);

export const cardItem = (item) => {
  return (

    <Link to={`/admin/cards/${item.id}/products`}>
      <FlexRow justifyContent="between">
        <FlexColumn>
          <H2>{item.title}</H2>
          <P>{item.description}</P>
          <P>{item.products.length} products</P>
        </FlexColumn>
        <Div className="flex-shrink-0">
          <ChevronRight height={22} width={22}/>
        </Div>
      </FlexRow>
    </Link>
  )

}