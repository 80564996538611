import { useDispatch } from "react-redux";
import useRedirect from "./userRedirect";
import { Authenticate } from "../modules/auth";
import { setAuthenticated } from "../features/main/appSlice";
import { app } from "../constant";

const useLogin = () => {
  const dispatch = useDispatch();
  const navigateTo = useRedirect();

  const login = (token, shouldRedirect = true) => {
    Authenticate(token);
    dispatch(setAuthenticated(true));
    shouldRedirect && navigateTo.navigate(app.loginRedirect);
  };

  return login;
};

export default useLogin;
