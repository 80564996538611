import React from "react";
import Div from "../../tags/element/Div";

export default function CenterByMaxWidth({
  children,
  className,
  maxWidth,
  style,
  ...props
}) {
  const combinedClassName = `center-by-max-width ${className || ""}`;

  return (
    <Div
      className={combinedClassName}
      style={{ maxWidth: maxWidth, ...style }}
      {...props}
    >
      {children}
    </Div>
  );
}
