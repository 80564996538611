import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  dumpCardRecommendations,
  dumpCards,
} from "../../../features/main/homeSlice";
import useFetch from "../../../hook/useFetch";
import Section from "../../tags/element/Section";
import CardRender from "../card/body/CardRender";

function baseParse(url, content, setter, data) {
  const get = useFetch(url, [], false, { loading: false });
  const dispatch = useDispatch();

  useEffect(() => {
    if (content.length === 0) {
      if (data) {
        if (window.location.pathname !== data.preventPath) {
          get.fetchData({ loading: false });
        }
      } else {
        get.fetchData({ loading: false });
      }
    }
  }, []);

  useEffect(() => {
    if (get.response) {
      dispatch(setter(get.response));
    }
  }, [get.response]);

  return {
    loading: get.loading,
    error: get.error,
    response: content,
    loadingCondition: content.length === 0,
  };
}

export const useHomeCardsParse = (url, data = { preventPath: null }) => {
  const content = useSelector((state) => state.home.content);
  return baseParse(url, content.cards, dumpCards, data);
};

const useCardsRecommandationParse = (url) => {
  const content = useSelector((state) => state.home.content);
  return baseParse(url, content.cardRecommendations, dumpCardRecommendations);
};

export default function HomeCardSection() {
  return (
    <Section className="home__card__section">
      <CardRender
        url={"/api/cards"}
        renderNumber={5}
        parser={useHomeCardsParse}
      />
      <CardRender
        url={"/api/cards/recommendation"}
        parser={useCardsRecommandationParse}
      />
    </Section>
  );
}
