import { useDispatch, useSelector } from "react-redux";
import { hideSidebar, showSidebar } from "../features/main/sidebarSlice";

const useSidebar = () => {
  const dispatch = useDispatch();
  const sidebar = useSelector((state) => state.sidebar);

  const show = () => {
    dispatch(showSidebar());
  };

  const hide = () => {
    dispatch(hideSidebar());
  };

  return { show, hide, sidebar };
};

export default useSidebar;
