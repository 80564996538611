import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import MapList from "../../../components/public/MapList";
import Card from "../../../components/public/card";
import GoBackHeader from "../../../components/public/card/header/GoBackHeader";
import SimpleHeader from "../../../components/public/card/header/SimpleHeader";
import RequestManager from "../../../components/public/layout/RequestManager";
import DashborMimidCardInfo from "../../../components/public/manager/admin/card/DashborMimidCardInfo";
import DefaultDashbordCard from "../../../components/public/manager/admin/card/DefaultDashbordCard";
import Div from "../../../components/tags/element/Div";
import H1 from "../../../components/tags/element/H1";
import H3 from "../../../components/tags/element/H3";
import FlexColumn from "../../../components/tags/layout/flex/public/FlexColumn";
import FlexRow from "../../../components/tags/layout/flex/public/FlexRow";
import useFetch from "../../../hook/useFetch";
import { useScrollTop } from "../../../hook/useScroolTop";
import { calculMean } from "../../../modules/utils";
import DefaultDashbordCardListItem from "../../../components/public/manager/admin/card/DefaultDashbordCardListItem";
import UserSvg from "../../../components/public/navbar/ico/UserSvg";

export default function AdminVisitorItem() {
  useScrollTop();
  const { visitorId } = useParams();
  const getVisitorItem = useFetch("/api/admin/visitor/" + visitorId);
  const [sessionData, setSessionData] = useState({});
  useEffect(() => {
    if (getVisitorItem.response) {
      var totalPage = 0;
      var totalAction = 0;
      var timeTotal = 0;
      var pageTimeStamp = [];
      for (let i = 0; i < getVisitorItem.response.session.length; i++) {
        const session = getVisitorItem.response.session[i];
        totalPage = totalPage + session.pages.length;

        for (let p = 0; p < session.pages.length; p++) {
          const page = session.pages[p];
          totalAction = totalAction + page.actions.length;
          timeTotal = timeTotal + page.timestamp;
          pageTimeStamp.push(page.timestamp);
        }
      }
      setSessionData((state) => {
        return {
          ...state,
          totalPage,
          totalAction,
          timeTotal,
          pageStampMean: calculMean(pageTimeStamp),
        };
      });
    }
  }, [getVisitorItem.response]);

  if (getVisitorItem.loading || getVisitorItem.error)
    return <RequestManager request={getVisitorItem} />;

  return (
    <Div>
      <GoBackHeader to={".."} title={"Visiteur - " + visitorId} h={H1} />

      <Card header={<SimpleHeader title={"Session"} />}>
        <FlexRow flexGap="1" flexWrap="wrap">
          <DashborMimidCardInfo
            title={"Total de session"}
            value={getVisitorItem.response.session.length}
          />
          <DashborMimidCardInfo
            title={"Page Visité"}
            value={sessionData.totalPage}
          />
          <DashborMimidCardInfo
            title={"Action effectué"}
            value={sessionData.totalAction}
          />
          <DashborMimidCardInfo
            title={"Temps total (s)"}
            value={Math.round(sessionData.timeTotal)}
          />
          <DashborMimidCardInfo
            title={"Temps moyen par page (s)"}
            value={Math.round(sessionData.pageStampMean || 0)}
          />
          <DashborMimidCardInfo
            title={"Actif depuis"}
            value={getVisitorItem.response.added_date}
          />
          <DashborMimidCardInfo
            title={"Dernier session session"}
            value={getVisitorItem.response.session[0]?.added_date}
          />
        </FlexRow>
        <Card header={<SimpleHeader title={"Navigateur Information"} />}>
          <FlexRow flexWrap="wrap" flexGap="1">
            <MapList
              data={getVisitorItem.response.browser_infos}
              renderItem={(item) => {
                return (
                  <Card header={<SimpleHeader h={H3} title={item.info_name} />}>
                    <FlexColumn>
                      <Div>{item.info_value}</Div>
                    </FlexColumn>
                  </Card>
                );
              }}
            />
          </FlexRow>
        </Card>
        <FlexRow flexGap="1" flexWrap="wrap">
          <DefaultDashbordCard
            to={`/admin/visitor/${visitorId}/session`}
            data={getVisitorItem.response.session}
            label={"Session"}
            renderItem={(item) => {
              return (
                <DefaultDashbordCardListItem
                  left={<UserSvg width={16} />}
                  middle={
                    <Link
                      to={"/admin/visitor/" + visitorId + "/session/" + item.id}
                    >
                      <FlexColumn>
                        <Div>{item.id}</Div>
                        <Div className="admin-order-data-item">
                          <Div>{item.added_date}</Div>
                          <Div>{item.pages.length} page(s)</Div>
                          <Div style={{ marginTop: ".2em" }}>{item.page}</Div>
                        </Div>
                      </FlexColumn>
                    </Link>
                  }
                />
              );
            }}
          />
        </FlexRow>
      </Card>
    </Div>
  );
}
