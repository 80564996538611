import React, { useEffect } from 'react'
import { useForm } from '../../../hook/useForm'
import CustomModal from '../../modal/CustomModal'
import Form from '../../tags/element/Form'
import FlexColumn from '../../tags/layout/flex/public/FlexColumn'
import InputLitsRender from '../layout/InputLitsRender'
import RequestManager from '../layout/RequestManager'
import Div from '../../tags/element/Div'
import GradientButton from '../GradientButton'
import useToastManager from '../../../hook/useToastManager'
import { toastData } from '../../../constant'
import ModalWithStickyButtonFooter from '../ModalWithStickyButtonFooter'

function FormBody({form})  {
  return (
    <Form method="post" onSubmit={form.handleSubmit}>
      <FlexColumn flexGap="1">
        <InputLitsRender formData={form.formData} formDisabled={form.formDisabled}/>
        <ModalWithStickyButtonFooter>
          <GradientButton disabled={form.formDisabled} type={"button"}>Continuer</GradientButton>
        </ModalWithStickyButtonFooter>
      </FlexColumn>
    </Form>
  )
}

export default function DynamicForm({close, ...props}) {
  const getForm = useForm(props.url, undefined,  [props.url], false)
  const toastManager = useToastManager()
  useEffect(() => {
    if (getForm.formResponse && getForm.formResponse.is_valid) {
      close()
      toastManager.addItem(toastData.dynamicFormSuccesUpdate())
    }
  }, [getForm.formResponse])
  
  return (
    <CustomModal title={props.title} onHid={close}>
      <Div className="padding-1-em">
      {
        (getForm.loading || getForm.error) ? 
          <RequestManager request={getForm}/> 
        :<FormBody form={getForm}/>
      }
      </Div>
    </CustomModal>
  )
}
