import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRenderDelay } from "../../hook/useRenderDelay";
import { useVibrate } from "../../hook/useVibrate";
import Div from "../tags/element/Div";
import { useBlockBodyScroll } from "../../hook/useBodyScroll";

export default function Modal({
  children,
  className,
  bodyClickNavigateTo = "/",
  head,
  modalContainerClick,
  containerClassName,
  canVibrate = true,
}) {
  const navigate = useNavigate();
  const vibrate = useVibrate();
  const shouldRenderModal = useRenderDelay(100);
  useBlockBodyScroll();
  useEffect(() => {
    canVibrate && vibrate();
  }, []);
  const handleModalContainerClick = (e) => {
    if (e.target.classList.contains("modal-container")) {
      modalContainerClick && modalContainerClick();
      bodyClickNavigateTo !== false && navigate(bodyClickNavigateTo);
      e.stopPropagation();
    }
  };

  const modelItemClassName = shouldRenderModal ? "modal-items" : "modal-h-0";
  const className_ = className ? className : "";
  const containerClassName_ = containerClassName
    ? "modal-container " + containerClassName
    : "modal-container";
  const className__ = shouldRenderModal && className_;

  return (
    <Div className={containerClassName_} onClick={handleModalContainerClick}>
      <Div className={modelItemClassName + " " + className__}>
        {head}
        <Div className="modal-body">{children}</Div>
      </Div>
    </Div>
  );
}
