import React from "react";
import { Link } from "react-router-dom";
import ModalHead from "./ModalHead";
import Div from "../tags/element/Div";
import Span from "../tags/element/Span";

export default function ModalHeadWithMSGLink({
  title,
  msg,
  to,
  onClick,
  ...props
}) {
  return (
    <ModalHead className="modal-head-with-msg-link" {...props}>
      {title}
      {to ? (
        <Link className="link" onClick={onClick} to={to}>
          {msg}
        </Link>
      ) : (
        <Div onClick={onClick} className="link">
          <Span>{msg}</Span>
        </Div>
      )}
    </ModalHead>
  );
}
