import React from "react";
import InputGroup from "./InputGroup";
import MapList from "../MapList";

export default function InputLitsRender({ formData, formDisabled, blockedInput=[], ...props }) {
  const renderItem = (item) => {
    return (
      !blockedInput.includes(item.name) &&
        <InputGroup
          type={item.type}
          value={item.value}
          errorList={item.errors}
          name={item.name}
          placeholder={item.label}
          required={item.required}
          disabled={formDisabled}
          {...props}
        />
    )
  }
  return <MapList data={formData} renderItem={renderItem} />
}
