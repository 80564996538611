import React from "react";
import { Link, useNavigate } from "react-router-dom";
import useFetch from "../../../hook/useFetch";
import Button from "../../tags/element/Button";
import Div from "../../tags/element/Div";
import H2 from "../../tags/element/H2";
import Li from "../../tags/element/Li";
import Ul from "../../tags/element/Ul";
import FlexRow from "../../tags/layout/flex/public/FlexRow";
import LeftLayoutIco from "../ico/LeftLayoutIco";
import MapList from "../MapList";

function SidebarBlogLiItem({ text, to }) {
  return (
    <Li className="sidebar-blog-li-item">
      <Div className="sidebar-blog-li-div">
        <FlexRow alignItems="center">
          <Link to={to}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="16"
              height="16"
              fill="currentColor"
            >
              <path d="M9 2.00318V2H19.9978C20.5513 2 21 2.45531 21 2.9918V21.0082C21 21.556 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5501 3 20.9932V8L9 2.00318ZM5.82918 8H9V4.83086L5.82918 8ZM11 4V9C11 9.55228 10.5523 10 10 10H5V20H19V4H11Z"></path>
            </svg>
          </Link>
          <Link to={to}>{text}</Link>
        </FlexRow>
      </Div>
    </Li>
  );
}

export const SidebarHeader = ({ visiblityManager }) => {
  const navigate = useNavigate();
  return (
    <FlexRow
      flexGap="1"
      justifyContent="between"
      className="blog-sidebar__header"
    >
      <Button onClick={() => visiblityManager.toFalse()}>
        <LeftLayoutIco width="18" height="18" />
      </Button>
      <Button onClick={() => navigate("/blog/add")}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="18"
          height="18"
          fill="currentColor"
        >
          <path d="M4 1V4H1V6H4V9H6V6H9V4H6V1H4ZM3 20.0066V11H5V19H13V14C13 13.45 13.45 13 14 13L19 12.999V5H11V3H20.0066C20.5552 3 21 3.45576 21 4.00247V15L15 20.996L4.00221 21C3.4487 21 3 20.5551 3 20.0066ZM18.171 14.999L15 15V18.169L18.171 14.999Z"></path>
        </svg>
      </Button>
    </FlexRow>
  );
};

export const SidebarBody = () => {
  const getBlog = useFetch("/api/blog/list");
  if (getBlog.loading || getBlog.error) {
    return "Chargement...";
  }
  return (
    <Div>
      <H2 className="my-blog">Mes Blog</H2>
      <Ul>
        <MapList
          data={getBlog.response}
          renderItem={(item) => (
            <SidebarBlogLiItem to={`/blog/edit/${item.id}`} text={item.title} />
          )}
        />
      </Ul>
    </Div>
  );
};
export default function BlogSidebar({ visiblityManager }) {
  return (
    <Div className="blog-sidebar">
      <SidebarHeader visiblityManager={visiblityManager} />
      <SidebarBody />
    </Div>
  );
}
