/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Separator from "../../../components/public/Separator";
import InputLitsRender from "../../../components/public/layout/InputLitsRender";
import RequestManager from "../../../components/public/layout/RequestManager";
import ProfileEdit from "../../../components/public/profile/ProfileEdit";
import P from "../../../components/tags/element/P";
import { toApiEditName } from "../../../constant";
import { useForm } from "../../../hook/useForm";

export default function EditName() {
  const editForm = useForm(toApiEditName());

  if (editForm.loading || editForm.error)
    return <RequestManager request={editForm} />;

  return (
    <ProfileEdit
      onSubmit={editForm.handleSubmit}
      isDisabled={editForm.formDisabled}
      description={
        "Ajoutez, modifiez ou supprimez vos adresses de livraison pour recevoir vos commandes."
      }
      title={"Modifier votre nom"}
    >
      <InputLitsRender
        formData={editForm.formData}
        formDisabled={editForm.formDisabled}
      />
      <Separator />
      {editForm.FormError && (
        <P className="incorrect-info">
          Une erreur s'est produite veuillez réessayer
        </P>
      )}
    </ProfileEdit>
  );
}
