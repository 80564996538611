import React from "react";
import PagnationContainer from "./pagnation/PagnationContainer";
import { ShopItem } from "../../../components/public/manager/admin/renderItem";

export default function AdminShop() {
  return (
    <PagnationContainer
      url={"/api/admin/shop"}
      renderItem={ShopItem}
      title={"Vendeur"}
    />
  );
}
