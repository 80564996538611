import React from "react";
import Button from "../../../../tags/element/Button";
import Div from "../../../../tags/element/Div";
import H1 from "../../../../tags/element/H1";
import H2 from "../../../../tags/element/H2";
import P from "../../../../tags/element/P";
import Span from "../../../../tags/element/Span";
import FlexColumn from "../../../../tags/layout/flex/public/FlexColumn";
import FlexRow from "../../../../tags/layout/flex/public/FlexRow";
import ImageEditIco from "../../../ico/ImageEditIco";
import UserAvatar from "../../../layout/UserAvatar";
import Img from "../../../../tags/element/Img";
import { formImageUrlForDebug } from "../../../../../modules/utils";

export default function SellerProfileCard({
  full_name,
  username,
  shopDescription,
  shopName,
  productTotal,
  onEditClick,
  addImage,
  profile
}) {

  const handleInputImageChange = (e) => {
    addImage.handleImageChange(e)
  }
  

  const handleEditProfile = () => {
    if (addImage.fileInputRef.current) {
      addImage.fileInputRef.current.click()
    }
  }

  return (
    <>
    
      <FlexRow
        flexGap="1"
        className="admin-get-seller"
        flexWrap="wrap"
        alignItems="center"
      >
        <Div onClick={handleEditProfile} style={{cursor:"pointer", userSelect:"none"}} className="flex-shrink-0 seller-profile-image-container">
          <input type="file" accept="image/*" hidden ref={addImage.fileInputRef} onChange={handleInputImageChange}/>

          {
            profile ? (<Img width='100' height="100" style={{borderRadius:"50%", objectFit:"cover"}} src={formImageUrlForDebug(profile)}/>): <UserAvatar size={"100"} label={full_name} />
          }

          <Div className="seller-profile-image-logo">
            <ImageEditIco width="18"/>
          </Div>
        </Div>
        <FlexColumn>
          <H1 style={{marginBottom:".1em"}}>{full_name}</H1>
          <Div className="username">
            <Span>@{username}</Span>
          </Div>
        </FlexColumn>
      </FlexRow>

      <Div className="seller-profile-shop-info">
        <H2>
          <FlexColumn>
            <Div>{shopName}</Div>
            <Div className="seller-state">
              <Span>{productTotal}</Span>
            </Div>
          </FlexColumn>
        </H2>

        <P className="seller-description">{shopDescription}</P>
        <Button onClick={onEditClick} className="seller-edit-profile-btn">
          Modifier la boutique
        </Button>
      </Div>
    </>
  );
}
