import React from "react";
import Tags from "..";

export default function Section({ children, ...props }) {
  return (
    <Tags tag="section" {...props}>
      {children}
    </Tags>
  );
}
