import React from "react";
import { Link } from "react-router-dom";
import Span from "../tags/element/Span";
import ArrowLeft from "../public/ico/ArrowLeft";
import FlexRow from "../tags/layout/flex/public/FlexRow";

export default function HeadLeft({ to, title, onClick }) {
  return (
    <FlexRow alignItems="center">
      <Link onClick={() => onClick && onClick()} to={to}>
        <ArrowLeft width={24} height={24} />
      </Link>
      <Link
        onClick={() => onClick && onClick()}
        to={to}
        className="modal-head-title"
      >
        <Span>{title}</Span>
      </Link>
    </FlexRow>
  );
}
