import React, { useEffect } from "react";
import { useBoolean } from "../../../hook/useBoolean";
import Div from "../../tags/element/Div";
import FlexRow from "../../tags/layout/flex/public/FlexRow";
import BlogSidebar from "./BlogSidebar";
import BlogSidebarMobile from "./BlogSidebarMobile";
import MiddleNavbar from "./MiddleNavbar";
import MobileNavbar from "./MobileNavbar";

export default function BlogLayout({
  children,
  onSave,
  OnWatch,
  hasChanges,
  isEditMode,
}) {
  const isSidebarVisible = useBoolean(true);
  const isSidebarMobileVisible = useBoolean(false);

  useEffect(() => {
    document.body.classList.add("bg-white");
    return () => document.body.classList.remove("bg-white");
  }, []);

  return (
    <FlexRow className="blog-render">
      <BlogSidebarMobile visiblityManager={isSidebarMobileVisible} />

      <Div
        className={
          "blog-render__sidebar " + (isSidebarVisible.value ? "" : "d-none")
        }
      >
        <Div className="h-full padding-1-em">
          <BlogSidebar visiblityManager={isSidebarVisible} />
        </Div>
      </Div>
      <Div className="blog-render__main">
        <MiddleNavbar
          hasChanges={hasChanges}
          isEditMode={isEditMode}
          OnWatch={OnWatch}
          onSave={onSave}
          sidebarVisiblityManager={isSidebarVisible}
        />
        <MobileNavbar
          hasChanges={hasChanges}
          isEditMode={isEditMode}
          OnWatch={OnWatch}
          onSave={onSave}
          sidebarVisiblityManager={isSidebarMobileVisible}
        />

        <Div className="blog-render__main__margin">
          <Div
            style={{ maxWidth: "800px" }}
            className="flex-grow-1 padding-1-em"
          >
            {children}
          </Div>
        </Div>
      </Div>
    </FlexRow>
  );
}
