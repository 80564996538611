import React from "react";
import Skeleton from "../Skeleton";
import Div from "../../tags/element/Div";
import FlexColumn from "../../tags/layout/flex/public/FlexColumn";

export default function ProductSkeleton() {
  return (
    <FlexColumn flexWrap="wrap" className="product">
      <Div className="product-img-container">
        <Skeleton className="product-img" />
      </Div>
      <Skeleton width={"100%"} height={10} className="product-title" />
      <Skeleton width={"80%"} height={10} className="product-price" />
    </FlexColumn>
  );
}
