import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  productId: null,
  topRate: {},
  productDetail: {},
};

const detailSlice = createSlice({
  name: "detail",
  initialState: initialState,
  reducers: {
    setProductId: (state, action) => {
      state.productId = action.payload;
    },
    setTopRate: (state, action) => {
      state.topRate = action.payload;
    },
    setProductDetail: (state, action) => {
      state.productDetail = action.payload;
    },
  },
});
export const { setProductId, setTopRate, setProductDetail } =
  detailSlice.actions;

export default detailSlice.reducer;
