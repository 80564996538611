import React from "react";
import CardRender from "../../../../components/public/card/body/CardRender";
import { useScrollTop } from "../../../../hook/useScroolTop";
import MaxWidth from "../../../../components/public/layout/MaxWidth";

export default function SellerProduct() {
  useScrollTop();

  return (
    <MaxWidth>
      <CardRender url={"/api/seller/products"} />
    </MaxWidth>
  );
}
