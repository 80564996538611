import React from "react";
import CustomModal from "../../../components/modal/CustomModal";
import ModalHeadWithMSGLink from "../../../components/modal/ModalHeadWithMSGLink";
import H2 from "../../../components/tags/element/H2";
import { useNavigate } from "react-router-dom";
import MapList from "../../../components/public/MapList";
import { OrderItem } from "../../../components/public/manager/admin/renderItem";
import Card from "../../../components/public/card";
import FlexColumn from "../../../components/tags/layout/flex/public/FlexColumn";
import useFetch from "../../../hook/useFetch";
import Loader from "../../../components/public/layout/Loader";
import ErrorMessageRetryButton from "../../../components/public/layout/ErrorMessageRetryButton";

const ModalContainer = ({ children }) => {
  const navigate = useNavigate();
  const handleGoback = () => navigate("/profile");
  return (
    <CustomModal
      onHid={handleGoback}
      head={
        <ModalHeadWithMSGLink
          onClick={handleGoback}
          title={<H2>Mes commandes</H2>}
          msg={"Fermer"}
        />
      }
      className={"user-order-modal-item orders"}
    >
      {children}
    </CustomModal>
  );
};

export default function UserOrder() {
  const getOrder = useFetch("/api/profile/orders");

  return (
    <ModalContainer>
      {getOrder.loading ? (
        <Loader />
      ) : getOrder.error ? (
        <ErrorMessageRetryButton onRetry={getOrder.fetchData} />
      ) : (
        <Card>
          <FlexColumn flexGap="1">
            <MapList
              data={getOrder.response}
              renderItem={(item) => <OrderItem profile item={item} />}
            />
          </FlexColumn>
        </Card>
      )}
    </ModalContainer>
  );
}
