import { React, useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "../src/components/tags/layout/flex/FlexContainer.css";
import AddBlog from "./components/public/blog/AddBlog";
import BlogDetails from "./components/public/blog/BlogDetails";
import BlogRenderer from "./components/public/blog/BlogRenderer";
import BlogSettings from "./components/public/blog/BlogSettings";
import UpdateBlog from "./components/public/blog/UpdateBlog";
import { useHomeCardsParse } from "./components/public/home/HomeCardSection";
import LoginRequired from "./components/public/LoginRequired";
import SellerLayout from "./components/public/manager/seller/SellerLayout";
import { app } from "./constant";
import { useAuthentication } from "./hook/useAuthentication";
import useAuthSync from "./hook/useAuthSync";
import { useLoadCard } from "./hook/useLoadCard";
import { useProfile } from "./hook/useProfile";
import { useVisitorInit } from "./hook/useVisitorInit";
import Admin from "./pages/manager/admin/Admin";
import AdminOrder from "./pages/manager/admin/AdminOrder";
import AdminOrderItem from "./pages/manager/admin/AdminOrderItem";
import AdminProductImage from "./pages/manager/admin/AdminProductImage";
import AdminShop from "./pages/manager/admin/AdminShop";
import AdminShopItem from "./pages/manager/admin/AdminShopItem";
import AdminUser from "./pages/manager/admin/AdminUser";
import AdminUserItem from "./pages/manager/admin/AdminUserItem";
import AdminVisitor from "./pages/manager/admin/AdminVisitor";
import AdminVisitorItem from "./pages/manager/admin/AdminVisitorItem";
import AdminVisitorSession from "./pages/manager/admin/AdminVisitorSession";
import AdminVisitorSessionItem from "./pages/manager/admin/AdminVisitorSessionItem";
import AdminVisitorSessionPage from "./pages/manager/admin/AdminVisitorSessionPage";
import AdminDashbord from "./pages/manager/admin/dashbord/AdminDashbord";
import SellerImage from "./pages/manager/seller/image/SellerImage";
import SellerOrder from "./pages/manager/seller/order/SellerOrder";
import SellerOrderItem from "./pages/manager/seller/order/SellerOrderItem";
import SellerAddProduct from "./pages/manager/seller/product/CrudProduct/SellerAddProduct";
import SellerUpdateProduct from "./pages/manager/seller/product/CrudProduct/SellerUpdateProduct";
import SellerProduct from "./pages/manager/seller/product/SellerProduct";
import SellerProfile from "./pages/manager/seller/profile/SellerProfile";
import PageContainer from "./pages/PageContainer";
import Login from "./pages/public/auth/Login";
import SignIn from "./pages/public/auth/SignIn";
import Cart from "./pages/public/cart/Cart";
import Category from "./pages/public/category/Category";
import { BuyNow, CartFinalize } from "./pages/public/checkout/Checkout";
import CheckoutSucces from "./pages/public/checkout/CheckoutSucces";
import ContactUs from "./pages/public/contact/ContactUs";
import Details from "./pages/public/details/Details";
import AllRateModal from "./pages/public/details/modal/AllRateModal";
import DescriptionModal from "./pages/public/details/modal/DescriptionModal";
import RateFormAdd, {
  RateFormUpdate,
} from "./pages/public/details/modal/RateFormModal";
import NotFound from "./pages/public/error/NotFound";
import Gaaw from "./pages/public/gaaw/Gaaw";
import Home from "./pages/public/home/Home";
import Main from "./pages/public/home/Main";
import TermOfUse from "./pages/public/policies/TermOfUse";
import EditAdresse from "./pages/public/profile/EditAdresse";
import EditBecomeSeller from "./pages/public/profile/EditBecomeSeller";
import EditBirthday from "./pages/public/profile/EditBirthday";
import EditEmail from "./pages/public/profile/EditEmail";
import EditGender from "./pages/public/profile/EditGender";
import EditName from "./pages/public/profile/EditName";
import EditPhoneOne from "./pages/public/profile/EditPhoneOne";
import EditPhoneTwo from "./pages/public/profile/EditPhoneTwo";
import Profile from "./pages/public/profile/Profile";
import UserOrder from "./pages/public/profile/UserOrder";
import UserOrderItem from "./pages/public/profile/UserOrderItem";
import Search from "./pages/public/search/Search";
import SearchQuery from "./pages/public/search/SearchQuery";
import SellerOverview from "./pages/public/SellerOverview";
import Tags from "./pages/public/tag/Tags";

import AdminCardItem from "./pages/manager/admin/AdminCardItem";
import AdminCardItemProduct from "./pages/manager/admin/AdminCardItemProduct";
import AdminCards from "./pages/manager/admin/AdminCards";
import AdminCardsAdd from "./pages/manager/admin/AdminCardsAdd";
import Community from "./pages/public/community/Community";
import PageAdd from "./pages/public/page/editor/PageAdd";
import PageCSS from "./pages/public/page/editor/PageCSS";
import PageEditor from "./pages/public/page/editor/PageEditor";
import PageHTML from "./pages/public/page/editor/PageHTML";
import PagePreview from "./pages/public/page/editor/PagePreview";
import PageSettings from "./pages/public/page/editor/PageSettings";
import Page from "./pages/public/page/Page";
// query.css le dernier à etre importer dans les style important
import "../src/styles/query.css";
import AdminCardItemSettings from "./pages/manager/admin/AdminCardItemSettings";
import ShopItem from "./pages/public/shop/ShopItem";

function App() {
  useLoadCard();
  useAuthSync();
  useAuthentication();
  useVisitorInit();
  useHomeCardsParse("/api/cards", { preventPath: "/" });
  const profile = useProfile(true);
  const isAuthenticated = useSelector((state) => state.isAuthenticated);
  useEffect(() => {
    isAuthenticated && profile.fetchData();
  }, [isAuthenticated]);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PageContainer />}>
          {/* Main */}
          <Route element={<Main />}>
            <Route path={app.url.home} element={<Home />}>
              <Route path={app.url.card} element={<Cart />} />
              {/*<Route path={app.url.auth} element={<Auth />} />*/}
              <Route path={app.url.search} element={<Search />} />
              <Route
                path={app.url.checkoutSucces}
                element={<CheckoutSucces />}
              />
            </Route>

            <Route path={"/seller/overview"} element={<SellerOverview />} />
            <Route path={"/blog"} element={<BlogRenderer />} />
            <Route path={"/blog/:id/:slug"} element={<BlogDetails />} />
            <Route path={"/p/*"} element={<Page />} />
            <Route path={app.url.community} element={<Community/>} />
            <Route path={"/shop/:name"} element={<ShopItem/>} />

            {/* Details */}
            <Route path={app.url.contactUs} element={<ContactUs />} />
            <Route path={app.url.details} element={<Details />}>
              <Route
                path={app.url.detailDescription}
                element={<DescriptionModal />}
              />
              <Route path={app.url.detailRates} element={<AllRateModal />} />
              <Route
                path={app.url.detailRateAdd}
                element={<LoginRequired component={<RateFormAdd />} />}
              />
              <Route
                path={app.url.detailRateUpdate}
                element={<LoginRequired component={<RateFormUpdate />} />}
              />
            </Route>
            {/* other */}
            <Route path="*" element={<NotFound />} />
            <Route path={app.url.termOfUse} element={<TermOfUse />} />
            <Route path={app.url.tag} element={<Tags />} />
            <Route path={app.url.category} element={<Category />} />
            <Route path={app.url.searchQuery} element={<SearchQuery />} />
            <Route path={app.url.cartFinalize} element={<CartFinalize />} />
            <Route path={app.url.buyNow} element={<BuyNow />} />
            <Route
              path={app.url.profile}
              element={<LoginRequired component={<Profile />} />}
            >
              <Route
                path={app.url.userOrder}
                element={<LoginRequired component={<UserOrder />} />}
              />
              <Route
                path={app.url.userOrderItem}
                element={<LoginRequired component={<UserOrderItem />} />}
              />
            </Route>
            <Route
              path={app.url.editName}
              element={<LoginRequired component={<EditName />} />}
            />
            <Route
              path={app.url.editBirthday}
              element={<LoginRequired component={<EditBirthday />} />}
            />
            <Route
              path={app.url.editAdresse}
              element={<LoginRequired component={<EditAdresse />} />}
            />
            <Route
              path={app.url.editGender}
              element={<LoginRequired component={<EditGender />} />}
            />
            <Route
              path={app.url.editPhoneOne}
              element={<LoginRequired component={<EditPhoneOne />} />}
            />
            <Route
              path={app.url.editPhoneTwo}
              element={<LoginRequired component={<EditPhoneTwo />} />}
            />
            <Route
              path={app.url.editEmail}
              element={<LoginRequired component={<EditEmail />} />}
            />
            <Route
              path={app.url.editBecomeSeller}
              element={<LoginRequired component={<EditBecomeSeller />} />}
            />
          </Route>

          <Route path={"/blog/add"} element={<AddBlog />} />
          <Route path={"/blog/edit/:id"} element={<UpdateBlog />} />
          <Route path={"/blog/edit/:id/settings"} element={<BlogSettings />} />

          <Route path="/page/editor" element={<PageEditor />}>
            <Route path="/page/editor/:id/html" element={<PageHTML />} />
            <Route path="/page/editor/:id/css" element={<PageCSS />} />
            <Route
              path="/page/editor/:id/settings"
              element={<PageSettings />}
            />
            <Route path="/page/editor/:id/preview" element={<PagePreview />} />
            <Route path="/page/editor/add" element={<PageAdd />} />
          </Route>

          {/* Auth */}
          <Route path={app.url.signIn} element={<SignIn />} />
          <Route path={app.url.login} element={<Login />} />
          {/* Seller */}
          <Route element={<SellerLayout />}>
            <Route
              path={app.url.sellerProfile}
              element={<LoginRequired component={<SellerProfile />} />}
            >
              <Route
                path={app.url.sellerProduct}
                element={<LoginRequired component={<SellerProduct />} />}
              />
              <Route
                path={app.url.sellerOrder}
                element={<LoginRequired component={<SellerOrder />} />}
              />
              <Route
                path={app.url.sellerImage}
                element={<LoginRequired component={<SellerImage />} />}
              />
            </Route>
            <Route
              path={app.url.sellerOrderItem}
              element={<LoginRequired component={<SellerOrderItem />} />}
            />
            <Route
              path={app.url.sellerProductAdd}
              element={<LoginRequired component={<SellerAddProduct />} />}
            />
            <Route
              path={app.url.sellerProductUpdate}
              element={<LoginRequired component={<SellerUpdateProduct />} />}
            />
          </Route>
          {/* Admin */}

          <Route
            path={app.url.admin}
            element={<LoginRequired component={<Admin />} />}
          >
            <Route
              path={app.url.adminDashbord}
              element={<LoginRequired component={<AdminDashbord />} />}
            />
            <Route
              path={app.url.adminVisitor}
              element={<LoginRequired component={<AdminVisitor />} />}
            />
            <Route
              path={app.url.adminVisitorItem}
              element={<LoginRequired component={<AdminVisitorItem />} />}
            />
            <Route
              path={app.url.adminVisitorSession}
              element={<LoginRequired component={<AdminVisitorSession />} />}
            />
            <Route
              path={app.url.adminVisitorSessionItem}
              element={
                <LoginRequired component={<AdminVisitorSessionItem />} />
              }
            >
              <Route
                path={app.url.adminVisitorSessionPage}
                element={
                  <LoginRequired component={<AdminVisitorSessionPage />} />
                }
              />
            </Route>
            <Route
              path={app.url.adminOrder}
              element={<LoginRequired component={<AdminOrder />} />}
            />
            <Route
              path={app.url.adminOrderItem}
              element={<LoginRequired component={<AdminOrderItem />} />}
            />
            <Route
              path={app.url.adminShopItem}
              element={<LoginRequired component={<AdminShopItem />} />}
            />
            <Route
              path={app.url.adminProductImage}
              element={<LoginRequired component={<AdminProductImage />} />}
            />

            <Route
              path={app.url.adminUser}
              element={<LoginRequired component={<AdminUser />} />}
            />
            <Route
              path={app.url.adminUserItem}
              element={<LoginRequired component={<AdminUserItem />} />}
            />
            <Route
              path={"/admin/cards"}
              element={<LoginRequired component={<AdminCards />} />}
            />
            <Route
              path={"/admin/cards/add"}
              element={<LoginRequired component={<AdminCardsAdd />} />}
            />
            <Route
              path={"/admin/cards/:id"}
              element={<LoginRequired component={<AdminCardItem />} />}
            >
              <Route path={"/admin/cards/:id/products"} element={<LoginRequired component={<AdminCardItemProduct />} />}/>

              <Route path={"/admin/cards/:id/settings"} element={<LoginRequired component={<AdminCardItemSettings />} />}/>

            </Route>
            <Route
              path={app.url.adminShop}
              element={<LoginRequired component={<AdminShop />} />}
            />
          </Route>

          {/* Admin */}
          <Route path={app.url.gaaw} element={<Gaaw />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
