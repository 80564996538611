import React from "react";
import { Link } from "react-router-dom";
import { app } from "../../constant";
import Div from "../tags/element/Div";

export default function AcceptTermOnContinue({ ...props }) {
  const text = "les conditions d'utilisation";
  return (
    <Div className="term-info">
      En continuant, vous acceptez{" "}
      <Link {...props} to={app.url.termOfUse}>
        {text}
      </Link>{" "}
      de Cicaw.
    </Div>
  );
}
