import { useEffect } from "react";
import useFetch from "./useFetch";

const updateMetaTags = (metaList) => {
  metaList.forEach((meta) => {
    const existingMeta = document.querySelector(
      `meta[${meta.key}="${meta.value}"]`,
    );
    if (existingMeta) {
      existingMeta.setAttribute("content", meta.content);
    } else {
      const metaEl = document.createElement("meta");
      metaEl.setAttribute(meta.key, meta.value);
      metaEl.setAttribute("content", meta.content);
      document.head.appendChild(metaEl);
    }
  });
};

const removeMetaTags = (metaList) => {
  metaList.forEach((meta) => {
    const existingMeta = document.querySelector(
      `meta[${meta.key}="${meta.value}"]`
    );
    if (existingMeta) {
      document.head.removeChild(existingMeta);
    }
  });
};

const removeJsonLdScript = () => {
  const existingJsonLd = document.querySelector(
    'script[type="application/ld+json"]'
  );
  if (existingJsonLd) {
    document.head.removeChild(existingJsonLd);
  }
}

const updateJsonLdScript = (jsonLdData) => {
  const existingJsonLd = document.querySelector(
    'script[type="application/ld+json"]',
  );
  if (existingJsonLd) {
    // Si une balise <script> JSON-LD existe déjà, mettre à jour son contenu
    existingJsonLd.textContent = JSON.stringify(jsonLdData);
  } else {
    // Sinon, créer une nouvelle balise <script> JSON-LD
    const scriptJsonLd = document.createElement("script");
    scriptJsonLd.type = "application/ld+json";
    scriptJsonLd.textContent = JSON.stringify(jsonLdData);
    document.head.appendChild(scriptJsonLd);
  }
};

export const useMetadata = (query = "", dependance = []) => {
  const metadata = useFetch(`/api/metadata?${query}`, dependance);

  useEffect(() => {
    if (metadata.response) {
      metadata.response.meta && updateMetaTags(metadata.response.meta);
      metadata.response.jsonLd && updateJsonLdScript(metadata.response.jsonLd);

      if (metadata.response.title) {
        document.title = metadata.response.title;
      }
    }

    return () => {
      if (metadata.response) {
        metadata.response.meta && removeMetaTags(metadata.response.meta);
        metadata.response.jsonLd && removeJsonLdScript();
      }
    }
  }, [metadata.response]);
};
