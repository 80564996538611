import React from "react";
import FlexRow from "../../../tags/layout/flex/public/FlexRow";
import FlexColumn from "../../../tags/layout/flex/public/FlexColumn";

import Skeleton from "../../Skeleton";

export default function LoadHeader() {
  return (
    <FlexRow alignItems="center" className="card-header padding-1em">
      <FlexColumn>
        <Skeleton height={20} width={150}></Skeleton>
        <Skeleton height={10} width={200} className="card-description" />
      </FlexColumn>
    </FlexRow>
  );
}
