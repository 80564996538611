import Editor from '@monaco-editor/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import usePageEditor from '../../../../hook/usePageEditor';

export default function PageHTML() {
  const { id } = useParams();
  const { getPageItem, handleEditorChange } = usePageEditor(id, 'html');

  if (getPageItem.loading || getPageItem.error) {
    return  "loading or error";
  }

  return (
    <Editor
      height="100%"
      defaultLanguage="html"
      defaultValue={getPageItem.response?.html}
      onChange={handleEditorChange}
      options={{
        fontSize: "18px",
      }}
    />
  )
}
