import React, { useEffect } from 'react'
import { useForm } from '../../../hook/useForm'
import { useParams } from 'react-router-dom'
import RequestManager from '../../../components/public/layout/RequestManager'
import Form from '../../../components/tags/element/Form'
import InputLitsRender from '../../../components/public/layout/InputLitsRender'
import FlexColumn from '../../../components/tags/layout/flex/public/FlexColumn'
import GradientButton from '../../../components/public/GradientButton'
import useDynamicComponent from '../../../hook/useDynamicComponent'
import Button from '../../../components/tags/element/Button'
import InputSelect from '../../../components/public/layout/InputSelect'
import Option from '../../../components/tags/element/Option'
import Separator from '../../../components/public/Separator'

export default function AdminCardItemSettings() {
  const { id } = useParams()
  const getCardForm = useForm("/api/admin/cards/"+id+"/settings", undefined, [id], false)
  const dynamicComponent = useDynamicComponent()

  useEffect(() => {
    if (getCardForm.response) {
      const seo = getCardForm.response.find((item) => item.name === "seo")?.value
      const products = getCardForm.response.find((item) => item.name === "products")?.value
      getCardForm.setFomProps({"seo": seo})
      getCardForm.setFomProps({"products": products})
    }
  }, [getCardForm.response])

  const handleSeoForm = () => {
    const seoId = getCardForm.response?.find((item) => item.name === "seo")?.value
    const title = getCardForm.response?.find((item) => item.name === "title")?.value

    if (seoId) {
      const props = {
        url: "/api/admin/seo/"+seoId+"/update",
        title: `Seo: ${title}`
      }
      dynamicComponent.activeComponent("dynamic-form", props) 
    }else {
      alert("pas de id il faut creer")
    }
  }

  
  useEffect(() => {
    if (getCardForm.formResponse && getCardForm.formResponse.is_valid) {
      getCardForm.fetchData({loading:true})
    }
  }, [getCardForm.formResponse])

  if (getCardForm.loading || getCardForm.error) {
    return <RequestManager request={getCardForm}/>
  }

  const defaultStatus =  getCardForm.response.find((item) => item.name === "status")?.value

  return (
    <>
    <Button onClick={handleSeoForm}>seo</Button>
    <Separator/>
    <Form method="POST" onSubmit={getCardForm.handleSubmit}>
      <FlexColumn flexGap="1">
        <InputLitsRender
          blockedInput={["seo", "status", "products"]}
          formData={getCardForm.formData}
          formDisabled={getCardForm.formDisabled}
        />
        <InputSelect name="status" value={defaultStatus}>
          <Option value="draft">En attente</Option>
          <Option value="published">Publier</Option>
        </InputSelect>

        <GradientButton disabled={getCardForm.formDisabled} type={"button"}>Continuer</GradientButton>
      </FlexColumn>
    </Form>
    </>
  )
}
