import React from "react";
import Tags from "..";

export default function P({ children, ...props }) {
  return (
    <Tags tag="p" {...props}>
      {children}
    </Tags>
  );
}
