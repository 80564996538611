import React from "react";
import { Outlet } from "react-router-dom";
import ShowAfterNavbar from "../../../components/public/layout/ShowAfterNavbar";
import Navbar from "../../../components/public/navbar/Navbar";
import Sidebar from "../../../components/public/sidebar/Sidebar";

export default function Main() {
  return (
    <>
      <Navbar />
      <Sidebar />
      <ShowAfterNavbar>
        <Outlet />
      </ShowAfterNavbar>
    </>
  );
}
