import { useEffect } from "react";
import { useSelector } from "react-redux";
import { app, titleSyntax } from "../constant";
import { setTitle } from "./useTitle";

const useLoginRequired = () => {
  const isAuthenticated = useSelector((state) => state.app.isAuthenticated);

  useEffect(() => {
    if (!isAuthenticated) {
      return setTitle(titleSyntax(app.page.auth.text.title));
    }
  }, [isAuthenticated]);
  return { isAuthenticated };
};

export default useLoginRequired;
