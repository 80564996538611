import React, { useEffect, useState } from "react";
import Div from "../../tags/element/Div";
import Span from "../../tags/element/Span";

export default function BottomToast({ item }) {
  const [transitionClass, setTransitionClass] = useState("hidden");

  useEffect(() => {
    setTransitionClass("hidden");
    let timeout = null;
    if (item) {
      timeout = setTimeout(() => {
        setTransitionClass("");
      }, 300);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [item]);

  return (
    <Div className="bottom-toast-container">
      {item && (
        <Div className={"bottom-toast " + transitionClass}>
          <Span>{transitionClass !== "hidden" && item.content}</Span>
        </Div>
      )}
    </Div>
  );
}
