import React from 'react'
import Card from '../../../../components/public/card'
import SimpleHeader from '../../../../components/public/card/header/SimpleHeader'
import Form from '../../../../components/tags/element/Form'
import { useForm } from '../../../../hook/useForm'
import InputLitsRender from '../../../../components/public/layout/InputLitsRender'
import GradientButton from '../../../../components/public/GradientButton'
import FlexColumn from '../../../../components/tags/layout/flex/public/FlexColumn'

export default function PageAdd() {
  const addPageForm = useForm("/api/page/editor/add")
  if (addPageForm.loading || addPageForm.error) return "chargement..."

  return (
    <Card 
      header={<SimpleHeader 
        title={"Ajouter une nouvelle page"} 
        description={"Remplissez le formulaire ci-dessous pour créer une nouvelle page."} 
      />}
    >

      <Form onSubmit={addPageForm.handleSubmit}>
        <FlexColumn flexGap="1">
          <InputLitsRender formData={addPageForm.formData} formDisabled={addPageForm.formDisabled}/>
          <GradientButton disabled={addPageForm.formDisabled} type={"button"}>Continuer</GradientButton>
        </FlexColumn>
      </Form>
    </Card>
  )
}
