import React from "react";
import Div from "../../../tags/element/Div";
import LoadHeader from "../header/LoadHeader";
import ProductSkeleton from "../../product/ProductSkeleton";
import MapList from "../../MapList";

export function CardProductLoading({ skeletonNumber }) {
  const renderProductSkeleton = () => <ProductSkeleton />;
  const skeletonIndexes = Array.from(
    { length: skeletonNumber || 9 },
    (v, i) => i,
  );
  return (
    <Div className="slider-children-container-grid card-loading">
      <MapList data={skeletonIndexes} renderItem={renderProductSkeleton} />
    </Div>
  );
}

export default function CardLoading({
  skeletonNumber,
  renderNumber = 1,
  ...props
}) {
  const renderIndexes = Array.from({ length: renderNumber || 1 }, (v, i) => i);
  const renderCard = () => (
    <Div {...props} className="card">
      <Div className="card__bottom">
        <LoadHeader />
        <CardProductLoading skeletonNumber={skeletonNumber} />
      </Div>
    </Div>
  );

  const renderCardList = () => (
    <MapList data={renderIndexes} renderItem={renderCard} />
  );

  return <>{renderCardList()}</>;
}
