import React from "react";
import { Link } from "react-router-dom";
import { concatenateClass } from "../../../tools/utils";
import H2 from "../../tags/element/H2";
import Li from "../../tags/element/Li";

export default function SidebarLinkItem({ to, children, className, type = "link", ...props }) {
  return (
    <Li className={concatenateClass(["sidebar__li-item", className])}>
      {type === "link" ? (
        <Link to={to} {...props}>
          {children}
        </Link>
      ) : (
        <H2>{children}</H2>
      )}
    </Li>
  );
}
