import React from "react";
import MapList from "../../../../components/public/MapList";
import Skeleton from "../../../../components/public/Skeleton";
import Card from "../../../../components/public/card";
import SimpleHeader from "../../../../components/public/card/header/SimpleHeader";
import MaxWidth from "../../../../components/public/layout/MaxWidth";
import { OrderItem } from "../../../../components/public/manager/admin/renderItem";
import FlexColumn from "../../../../components/tags/layout/flex/public/FlexColumn";
import useFetch from "../../../../hook/useFetch";

const LoaderOrder = () => {
  return (
    <FlexColumn
      flexGap="1"
      className="padding-1-em"
      style={{ background: "white", height: "200px" }}
    >
      <Skeleton height={20} width={100} />
      <Skeleton height={40} width={300} />
    </FlexColumn>
  );
};

export default function SellerOrder() {
  const getOrders = useFetch("/api/orders");
  if (getOrders.loading || getOrders.error) return <LoaderOrder />;

  const renderItem = (item) => <OrderItem item={item} />;

  return (
    <MaxWidth>
      <Card
        className={"seller-image"}
        header={
          <SimpleHeader
            title={`Mes Commandes`}
            description={"Voici la liste de vos Commandes."}
          />
        }
      >
        <FlexColumn flexGap="1">
          <MapList data={getOrders.response} renderItem={renderItem} />
        </FlexColumn>
      </Card>
    </MaxWidth>
  );
}
