/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Separator from "../../../components/public/Separator";
import InputSelect from "../../../components/public/layout/InputSelect";
import RequestManager from "../../../components/public/layout/RequestManager";
import ProfileEdit from "../../../components/public/profile/ProfileEdit";
import Option from "../../../components/tags/element/Option";
import P from "../../../components/tags/element/P";
import { toApiEditGender } from "../../../constant";
import useProfileField from "../../../hook/useProfileField";

export default function EditGender() {
  const { getProfile, postError, isDisabled, handleSubmit } =
    useProfileField(toApiEditGender());

  if (getProfile.loading || getProfile.error)
    return <RequestManager request={getProfile} />;

  return (
    <ProfileEdit
      title={"Modifier votre sexe"}
      description={
        "Modifiez votre sexe pour une expérience de shopping plus personnalisée et des recommandations de produits adaptées à vos préférences."
      }
      onSubmit={handleSubmit}
      isDisabled={isDisabled}
    >
      <InputSelect name="gender" value={getProfile.response.value}>
        <Option value="Homme">Homme</Option>
        <Option value="Femme">Femme</Option>
      </InputSelect>
      <Separator />
      {postError && (
        <P className="incorrect-info">
          Une erreur s'est produite veuillez réessayer
        </P>
      )}
    </ProfileEdit>
  );
}
