/* eslint-disable react/no-unescaped-entities */
import React from "react";
import InputLitsRender from "../../../components/public/layout/InputLitsRender";
import RequestManager from "../../../components/public/layout/RequestManager";
import ProfileEdit from "../../../components/public/profile/ProfileEdit";
import P from "../../../components/tags/element/P";
import FlexRow from "../../../components/tags/layout/flex/public/FlexRow";
import { toApiEditAddress } from "../../../constant";
import { useForm } from "../../../hook/useForm";

export default function EditAdresse() {
  const editForm = useForm(toApiEditAddress());

  if (editForm.loading || editForm.error)
    return <RequestManager request={editForm} />;

  return (
    <ProfileEdit
      title={"Modifier vos adresses de livraison"}
      description={
        "Ajoutez, modifiez ou supprimez vos adresses de livraison pour recevoir vos commandes."
      }
      onSubmit={editForm.handleSubmit}
      isDisabled={editForm.formDisabled}
    >
      <FlexRow flexGap="1" flexWrap="wrap">
        <InputLitsRender
          formData={editForm.formData}
          formDisabled={editForm.formDisabled}
        />
        {editForm.FormError && (
          <P className="incorrect-info">
            Une erreur s'est produite veuillez réessayer
          </P>
        )}
      </FlexRow>
    </ProfileEdit>
  );
}
