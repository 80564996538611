import React, { useEffect, useRef, useState } from "react";
import Div from "../../tags/element/Div";
import Label from "../../tags/element/Label";
import Span from "../../tags/element/Span";
import FlexColumn from "../../tags/layout/flex/public/FlexColumn";

// eslint-disable-next-line no-unused-vars
const InputGroup = React.forwardRef(
  (
    {
      onChange,
      id,
      name,
      className,
      placeholder,
      requiredMsg,
      value,
      unique = false,
      errorList = [],
      ...props
    },
    // eslint-disable-next-line no-unused-vars
    ref
  ) => {
    const [isActive, setIsActive] = useState(false);
    const [isFocus, setIsFocus] = useState(false);
    const [valueState, setValueState] = useState(value);
    const [showRequiredMsg, setShowRequiredMsg] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
      if (valueState || unique) {
        setIsActive(true);
      }
    }, [valueState]);

    const handleFocus = () => {
      setShowRequiredMsg(false);
      setIsFocus(true);
      setIsActive(true);
    };

    const handleBlur = () => {
      setIsFocus(false);
      if (!valueState) {
        setIsActive(false);
        requiredMsg && setShowRequiredMsg(true);
      }
    };

    const handleChange = (e) => {
      setValueState(e.target.value);
      onChange && onChange(e);
    };

    const Type = props.type === "textarea" ? "textarea" : "input";
    const disable_class = props.disabled ? "disabled" : "";
    const error_class = showRequiredMsg ? "error" : "";
    return (
      <FlexColumn className="input-group__container">
        <Div
          onClick={() => {
            inputRef.current && inputRef.current.focus();
          }}
          className={
            (isActive ? "input-group focus-or-hasValue" : "input-group") +
            " " +
            disable_class +
            (isFocus ? " focused" : "") +
            " " +
            error_class
          }
        >
          <Label
            className={"input-group__label" + props.type ? props.type : ""}
            htmlFor={id}
          >
            {placeholder}
          </Label>
          <Type
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            ref={inputRef}
            className={"default-input login " + className}
            id={id}
            name={name}
            value={(unique ? value : valueState) || ""}
            {...props}
          />
        </Div>
        {showRequiredMsg && requiredMsg && (
          <Div className="error-msg">
            <Span>{requiredMsg}</Span>
          </Div>
        )}
        {errorList.map((error, index) => (
          <Div key={index} className="error-msg">
            <Span>{error}</Span>
          </Div>
        ))}
      </FlexColumn>
    );
  },
);

InputGroup.displayName = "InputGroup";

export default InputGroup;
