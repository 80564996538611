import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { app, socialNetworkLink, toCategory } from '../../../constant';
import { useProfile } from '../../../hook/useProfile';
import Div from '../../tags/element/Div';
import Li from '../../tags/element/Li';
import P from '../../tags/element/P';
import Ul from '../../tags/element/Ul';
import FlexColumn from '../../tags/layout/flex/public/FlexColumn';
import FlexRow from '../../tags/layout/flex/public/FlexRow';
import Facebook from '../ico/Facebook';
import Instagram from '../ico/Instagram';
import TwitterX from '../ico/TwitterX';
import Youtube from '../ico/Youtube';
import Tiktok from '../layout/Tiktok';
import MapList from '../MapList';
import CategoryLoading from './CategoryLoading';
import SidebarLinkItem from './SidebarLinkItem';
import SidebarProfileCard from './SidebarProfileCard';


function SidebarSeparator() {
  return <Li className="sidebar__li-separator"></Li>;
}


function AuthLinks() {
  return (
    <FlexRow className="sidebar-sign-btn-list">
      <Link to={app.url.login}>Connexion</Link>
      <Link to={app.url.signIn}>{"S'inscrire"}</Link>
    </FlexRow>
  );
}

function CategorySection({ home }) {
  return (
    <Ul>
      <SidebarSeparator />
      <SidebarLinkItem type="h2">Catégorie</SidebarLinkItem>
      {home.content.cards.length === 0 ? (
        <CategoryLoading />
      ) : (
        <MapList
          data={home.content.cards}
          renderItem={(item) => (
            <SidebarLinkItem to={toCategory(item.id, item.title)}>
              {item.title}
            </SidebarLinkItem>
          )}
        />
      )}
      <SidebarSeparator />
    </Ul>
  );
}


function PageLinks({ profile, handleFeedback }) {
  return (
    <Ul>
      <SidebarLinkItem type="h2">Page</SidebarLinkItem>
      <SidebarLinkItem to={app.url.home}>Accueil</SidebarLinkItem>
      <SidebarLinkItem to={app.url.card}>Panier</SidebarLinkItem>
      <SidebarLinkItem to={app.url.profile}>Profil</SidebarLinkItem>
      <SidebarLinkItem to={app.url.search}>Recherche</SidebarLinkItem>
      {profile.profile.userInfo?.is_seller ? (
        <SidebarLinkItem to={app.url.sellerProduct}>Espace Vendeur</SidebarLinkItem>
      ) : (
        <SidebarLinkItem to={"/seller/overview"}>Devenir vendeur</SidebarLinkItem>
      )}
      <SidebarLinkItem to={"/blog"}>Blog</SidebarLinkItem>
      <SidebarSeparator />
      <SidebarLinkItem type="h2">À propos</SidebarLinkItem>
      <SidebarLinkItem to={app.url.termOfUse}>{"Conditions d'utilisation"}</SidebarLinkItem>
      <SidebarLinkItem to={app.url.contactUs}>Contactez-nous</SidebarLinkItem>
      <SidebarLinkItem to={"#"} onClick={handleFeedback}>Aide et commentaires</SidebarLinkItem>
      <SidebarSeparator />
    </Ul>
  );
}

function SocialLinks() {
  return (
    <FlexRow justifyContent="center" flexGap="1" className="padding-1-em">
      <Link target="_blank" to={socialNetworkLink.tiktok}>
        <Tiktok width={22} />
      </Link>
      <Link target="_blank" to={socialNetworkLink.instagram}>
        <Instagram width={22} />
      </Link>
      <Link target="_blank" to={socialNetworkLink.facebook}>
        <Facebook fill={false} width={22} />
      </Link>
      <Link target="_blank" to={socialNetworkLink.youtube}>
        <Youtube width={22} />
      </Link>
      <Link target="_blank" to={socialNetworkLink.x}>
        <TwitterX width={22} />
      </Link>
    </FlexRow>
  );
}

function ContactInfo() {
  return (
    <FlexColumn justifyContent="center" flexWrap="wrap" className="sidebar__footer-info">
      <Link to={app.url.termOfUse}>{"Conditions d'utilisation"}</Link>
      <Link to={"tel:772849433"}>Numéro de téléphone</Link>
      <Link to={"mailto:cicaw.com@gmail.com"}>Adresse email</Link>
    </FlexColumn>
  );
}

function Footer({ currentYear }) {
  return (
    <Div className="sidebar__copyright">
      <P>&copy; {currentYear} CICAW. Tous droits réservés.</P>
    </Div>
  );
}


export default function SidebarBody({handleFeedback}) {
  const home = useSelector((state) => state.home);
  const currentYear = new Date().getFullYear();
  const isAuthenticated = useSelector((state) => state.app.isAuthenticated);
  const profile = useProfile()

  return (
    <Div style={{ padding: "1em 0 2em 0" }} className="sidebar">
      {!isAuthenticated ? (
        <AuthLinks />
      ) : (
        <SidebarProfileCard />
      )}

      <CategorySection home={home} />
      <PageLinks profile={profile} handleFeedback={handleFeedback} />
      <SocialLinks />
      <ContactInfo />
      <Footer currentYear={currentYear} />
    </Div>
  );
}
