import React from "react";
import UserAvatar from "../../../components/public/layout/UserAvatar";
import Div from "../../../components/tags/element/Div";
import P from "../../../components/tags/element/P";
import Span from "../../../components/tags/element/Span";
import FlexColumn from "../../../components/tags/layout/flex/public/FlexColumn";
import FlexRow from "../../../components/tags/layout/flex/public/FlexRow";
import StarRateList from "./StarRateList";

export default function DetailsComment({
  comment,
  username,
  date,
  star,
  colorCode,
}) {
  return (
    <FlexColumn>
      <FlexRow alignItems="center" justifyContent="between">
        <FlexRow alignItems="center" flexGap="1">
          <FlexColumn>
            <UserAvatar size={22} color={colorCode} label={username}/>
          </FlexColumn>
          <Div className="details-comment-username">
            <FlexColumn>
              <Span>{username}</Span>
              <FlexRow flexGap="1" alignItems="center">
                <FlexRow>
                  <StarRateList info={true} rateValue={star} />
                </FlexRow>
                <Div className="comment-date">{date}</Div>
              </FlexRow>
            </FlexColumn>
          </Div>
        </FlexRow>
      </FlexRow>
      <P className="details-comment">{comment}</P>
    </FlexColumn>
  );
}
