import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomModal from "../../../components/modal/CustomModal";
import Card from "../../../components/public/card";
import SimpleHeader from "../../../components/public/card/header/SimpleHeader";
import ErrorMessageRetryButton from "../../../components/public/layout/ErrorMessageRetryButton";
import Loader from "../../../components/public/layout/Loader";
import Div from "../../../components/tags/element/Div";
import H3 from "../../../components/tags/element/H3";
import FlexRow from "../../../components/tags/layout/flex/public/FlexRow";
import useFetch from "../../../hook/useFetch";
import FlexColumn from "../../../components/tags/layout/flex/public/FlexColumn";
import H2 from "../../../components/tags/element/H2";
import MapList from "../../../components/public/MapList";

export default function AdminVisitorSessionPage() {
  const { visitorId, sessionId, pageId } = useParams();
  const navigate = useNavigate();
  const getPageItem = useFetch(`/api/admin/visitor/session/page/${pageId}`);
  const sessionPage = `/admin/visitor/${visitorId}/session/${sessionId}`;
  const goToSessionPage = () => {
    navigate(sessionPage);
  };
  return (
    <CustomModal
      onHid={goToSessionPage}
      to={sessionPage}
      title={"Page - " + pageId}
    >
      <Div className="padding-1-em">
        {getPageItem.loading ? (
          <Loader />
        ) : getPageItem.error ? (
          <ErrorMessageRetryButton onRetry={getPageItem.fetchData} />
        ) : (
          <>
            <Card
              header={<SimpleHeader h={H2} title={"Information générale"} />}
            >
              <FlexRow flexWrap="wrap" flexGap="1">
                <Card header={<SimpleHeader h={H3} title={"url"} />}>
                  <FlexColumn>
                    <Div>{getPageItem.response.url}</Div>
                  </FlexColumn>
                </Card>
                <Card header={<SimpleHeader h={H3} title={"Date d'ajout"} />}>
                  <FlexColumn>
                    <Div>{getPageItem.response.added_date}</Div>
                  </FlexColumn>
                </Card>
                <Card
                  header={
                    <SimpleHeader h={H3} title={"Derniere modification"} />
                  }
                >
                  <FlexColumn>
                    <Div>{getPageItem.response.last_update}</Div>
                  </FlexColumn>
                </Card>
                <Card header={<SimpleHeader h={H3} title={"Temps passé"} />}>
                  <FlexColumn>
                    <Div>{getPageItem.response.timestamp} (s)</Div>
                  </FlexColumn>
                </Card>
              </FlexRow>
            </Card>
            <Card header={<SimpleHeader h={H2} title={"Action"} />}>
              <MapList
                data={getPageItem.response.actions}
                renderItem={(item) => {
                  return (
                    <Card header={<SimpleHeader h={H3} title={item.key} />}>
                      <FlexColumn>
                        <Div>{item.value}</Div>
                        <Div className="admin-order-data-item">
                          <Div style={{ marginTop: ".2em" }}>
                            {item.added_date}
                          </Div>
                        </Div>
                      </FlexColumn>
                    </Card>
                  );
                }}
              />
            </Card>
          </>
        )}
      </Div>
    </CustomModal>
  );
}
