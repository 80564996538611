import { useEffect } from "react";
import { useSelector } from "react-redux";
import { toastData } from "../../../constant";
import { useClickLimit } from "../../useClickLimit";
import useDynamicComponent from "../../useDynamicComponent";
import useToastManager from "../../useToastManager";
import { useVibrate } from "../../useVibrate";
import { useSendSubscribe } from "./useSendSubscribe";
import { useSubscribeStatus } from "./useSubscribeStatus";
import { useSubscriptionData } from "./useSubscriptionData";
import { useLocation } from "react-router-dom";

export const useSubscribeToSeller = (url) => {
  const isAuthenticated = useSelector((state) => state.app.isAuthenticated);
  const vibrate = useVibrate();
  const { handleClick, blockClick } = useClickLimit(10);
  const location = useLocation()
  const dynamicComponent = useDynamicComponent()
  const toastManager = useToastManager();
  const { 
    isSubscribed,
    followerCount,
    setFollowerCount,
    followerText,
    setFollowerText
  } = useSubscribeStatus();
  const getData = useSubscriptionData(url);
  const {
    subscribeSend,
    subscribeResponse,
    subscribeError,
    prevFollowerCount,
    setPrevFollowerCount
  } = useSendSubscribe(url);

  useEffect(() => {
    if (getData.response) {
      isSubscribed.set(getData.response.is_followed);
      setFollowerCount(getData.response.follower_count);
      setPrevFollowerCount(getData.response.follower_count);
      setFollowerText(getData.response.follower_text);
    }
  }, [getData.response]);

  useEffect(() => {
    if (subscribeError) {
      isSubscribed.toggle();
      setFollowerCount(prevFollowerCount);
      toastManager.addItem(toastData.subscribeError());
    }
  }, [subscribeError]);

  useEffect(() => {
    if (subscribeResponse) {
      isSubscribed.set(subscribeResponse.is_followed);
      setFollowerCount(subscribeResponse.follower_count);
      setFollowerText(subscribeResponse.follower_text);
      if (subscribeResponse.is_followed) {
        toastManager.addItem(toastData.subscribeSuccess());
      } else {
        toastManager.addItem(toastData.unsubscribeSuccess());
      }
    }
  }, [subscribeResponse]);


  const handleSubscribe = async () => {
    vibrate();
    if (blockClick) return;

    if (isAuthenticated) {
      if (!handleClick()) {
        toastManager.addItem({
          content: "Trop de clics. Veuillez réessayer plus tard.",
          timeout: toastData.TOAST_DURATION_LONG,
          id: "click-block",
        });
        return;
      }

      const wasSubscribed = isSubscribed.value;
      isSubscribed.toggle();
      if (wasSubscribed) {
        setFollowerCount((prevState) => prevState - 1);
      } else {
        setFollowerCount((prevState) => prevState + 1);
      }
      await subscribeSend();
    } else {
      dynamicComponent.activeComponent("login-required-modal", {pathname:location.pathname})
    }
  };

  return {
    getData,
    handleSubscribe,
    isSubscribed,
    followerCount,
    followerText,
  };
};
