import React from 'react'
import GradientButton from '../../../components/public/GradientButton'
import InputLitsRender from '../../../components/public/layout/InputLitsRender'
import RequestManager from '../../../components/public/layout/RequestManager'
import H1 from '../../../components/tags/element/H1'
import FlexColumn from '../../../components/tags/layout/flex/public/FlexColumn'
import { useForm } from '../../../hook/useForm'
import Form from '../../../components/tags/element/Form'
import Separator from '../../../components/public/Separator'

export default function AdminCardsAdd() {
  const addCardForm = useForm("/api/admin/cards/add")

  if (addCardForm.loading || addCardForm.error) return <RequestManager request={addCardForm}/>

  return (
    <FlexColumn alignItems="center">
      <Form onSubmit={addCardForm.handleSubmit} method='post' style={{maxWidth:"500px", width:"100%"}}>
        <FlexColumn alignItems="center">
          <H1>Ajouter une carte</H1>
          <p style={{textAlign:"center"}}>Utilisez le formulaire ci-dessous pour ajouter une nouvelle carte avec les informations pertinentes et les détails requis.</p>
        </FlexColumn>
        <Separator/>
        <FlexColumn flexGap="1">
          <InputLitsRender formData={addCardForm.formData} formDisabled={addCardForm.formDisabled} />
          <GradientButton type={"button"}>Continuer</GradientButton>
        </FlexColumn>
      </Form>
    </FlexColumn>
  )
}

