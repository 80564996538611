import React, { useEffect } from "react";
import Card from "..";
import { toCategory } from "../../../../constant";
import { useBoolean } from "../../../../hook/useBoolean";
import useFetch from "../../../../hook/useFetch";
import Div from "../../../tags/element/Div";
import H2 from "../../../tags/element/H2";
import MapList from "../../MapList";
import Separator from "../../Separator";
import Slider from "../../layout/Slider";
import Product from "../../product";
import DefaultHeader from "../header/DefaultHeader";
import GoBackHeader from "../header/GoBackHeader";
import SimpleHeader from "../header/SimpleHeader";
import CardFilterSection from "./CardFilterSection";
import CardLoading, { CardProductLoading } from "./CardLoading";

// Fonction pour obtenir la valeur "to" en fonction du type de lien
function getToValue(card) {
  if (card.to === "category") {
    return toCategory(card.id, card.title);
  } else if (card.to === "back") {
    return "..";
  }
  return null;
}

// Fonction pour obtenir le composant d'en-tête en fonction du type de carte
function getHeaderComponent(card) {
  if (card.header === "DefaultHeader") {
    return DefaultHeader;
  } else if (card.header === "GoBackHeader") {
    return GoBackHeader;
  }
  return SimpleHeader;
}

// Composant pour afficher l'en-tête de la carte
function GetCardHeader({ card, h }) {
  const Header = getHeaderComponent(card);
  const to = getToValue(card);
  return (
    <Header h={h} title={card.title} description={card.description} to={to} />
  );
}

// Composant pour afficher les produits de la carte dans un Slider ou une grille
export function CardProductRender({ card }) {
  const renderItem = (product) => <Product key={product.id} data={product} />;
  return card.isSlider === true || card.isSlider === undefined ? (
    <Slider className={`card__product__render-slider position-relative`}>
      <MapList data={card.products} renderItem={renderItem} />
    </Slider>
  ) : card.products.length >= 1 ? (
    <Div className="slider-children-container-grid s-c-c-g ">
      <MapList data={card.products} renderItem={renderItem} />
    </Div>
  ) : (
    <H2 className="empty-product-in-card">Aucun résultat trouvé</H2>
  );
}

const useParse = (url, dependance) => {
  const cards = useFetch(url, dependance);
  return {
    request: cards,
    loading: cards.loading,
    error: cards.error,
    response: cards.response,
    loadingCondition: cards.error || cards.loading,
  };
};
// Composant pour rendre les cartes
const CardRender = ({
  url,
  loader,
  skeletonNumber,
  renderNumber,
  h = H2,
  parser,
  dependance = [],
  filter = false,
  header,
  onResponse
}) => {
  //const cards = useFetch(url, [], autoSend);
  const parse = parser ? parser(url) : useParse(url, dependance);
  const isFilterFetchLoading = useBoolean(false);

  useEffect(() => {
    if (parse.response) {
      onResponse && onResponse(parse.response)
      isFilterFetchLoading.toFalse();
    }
  }, [parse.response]);

  // Si les cartes sont en cours de chargement ou s'il y a une erreur, affiche un loader ou un composant de chargement de carte
  if (parse.loadingCondition)
    return loader ? (
      loader
    ) : (
      <CardLoading
        renderNumber={renderNumber}
        skeletonNumber={skeletonNumber}
      />
    );

  const handleFilter = (data) => {
    isFilterFetchLoading.toTrue();
    var newUrl = new URL(url, window.location.origin);

    if (data?.min && !newUrl.searchParams.has("minPrice")) {
      newUrl.searchParams.append("minPrice", data.min);
    }

    if (data?.max && !newUrl.searchParams.has("maxPrice")) {
      newUrl.searchParams.append(
        "maxPrice",
        data.max === Infinity ? "infinity" : data.max,
      );
    }
    var relativeUrl = newUrl.pathname + newUrl.search;

    parse.request.fetchData({
      newUrl: relativeUrl,
      loading: false,
      response: parse.response,
    });
  };

  // Fonction pour rendre une carte
  const renderItem = (card) => (
    <Card header={header ? header : <GetCardHeader h={h} card={card} />}>
      {filter && <CardFilterSection onSubmit={handleFilter} />}
      {filter && <Separator />}

      {isFilterFetchLoading.value ? (
        <CardProductLoading skeletonNumber={skeletonNumber} />
      ) : (
        <CardProductRender card={card} />
      )}
    </Card>
  );

  // Affiche la liste de cartes rendues
  return <MapList data={parse.response} renderItem={renderItem} />;
};

export default CardRender;
