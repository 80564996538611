import { useEffect } from "react";
import { toastData } from "../constant";
import useFetch from "./useFetch";
import usePost from "./usePost";
import useToastManager from "./useToastManager";

export default function usePageEditor(id, type) {
  const getPageItem = useFetch(`/api/page/editor/${id}`, [id]);
  const [send, , , error] = usePost(`/api/page/editor/${id}/${type}/update`);
  const toastManager = useToastManager();

  useEffect(() => {
    if (error) {
      toastManager.addItem(toastData.PageEditorSaveError());
    }
  }, [error]);

  const handleEditorChange = async (value) => {
    await send({ [type]: value });
  };

  return {
    getPageItem,
    handleEditorChange,
  };
}
