import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toastData } from "../../../constant";
import useAutoResizeAndFocus from "../../../hook/useAutoResizeAndFocus";
import useBeforeUnload from "../../../hook/useBeforeUnload";
import { useBoolean } from "../../../hook/useBoolean";
import { useForm } from "../../../hook/useForm";
import useToastManager from "../../../hook/useToastManager";
import NotFound from "../../../pages/public/error/NotFound";
import H1 from "../../tags/element/H1";
import FlexColumn from "../../tags/layout/flex/public/FlexColumn";
import FlexRow from "../../tags/layout/flex/public/FlexRow";
import Skeleton from "../Skeleton";
import BlogLayout from "./BlogLayout";
import BlogTextarea from "./BlogTextarea";
import RenderMarkdown from "./RenderMarkdown";
import { useScrollTop } from "../../../hook/useScroolTop";

export default function UpdateBlog() {
  const { id } = useParams();
  useScrollTop()
  const [titleValue, setTitleValue] = useState("");
  const textareaManager = useAutoResizeAndFocus("", { adjustDependance: [id] });
  const [newContent, setNewContent] = useState(textareaManager.state);
  const hasChanges = newContent === textareaManager.state;
  const isEditMode = useBoolean(false);
  const blogContentForm = useForm(`/api/blog/update/${id}`, undefined, [id], false);
  const submitButtonRef = useRef(null);
  const toastManager = useToastManager();
  const navigate = useNavigate();
  useBeforeUnload(hasChanges);

  useEffect(() => {
    isEditMode.toFalse();
  }, [navigate]);

  const handleSubmit = () => {
    if (!hasChanges) {
      submitButtonRef.current && submitButtonRef.current.click();
    }
  };
  useEffect(() => {
    setNewContent("");
    textareaManager.setState("");
  }, [id]);

  const handleChange = (e) => {
    textareaManager.handleChange(e);
  };

  useEffect(() => {
    if (blogContentForm.formResponse) {
      const newContent = blogContentForm.formResponse.data.find(
        (item) => item.name === "content",
      ).value;
      setNewContent(newContent);
      toastManager.addItem(toastData.blogSaveSuccess());
    }
  }, [blogContentForm.formResponse]);

  useEffect(() => {
    blogContentForm.setFomProps({ title: titleValue });
  }, [titleValue]);

  useEffect(() => {
    if (blogContentForm.response) {
      const content =
        blogContentForm.response.find((item) => item.name === "content")
          ?.value || "";
      textareaManager.setState(content);
      setNewContent(content);
    }
  }, [blogContentForm.response]);

  useEffect(() => {
    if (blogContentForm.response) {
      const title =
        blogContentForm.response.find((item) => item.name === "title")?.value ||
        "";
      setTitleValue(title);
    }
  }, [blogContentForm.response]);

  if (blogContentForm.error) {
    return <NotFound/>
  }
  if (blogContentForm.loading) {
    return 
  }
  return (
    <BlogLayout
      onSave={handleSubmit}
      OnWatch={() => isEditMode.toggle()}
      hasChanges={!hasChanges}
      isEditMode={isEditMode.value}
    >
      {blogContentForm.loading || blogContentForm.error ? (
        <FlexColumn flexGap="1">
          <Skeleton
            style={{ maxWidth: "400px", width: "100%", height: "20px" }}
          />
          <Skeleton
            style={{ maxWidth: "200px", width: "100%", height: "15px" }}
          />
        </FlexColumn>
      ) : (
        <>
          <FlexRow></FlexRow>
          <H1>{titleValue}</H1>
          {isEditMode.value ? (
            <form method="POST" onSubmit={blogContentForm.handleSubmit}>
              <button
                ref={submitButtonRef}
                style={{ display: "none" }}
              ></button>

              <BlogTextarea
                value={textareaManager.state}
                textareaRef={textareaManager.textareaRef}
                onChange={handleChange}
                name="content"
                onBlur={handleSubmit}
              />
            </form>
          ) : (
            <RenderMarkdown value={textareaManager.state} />
          )}
        </>
      )}
    </BlogLayout>
  );
}
