import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Card from '../../../../components/public/card'
import SimpleHeader from '../../../../components/public/card/header/SimpleHeader'
import GradientButton from '../../../../components/public/GradientButton'
import InputLitsRender from '../../../../components/public/layout/InputLitsRender'
import InputSelect from '../../../../components/public/layout/InputSelect'
import Button from '../../../../components/tags/element/Button'
import Div from '../../../../components/tags/element/Div'
import Form from '../../../../components/tags/element/Form'
import Option from '../../../../components/tags/element/Option'
import P from '../../../../components/tags/element/P'
import FlexColumn from '../../../../components/tags/layout/flex/public/FlexColumn'
import { toastData } from '../../../../constant'
import { useForm } from '../../../../hook/useForm'
import usePost from '../../../../hook/usePost'
import useToastManager from '../../../../hook/useToastManager'


function PageDelete() {
  const { id } = useParams()
  const [send, response, loading, error] = usePost(`/api/page/editor/${id}/delete`)
  const navigate = useNavigate()
  const toastManager = useToastManager()

  const handleDelete = async () => {
    await send()
  }

  useEffect(() => {
    if (response && (response.status === 1)) {
      toastManager.addItem(toastData.PageDeleteSuccess())
      navigate("/page/editor")
    }
  }, response)
  
  return (
    <Div>
      <Button disabled={!loading} onClick={handleDelete} className="delete-page-btn">Supprimer la page</Button>
      {error && <P className="page-delete-error-msg">{"Une erreur s'est produite lors de la suppréssion"}</P>}
    </Div>
  )
}

export default function PageSettings() {
  const { id } = useParams()
  const pageSettingsForm = useForm(`/api/page/editor/${id}/settings/update`, undefined,[id],false)
  const toastManager = useToastManager()
  const statusValue = pageSettingsForm.formData.find((item) => item.name === 'status')?.value

  useEffect(()=> {
    if(pageSettingsForm.formResponse && pageSettingsForm.formResponse.is_valid) {
      toastManager.addItem(toastData.PageParamsUpdateSuccess())
    }
  }, [pageSettingsForm.formResponse])

  const handleStatusSelectChange = (e) => {
    const newValue = e.target.value
    pageSettingsForm.setFomProps({ "status": newValue })
  }

  if (pageSettingsForm.loading || pageSettingsForm.error) return "chargement..."
   // don't delete the '?'
  return (
    <Card 
      header={<SimpleHeader 
        title={"Modifier la page"} 
        description={"Remplissez le formulaire ci-dessous pour modifier la page."} 
      />}
    >

      <Form onSubmit={pageSettingsForm.handleSubmit}>
        <FlexColumn flexGap="1">
          <InputLitsRender blockedInput={["status"]} formData={pageSettingsForm.formData} formDisabled={pageSettingsForm.formDisabled}/>
          <InputSelect onChange={handleStatusSelectChange} name="status" value={statusValue} >
            <Option value="draft">En attente</Option>
            <Option value="published">Publier</Option>
          </InputSelect>
          <GradientButton disabled={pageSettingsForm.formDisabled} type={"button"}>Continuer</GradientButton>
        </FlexColumn>
      </Form>
      <PageDelete/>
    </Card>
  )
}
