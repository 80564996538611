import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Card from '../../../components/public/card';
import PaddingContainer from '../../../components/public/layout/PaddingContainer';
import RequestManager from '../../../components/public/layout/RequestManager';
import DOMPurify from '../../../extern/dompurify/dompurify';
import useFetch from '../../../hook/useFetch';
import useIframeCss from '../../../hook/useIframeCss';
import useIframeHeight from '../../../hook/useIframeHeight';
import useIframeLinkNavigation from '../../../hook/useIframeLinkNavigation';
import { useMetadata } from '../../../hook/useMetadata';
import "../../../styles/page-render.css";
import NotFound from '../error/NotFound';

export default function Page() {
  const location = useLocation();
  const iframeRef = useRef(null); // Référence pour l'iframe
  const pathWithoutP = location.pathname.replace('/p', '');
  useMetadata(`p=page&path=${pathWithoutP}`, [pathWithoutP]);
  const getPage = useFetch(`/api/page?p=${pathWithoutP}`);
  useIframeCss(iframeRef, getPage.response?.css);
  useIframeHeight(iframeRef, getPage.response?.html);
  useIframeLinkNavigation(iframeRef, getPage.response?.html); 
  
  if (getPage.error) {
    return <NotFound/>
  }

  if (getPage.loading) {
    return <RequestManager request={getPage} />;
  }

  return (
    <PaddingContainer className="padding-container-card cover-with-bg base-container-card">
      <Card className={"page-iframe-card-container"}>
        <iframe
          ref={iframeRef}
          style={{ width: '100%' }}
          title='1'
          srcDoc={DOMPurify.sanitize(getPage.response?.html)}
          frameBorder="0"
          seamless="seamless"
          scrolling="no"
        ></iframe>
      </Card>
    </PaddingContainer>
  );
}
