import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSessionId, setVisitorId } from "../features/main/visitorSlice";
import { collectedBrowserData } from "../modules/utils";
import usePost from "./usePost";
import useGetIpInfo from "./useGetIpInfo";

const useInit = () => {
  const dispatch = useDispatch();
  const visitor = useSelector((state) => state.visitor);
  const [send, response, , error] = usePost("/visitor/init");

  const sendData = async () => {
    const browerData = collectedBrowserData();
    const data = {
      ...browerData,
      v_id: visitor.visitorId,
      s_id: visitor.sessionId,
    };
    await send(data);
  };

  useEffect(() => {
    sendData();
  }, []);

  useEffect(() => {
    if (error) {
      setTimeout(async () => {
        await sendData();
      }, 5000);
    }
  }, [error]);

  useEffect(() => {
    if (response) {
      if (response.status === 1) {
        const visitorId = response.v_id;
        const sessionId = response.s_id;

        localStorage.setItem("v_id", visitorId);
        sessionStorage.setItem("s_id", sessionId);

        dispatch(setVisitorId(visitorId));
        dispatch(setSessionId(sessionId));
      }
    }
  }, [response]);
};

export const useVisitorInit = () => {
  useInit();
  useGetIpInfo();
};
