import React from "react";
import Div from "../../tags/element/Div";
import FlexRow from "../../tags/layout/flex/public/FlexRow";
import Span from "../../tags/element/Span";

export default function CartFormHeader({
  labelOne,
  lableTwo,
  disabledOne = false,
  disabledTwo = false,
}) {
  return (
    <Div className="cart-form__header">
      <FlexRow justifyContent="between">
        <Div
          className={
            disabledOne
              ? "cart-form__header-item selected disabled"
              : "cart-form__header-item selected"
          }
        >
          <Span>{labelOne}</Span>
        </Div>
        <Div
          className={
            disabledTwo
              ? "cart-form__header-item  disabled"
              : "cart-form__header-item"
          }
        >
          <Span>{lableTwo}</Span>
        </Div>
      </FlexRow>
    </Div>
  );
}
