import React from "react";
import { useLocation, useParams } from "react-router-dom";
import DeliveryCar from "../../../components/public/ico/DeliveryCar";
import Earth from "../../../components/public/ico/Earth";
import Share from "../../../components/public/ico/Share";
import Wallet from "../../../components/public/ico/Wallet";
import MapList from "../../../components/public/MapList";
import Div from "../../../components/tags/element/Div";
import Li from "../../../components/tags/element/Li";
import Ul from "../../../components/tags/element/Ul";
import useDynamicComponent from "../../../hook/useDynamicComponent";

const detailsList = [
  {
    ico: <DeliveryCar width={18} height={18} />,
    title: "En 48h",
    type: "deliveryTime",
  },
  {
    ico:<Wallet width={18} height={18} />,
    title: "Livraison",
    type: "delivery",
  },
  {
    ico: <Earth width={18} height={18} />,
    title: "Sénégal",
    type: "country"
  },
  {
    ico: <Share width={18} height={18} />,
    title: "Partager",
    type: "share"
  },
];



function DetailsFeaturesLi({ title, type, ico}) {
  const { id } = useParams()
  const dynamicComponent = useDynamicComponent()
  const location = useLocation()

  const handleClick = () => {
    if (type === "share") {
      dynamicComponent.activeComponent("share-modal", {pathname: location.pathname})
      return
    }

    dynamicComponent.activeComponent("modal-text-info", 
      {
        title: title,
        url: `/api/details/get?product=${id}&type=${type}`
      })
    
  };

  return (
    <Li onClick={handleClick} className="details-features-li">
      <Div className="details-features-key">{ico}</Div>
      <Div className="details-features-value">{title}</Div>
    </Li>
  );
}


export default function DetailsFeaturesListComponent() {

  const detailsListRenderItem = (item) => (
    <DetailsFeaturesLi
      title={item.title}
      ico={item.ico}
      type={item.type}
    />
  );

  return (
    <>
      <Ul className="details-features-list">
        <MapList data={detailsList} renderItem={detailsListRenderItem} />
      </Ul>
    </>
  );
}