import { useEffect, useState } from "react";

export function useRenderDelay(delay = 100) {
  const [renderDelayed, setRenderDelayed] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setRenderDelayed(true);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [delay]);

  return renderDelayed;
}
