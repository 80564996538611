import React from "react";
import Div from "../../tags/element/Div";

export default function ArrowLineRight({ width, height, ...props }) {
  return (
    <Div {...props}>
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path>
      </svg>
    </Div>
  );
}
