import { useDispatch, useSelector } from "react-redux"
import { activeComponentItem, addComponentItem, deactivateComponentItem } from "../features/main/dynamicComponentSlice"

export default function useDynamicComponent() {
  const dispatch = useDispatch()
  const state = useSelector((state) => state.dynamicComponent)

  const addComponent = (componentId) => {
    dispatch(addComponentItem(componentId))
  }

  const getComponent = (componentId) => {
    return state.components.find((item) => item.id === componentId)
  }

  const isComponentActive = (componentId) => {
    return !!state.components.find((item) => item.id === componentId)?.isActive
  }

  const activeComponent = (componentId, componentProps) => {
    dispatch(activeComponentItem({componentId, componentProps}))
  }

  const deactiveComponent = (componentId, componentProps) => {
    dispatch(deactivateComponentItem({componentId, componentProps}))
  }


  const toggleComponent = (componentId, componentProps) => {
    if (isComponentActive(componentId)) {
      deactiveComponent(componentId, componentProps);
    } else {
      activeComponent(componentId, componentProps);
    }
  };


  return {
    state,
    addComponent,
    isComponentActive,
    activeComponent,
    deactiveComponent,
    toggleComponent,
    getComponent,
  }
}
