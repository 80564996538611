import React from "react";
import SidebarLinkItem from "./SidebarLinkItem";
import Skeleton from "../Skeleton";

export default function CategoryLoading() {
  const skeletonItems = Array(5).fill(null);
  return (
    <>
      {skeletonItems.map((_, index) => (
        <SidebarLinkItem key={index} className={"sidebar__category-skeleton-container"}>
          <Skeleton className={"sidebar__category-skeleton"} height={15} />
        </SidebarLinkItem>
      ))}
    </>
  );
}
