import React from "react";
import Modal from "../../../components/modal";
import ModalHeadWithMSGLink from "../../../components/modal/ModalHeadWithMSGLink";
import Div from "../../../components/tags/element/Div";
import { app } from "../../../constant";
import { useMetadata } from "../../../hook/useMetadata";
import { usePageAnalize } from "../../../hook/usePageAnalize";
import "../../../styles/search.css";
import SearchInputSection from "./SearchInputSection";
import SearchSuggestionSection from "./SearchSuggestionSection";

export default function Search() {
  useMetadata("p=search");
  const pageAnalize = usePageAnalize();

  return (
    <Modal
      head={
        <ModalHeadWithMSGLink
          title={"Recherche"}
          msg={"Annuler"}
          to={app.url.home}
        />
      }
    >
      <Div className="padding-1-em search__container">
        <SearchInputSection pageAnalize={pageAnalize} />
        <SearchSuggestionSection />
      </Div>
    </Modal>
  );
}
