import React from "react";
import Div from "../../tags/element/Div";
import { concatenateClass } from "../../../tools/utils";

export default function Card({ header, children, style, className, ...props }) {
  return (
    <Div {...props} className={concatenateClass(["card", className])}>
      <Div className="card__bottom" style={style}>
        {header}
        {children}
      </Div>
    </Div>
  );
}
