import React from "react";
import CustomModal from "../../modal/CustomModal";
import Div from "../../tags/element/Div";
import H2 from "../../tags/element/H2";
import GradientButton from "../GradientButton";
import InputGroup from "../layout/InputGroup";
import Separator from "../Separator";
import BlogLayout from "./BlogLayout";
import Form from "../../tags/element/Form";
import useSubmit from "../../../hook/useSubmit";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGoBack } from "../../../hook/useGoBack";

export default function AddBlog() {
  const [formSubmit, response] = useSubmit("/api/blog/add");
  const navigate = useNavigate();
  const goback = useGoBack();
  const handleSubmit = async (e) => {
    await formSubmit(e);
  };
  useEffect(() => {
    if (response && response.status === 1) {
      navigate(`/blog/edit/${response.id}`);
    }
  }, [response]);

  return (
    <BlogLayout>
      <CustomModal
        handleHeadClick={goback}
        onHid={goback}
        title={"Ajouter un article"}
      >
        <Div className="padding-1-em">
          <Form onSubmit={handleSubmit} method="POST">
            <H2>{"Titre de l'article"}</H2>
            <Separator />
            <InputGroup
              name="title"
              type="text"
              placeholder="Titre de l'article"
              required
            />
            <Separator />
            <GradientButton type={"button"}>Continue</GradientButton>
          </Form>
        </Div>
      </CustomModal>
    </BlogLayout>
  );
}
