import { useEffect } from "react";

export function setTitle(title) {
  const prevTitle = document.title;
  document.title = title;
  return () => {
    document.title = prevTitle;
  };
}

export function useTitle(title) {
  useEffect(() => {
    const prevTitle = document.title;
    document.title = title;
    return () => {
      document.title = prevTitle;
    };
  }, [title]);
}
