import React from "react";
import { useParams } from "react-router-dom";
import useFetch from "../../../../hook/useFetch";
import CustomModal from "../../../../components/modal/CustomModal";
import Loader from "../../../../components/public/layout/Loader";
import Card from "../../../../components/public/card";
import DetailsComment from "../DetailsComment";
import { useGoBack } from "../../../../hook/useGoBack";
import MapList from "../../../../components/public/MapList";
import { usePageAnalize } from "../../../../hook/usePageAnalize";

function Container({ children }) {
  const goBack = useGoBack();

  return (
    <CustomModal title={"Notes et avis"} onHid={goBack} to={".."}>
      {children}
    </CustomModal>
  );
}

export default function AllRateModal() {
  const { id, slug } = useParams();
  const getRates = useFetch(`/api/products/${id}/${slug}/rates`);
  usePageAnalize([id]);
  if (getRates.loading || getRates.error)
    return (
      <Container>
        <Loader />
      </Container>
    );

  const renderItem = (comment) => (
    <Card className={"detail-comment hover-bg"}>
      <DetailsComment
        colorCode={comment.user.profile_color}
        star={comment.star}
        date={comment.added_date}
        username={`${comment.user.full_name}`}
        comment={comment.comment}
      />
    </Card>
  );

  return (
    <Container>
      <MapList renderItem={renderItem} data={getRates.response} />
    </Container>
  );
}
