/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { useSelector } from "react-redux";
import Form from "../../../components/tags/element/Form";
import CartFormHeader from "../../../components/public/layout/CartFormHeader";
import Separator from "../../../components/public/Separator";
import Div from "../../../components/tags/element/Div";
import H2 from "../../../components/tags/element/H2";
import H3 from "../../../components/tags/element/H3";
import FlexColumn from "../../../components/tags/layout/flex/public/FlexColumn";
import InputLitsRender from "../../../components/public/layout/InputLitsRender";
import GradientButton from "../../../components/public/GradientButton";
import P from "../../../components/tags/element/P";

export default function CartForm({
  onSubmit,
  disabled,
  data,
  isCartFinalize = false,
  error,
}) {
  const appState = useSelector((state) => state.app);
  const carts = appState.carts;
  const buyNow = useSelector((state) => state.cart).buyNow;

  return (
    <Form method="post" onSubmit={onSubmit}>
      <CartFormHeader
        labelOne={"Expédition"}
        lableTwo={"Paiment"}
        disabledTwo
      />
      <Separator />
      <input
        type="hidden"
        name="carts"
        value={
          isCartFinalize
            ? JSON.stringify(carts)
            : JSON.stringify([
                { id: buyNow.data.id, quantity: buyNow.data.quantity },
              ])
        }
      />
      <Div>
        <H2>Où Cicaw livre-t-il ce produit?</H2>
        <H3>Adresse de livraison</H3>
        <FlexColumn flexGap="1">
          <InputLitsRender formData={data} formDisabled={disabled} />
        </FlexColumn>
      </Div>
      <Separator />
      {error && (
        <P className="incorrect-info">
          Une erreur s'est produite lors de la traitement du commande.
        </P>
      )}
      <Separator />
      <GradientButton
        style={{ height: "25px", width: "25px" }}
        type={"button"}
        disabled={disabled}
        isLoading={disabled}
      >
        Commander
      </GradientButton>
    </Form>
  );
}
