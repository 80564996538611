import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addGroupCart,
  addProductToCart,
  changeQuantity,
  removeProductFromCart,
} from "../features/main/appSlice";

const saveToLocalStorage = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

const text = {
  BUY_NOW: "Acheter Maintenant",
  ADD_TO_CART: "Ajouter Au Panier",
  REMOVE_FROM_CART: "Retiré du panier",
  DELIVERY_DATE: "Livraison dans ",
  FINALIZE_THE_ORDER: "Finaliser la commande",
  YOUR_BASKET_IS_EMPTY: "Votre panier est vide",
  MY_BASKET: "Mon panier",
  SOLD_OUT: "Épuisé",
};

const useCartManager = () => {
  const cart = useSelector((state) => state.app.carts);
  const dispatch = useDispatch();

  useEffect(() => {
    updateCart();
  }, [cart]);

  const isExistingProduct = (id) => {
    return cart.some((product) => product.id === id);
  };

  const getCartItem = (id) => {
    return cart.find((cart) => cart.id === id);
  };

  const renderText = (id) => {
    return isExistingProduct(id) ? text.REMOVE_FROM_CART : text.ADD_TO_CART;
  };

  const updateCart = (defaultCart) => {
    saveToLocalStorage("cart", defaultCart || cart);
  };

  const changeCartItemQuantity = (productId, newQuantity) => {
    const payload = { id: productId, quantity: parseInt(newQuantity) };
    dispatch(changeQuantity(payload));
  };

  const removeProduct = (productId) => {
    dispatch(removeProductFromCart(productId));
  };

  const addProduct = (id) => {
    if (isExistingProduct(id)) {
      dispatch(removeProductFromCart(id));
    } else {
      dispatch(addProductToCart(id));
    }
  };

  const addGroup = (data) => {
    dispatch(addGroupCart(data));
  };

  return {
    cart,
    text,
    addGroup,
    isExistingProduct,
    getCartItem,
    renderText,
    changeCartItemQuantity,
    removeProduct,
    addProduct,
    updateCart,
  };
};

export default useCartManager;
