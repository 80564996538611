import React, { useRef } from 'react'
import { useParams } from 'react-router-dom'
import useFetch from '../../../../hook/useFetch'
import useIframeCss from '../../../../hook/useIframeCss'
import useIframeLinkNavigation from '../../../../hook/useIframeLinkNavigation'
import DOMPurify from '../../../../extern/dompurify/dompurify'

export default function PagePreview() {
  const { id } = useParams()
  const getPage = useFetch(`/api/page/editor/${id}/preview`)
  const iframeRef = useRef(null)
  useIframeCss(iframeRef, getPage.response?.css);
  useIframeLinkNavigation(iframeRef, getPage.response?.html)

  if (getPage.loading || getPage.error) {
    return "Chargement ...."
  }
  


  return (
    <iframe ref={iframeRef} style={{width:"100%", height:"100%"}} title='1' srcDoc={DOMPurify.sanitize(getPage.response.html)} frameBorder="0"></iframe>
  )
}
