import { useState } from "react";
import { submitPostForm } from "../modules/event";

export function useSubmit(url, header) {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const init = () => {
    setResponse(null);
    setError(false);
  };

  const send = async (event, data, setFormDisabled) => {
    try {
      init();
      const result = await submitPostForm(
        event,
        url,
        data,
        setFormDisabled,
        header,
      );
      setResponse(result);
    } catch (error) {
      setError(error);
      setFormDisabled && setFormDisabled(false);
    }
  };

  return [send, response, error];
}

export default useSubmit;
