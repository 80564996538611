/* eslint-disable react/no-unescaped-entities */
import React from "react";
import ProfileEdit from "../../../components/public/profile/ProfileEdit";
import FlexRow from "../../../components/tags/layout/flex/public/FlexRow";
import MonthSelector from "../../../components/public/MonthSelector";
import DaySelector from "../../../components/public/DaySelector";
import YearSelector from "../../../components/public/YearSelector";
import Separator from "../../../components/public/Separator";
import P from "../../../components/tags/element/P";
import useProfileField from "../../../hook/useProfileField";
import { toApiEditBirthday } from "../../../constant";
import RequestManager from "../../../components/public/layout/RequestManager";

export default function EditBirthday() {
  const { getProfile, postError, isDisabled, handleSubmit } =
    useProfileField(toApiEditBirthday());

  if (getProfile.loading || getProfile.error)
    return <RequestManager request={getProfile} />;

  const dataSplit = getProfile.response.value.split(" ");

  return (
    <ProfileEdit
      description={
        "Modifier votre date de naissance pour qu'elle soit correctement enregistrée sur votre profil."
      }
      title={"Modifier votre date de naissance"}
      onSubmit={handleSubmit}
      isDisabled={isDisabled}
    >
      <FlexRow flexGap="1">
        <DaySelector name="day" value={dataSplit[0]} />
        <MonthSelector name="month" value={dataSplit[1]} />
        <YearSelector name="year" value={dataSplit[2]} />
      </FlexRow>
      <Separator />
      {postError && (
        <P className="incorrect-info">
          Une erreur s'est produite veuillez réessayer
        </P>
      )}
    </ProfileEdit>
  );
}
