import React from "react";
import FlexRow from "../../../tags/layout/flex/public/FlexRow";
import H2 from "../../../tags/element/H2";
import FlexColumn from "../../../tags/layout/flex/public/FlexColumn";
import { Link } from "react-router-dom";
import { app } from "../../../../constant";
import ArrowLeft from "../../ico/ArrowLeft";
import Div from "../../../tags/element/Div";
import { useVibrate } from "../../../../hook/useVibrate";

function LinkContainer({ to, children }) {
  const vibrate = useVibrate();

  return (
    <Link to={to} className="go__back__header" onClick={() => vibrate()}>
      {children}{" "}
    </Link>
  );
}

export default function GoBackHeader({ title, description, to, h }) {
  const Header = h ? h : H2;
  to = to ? to : app.url.home;
  return (
    <FlexRow className="card-header padding-1em">
      <LinkContainer to={to}>
        <ArrowLeft width={22} height={22} style={{ marginRight: ".5em" }} />
      </LinkContainer>
      <FlexColumn>
        <LinkContainer to={to}>
          <Header>{title}</Header>
        </LinkContainer>
        {description && <Div className="card-description">{description}</Div>}
      </FlexColumn>
    </FlexRow>
  );
}
