import React from "react";
import MapList from "../../../components/public/MapList";
import Tag from "../../../components/public/Tag";
import Card from "../../../components/public/card";
import DefaultHeader from "../../../components/public/card/header/DefaultHeader";
import Div from "../../../components/tags/element/Div";
import Ul from "../../../components/tags/element/Ul";

export default function DetailsTagsCard({ tags }) {
  const renderItem = (item) => <Tag key={item.id} data={item} />;

  return (
    tags.length > 0 && (
      <Card
        className={"details-tags-card"}
        header={<DefaultHeader title="Tags" link={false} />}
      >
        <Div>
          <Ul className="tag__suggestion__list">
            <MapList data={tags} renderItem={renderItem} />
          </Ul>
        </Div>
      </Card>
    )
  );
}
