import React from "react";
import MaxWidth from "../../../../../components/public/layout/MaxWidth";
import ProductManager from "../../../../../components/public/manager/seller/product/ProductManager";
import { useTitle } from "../../../../../hook/useTitle";
import { titleSyntax } from "../../../../../constant";

export default function SellerAddProduct() {
  useTitle(titleSyntax("Ajouter un produit"));
  return (
    <MaxWidth>
      <ProductManager type={"add"} />
    </MaxWidth>
  );
}
