import React from "react";

export default function MapList({ data, renderItem, useId=false }) {
  return (
    <React.Fragment>
      {data.map((item, index) => (
        <React.Fragment key={useId ? item.id : index}>{renderItem(item, index)}</React.Fragment>
      ))}
    </React.Fragment>
  );
}
