import React from "react";
import Div from "../../tags/element/Div";
import FlexRow from "../../tags/layout/flex/public/FlexRow";
import UserAvatar from "../layout/UserAvatar";
import Button from "../../tags/element/Button";
import LeftLayoutIco from "../ico/LeftLayoutIco";
import { NavbarActionButtonList } from "./MobileNavbar";

export default function MiddleNavbar({
  sidebarVisiblityManager,
  onSave,
  OnWatch,
  hasChanges,
  isEditMode,
}) {
  return (
    <Div className="blog-render__main-navbar">
      <FlexRow justifyContent="between">
        <FlexRow alignItems="center">
          {!sidebarVisiblityManager.value && (
            <Button
              onClick={() => sidebarVisiblityManager.toTrue()}
              className="sidebar-open-btn"
            >
              <LeftLayoutIco width="18" height="18" />
            </Button>
          )}
          <Button className="app-name__btn">cicaw</Button>
        </FlexRow>
        <NavbarActionButtonList
          onSave={onSave}
          onWatch={OnWatch}
          hasChanges={hasChanges}
          isEditMode={isEditMode}
        />
        <UserAvatar label={"Cheikhou Dieng"} />
      </FlexRow>
    </Div>
  );
}
