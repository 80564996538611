import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import AuthBody from "../../../components/public/AuthBody";
import GradientButton from "../../../components/public/GradientButton";
import Separator from "../../../components/public/Separator";
import InputGroup from "../../../components/public/layout/InputGroup";
import Div from "../../../components/tags/element/Div";
import P from "../../../components/tags/element/P";
import FlexColumn from "../../../components/tags/layout/flex/public/FlexColumn";
import { app, toastData } from "../../../constant";
import useLogin from "../../../hook/useLogin";
import { useMetadata } from "../../../hook/useMetadata";
import { usePageAnalize } from "../../../hook/usePageAnalize";
import { useScrollTop } from "../../../hook/useScroolTop";
import useSubmit from "../../../hook/useSubmit";
import "../../../styles/profile.css";
import useRedirect from "../../../hook/userRedirect";
import useToastManager from "../../../hook/useToastManager";
import AcceptTermOnContinue from "../../../components/public/AcceptTermOnContinue";

export default function Login() {
  useScrollTop();
  usePageAnalize();
  useMetadata("p=login");
  const dispatch = useDispatch();
  const login = useLogin();
  const [searchParams] = useSearchParams();
  const [isDisabled, setDisabled] = useState(false);
  const [okMsg, setOkMsg] = useState(false);
  const navigate = useNavigate();
  const [postData, response, error] = useSubmit("/api/auth/");
  const signin = searchParams.get("signin");
  const username = searchParams.get("username");
  const redirect = useRedirect()
  const toastManager = useToastManager()
  const handleSubmit = async (e) => {
    await postData(e, {}, setDisabled);
  };

  useEffect(() => {
    if (response) {
      setOkMsg(true);
      login(response.token, false);
      toastManager.addItem(toastData.userLoginSuccess())
      redirect.params? redirect.navigate(): navigate(app.loginRedirect)
    }
  }, [response, navigate, dispatch, searchParams]);

  return (
    <AuthBody
      title={app.page.login.text.head.title}
      description={app.page.login.text.noAccount}
      anoterAction={app.text.register}
      anoterActionTo={`${app.url.signIn}?redirect=${redirect.params}`}
      onSubmit={handleSubmit}
    >
      {signin === "succes" && (
        <Div>
          <P className="correct-info">Votre compte a été creé avec succés.</P>
          <Separator />
        </Div>
      )}
      <FlexColumn flexGap="1">
        <InputGroup
          type="text"
          placeholder="Nom d'utilisateur"
          name="username"
          required
          requiredMsg="Un nom d'utilisateur est obligatoire."
          value={signin === "succes" ? username : ""}
          disabled={isDisabled ? true : false}
        />
        <InputGroup
          type="password"
          placeholder="Mot de passe"
          name="password"
          required
          disabled={isDisabled ? true : false}
        />
        {error && (
          <P className="incorrect-info">{app.page.login.text.loginError}</P>
        )}
        {okMsg && <P className="correct-info">Connexion réussis avec succés</P>}
        <AcceptTermOnContinue />
        <GradientButton
          disabled={isDisabled ? true : false}
          isLoading={isDisabled ? true : false}
          style={{ height: "25px", width: "25px" }}
          type={"button"}
        >
          {app.text.continue}
        </GradientButton>
      </FlexColumn>
    </AuthBody>
  );
}
