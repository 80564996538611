import React from 'react'
import { Outlet, useParams } from 'react-router-dom'
import Card from '../../../components/public/card'
import GoBackHeader from '../../../components/public/card/header/GoBackHeader'
import RequestManager from '../../../components/public/layout/RequestManager'
import TabsNavigation from '../../../components/public/layout/TabsNavigation'
import Div from '../../../components/tags/element/Div'
import H1 from '../../../components/tags/element/H1'
import P from '../../../components/tags/element/P'
import useBodyClass from '../../../hook/useBodyClass'
import useFetch from '../../../hook/useFetch'

export default function AdminCardItem() {
  const {id} = useParams()
  useBodyClass("admin-card-item")
  const getCard = useFetch("/api/admin/cards/"+id)
  
  if (getCard.loading || getCard.error) {
    return <RequestManager request={getCard}/>
  }

  const tabs = [
    {label:"Produit", to:`/admin/cards/${id}/products`},
    {label:"Paramétre", to:`/admin/cards/${id}/settings`},
  ]

  return (
    <div>
      <GoBackHeader
        h={H1}
        title={getCard.response.title}
        to={".."}
        />
        <P>{getCard.response.description}</P>
        <Div style={{margin: "0 -1em"}}>
          <Card>
            <TabsNavigation data={tabs} linkProps={{end:true}}/>
          </Card>
        </Div>
      <Outlet/>
    </div>
  )
}
