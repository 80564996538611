import React from "react";
import CustomModal from "../../modal/CustomModal";
import ModalHead from "../../modal/ModalHead";
import Button from "../../tags/element/Button";
import FlexRow from "../../tags/layout/flex/public/FlexRow";
import Card from "../card";
import LeftLayoutIco from "../ico/LeftLayoutIco";
import { SidebarBody } from "./BlogSidebar";
import { useNavigate } from "react-router-dom";

export default function BlogSidebarMobile({ visiblityManager }) {
  const navigate = useNavigate();
  return (
    <CustomModal
      head={
        <ModalHead>
          <FlexRow
            className="w-100 blog-sidebar-mobile__header"
            alignItems="center"
            justifyContent="between"
          >
            <Button onClick={() => visiblityManager.toFalse()}>
              <LeftLayoutIco width="18" height="18" />
            </Button>
            <Button onClick={() => navigate("/blog/add")}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="18"
                height="18"
                fill="currentColor"
              >
                <path d="M4 1V4H1V6H4V9H6V6H9V4H6V1H4ZM3 20.0066V11H5V19H13V14C13 13.45 13.45 13 14 13L19 12.999V5H11V3H20.0066C20.5552 3 21 3.45576 21 4.00247V15L15 20.996L4.00221 21C3.4487 21 3 20.5551 3 20.0066ZM18.171 14.999L15 15V18.169L18.171 14.999Z"></path>
              </svg>
            </Button>
          </FlexRow>
        </ModalHead>
      }
      onHid={visiblityManager.toFalse}
      containerClassName={"blog-sidebar-mobile__modal-container"}
      className={"blog-sidebar-mobile__modal-item"}
      isVisible={visiblityManager.value}
    >
      <Card>
        <SidebarBody />
      </Card>
    </CustomModal>
  );
}
