import React, { useEffect, useState } from "react";
import useFetch from "../../../../../hook/useFetch";
import CustomModal from "../../../../modal/CustomModal";
import ModalHeadWithMSGLink from "../../../../modal/ModalHeadWithMSGLink";
import Div from "../../../../tags/element/Div";
import H2 from "../../../../tags/element/H2";
import Li from "../../../../tags/element/Li";
import P from "../../../../tags/element/P";
import Ul from "../../../../tags/element/Ul";
import FlexColumn from "../../../../tags/layout/flex/public/FlexColumn";
import FlexRow from "../../../../tags/layout/flex/public/FlexRow";
import Card from "../../../card";
import SimpleHeader from "../../../card/header/SimpleHeader";
import GradientButton from "../../../GradientButton";
import ChevronRight from "../../../ico/ChevronRight";
import MapList from "../../../MapList";
import ArrowLeft from "../../../ico/ArrowLeft";
import Loader from "../../../layout/Loader";
import ErrorMessageRetryButton from "../../../layout/ErrorMessageRetryButton";
export default function ProductCategoryModal({ isVisible, onHid, onSubmit }) {
  const getdata = useFetch("/api/categories");
  const [categories, setCategories] = useState({
    children: [],
    id: null,
    name: null,
    slug: null,
    description: null,
  });
  const [history, setHistory] = useState([]);

  const handleSubmit = () => {
    onSubmit && onSubmit(categories);
    onHid();
  };

  useEffect(() => {
    if (getdata.response) {
      if (Array.isArray(getdata.response)) {
        setCategories((prevState) => ({
          ...prevState,
          children: getdata.response,
        }));
      } else {
        setCategories(getdata.response);
      }
    }
  }, [getdata.response]);

  const handleClick = (item) => {
    setCategories(item);
    setHistory([...history, categories]);
  };

  const handleGoBack = () => {
    if (history.length > 0) {
      const prevCategory = history[history.length - 1];
      setCategories(prevCategory);
      setHistory(history.slice(0, -1));
    }
  };

  const CurrentLocation = () => (
    <P className="current-location">
      {history.map((item, index) => (
        <span key={index}>
          {item.name}
          {index !== 0 && " > "}
        </span>
      ))}

      {categories.name}
    </P>
  );

  const RenderItem = (item) => (
    <Li key={item.id} onClick={(e) => handleClick(item, e)}>
      <FlexRow justifyContent="between" alignItems="center">
        <Div>{item.name}</Div>
        <Div>
          <ChevronRight width={22} height={22} />
        </Div>
      </FlexRow>
    </Li>
  );

  return (
    <CustomModal
      className={"product-category-modal__item"}
      isVisible={isVisible}
      onHid={onHid}
      head={
        <ModalHeadWithMSGLink
          onClick={onHid}
          msg={"Annuler"}
          title={"Sélectionner une catégorie"}
        />
      }
    >
      {getdata.loading ? (
        <Loader modal />
      ) : getdata.error ? (
        <ErrorMessageRetryButton onRetry={getdata.fetchData} />
      ) : (
        <>
          <FlexColumn>
            <Card className={"product-category__list-container"}>
              <Ul>
                <Li style={{ margin: 0, padding: 0 }}>
                  <Div className="padding-1-em product-category__list-container-header">
                    {history.length > 0 ? (
                      <FlexRow
                        flexGap="1"
                        alignItems="center"
                        onClick={handleGoBack}
                      >
                        <ArrowLeft width={22} />
                        <FlexColumn>
                          <H2>{categories.name}</H2>
                          <P className="product-category__list-container-header-description">
                            {categories.description}
                          </P>
                        </FlexColumn>
                      </FlexRow>
                    ) : (
                      <SimpleHeader
                        title={"Catégorie"}
                        description={"Sélectionner une catégorie"}
                      />
                    )}
                  </Div>
                </Li>
                {categories.children.length >= 1 ? (
                  <MapList data={categories.children} renderItem={RenderItem} />
                ) : (
                  <Li className="selected" key={categories.id}>
                    <FlexRow justifyContent="between" alignItems="center">
                      <Div>{categories.name}</Div>
                    </FlexRow>
                  </Li>
                )}
              </Ul>
            </Card>
          </FlexColumn>
          <Card className={"product-category-modal_btn"}>
            <P className="category-state-info">
              {history.length > 0 ? (
                <CurrentLocation />
              ) : (
                "Sélectionnez une catégorie"
              )}
            </P>
            <GradientButton
              onClick={handleSubmit}
              disabled={!categories.id}
              type="button"
            >
              Sélectionner
            </GradientButton>
          </Card>
        </>
      )}
    </CustomModal>
  );
}
