import React from "react";

export default function Facebook({ width, height, fill = true }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={fill ? "#1877F2" : ""}
      viewBox="0 0 24 24"
      width={width}
      height={height}
    >
      <path d="M12.001 2C6.47813 2 2.00098 6.47715 2.00098 12C2.00098 16.9913 5.65783 21.1283 10.4385 21.8785V14.8906H7.89941V12H10.4385V9.79688C10.4385 7.29063 11.9314 5.90625 14.2156 5.90625C15.3097 5.90625 16.4541 6.10156 16.4541 6.10156V8.5625H15.1931C13.9509 8.5625 13.5635 9.33334 13.5635 10.1242V12H16.3369L15.8936 14.8906H13.5635V21.8785C18.3441 21.1283 22.001 16.9913 22.001 12C22.001 6.47715 17.5238 2 12.001 2Z"></path>
    </svg>
  );
}
