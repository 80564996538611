import { useEffect } from 'react';

function useIframeHeight(iframeRef, html) {
  useEffect(() => {
    const adjustIframeHeight = () => {
      if (iframeRef.current) {
        const iframeDocument = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
        if (iframeDocument) {
          iframeRef.current.style.height = `${iframeDocument.body.scrollHeight + 50}px`;
        }
      }
    };

    const handleWindowResize = () => {
      adjustIframeHeight();
    };

    const handleIframeLoad = () => {
      adjustIframeHeight();
      const iframeDocument = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
      if (iframeDocument) {
        const observer = new MutationObserver(adjustIframeHeight);
        observer.observe(iframeDocument.body, { childList: true, subtree: true });

        return () => {
          observer.disconnect();
        };
      }
    };

    if (iframeRef.current) {
      iframeRef.current.addEventListener('load', handleIframeLoad);
      window.addEventListener('resize', handleWindowResize);
    }

    return () => {
      if (iframeRef.current) {
        iframeRef.current.removeEventListener('load', handleIframeLoad);
      }
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [html, iframeRef]);
}

export default useIframeHeight;
