import React, { forwardRef } from "react";

// eslint-disable-next-line react/display-name
const Tags = forwardRef(({ tag, children, ...props }, ref) => {
  const TagComponent = tag || "div";
  return (
    <TagComponent ref={ref} {...props}>
      {children}
    </TagComponent>
  );
});

export default Tags;
