import React, { useEffect, useState } from "react";
import FlexColumn from "../../../components/tags/layout/flex/public/FlexColumn";
import Div from "../../../components/tags/element/Div";
import { Link } from "react-router-dom";
import { toSearchQuery } from "../../../constant";
import MapList from "../../../components/public/MapList";
import useFetch from "../../../hook/useFetch";
import useSearch from "../../../hook/useSearch";

function SearchSuggestionItem({ text }) {
  return (
    <Div className="search__suggestion__section-item">
      <Link to={toSearchQuery(text)}>{text}</Link>
    </Div>
  );
}

export default function SearchSuggestionSection() {
  const search = useSearch()
  const getSuggestions = useFetch("/api/search/suggestions");
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    if (getSuggestions.response) {
      setSuggestions(getSuggestions.response);
    }
  }, [getSuggestions.response]);

  const renderItem = (item) => <SearchSuggestionItem text={item} />;

  return (
    suggestions.length >= 1 && (
      <FlexColumn className="search__suggestion__section">
        <MapList data={suggestions} renderItem={renderItem} />
        <MapList data={search.searchHistory} renderItem={renderItem} />
      </FlexColumn>
    )
  );
}
