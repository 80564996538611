import React from "react";
import Div from "../../../../tags/element/Div";
import Form from "../../../../tags/element/Form";
import FlexColumn from "../../../../tags/layout/flex/public/FlexColumn";
import FlexRow from "../../../../tags/layout/flex/public/FlexRow";
import AcceptTermOnContinue from "../../../AcceptTermOnContinue";
import MapList from "../../../MapList";
import Card from "../../../card";
import SimpleHeader from "../../../card/header/SimpleHeader";
import FormLoaderButton from "../../../layout/FormLoaderButton";
import InputGroup from "../../../layout/InputGroup";
import TagRender from "../../../layout/Tag/TagRender";
import ProductImageInput from "./image/ProductImageInput";

export default function ProductAddForm({
  form,
  handleImageClick,
  imageManager,
  btnMsg,
  updateData,
  handleShowCategotyModal,
  category,
}) {
  const renderItem = (data) => {
    const blockedInput = ["image_1", "image_2", "image_3", "category"];
    return (
      !blockedInput.includes(data.name) && (
        <InputGroup
          type={data.type}
          value={data.value}
          errorList={data.errors}
          name={data.name}
          placeholder={data.label}
          required={data.required}
        />
      )
    );
  };

  const handleTagInputChange = (e) => {
    form.setFomProps({ tags: e.target.value });
  };

  const handleCategoryInputClick = () => {
    handleShowCategotyModal();
  };

  return (
    <Form
      className="product-add-form"
      method="POST"
      onSubmit={form.handleSubmit}
    >
      <FlexColumn className="seller-product-add">
        <Card
          header={
            <SimpleHeader
              title={"Information générale"}
              description={"Les details du produits."}
            />
          }
        >
          <FlexColumn flexGap="1" className="seller-product-add">
            <MapList data={form.formData} renderItem={renderItem} />
          </FlexColumn>
        </Card>
        <Card
          header={
            <SimpleHeader
              description={"Ajouter maximum 3 images pour le produit."}
              title={"Images"}
            />
          }
        >
          <FlexRow flexGap="1">
            <ProductImageInput
              imageManager={imageManager}
              handleClick={handleImageClick}
              id={1}
              value={imageManager.imagesValue.images.image1}
            />
            <ProductImageInput
              imageManager={imageManager}
              handleClick={handleImageClick}
              id={2}
              value={imageManager.imagesValue.images.image2}
            />
            <ProductImageInput
              imageManager={imageManager}
              handleClick={handleImageClick}
              id={3}
              value={imageManager.imagesValue.images.image3}
            />
          </FlexRow>
        </Card>

        <Card
          header={
            <SimpleHeader
              title={"Catégorie"}
              description={"Ajouter un catégorie."}
            />
          }
        >
          <Div
            onClick={handleCategoryInputClick}
            className="add-product-category-input-container"
          >
            <InputGroup
              unique={true}
              placeholder="Catégorie"
              value={
                (category?.name && category?.name) ||
                (updateData?.product.category &&
                  updateData?.product.category.name)
              }
              disabled
              type="text"
            />
            <Div className="overlay"></Div>
          </Div>
        </Card>

        <Card
          header={
            <SimpleHeader
              title={"Information suplémentaire"}
              description={"Ces informations sont utiles pour la suggestion."}
            />
          }
        >
          {updateData?.product.tags ? (
            <TagRender
              onChange={handleTagInputChange}
              value={updateData.product.tags}
            />
          ) : (
            <TagRender onChange={handleTagInputChange} />
          )}
        </Card>

        <Card header={<SimpleHeader description={<AcceptTermOnContinue />} />}>
          <FormLoaderButton form={form} label={btnMsg} />
        </Card>
      </FlexColumn>
    </Form>
  );
}
