import React from "react";
import Div from "../../tags/element/Div";

export default function ShowAfterNavbar({ children, ...props }) {
  return (
    <Div className="after__navbar" {...props}>
      {children}
    </Div>
  );
}
