import { Editor } from '@monaco-editor/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import usePageEditor from '../../../../hook/usePageEditor';

export default function PageCSS() {
  const { id } = useParams();
  const { getPageItem, handleEditorChange } = usePageEditor(id, 'css');

  if (getPageItem.loading || getPageItem.error) {
    return"loading or error";
  }

  return (
    <Editor
      height="100%"
      defaultLanguage="css"
      defaultValue={getPageItem.response.css}
      onChange={handleEditorChange}
      options={{
        fontSize: "18px",
      }}
    />
  )
}
