import React from "react";
import GradientButton from "../GradientButton";

export default function FormLoaderButton({ form, label = "Continuer" }) {
  return (
    <GradientButton
      disabled={form.formDisabled}
      isLoading={form.formDisabled}
      style={{ height: "25px", width: "25px" }}
      type={"button"}
    >
      {label}
    </GradientButton>
  );
}
