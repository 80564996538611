import { useEffect, useState } from "react";

export const useClickLimit = (limit = 3, blockDuration = 10000) => {
  const [clickCount, setClickCount] = useState(0);
  const [blockClick, setBlockClick] = useState(false);

  useEffect(() => {
    if (blockClick) {
      const timer = setTimeout(() => {
        setClickCount(0);
        setBlockClick(false);
      }, blockDuration);

      return () => clearTimeout(timer);
    }
  }, [blockClick]);

  const handleClick = () => {
    if (blockClick) return false;

    setClickCount((prev) => prev + 1);

    if (clickCount >= limit) {
      setBlockClick(true);
      return false;
    }
    return true;
  };

  return { handleClick, blockClick };
};