import React from "react";
import Option from "../tags/element/Option";
import InputSelect from "./layout/InputSelect";

export default function DaySelector({ ...props }) {
  const days = Array.from({ length: 31 }, (_, i) => {
    const day = i + 1;
    return day < 10 ? "0" + day : day; // Ajoute un zéro devant les nombres inférieurs à 10
  });

  return (
    <InputSelect {...props}>
      {days.map((day) => (
        <Option value={day} key={day}>
          {day}
        </Option>
      ))}
    </InputSelect>
  );
}
