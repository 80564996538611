import React, { useEffect } from "react";

export default function BlogTextarea({
  textareaRef,
  onChange,
  value,
  ...props
}) {
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [value]);

  return (
    <textarea
      value={value}
      ref={textareaRef}
      onChange={onChange}
      className="blog-render-textarea"
      placeholder="Commencer à ecrire"
      {...props}
    ></textarea>
  );
}
