import React from "react";
import { Link } from "react-router-dom";
import DefaultDashbordCardListItem from "../../../components/public/manager/admin/card/DefaultDashbordCardListItem";
import UserSvg from "../../../components/public/navbar/ico/UserSvg";
import Div from "../../../components/tags/element/Div";
import FlexColumn from "../../../components/tags/layout/flex/public/FlexColumn";
import { useScrollTop } from "../../../hook/useScroolTop";
import PagnationContainer from "./pagnation/PagnationContainer";

export default function AdminVisitor() {
  useScrollTop();

  const renderItem = (item) => {
    return (
      <DefaultDashbordCardListItem
        left={<UserSvg width={16} />}
        middle={
          <Link to={"/admin/visitor/" + item.id}>
            <FlexColumn>
              <Div>{item.id}</Div>
              <Div className="admin-order-data-item">
                <Div>{item.added_date}</Div>
                <Div style={{ marginTop: ".2em" }}>
                  {item.session.length} sessions
                </Div>
              </Div>
            </FlexColumn>
          </Link>
        }
      />
    );
  };

  return (
    <PagnationContainer
      url={"/api/admin/visitors"}
      title={"Visiteur"}
      renderItem={renderItem}
    />
  );
}
