import React from "react";
import { Link } from "react-router-dom";
import { app } from "../../constant";
import Div from "../tags/element/Div";
import Form from "../tags/element/Form";
import H1 from "../tags/element/H1";
import P from "../tags/element/P";
import Separator from "./Separator";
import Card from "./card";
import CenterByMaxWidth from "./layout/CenterByMaxWidth";
import useBodyClass from "../../hook/useBodyClass";

export default function AuthBody({
  title,
  description,
  anoterAction,
  anoterActionTo,
  children,
  onSubmit,
}) {
  useBodyClass('auth-body')
  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit && onSubmit(event);
  };
  return (
    <Div className="cover-with-bg">
      <CenterByMaxWidth maxWidth={500} style={{display:"flex", alignItems:"center"}}>
        <Div className="login__form">
          <H1 className="login-app-logo">
            <Link to={app.url.home}>{title}</Link>
          </H1>
          <Card>
            <Separator />
            <Form method="POST" onSubmit={handleSubmit}>
              {children}
              <Separator />
              <P className="term-info login_page__another-option">
                {description}
                <Link to={anoterActionTo}>{anoterAction}</Link>
              </P>
              <Separator />
            </Form>
          </Card>
        </Div>
      </CenterByMaxWidth>
    </Div>
  );
}
