import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function useIframeLinkNavigation(iframeRef, htmlContent) {
  const navigate = useNavigate();

  useEffect(() => {
    const handleLoad = () => {
      if (iframeRef.current) {
        const iframeDocument = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
        if (iframeDocument) {
          const links = iframeDocument.querySelectorAll('a');
          links.forEach((element) => {
            element.addEventListener('click', (e) => {
              e.preventDefault();
              const url = new URL(element.href);
              // Vérifier si le lien est interne ou externe
              if (url.hostname === window.location.hostname) {
                // Lien interne - Utilisation de React Router pour naviguer
                navigate(url.pathname);
              } else {
                // Lien externe - Ouvrir dans un nouvel onglet
                window.open(element.href, '_blank');
              }
            });
          });
        }
      }
    };

    const iframe = iframeRef.current;
    if (iframe) {
      iframe.addEventListener('load', handleLoad);
    }

    return () => {
      if (iframe) {
        iframe.removeEventListener('load', handleLoad);
      }
    };
  }, [iframeRef, htmlContent, navigate]);
}

export default useIframeLinkNavigation;
