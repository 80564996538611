import React from "react";
import { useParams } from "react-router-dom";
import useFetch from "../../../../hook/useFetch";
import CustomModal from "../../../../components/modal/CustomModal";
import Card from "../../../../components/public/card";
import P from "../../../../components/tags/element/P";
import Loader from "../../../../components/public/layout/Loader";
import { useGoBack } from "../../../../hook/useGoBack";
import { toApiProductDescription } from "../../../../constant";
import { usePageAnalize } from "../../../../hook/usePageAnalize";

function Container({ children }) {
  const goBack = useGoBack();

  return (
    <CustomModal to={".."} title={"À propos du produit"} onHid={goBack}>
      {children}
    </CustomModal>
  );
}

export default function DescriptionModal() {
  const { id, slug } = useParams();
  usePageAnalize([id]);
  const getDescription = useFetch(toApiProductDescription(id, slug), [id]);

  if (getDescription.loading || getDescription.error)
    return (
      <Container>
        <Loader />
      </Container>
    );

  return (
    <Container>
      <Card>
        <P>{getDescription.response.description}</P>
      </Card>
    </Container>
  );
}
