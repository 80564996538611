import React from "react";
import RequestLoading from "./RequestLoading";
import ErrorMessageRetryButton from "./ErrorMessageRetryButton";
import Div from "../../tags/element/Div";

export default function RequestManager({ request }) {
  if (request.loading) return <RequestLoading />;
  if (request.error)
    return (
      <Div className="page-loading-error-container">
        <ErrorMessageRetryButton onRetry={request.fetchData} />
      </Div>
    );
}
