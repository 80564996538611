import React from 'react'
import Card from '../../../components/public/card'
import UserAvatar from '../../../components/public/layout/UserAvatar'
import SidebarBody from '../../../components/public/sidebar/SidebarBody'
import Button from '../../../components/tags/element/Button'
import Div from '../../../components/tags/element/Div'
import H2 from '../../../components/tags/element/H2'
import P from '../../../components/tags/element/P'
import Span from '../../../components/tags/element/Span'
import FlexRow from '../../../components/tags/layout/flex/public/FlexRow'
import useBodyClass from '../../../hook/useBodyClass'
import { useScrollTop } from '../../../hook/useScroolTop'


export default function Community() {
  useScrollTop()
  useBodyClass('community')
  return (
    <Div>
      <Div className="c-sidebar">
        <SidebarBody/>
      </Div>

      <Div className="c-content">
          <Card className={"c-post-card"}>
            <FlexRow justifyContent='between' alignItems='center'>
              <FlexRow alignItems="center" flexGap="1">
                <UserAvatar label={"cheikhou Dieng"}/>
                <Div><Span>Cicaw &#8226; 3h</Span></Div>
              </FlexRow>
              <Button>Suivre</Button>
            </FlexRow>
            <H2>Les meilleurs Produit pour cetter été Disponible</H2>
            <P>{"I'm an affiliate marketer focusing on but not limited to the 'make money online' niche, and I'm curious if anyone has experience promoting Amazon products in this space. Most of the affiliate programs I come across are for digital products and courses. Are there any physical or digital products on Amazon that could resonate with people looking to start an online business or side hustle? Any success stories or tips would be appreciated!"}</P>
          </Card>
          <Card className={"c-post-card"}>
            <h1>My post numero 1</h1>
          </Card>
          <Card className={"c-post-card"}>
            <h1>My post numero 1</h1>
          </Card>
      </Div>
    </Div>
  )
}