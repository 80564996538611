import React, { useRef, useState } from "react";
import { shareOneSocialMedia } from "../../constant";
import useCopy from "../../hook/useCopy";
import Button from "../tags/element/Button";
import Div from "../tags/element/Div";
import Span from "../tags/element/Span";
import FlexColumn from "../tags/layout/flex/public/FlexColumn";
import FlexRow from "../tags/layout/flex/public/FlexRow";
import Clipboard from "./ico/Clipboard";
import Facebook from "./ico/Facebook";
import TwitterX from "./ico/TwitterX";
import WhatsApp from "./ico/Whatsapp";
import MapList from "./MapList";
import { useBoolean } from "../../hook/useBoolean";

export default function ShareModal() {
  const [shareInputValue] = useState(window.location.href);
  const inputRef = useRef(null);
  const isCopied = useBoolean(false)
  const copy = useCopy(isCopied.toTrue)


  const socialMediaIcons = [
    {
      name: "WhatsApp",
      icon: <WhatsApp width={50} height={50} />,
      clickHandler: () => window.open(shareOneSocialMedia.whatssap()),
    },
    {
      name: "Facebook",
      icon: <Facebook width={50} height={50} />,
      clickHandler: () => window.open(shareOneSocialMedia.facebook()),
    },
    {
      name: "X",
      icon: <TwitterX width={50} height={50} />,
      clickHandler: () => window.open(shareOneSocialMedia.x()),
    },
  ];

  const renderItem = (item) => (
    <FlexColumn onClick={item.clickHandler} alignItems="center">
      {item.icon}
      <Div>
        <Span>{item.name}</Span>
      </Div>
    </FlexColumn>
  );

  return (
    <FlexColumn justifyContent="between" flexGap="1" className="share">
      <FlexRow flexGap="1" justifyContent="evenly">
        <MapList data={socialMediaIcons} renderItem={renderItem} />
      </FlexRow>
      <FlexRow className="clipboard-container" onClick={()=> copy(window.location.href)}>
        <div className="flex-grow-1" ref={inputRef}>
          {shareInputValue}
        </div>
        <Button>
          {isCopied.value ? (
            <Span className="clipboard-succes">Copié</Span>
          ) : (
            <Clipboard width={20} height={20} />
          )}
        </Button>
      </FlexRow>
    </FlexColumn>
  );
}
