import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInfo: {},
  isProfileLoad: false,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setUserProfile: (state, action) => {
      state.isProfileLoad = true;
      state.userInfo = action.payload;
    },
    resetProfile: (state) => {
      state.isProfileLoad = initialState.isProfileLoad
      state.userInfo = initialState.userInfo
    }
  },
});

export const { setUserProfile, resetProfile } = profileSlice.actions;
export default profileSlice.reducer;
