import { useEffect } from "react";

function useBeforeUnload(hasChanges) {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!hasChanges) {
        event.preventDefault();
        event.returnValue = ""; // Requis pour certains navigateurs
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasChanges]);
}

export default useBeforeUnload;
