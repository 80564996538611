import React from "react";
import { Link } from "react-router-dom";
import { app } from "../../../constant";
import useSidebar from "../../../hook/useSidebar";
import Button from "../../tags/element/Button";
import Span from "../../tags/element/Span";
import FlexRow from "../../tags/layout/flex/public/FlexRow";
import CicawLogo from "../../tags/public/CicawLogo";
import PositionFix from "../../tags/public/PositionFix";
import MapList from "../MapList";
import MenuIco from "../ico/MenuIco";
//import TeamIco from "../ico/TeamIco";
import SearchIco from "./ico/SearchIco";
import ShoppingBag from "./ico/ShopingBag";
import UserSvg from "./ico/UserSvg";

const icoLinks = [
  /*{
    to:app.url.community,
    icon:(
        <TeamIco
          width={app.navbar.icoSize.width}
          height={app.navbar.icoSize.height}
        />
    )
  },*/
  {
    to: app.url.search,
    icon: (
      <SearchIco
      width={app.navbar.icoSize.width}
      height={app.navbar.icoSize.height}
      />
    ),
    className:"navbar___ico-search"
  },
  
  {
    to: app.url.card,
    icon: (
      <ShoppingBag
        width={app.navbar.icoSize.width}
        height={app.navbar.icoSize.height}
      />
    ),
  },
  {
    to: app.url.profile,
    icon: (
      <UserSvg
        width={app.navbar.icoSize.width}
        height={app.navbar.icoSize.height}
      />
    ),
  },
  
];

function NavbarSearch() {
  return (
    <Link className="navbar__search" to={app.url.search}>
      <FlexRow className="navbar__search-item" alignItems="center">
        <SearchIco
          width={15}
          height={15}
          className="navbar__search-searh-logo"
        />
        <Span>{app.navbar.inputTextPlaceholder}</Span>
      </FlexRow>
    </Link>
  );
}

export function NavIcoList() {
  const renderItem = ({to, icon, ...link}) =>  <Link {...link} to={to}>{icon}</Link>;

  return (
    <FlexRow className="navbar___ico gap-1_2">
      <MapList data={icoLinks} renderItem={renderItem} />
    </FlexRow>
  );
}

export default function Navbar() {
  const sidebar = useSidebar();
  const handleLogoClick = () => {
    if (window.location.pathname === app.url.home) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };
  return (
    <PositionFix top={0} className="navbar w-100">
      <FlexRow
        justifyContent="between"
        className="h-100 container-padding"
        alignItems="center"
      >
        <FlexRow flexGap="1" alignItems="center">
          <Button onClick={sidebar.show} className="navbar__button-menu">
            <MenuIco width="24" height="24" />
          </Button>
          <CicawLogo onClick={handleLogoClick} />
        </FlexRow>
        <NavbarSearch />
        <NavIcoList />
      </FlexRow>
    </PositionFix>
  );
}
