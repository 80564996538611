import React from "react";
import Card from "../card";
import SimpleHeader from "../card/header/SimpleHeader";
import FlexColumn from "../../tags/layout/flex/public/FlexColumn";

export default function ProfileCard({ title, description, children }) {
  return (
    <Card
      className="profile-card"
      header={<SimpleHeader title={title} description={description} />}
    >
      <FlexColumn>{children}</FlexColumn>
    </Card>
  );
}
