import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CardRender from "../../../components/public/card/body/CardRender";
import GoBackHeader from "../../../components/public/card/header/GoBackHeader";
import PaddingContainer from "../../../components/public/layout/PaddingContainer";
import { app, titleSyntax } from "../../../constant";
import { useMetadata } from "../../../hook/useMetadata";
import { usePageAnalize } from "../../../hook/usePageAnalize";
import { useScrollTop } from "../../../hook/useScroolTop";
import { useTitle } from "../../../hook/useTitle";

export default function SearchQuery() {
  const [searchParams] = useSearchParams();
  usePageAnalize();

  const q = searchParams.get("q");
  useMetadata("p=search_query");
  useTitle(titleSyntax(`Recherche '${q}'`));
  useScrollTop();
  const navigate = useNavigate();

  useEffect(() => {
    if (!q) {
      navigate(app.url.search);
    }
  }, [q, navigate]);

  return (
    <PaddingContainer className="padding-container-card cover-with-bg base-container-card">
      {searchParams.get("q") && (
        <CardRender
          filter
          url={`/api/search?q=${searchParams.get("q")}`}
          to={app.url.search}
          header={GoBackHeader}
          many
        />
      )}
    </PaddingContainer>
  );
}
