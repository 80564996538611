import React, { useEffect } from "react";
import Card from "../../../../components/public/card";
import SimpleHeader from "../../../../components/public/card/header/SimpleHeader";
import { ProductImageItem } from "../../../../components/public/manager/admin/renderItem";
import Skeleton from "../../../../components/public/Skeleton";
import FlexColumn from "../../../../components/tags/layout/flex/public/FlexColumn";
import useFetch from "../../../../hook/useFetch";
import usePost from "../../../../hook/usePost";

export default function SellerImage() {
  const [send, response] = usePost("/api/product/images/delete");
  const getImages = useFetch("/api/product/images");

  useEffect(() => {
    if (response && response.status === 1) {
      getImages.fetchData();
    }
  }, [response]);

  const handleDeleteImage = (id) => {
    send({ id: id });
  };

  if (getImages.loading || getImages.error)
    return (
      <FlexColumn
        flexGap="1"
        className="padding-1-em"
        style={{ background: "white", height: "200px" }}
      >
        <Skeleton height={20} width={100} />{" "}
        <Skeleton height={40} width={300} />{" "}
      </FlexColumn>
    );

  return (
    <Card
      className={"seller-image"}
      header={
        <SimpleHeader
          title={`Mes images (${getImages.response.length})`}
          description={
            "Voici une liste de vos images. Il est recommandé de supprimer les images non utilisées."
          }
        />
      }
    >
      <FlexColumn flexGap="1">
        {getImages.response.map((item, index) => (
          <ProductImageItem
            key={index}
            item={item}
            onDelete={handleDeleteImage}
          />
        ))}
      </FlexColumn>
    </Card>
  );
}
