import useFetch from "./useFetch";
import { useEffect, useState } from "react";
import useSubmit from "./useSubmit";
import { useNavigate } from "react-router-dom";

export const useForm = (
  url,
  header,
  getDependance = [],
  canNavigate = true,
  autoSend = true,
) => {
  const getData = useFetch(url, getDependance, autoSend);
  const [formSubmit, formResponse, FormError] = useSubmit(url, header);
  const [formData, setFormData] = useState([]);
  const [formDisabled, setFormDisabled] = useState(false);
  const [formProps, setFomProps_] = useState({});
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    await formSubmit(e, formProps, setFormDisabled);
  };

  const setFomProps = (value) => {
    setFomProps_((state) => ({ ...state, ...value }));
  };

  useEffect(() => {
    if (formResponse) {
      setFormData(formResponse.data);
      if (canNavigate && formResponse.is_valid) {
        navigate(formResponse.sucecess_redirect);
      }
    }
  }, [formResponse, navigate]);

  useEffect(() => {
    if (getData.response) {
      setFormData(getData.response);
    }
  }, [getData.response]);

  return {
    getData,
    response: getData.response,
    loading: getData.loading,
    error: getData.error,
    fetchData: getData.fetchData,
    formData,
    formDisabled,
    handleSubmit,
    FormError,
    setFomProps,
    formResponse,
  };
};
