import React, { useState } from "react";
import Div from "../../../tags/element/Div";
import Li from "../../../tags/element/Li";
import MapList from "../../MapList";
import { TagSectionContainer } from "../../home/TagSection";
import TagInput from "./TagInput";

export default function TagRender({ onChange, value = "" }) {
  const [TagValue, setTagValue] = useState(value);

  const filterExistedTag = () => {
    return TagValue.split(",").filter((data) => data);
  };

  const handleChange = (e) => {
    setTagValue(e.target.value);
    onChange && onChange(e);
  };

  const renderItem = (item) => {
    if (item)
      return (
        <Li>
          <Div className="tag__suggestion__list-tag">{item}</Div>
        </Li>
      );
  };

  return (
    <>
      <TagInput onChange={handleChange} value={TagValue} />
      {filterExistedTag().length >= 1 && (
        <TagSectionContainer>
          <MapList data={filterExistedTag()} renderItem={renderItem} />
        </TagSectionContainer>
      )}
    </>
  );
}
