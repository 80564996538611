import React from "react";
import FlexColumn from "../../../components/tags/layout/flex/public/FlexColumn";
import FlexRow from "../../../components/tags/layout/flex/public/FlexRow";
import Div from "../../../components/tags/element/Div";
import Span from "../../../components/tags/element/Span";
import MapList from "../../../components/public/MapList";

function CartDetailsItem({ label, value }) {
  return (
    <FlexRow justifyContent="between" alignItems="center">
      <Div className="cart-summary-details__item-label">
        <Span>{label}</Span>
      </Div>
      <Div className="cart-summary-details__item-value">
        <Span>{value}</Span>
      </Div>
    </FlexRow>
  );
}

export default function CartDetails({ data }) {
  const renderItem = (item) => (
    <CartDetailsItem label={item.label} value={item.value} />
  );

  return (
    <Div className="cart-summary-details">
      <FlexColumn flexGap="1">
        <MapList data={data.meta_data} renderItem={renderItem} />
      </FlexColumn>
      <FlexRow
        justifyContent="between"
        alignItems="center"
        className="checkout-total"
      >
        <Div>
          <Span>Total</Span>
        </Div>
        <Div>
          <Span>{data.total_price}</Span>
        </Div>
      </FlexRow>
    </Div>
  );
}
