import React, { useState } from "react";
import Button from "../../../tags/element/Button";
import Span from "../../../tags/element/Span";
import FilterIco from "../../ico/FilterIco";
import FlexRow from "../../../tags/layout/flex/public/FlexRow";
import CustomModal from "../../../modal/CustomModal";
import H2 from "../../../tags/element/H2";
import Div from "../../../tags/element/Div";
import MapList from "../../MapList";
import ModalWithStickyButtonFooter from "../../ModalWithStickyButtonFooter";
import Separator from "../../Separator";
import { useBoolean } from "../../../../hook/useBoolean";

function PriceButtonItem({ item, data, setPriceData }) {
  const handleClick = () => {
    setPriceData((state) => {
      return { ...state, selected: item.id };
    });
  };
  return (
    <Button
      onClick={handleClick}
      className={item.id == data.selected ? "selected" : ""}
    >
      {item.label}
    </Button>
  );
}

export default function CardFilterSection({ onSubmit }) {
  const filterModal = useBoolean(false);
  const [priceData, setPriceData] = useState({
    selected: 0,
    items: [
      { id: 1, min: 1, max: 1000, label: "1 à 1 000 FCFA" },
      { id: 2, min: 1000, max: 5000, label: "1 000 à 5 000 FCFA" },
      { id: 3, min: 5000, max: 10000, label: "5 000 à 10 000 FCFA" },
      { id: 4, min: 10000, max: 50000, label: "10 000 à 50 000 FCFA" },
      { id: 5, min: 50000, max: 100000, label: "50 000 à 100 000 FCFA" },
      { id: 6, min: 100000, max: 500000, label: "100 000 à 500 000 FCFA" },
      { id: 7, min: 500000, max: Infinity, label: "500 000 FCFA et plus" },
    ],
  });

  const resetFilter = () => {
    setPriceData((state) => ({ ...state, selected: 0 }));
  };

  const filterSubmit = () => {
    const selectedItem = priceData.items.find(
      (item) => item.id === priceData.selected,
    );
    onSubmit && onSubmit(selectedItem);
    filterModal.toFalse();
  };

  return (
    <>
      <CustomModal
        onHid={filterModal.toFalse}
        isVisible={filterModal.value}
        title={"Filtre"}
        className={"card-filter-modal-item"}
        containerClassName={"card-filter-modal-container"}
      >
        <Div className="padding-1-em">
          <H2>Prix</H2>
          <FlexRow flexWrap="wrap" className="price-range-container" f>
            <MapList
              data={priceData.items}
              renderItem={(item) => (
                <PriceButtonItem
                  data={priceData}
                  setPriceData={setPriceData}
                  item={item}
                />
              )}
            />
          </FlexRow>
          <ModalWithStickyButtonFooter>
            <FlexRow justifyContent="between">
              <Button onClick={resetFilter} className="filter-cancel-btn">
                Effacer les filtres
              </Button>
              <Button onClick={filterSubmit} className="filter-continue-btn">
                Continuer
              </Button>
            </FlexRow>
            <Separator />
          </ModalWithStickyButtonFooter>
        </Div>
      </CustomModal>
      <Button onClick={filterModal.toTrue} className="card-filter-button">
        <FlexRow alignItems="center">
          <FilterIco width="16" height="16" />
          <Span>Filtre</Span>
        </FlexRow>
      </Button>
    </>
  );
}
