import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Card from '../../../components/public/card';
import CardRender from '../../../components/public/card/body/CardRender';
import Dot from '../../../components/public/Dot';
import ErrorMessageRetryButton from '../../../components/public/layout/ErrorMessageRetryButton';
import PaddingContainer from '../../../components/public/layout/PaddingContainer';
import Avatar from '../../../components/public/layout/UserAvatar';
import Separator from '../../../components/public/Separator';
import Skeleton from '../../../components/public/Skeleton';
import Button from '../../../components/tags/element/Button';
import H1 from '../../../components/tags/element/H1';
import P from '../../../components/tags/element/P';
import Span from '../../../components/tags/element/Span';
import Strong from '../../../components/tags/element/Strong';
import FlexColumn from '../../../components/tags/layout/flex/public/FlexColumn';
import FlexRow from '../../../components/tags/layout/flex/public/FlexRow';
import { useSubscribeToSeller } from '../../../hook/subscribe/seller/useSubscribeToSeller';
import useBodyClass from '../../../hook/useBodyClass';
import { useScrollTop } from '../../../hook/useScroolTop';
import "../../../styles/shop-item.css";
import useDynamicComponent from '../../../hook/useDynamicComponent';
import { formImageUrlForDebug } from '../../../modules/utils';
import Img from '../../../components/tags/element/Img';


function CardHeadLoader() {
  return (
    <Card>
      <FlexRow flexGap="1" alignItems="center">
        <Skeleton width={62} height={62} style={{borderRadius:"50%"}}/>
        <FlexColumn>
          <Skeleton width={173} height={33}/>
          <Separator margin='0.1em 0'/>
          <Skeleton width={100} height={15}/>
          <Separator margin='0.1em 0'/>
          <Skeleton width={173} height={15}/>
          <Separator margin='0.1em 0'/>
          <FlexRow>
            <Skeleton width={82.5} height={30.31} style={{borderRadius:"999px", marginRight:"5px"}}/>
            <Skeleton width={82.5} height={30.31} style={{borderRadius:"999px"}}/>
          </FlexRow>
        </FlexColumn>
      </FlexRow>
    </Card>
  )
}

function CardHeadBody({data, subscriber}) {
  const dynamicComponent = useDynamicComponent()
  const location = useLocation()
  const handleShare  = () => {
    dynamicComponent.activeComponent("share-modal", {pathname:location.pathname})
  }
  
  const handleMoreInfo = () => {
    dynamicComponent.activeComponent("modal-text-info", {text: data.description})

  }
  
  return (
    <Card className="shop-item-card">
      <FlexRow alignItems="center" flexGap="1" className="shop-item-header">
        {
          data.profile ?
          <Img
          width="62" height="62" style={{borderRadius:"50%", objectFit:"cover"}} src={formImageUrlForDebug(data.profile)}/>:
          <Avatar color={data.profile_color} size={62} label={data.name} className="shop-item-avatar" />
        }
        <FlexColumn className="shop-item-info">
          <H1 className="shop-item-title" style={{ margin: "0" }}>{data.name}</H1>
          <P className="shop-item-stats">
            <Span className="shop-item-followers">{`${subscriber.followerCount} ${subscriber.followerText}`}</Span>
            <Dot className="shop-item-separator" />
            <Span className="shop-item-products">{data.total_products}</Span>
          </P>
          <P className="shop-item-more-info" onClick={handleMoreInfo}>
            <Span>En savoir plus </Span>
            <Strong>...plus</Strong>
          </P>
          <FlexRow className="shop-item-actions">
            <Button className={"shop-item-subscribe-button "+(!subscriber.isSubscribed.value? "not-subscribed": "")} onClick={subscriber.handleSubscribe}>
            {subscriber.isSubscribed.value ? "Abonné" : "S'abonner"}
            </Button>
            <Button onClick={handleShare} className="shop-item-share-button">{"Partager"}</Button>
          </FlexRow>
        </FlexColumn>
      </FlexRow>
    </Card>
  )
}

export default function ShopItem() {
  const { name } = useParams();
  useBodyClass("shop-item-page");
  useScrollTop();
  const url = `/api/shop/${name}/subscribe`
  
  const subscriber = useSubscribeToSeller(url)

  return (
    <PaddingContainer className="shop-item-container bg-white base-container-card padding-container-card" >
      {
        subscriber.getData.loading ? <CardHeadLoader/>: 
        subscriber.getData.error ? <ErrorMessageRetryButton onRetry={() => subscriber.getData.fetchData({loading:true})} />:
        <CardHeadBody subscriber={subscriber} data={subscriber.getData.response}/>
      }
      <CardRender
        url={`/api/shop/${name}/products`}
      />
    </PaddingContainer>
  );
}
