import React from "react";
import { useSelector } from "react-redux";
import GradientButton from "../../../components/public/GradientButton";
import FlexColumn from "../../../components/tags/layout/flex/public/FlexColumn";
import { toastData } from "../../../constant";
import useCartManager from "../../../hook/useCartManager";
import useToastManager from "../../../hook/useToastManager";
import useDynamicComponent from "../../../hook/useDynamicComponent";
import { useLocation } from "react-router-dom";

export default function DetailsAddToCartComponent({ data }) {
  const isAuthenticated = useSelector((state) => state.app.isAuthenticated);
  const cartManager = useCartManager();
  const toastManager = useToastManager();
  const dynamicComponent = useDynamicComponent()
  const location = useLocation()
  const cartButtonAction = () => {
    cartManager.addProduct(data.id);
    toastManager.addItem(toastData.addToCart(cartManager, data));
  };

  const handleBuyNow = (e) => {
    if (!isAuthenticated) {
      e.preventDefault();
      dynamicComponent.activeComponent("login-required-modal", {pathname: location.pathname})
    }
  };

  return (
    <>
      <FlexColumn flexGap="1" className="details-add-to-cart">
        {data.in_stock ? (
          <>
            <GradientButton type={"button"} onClick={cartButtonAction}>
              {cartManager.renderText(data.id)}
            </GradientButton>
            <GradientButton
              onClick={handleBuyNow}
              to={"/details/" + data.id + "/" + data.slug + "/buy-now"}
              gray
            >
              {cartManager.text.BUY_NOW}
            </GradientButton>
          </>
        ) : (
          <GradientButton type={"button"} disabled>
            {cartManager.text.SOLD_OUT}
          </GradientButton>
        )}
      </FlexColumn>
    </>
  );
}
