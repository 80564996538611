import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import CustomModal from "../../../../components/modal/CustomModal";
import ModalHeadWithMSGLink from "../../../../components/modal/ModalHeadWithMSGLink";
import AcceptTermOnContinue from "../../../../components/public/AcceptTermOnContinue";
import GradientButton from "../../../../components/public/GradientButton";
import ModalWithStickyButtonFooter from "../../../../components/public/ModalWithStickyButtonFooter";
import Card from "../../../../components/public/card";
import SimpleHeader from "../../../../components/public/card/header/SimpleHeader";
import FormLoaderButton from "../../../../components/public/layout/FormLoaderButton";
import InputLitsRender from "../../../../components/public/layout/InputLitsRender";
import Loader from "../../../../components/public/layout/Loader";
import MaxWidth from "../../../../components/public/layout/MaxWidth";
import RequestManager from "../../../../components/public/layout/RequestManager";
import TabsNavigation from "../../../../components/public/layout/TabsNavigation";
import SellerProfileCard from "../../../../components/public/manager/layout/profile/SellerProfileCard";
import Div from "../../../../components/tags/element/Div";
import Form from "../../../../components/tags/element/Form";
import Img from "../../../../components/tags/element/Img";
import FlexColumn from "../../../../components/tags/layout/flex/public/FlexColumn";
import FlexRow from "../../../../components/tags/layout/flex/public/FlexRow";
import { titleSyntax } from "../../../../constant";
import { useAddImage } from "../../../../hook/useAddImage";
import { useBoolean } from "../../../../hook/useBoolean";
import useFetch from "../../../../hook/useFetch";
import { useForm } from "../../../../hook/useForm";
import { useScrollTop } from "../../../../hook/useScroolTop";
import { useTitle } from "../../../../hook/useTitle";
import P from "../../../../components/tags/element/P";

const tabsList = [
  {
    to: "/seller/products",
    label: "Produit",
    id: 1,
  },
  {
    to: "/seller/orders",
    label: "Commande",
    id: 2,
  },

  {
    to: "/seller/images",
    label: "Image",
    id: 3,
  },
];

function EditShopModal({ onHid, onEdited }) {
  const editForm = useForm("/api/seller/profile/shop/edit");

  useEffect(() => {
    if (editForm.formResponse && editForm.formResponse.is_valid) {
      onHid();
      onEdited && onEdited();
    }
  }, [editForm.formResponse]);

  return (
    <CustomModal
      onHid={onHid}
      head={
        <ModalHeadWithMSGLink
          onClick={onHid}
          title={"Modifier ma boutique"}
          msg={"Annuler"}
        />
      }
    >
      <Div className="padding-1-em">
        {editForm.loading || editForm.error ? (
          <Loader modal />
        ) : (
          <Form onSubmit={editForm.handleSubmit}>
            <SimpleHeader
              title={"Modifier les informations de ma boutique"}
              description={<AcceptTermOnContinue />}
            />
            <FlexColumn flexGap="1">
              <InputLitsRender
                formData={editForm.formData}
                formDisabled={editForm.formDisabled}
              />
              <ModalWithStickyButtonFooter>
                <FormLoaderButton form={editForm} label="Modifier" />
              </ModalWithStickyButtonFooter>
            </FlexColumn>
          </Form>
        )}
      </Div>
    </CustomModal>
  );
}


function SellerProfileImageEdit({onHid, addImage}) {
  return (
    <CustomModal head className={"seller-proile-image-modal"} containerClassName={"seller-proile-image-modal-container"}>
      <Div className="padding-1-em">
        <FlexColumn justifyContent="center" alignItems="center" flexGap="1">
          <Img src={addImage.selectedImage} width="200" height="200" style={{borderRadius:"50%", objectFit: "cover", border: '2px solid #ddd'}}/>
          <P>{addImage.fileName}</P>
        </FlexColumn>
        <ModalWithStickyButtonFooter>
          <Div style={{fontSize:".9em", marginBottom:".5em"}}>
              <AcceptTermOnContinue/>
            </Div>
          <FlexRow flexGap="1">
            <GradientButton
              onClick={addImage.handleSubmit}
              type={"button"}
              isLoading={addImage.isUploading}
              disabled={addImage.isUploading}
            >
              Continuer
            </GradientButton>
            <GradientButton
              onClick={onHid}
              gray
              type={"button"}
              disabled={addImage.isUploading}
            >
              Annuler
            </GradientButton>
          </FlexRow>
        </ModalWithStickyButtonFooter>
      </Div>
    </CustomModal>
  )
}



export default function SellerProfile() {
  useScrollTop();
  const editModalManager = useBoolean();
  const editImageModalManager = useBoolean();
  const getProfile = useFetch("/api/seller/profile");
  const title = getProfile.response?.user.full_name;
  useTitle(titleSyntax(title));

  const onImageUploaded = () => {
    getProfile.fetchData({loading:true})
  }
  const addImage = useAddImage("/api/seller/change/profile-image", onImageUploaded, "profile")
  
  const closeImageModal = () => {
    editImageModalManager.toFalse()
    addImage.reset()
  }
  useEffect(() => {
    if (addImage.isImageSelected) {
      editImageModalManager.set(true); // Ouvrir le modal lorsque l'image est sélectionnée
    } else {
      editImageModalManager.set(false); // Fermer le modal lorsqu'il n'y a pas d'image sélectionnée
    }
  }, [addImage.isImageSelected]);

  if (getProfile.loading || getProfile.error)
    return <RequestManager request={getProfile} />;

  return (
    <>
      {editModalManager.value && (
        <EditShopModal
          onEdited={getProfile.fetchData}
          onHid={editModalManager.toFalse}
        />
      )}
      {editImageModalManager.value && (
  
        <SellerProfileImageEdit addImage={addImage} onHid={closeImageModal
        }/>
      )}
      <MaxWidth>
        <Card>
          <SellerProfileCard
            profileColor={getProfile.response.user.profile_color}
            shopName={getProfile.response.shop.name}
            productTotal={getProfile.response.product_total}
            username={getProfile.response.user.username}
            full_name={getProfile.response.user.full_name}
            shopDescription={getProfile.response.shop.description}
            profile={getProfile.response.shop.profile}
            onEditClick={editModalManager.toTrue}
            editImage={editImageModalManager}
            addImage={addImage}
            
          />
        </Card>
      </MaxWidth>

      <Card className={"card-render"}>
        <TabsNavigation data={tabsList} />
      </Card>
      <Outlet />
    </>
  );
}
