import { useEffect } from "react";
import useFetch from "./useFetch";
import { useDispatch, useSelector } from "react-redux";
import { setUserProfile } from "../features/main/profileSlice";

export const useProfile = (fetchNow = false) => {
  const getProfile = useFetch("/api/profile", [], fetchNow);
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);

  /*useEffect(() => {
        if (fetchNow && !getProfile.response) {
            getProfile.fetchData()
        }
    }, [])*/

  const fetchData = async () => {
    await getProfile.fetchData();
  };

  useEffect(() => {
    if (getProfile.response) {
      dispatch(setUserProfile(getProfile.response));
    }
  }, [getProfile.response]);

  return {
    profile,
    getProfile,
    fetchData,
  };
};
