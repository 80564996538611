import React from "react";
import Div from "../element/Div";
import { app } from "../../../constant";
import { Link } from "react-router-dom";

export default function CicawLogo({ ...props }) {
  return (
    <Div className="cicaw__logo">
      <Link {...props} to={app.url.home}>
        {app.appName}
      </Link>
    </Div>
  );
}
