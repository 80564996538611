import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  carts: [],
  isAuthenticated: false,
  token: "",
  session: "",
  history: [],
  feedbackModalVisible: false,
};

const appSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    addProductToCart: (state, action) => {
      const id = action.payload;
      state.carts.push({ id: id, quantity: 1 });
    },
    removeProductFromCart: (state, action) => {
      const id = action.payload;
      state.carts = state.carts.filter((cart) => cart.id !== id);
    },
    addGroupCart: (state, action) => {
      state.carts = action.payload;
    },
    changeQuantity: (state, action) => {
      const { id, quantity } = action.payload;
      state.carts = state.carts.map((item) => {
        if (item.id === id) {
          return { ...item, quantity: quantity };
        }
        return item;
      });
    },

    setAuthenticated: (state, action) => {
      if (action.payload === true) state.isAuthenticated = true;
      else state.isAuthenticated = false;
    },

    setToken: (state, action) => {
      state.token = action.payload;
    },
    setSession: (state, action) => {
      state.session = action.payload;
    },

    historyAdd: (state, action) => {
      state.history.push(action.payload);
    },
    hideFeedbackModal: (state) => {
      state.feedbackModalVisible = false;
    },
    showFeedbackModal: (state) => {
      state.feedbackModalVisible = true;
    },
  },
});

export const {
  addProductToCart,
  removeProductFromCart,
  addGroupCart,
  changeQuantity,
  setAuthenticated,
  setToken,
  setSession,
  historyAdd,
  hideFeedbackModal,
  showFeedbackModal,
} = appSlice.actions;
export default appSlice.reducer;
