import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useBoolean } from "../../hook/useBoolean";
import useFeedback from "../../hook/useFeedback";
import usePost from "../../hook/usePost";
import CustomModal from "../modal/CustomModal";
import Button from "../tags/element/Button";
import Div from "../tags/element/Div";
import H2 from "../tags/element/H2";
import Li from "../tags/element/Li";
import P from "../tags/element/P";
import Span from "../tags/element/Span";
import Ul from "../tags/element/Ul";
import FlexRow from "../tags/layout/flex/public/FlexRow";
import AcceptTermOnContinue from "./AcceptTermOnContinue";
import Card from "./card";
import Close from "./ico/Close";
import Loader from "./layout/Loader";

export default function Feedback() {
  const feedback = useFeedback();
  const [commentValue, setCommentValue] = useState("");
  const [send, response, loading, error] = usePost("/api/feedback", false);
  const isSubmitted = useBoolean(false);
  const sessionId = useSelector((state) => state.visitor.sessionId);

  const handleSubmit = async () => {
    await send({ content: commentValue, sessionId: sessionId });
    setCommentValue("");
  };

  useEffect(() => {
    if (response) {
      if (response.status === 1) {
        isSubmitted.toTrue();
      }
    }
  }, [response]);

  const handleChange = (e) => {
    const value = e.target.value;
    setCommentValue(value);
  };
  useEffect(() => {
    if (feedback.isModalVisible) {
      isSubmitted.toFalse();
    }
  }, [feedback.isModalVisible]);

  return (
    feedback.isModalVisible && (
      <CustomModal
        head={
          isSubmitted.value ? (
            true
          ) : (
            <FlexRow className="feedback-header">
              <FlexRow
                className="feedback-close__btn"
                onClick={() => feedback.hide()}
                alignItems="center"
                justifyContent="center"
              >
                <Close width={22} />
              </FlexRow>
              <Div>Envoyer des commentaires à Cicaw</Div>
            </FlexRow>
          )
        }
        containerClassName={"feedback-modal"}
        className={"feedback-modal__item"}
      >
        {!isSubmitted.value ? (
          <Div className="padding-1-em">
            <P className="p-input-title">Écrivez votre avis</P>
            <textarea
              value={commentValue}
              onChange={handleChange}
              placeholder="Écrivez-nous ce que nous devons améliorer..."
            ></textarea>
            {error && (
              <P className="feedback-post-error">
                {"Une erreur s'est produite. Réessayez"}
              </P>
            )}
            <P className="p-input-warning">
              {
                "Veuillez ne pas fournir d'informations sensibles ou confidentielles."
              }
            </P>

            <P className="p-input-subtitle">
              Nous apprécions vos commentaires!
            </P>
            <Ul className="feedback-guidelines">
              <Li>
                {
                  "Donnez des exemples spécifiques de ce que vous avez aimé ou n'avez pas aimé."
                }
              </Li>
              <Li>
                {
                  "Soyez aussi précis que possible dans vos suggestions d'amélioration."
                }
              </Li>
              <Li>
                Si vous avez rencontré des bugs, décrivez les étapes pour les
                reproduire.
              </Li>
              <Li>Vos commentaires sont anonymes et confidentiels.</Li>
            </Ul>

            <AcceptTermOnContinue onClick={() => feedback.hide()} />
            <Card className={"feedback-btn-card"}>
              {loading ? (
                <Loader modal />
              ) : (
                <Button onClick={handleSubmit} disabled={!commentValue}>
                  Envoyer
                </Button>
              )}
            </Card>
          </Div>
        ) : (
          <Div className="padding-1-em feedback-submitted">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="240"
              height="240"
              fill="rgba(255,255,255,1)"
            >
              <path d="M22 14H20V7.23792L12.0718 14.338L4 7.21594V19H14V21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H21C21.5523 3 22 3.44772 22 4V14ZM4.51146 5L12.0619 11.662L19.501 5H4.51146ZM19 22L15.4645 18.4645L16.8787 17.0503L19 19.1716L22.5355 15.636L23.9497 17.0503L19 22Z"></path>
            </svg>
            <H2>Commentaire envoyé, merci !</H2>
            <Div>
              <Span>
                Nous avons bien reçu vos commentaires. Merci pour votre retour !
              </Span>
            </Div>
            <Div>
              <Span>Nous les utiliserons pour améliorer notre service.</Span>
            </Div>
            <Button onClick={() => feedback.hide()}>Fermer</Button>
          </Div>
        )}
      </CustomModal>
    )
  );
}
