import React from "react";
import Div from "../../tags/element/Div";
import Span from "../../tags/element/Span";
import FlexColumn from "../../tags/layout/flex/public/FlexColumn";

function Modal({ height, spanLoader, ...props }) {
  const style = { paddingTop: "calc(90vh - 50vh)" };
  return (
    <Div style={style}>
      <Div style={height} className="loader-container" {...props}>
        {spanLoader}
      </Div>
    </Div>
  );
}

export default function Loader({ modal, ...props }) {
  const height = modal ? { height: "80%" } : {};
  const spanLoader = <Span className="main-loader"></Span>;
  return modal ? (
    <FlexColumn
      alignItems="center"
      style={height}
      className="loader-container"
      {...props}
    >
      {spanLoader}
    </FlexColumn>
  ) : (
    <Modal height={height} spanLoader={spanLoader} {...props} />
  );
}
