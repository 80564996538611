import { useDispatch } from "react-redux";
import { getToken, hasToken } from "../modules/auth";
import { setAuthenticated, setToken } from "../features/main/appSlice";
import { useEffect } from "react";

export const useAuthentication = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = getToken();
    if (hasToken) {
      dispatch(setToken(token));
      dispatch(setAuthenticated(hasToken()));
    }
  }, [dispatch]);
};
