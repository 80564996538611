import { useDispatch } from "react-redux";

import { setAuthenticated } from "../features/main/appSlice";
import { logout } from "../modules/auth";
import { resetProfile } from "../features/main/profileSlice";

export const useLogout = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    logout();
    dispatch(setAuthenticated(false));
    dispatch(resetProfile())
  };

  return handleLogout;
};
