import { useEffect, useRef } from "react";
const defaultOptions = {
  root: null, // Par défaut, l'observateur observe par rapport à la fenêtre du viewport.
  rootMargin: "0px",
  threshold: 0.0, // L'élément doit être 10% visible avant de déclencher l'observateur.
};
const useIntersectionObserver = (callback, options = defaultOptions, dependances=[]) => {
  const ref = useRef(null);
  const observer = new IntersectionObserver(callback, options);
  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, dependances);

  return {
    ref,
    observer,
  };
};

export default useIntersectionObserver;
