import React from "react";
import Div from "../../element/Div";

export default function FlexContainer({
  flexDirection,
  flexWrap,
  flexFlow,
  flexBasis,
  flexGrow,
  flexShrink,
  flex,
  flexGap,
  alignItems,
  justifyContent,
  children,
  className, // Renommez className pour éviter la confusion
  ...props
}) {
  const containerClasses = [
    "flex-container",
    flexGap && `flex-gap-${flexGap}`,
    flexDirection && `flex-direction-${flexDirection}`,
    flexWrap && `flex-wrap-${flexWrap}`,
    flexFlow && `flex-flow-${flexFlow}`,
    flexBasis && `flex-basis-${flexBasis}`,
    flexGrow && `flex-grow-${flexGrow}`,
    flexShrink && `flex-shrink-${flexShrink}`,
    flex && `flex-${flex}`,
    alignItems && `align-items-${alignItems}`,
    justifyContent && `justify-content-${justifyContent}`,
    className, // Ajoutez les classes supplémentaires sans écraser
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <Div className={containerClasses} {...props}>
      {children}
    </Div>
  );
}
