import { createSlice } from "@reduxjs/toolkit";
import { generate_random } from "../../modules/utils";

const initialState = {
  items: [],
};

const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    removeToastItem: (state, action) => {
      const newItems = state.items.filter((item) => item.id !== action.payload);
      state.items = newItems;
    },
    addToastItem: (state, action) => {
      const data = action.payload;
      if (!data.id) {
        data["id"] = generate_random(6);
      }
      state.items.push(data);
    },
  },
});

export const { removeToastItem, addToastItem } = toastSlice.actions;
export default toastSlice.reducer;
