/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { app } from "../../../constant";
import useFeedback from "../../../hook/useFeedback";
import useSidebar from "../../../hook/useSidebar";
import { concatenateClass } from "../../../tools/utils";
import Modal from "../../modal";
import ModalHeadWithMSGLink from "../../modal/ModalHeadWithMSGLink";
import Div from "../../tags/element/Div";
import Span from "../../tags/element/Span";
import SidebarBody from "./SidebarBody";




export default function Sidebar() {
  const sidebar = useSidebar();
  const navigate = useNavigate();
  const feedback = useFeedback();

  useEffect(() => {
    sidebar.hide();
  }, [navigate]);

  useEffect(() => {
    if (sidebar.sidebar.isVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [sidebar.sidebar.isVisible]);

  const hideSidebar = () => {
    sidebar.hide();
    const sidebarItem = document.querySelector(".sidebar-item .modal-body");
    sidebarItem.scroll(0, 0);
  };

  
  const handleFeedback = () => {
    sidebar.hide();
    feedback.show();
  }


  return (
    <Modal
      canVibrate={false}
      bodyClickNavigateTo={false}
      modalContainerClick={hideSidebar}
      containerClassName={concatenateClass([
        "sidebar",
        sidebar.sidebar.isVisible ? "" : "hidden",
      ])}
      className={"sidebar-item"}
      head={
        <ModalHeadWithMSGLink
          onClick={hideSidebar}
          title={
            <Div className="sidebar__cicaw-logo">
              <Span>cicaw</Span>
            </Div>
          }
          msg={app.text.close}
        />
      }
    >
      <SidebarBody handleFeedback={handleFeedback}/>
    </Modal>
  );
}
