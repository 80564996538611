import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toDetailDescription } from "../../../constant";
import Card from "../../../components/public/card";
import DefaultHeader from "../../../components/public/card/header/DefaultHeader";
import P from "../../../components/tags/element/P";
import Span from "../../../components/tags/element/Span";

export default function DetailsDescriptionCard({ description }) {
  const { id, slug } = useParams();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(toDetailDescription(id, slug));
  };
  return (
    <Card
      onClick={handleClick}
      className="details-description-card hover-bg  user-select-none"
      header={<DefaultHeader title="À propos du produit" />}
    >
      <P>
        {description}
        <Span style={{ fontWeight: "600" }}></Span>
      </P>
    </Card>
  );
}
