import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MapList from "../../../../components/public/MapList";
import SimpleHeader from "../../../../components/public/card/header/SimpleHeader";
import RequestManager from "../../../../components/public/layout/RequestManager";
import DefaultDashbordCard from "../../../../components/public/manager/admin/card/DefaultDashbordCard";
import DefaultDashbordCardListItem from "../../../../components/public/manager/admin/card/DefaultDashbordCardListItem";
import {
  becomeSellerDataItem,
  miniCardInfoItem,
  orderDataItem,
  productDataItem,
  regionDataItem,
  searchDataItem,
  topPageItem,
  usersDataItem,
  visitorDataItem,
} from "../../../../components/public/manager/admin/renderItem";
import UserSvg from "../../../../components/public/navbar/ico/UserSvg";
import Button from "../../../../components/tags/element/Button";
import Div from "../../../../components/tags/element/Div";
import H1 from "../../../../components/tags/element/H1";
import Img from "../../../../components/tags/element/Img";
import Span from "../../../../components/tags/element/Span";
import FlexColumn from "../../../../components/tags/layout/flex/public/FlexColumn";
import FlexRow from "../../../../components/tags/layout/flex/public/FlexRow";
import { app } from "../../../../constant";
import useFetch from "../../../../hook/useFetch";
import { useScrollTop } from "../../../../hook/useScroolTop";

export default function AdminDashbord() {
  useScrollTop();
  const [requestMsg, setRequestMsg] = useState("");
  const getDashboardData = useFetch("/api/admin/dashboard");

  useEffect(() => {
    if (getDashboardData.response) {
      setRequestMsg(getDashboardData.response.message);
    }
  }, [getDashboardData.response]);

  const handleRefesh = () => {
    setRequestMsg("Récupération des données...");
    getDashboardData.fetchData({
      loading: false,
      response: getDashboardData.response,
    });
  };

  if (getDashboardData.loading || getDashboardData.error)
    return <RequestManager request={getDashboardData} />;

  return (
    <Div>
      <SimpleHeader
        h={H1}
        title={
          <FlexRow flexWrap="wrap" alignItems="center" flexGap="1">
            <Span>Tableau de bord</Span>{" "}
            <Button
              style={{
                padding: ".5em",
                fontSize: ".8em",
                border: "none",
                borderRadius: "5px",
              }}
              onClick={handleRefesh}
            >
              Actualiser
            </Button>{" "}
            <Span className="header-last-update">{requestMsg}</Span>
          </FlexRow>
        }
      />
      <FlexColumn>
        <FlexRow flexGap="1" flexWrap="wrap">
          <MapList
            data={getDashboardData.response.miniDashboardInfo}
            renderItem={miniCardInfoItem}
          />
        </FlexRow>

        <FlexRow flexGap="1" flexWrap="wrap">
          <DefaultDashbordCard
            data={getDashboardData.response.lastSession}
            renderItem={(item) => {
              return (
                <DefaultDashbordCardListItem
                  left={<UserSvg width={16} />}
                  middle={
                    <Link
                      to={
                        "/admin/visitor/" +
                        item.visitorId +
                        "/session/" +
                        item.id
                      }
                    >
                      <FlexColumn>
                        <Div>{item.id}</Div>
                        <Div className="admin-order-data-item">
                          <Div>{item.added_date}</Div>
                          <Div>{item.pages.length} page(s)</Div>
                          <Div style={{ marginTop: ".2em" }}>{item.page}</Div>
                        </Div>
                      </FlexColumn>
                    </Link>
                  }
                />
              );
            }}
            label={"Session"}
          />
          <DefaultDashbordCard
            to={"/admin/visitor"}
            data={getDashboardData.response.lastVisitor}
            label={"Visiteur"}
            renderItem={visitorDataItem}
          />
        </FlexRow>

        <FlexRow flexGap="1" flexWrap="wrap">
          <DefaultDashbordCard
            to={app.url.adminOrder}
            label="Commande"
            data={getDashboardData.response.order}
            renderItem={orderDataItem}
          />
          <DefaultDashbordCard
            label="Produit"
            data={getDashboardData.response.product}
            renderItem={productDataItem}
          />
        </FlexRow>

        <FlexRow flexGap="1" flexWrap="wrap">
          <DefaultDashbordCard
            to={app.url.adminUser}
            data={getDashboardData.response.lastUser}
            renderItem={usersDataItem}
            label="Utilisateur"
          />
          <DefaultDashbordCard
            to={"/admin/product/images"}
            data={getDashboardData.response.images}
            renderItem={(item) => {
              return (
                <FlexRow flexGap="1" className="seller-image">
                  <Img src={item.image} />
                  <Div className="seller-image__render-item__container">
                    <Div className="seller-image-label seller-image__name">
                      <Span>{item.name}</Span>
                    </Div>
                    <Div className="seller-image-label seller-image__size">
                      <Span>{item.size} - </Span>
                      <Span>{item.added_date}</Span>
                    </Div>
                  </Div>
                </FlexRow>
              );
            }}
            label="Image"
          />
        </FlexRow>

        <FlexRow flexGap="1" flexWrap="wrap">
          <DefaultDashbordCard
            data={getDashboardData.response.lastBecomeSeller}
            renderItem={becomeSellerDataItem}
            label="Devenir vendeur"
          />
          <DefaultDashbordCard
            to={app.url.adminShop}
            label="Vendeur"
            data={getDashboardData.response.lastShop}
            renderItem={becomeSellerDataItem}
          />
        </FlexRow>

        <FlexRow flexGap="1" flexWrap="wrap">
          <DefaultDashbordCard
            data={getDashboardData.response.topPage}
            renderItem={topPageItem}
            label={"Top Page"}
          />
          <DefaultDashbordCard
            data={getDashboardData.response.lastSearch}
            label="Recherche"
            renderItem={searchDataItem}
          />
        </FlexRow>

        <FlexRow flexGap="1" flexWrap="wrap">
          <DefaultDashbordCard
            label="Platforme"
            data={[]}
            renderItem={regionDataItem}
          />
          <DefaultDashbordCard
            label="Appareil"
            data={[]}
            renderItem={regionDataItem}
          />
          <DefaultDashbordCard
            label="Language"
            data={[]}
            renderItem={regionDataItem}
          />
          <DefaultDashbordCard
            label="Connexion"
            data={[]}
            renderItem={regionDataItem}
          />
        </FlexRow>
      </FlexColumn>
    </Div>
  );
}
