import React from "react";
import FlexContainer from "../FlexContainer";

export default function FlexRow({ children, ...props }) {
  return (
    <FlexContainer {...props} flexDirection={"row"}>
      {children}
    </FlexContainer>
  );
}
