import { useState } from "react";

const initialImagesValue = {
  selectedInput: 0,
  images: {
    image1: null,
    image2: null,
    image3: null,
  },
};

export const useProductImageManager = () => {
  const [imagesValue, setImagesValue] = useState(initialImagesValue);

  const removeImage = (image) => {
    const newData = updateImage(image, null);
    const updatedImages = { ...imagesValue.images, ...newData };
    const updatedImagesValue = {
      ...imagesValue,
      images: updatedImages,
    };
    setImagesValue(updatedImagesValue);
  };

  const changeSelectedInput = (payload) => {
    setImagesValue((prevState) => ({
      ...prevState,
      selectedInput: payload,
    }));
  };

  const updateImage = (image, value) => {
    if (
      Object.keys(imagesValue.images).includes(image) &&
      value !== undefined
    ) {
      return { [image]: value };
    } else {
      return {};
    }
  };

  const handleImageSelect = (imageId) => {
    const newData = updateImage(`image${imagesValue.selectedInput}`, imageId);
    const updatedImages = { ...imagesValue.images, ...newData };
    const updatedImagesValue = {
      ...imagesValue,
      images: updatedImages,
    };
    setImagesValue(updatedImagesValue);
  };

  const updateImageValue = (image, value) => {
    setImagesValue((prevState) => ({
      ...prevState,
      images: {
        ...prevState.images,
        [image]: value,
      },
    }));
  };
  const resetImages = () => {
    setImagesValue(initialImagesValue);
  };

  return {
    imagesValue,
    changeSelectedInput,
    handleImageSelect,
    resetImages,
    updateImageValue,
    removeImage,
  };
};
