export function Authenticate(token) {
  localStorage.setItem("token", token);
}

export function getToken() {
  return localStorage.getItem("token");
}
export function removeToken() {
  return localStorage.removeItem("token");
}

export const hasToken = () => {
  return !!getToken();
};

export const logout = () => {
  removeToken();
};
