import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomModal from "../../../components/modal/CustomModal";
import GradientButton from "../../../components/public/GradientButton";
import IcoCheck from "../../../components/public/ico/IcoCheck";
import CenterByMaxWidth from "../../../components/public/layout/CenterByMaxWidth";
import FormLoaderButton from "../../../components/public/layout/FormLoaderButton";
import InputLitsRender from "../../../components/public/layout/InputLitsRender";
import PaddingContainer from "../../../components/public/layout/PaddingContainer";
import RequestManager from "../../../components/public/layout/RequestManager";
import Separator from "../../../components/public/Separator";
import Div from "../../../components/tags/element/Div";
import Form from "../../../components/tags/element/Form";
import H1 from "../../../components/tags/element/H1";
import H2 from "../../../components/tags/element/H2";
import P from "../../../components/tags/element/P";
import FlexColumn from "../../../components/tags/layout/flex/public/FlexColumn";
import FlexRow from "../../../components/tags/layout/flex/public/FlexRow";
import { app } from "../../../constant";
import { useBoolean } from "../../../hook/useBoolean";
import { useForm } from "../../../hook/useForm";
import "../../../styles/contact-us.css";
import { DetailsAssistance } from "../details/Details";

const ContactForm = ({ form }) => {
  return (
    <Form method="post" onSubmit={form.handleSubmit}>
      <FlexColumn flexGap="1">
        <InputLitsRender
          formData={form.formData}
          formDisabled={form.formDisabled}
        />
        {form.FormError && (
          <P className="incorrect-info">
            {"Une erreur s'est produite veuillez réessayer"}
          </P>
        )}
        <FormLoaderButton form={form} />
      </FlexColumn>
    </Form>
  );
};

const ContactSucces = () => {
  const navigate = useNavigate();
  const handleGoHome = () => {
    navigate(app.url.home);
  };
  return (
    <CustomModal onHid={handleGoHome} to={app.url.home} title={"Message reçu"}>
      <Div className="contact-succes padding-1-em">
        <IcoCheck className="succesful-order__ico" height={200} width={200} />
        <H2>Merci de nous avoir contactés</H2>
        <P>Nous reviendrons vers vous dans les plus brefs délais.</P>
        <Separator />
        <GradientButton to={app.url.home}>
          Retourner à la boutique
        </GradientButton>
      </Div>
    </CustomModal>
  );
};

export default function ContactUs() {
  const form = useForm("/api/contact-us");
  const isSucces = useBoolean(false);

  useEffect(() => {
    if (form.formResponse && form.formResponse.is_valid) {
      isSucces.toTrue();
    }
  }, [form.formResponse]);
  if (form.loading || form.error)
    return <RequestManager request={form.getData} />;
  return isSucces.value ? (
    <ContactSucces />
  ) : (
    <PaddingContainer className="padding-container-card cover-with-bg base-container-card contact-us">
      <CenterByMaxWidth maxWidth={"900px"}>
        <H1>Contacter notre équipe</H1>
        <P className="contact-description">
          {
            "Nous sommes à votre disposition pour répondre à toutes vos questions et préoccupations."
          }
        </P>
        <FlexRow className="contact-us__bottom-container" flexWrap="wrap">
          <ContactForm form={form} />
          <Div>
            <DetailsAssistance />
          </Div>
        </FlexRow>
      </CenterByMaxWidth>
    </PaddingContainer>
  );
}
