import React from "react";
import FlexRow from "../../../tags/layout/flex/public/FlexRow";
import Div from "../../../tags/element/Div";
import Span from "../../../tags/element/Span";
import PaddingContainer from "../../layout/PaddingContainer";
import GradientButton from "../../GradientButton";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { app } from "../../../../constant";

export default function ManagerNavbar() {
  const isAuthenticated = useSelector((state) => state.app.isAuthenticated);

  return (
    <Div className="seller-navbar">
      <PaddingContainer className="padding-container-card seller-layout">
        <FlexRow
          alignItems="center"
          className="seller-navbar__logo-container"
          justifyContent="between"
        >
          <Link to={app.url.sellerProduct} className="cicaw__logo">
            <Span>cicaw</Span>
          </Link>
          {isAuthenticated && (
            <FlexRow flexGap="1">
              <GradientButton to={"/seller/product/add"}>
                Ajouter
              </GradientButton>
              <GradientButton to="/" gray>
                Boutique
              </GradientButton>
            </FlexRow>
          )}
        </FlexRow>
      </PaddingContainer>
    </Div>
  );
}
