import React from "react";
import FlexRow from "../tags/layout/flex/public/FlexRow";
import { concatenateClass } from "../../tools/utils";

export default function ModalHead({ children, className = "" }) {
  return (
    <FlexRow
      className={concatenateClass(["modal-head", className])}
      alignItems="center"
      justifyContent="between"
    >
      {children}
    </FlexRow>
  );
}
