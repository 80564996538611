// useProfileField.js
import { useState, useEffect } from "react";
import useFetch from "./useFetch";
import { useNavigate } from "react-router-dom";
import usePost from "./usePost";
import { app } from "../constant";

const useProfileField = (apiUrl) => {
  const getProfile = useFetch(apiUrl);
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();
  const [fetchData, postResponse, , postError] = usePost(apiUrl);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsDisabled(true);
    const formData = new FormData(e.target);
    await fetchData({ data: Object.fromEntries(formData) });
    setIsDisabled(false);
  };

  useEffect(() => {
    if (postResponse) {
      navigate(app.url.profile);
    }
  }, [postResponse, navigate]);

  return { getProfile, postError, isDisabled, handleSubmit };
};

export default useProfileField;
