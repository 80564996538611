import React, { useEffect } from "react";
import useFetch from "../../../../../../hook/useFetch";
import Button from "../../../../../tags/element/Button";
import Div from "../../../../../tags/element/Div";
import Img from "../../../../../tags/element/Img";
import Card from "../../../../card";
import Close from "../../../../ico/Close";
import Loader from "../../../../layout/Loader";
import { formImageUrlForDebug } from "../../../../../../modules/utils";

function ProductImageDisplay({ getImage, imageManager, id }) {
  const handleClick = (e) => {
    e.stopPropagation();
    imageManager.removeImage(`image${id}`);
  };

  if (getImage.loading) return <Loader modal />;

  return (
    <Div>
      <Img src={formImageUrlForDebug(getImage.response.image)} />
      <Button onClick={handleClick}>
        <Close height={22} width={22} />
      </Button>
    </Div>
  );
}

export default function ProductImageInput({
  id,
  handleClick,
  value,
  imageManager,
}) {
  const getImage = useFetch(`/api/product/images/${value}`, [], false);

  useEffect(() => {
    value && getImage.fetchData();
  }, [value]);

  return (
    <Card onClick={() => handleClick(id)} className="image-item">
      {value ? (
        <ProductImageDisplay
          id={id}
          getImage={getImage}
          imageManager={imageManager}
        />
      ) : (
        <>image {id}</>
      )}
    </Card>
  );
}
