/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Button from "../../tags/element/Button";
import Div from "../../tags/element/Div";
import P from "../../tags/element/P";
import Span from "../../tags/element/Span";
import FlexColumn from "../../tags/layout/flex/public/FlexColumn";

export default function ErrorMessageRetryButton({ onRetry }) {
  return (
    <FlexColumn
      justifyContent="center"
      alignItems="center"
      className="padding-1-em error-message-container"
    >
      <P className="error-message">Une erreur s'est produite</P>
      <Div>
        <Span className="retry-text">Réessayez</Span>
      </Div>
      <Button onClick={() => onRetry()} className="retry-button">
        Réessayer
      </Button>
    </FlexColumn>
  );
}
