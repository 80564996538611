import React from "react";
import PagnationContainer from "./pagnation/PagnationContainer";
import { orderDataItem } from "../../../components/public/manager/admin/renderItem";

export default function AdminOrder() {
  return (
    <PagnationContainer
      url={"/api/admin/order"}
      renderItem={orderDataItem}
      title={"Commande"}
    />
  );
}
