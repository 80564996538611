import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToastItem, removeToastItem } from "../features/main/toastSlice";

const useToastManager = () => {
  const dispatch = useDispatch();
  const toast = useSelector((state) => state.toast);
  const currentItem = toast.items.length >= 1 ? toast.items[0] : null;

  const handleToastTimeout = (item) => {
    const timeout = item.timeout * 1000;
    const timer = setTimeout(() => {
      dispatch(removeToastItem(item.id));
    }, timeout);
    return timer;
  };

  useEffect(() => {
    if (currentItem) {
      const timer = handleToastTimeout(currentItem);
      return () => clearTimeout(timer);
    }
  }, [currentItem]);

  const addItem = (item) => {
    dispatch(addToastItem(item));
  };

  return {
    currentItem,
    addItem,
  };
};

export default useToastManager;
