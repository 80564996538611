import { toastData } from "../constant";
import useToastManager from "./useToastManager";

const useCopy = (onCopy) => {
  const toastManager = useToastManager();

  const copy = (text) => {
    // Sauvegarder la position de défilement actuelle
    const scrollPosition = window.scrollY;

    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(text)
        .then(() => {
          toastManager.addItem(toastData.copyLinkSucces());
          onCopy?.()
          // Restaurer la position de défilement
          window.scrollTo(0, scrollPosition);
        })
        .catch((err) => {
          console.error('Failed to copy using Clipboard API: ', err);
          fallbackCopyTextToClipboard(text, scrollPosition);
        });
    } else {
      fallbackCopyTextToClipboard(text, scrollPosition);
    }
  };

  const fallbackCopyTextToClipboard = (text, scrollPosition) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      const successful = document.execCommand('copy');
      if (successful) {
        onCopy?.()
        toastManager.addItem(toastData.copyLinkSucces());
      } else {
        throw new Error('Fallback method failed');
      }
    } catch (err) {
      console.error('Failed to copy using fallback method: ', err);
      toastManager.addItem(toastData.copyLinkError());
    }
    document.body.removeChild(textArea);
    
    // Restaurer la position de défilement
    window.scrollTo(0, scrollPosition);
  };

  return copy;

}

export default useCopy;
