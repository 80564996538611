import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../tags/element/Button";
import Div from "../../tags/element/Div";
import FlexRow from "../../tags/layout/flex/public/FlexRow";
import LeftLayoutIco from "../ico/LeftLayoutIco";
import UserAvatar from "../layout/UserAvatar";
import Span from "../../tags/element/Span";

export function NavbarActionButtonList({
  onSave,
  onWatch,
  hasChanges,
  isEditMode,
}) {
  const { id } = useParams();
  const navigate = useNavigate();

  const handleEditButtonClick = () => {
    if (window.location.pathname !== `/blog/edit/${id}`) {
      navigate(`/blog/edit/${id}`);
    }
    onWatch && onWatch();
  };
  return (
    <FlexRow flexGap="1" className="nav-action-btn-container">
      <Button
        onClick={onSave}
        className={"save-btn " + (hasChanges ? "unsave" : "")}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="18"
          height="18"
          fill="currentColor"
        >
          <path d="M12 2.58582L18.2071 8.79292L16.7929 10.2071L13 6.41424V16H11V6.41424L7.20711 10.2071L5.79289 8.79292L12 2.58582ZM3 18V14H5V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V14H21V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18Z"></path>
        </svg>
      </Button>
      <Button
        onClick={handleEditButtonClick}
        className={
          window.location.pathname === `/blog/edit/${id}` ? "active" : ""
        }
      >
        {isEditMode ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="18"
            height="18"
            fill="currentColor"
          >
            <path d="M12.0003 3C17.3924 3 21.8784 6.87976 22.8189 12C21.8784 17.1202 17.3924 21 12.0003 21C6.60812 21 2.12215 17.1202 1.18164 12C2.12215 6.87976 6.60812 3 12.0003 3ZM12.0003 19C16.2359 19 19.8603 16.052 20.7777 12C19.8603 7.94803 16.2359 5 12.0003 5C7.7646 5 4.14022 7.94803 3.22278 12C4.14022 16.052 7.7646 19 12.0003 19ZM12.0003 16.5C9.51498 16.5 7.50026 14.4853 7.50026 12C7.50026 9.51472 9.51498 7.5 12.0003 7.5C14.4855 7.5 16.5003 9.51472 16.5003 12C16.5003 14.4853 14.4855 16.5 12.0003 16.5ZM12.0003 14.5C13.381 14.5 14.5003 13.3807 14.5003 12C14.5003 10.6193 13.381 9.5 12.0003 9.5C10.6196 9.5 9.50026 10.6193 9.50026 12C9.50026 13.3807 10.6196 14.5 12.0003 14.5Z"></path>
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="18"
            height="18"
            fill="currentColor"
          >
            <path d="M15.7279 9.57627L14.3137 8.16206L5 17.4758V18.89H6.41421L15.7279 9.57627ZM17.1421 8.16206L18.5563 6.74785L17.1421 5.33363L15.7279 6.74785L17.1421 8.16206ZM7.24264 20.89H3V16.6473L16.435 3.21231C16.8256 2.82179 17.4587 2.82179 17.8492 3.21231L20.6777 6.04074C21.0682 6.43126 21.0682 7.06443 20.6777 7.45495L7.24264 20.89Z"></path>
          </svg>
        )}
      </Button>
      <Button
        className={
          window.location.pathname === "/blog/edit/" + id + "/settings"
            ? "active"
            : ""
        }
        onClick={() => navigate("/blog/edit/" + id + "/settings")}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="18"
          height="18"
          fill="currentColor"
        >
          <path d="M8.68637 4.00008L11.293 1.39348C11.6835 1.00295 12.3167 1.00295 12.7072 1.39348L15.3138 4.00008H19.0001C19.5524 4.00008 20.0001 4.4478 20.0001 5.00008V8.68637L22.6067 11.293C22.9972 11.6835 22.9972 12.3167 22.6067 12.7072L20.0001 15.3138V19.0001C20.0001 19.5524 19.5524 20.0001 19.0001 20.0001H15.3138L12.7072 22.6067C12.3167 22.9972 11.6835 22.9972 11.293 22.6067L8.68637 20.0001H5.00008C4.4478 20.0001 4.00008 19.5524 4.00008 19.0001V15.3138L1.39348 12.7072C1.00295 12.3167 1.00295 11.6835 1.39348 11.293L4.00008 8.68637V5.00008C4.00008 4.4478 4.4478 4.00008 5.00008 4.00008H8.68637ZM6.00008 6.00008V9.5148L3.5148 12.0001L6.00008 14.4854V18.0001H9.5148L12.0001 20.4854L14.4854 18.0001H18.0001V14.4854L20.4854 12.0001L18.0001 9.5148V6.00008H14.4854L12.0001 3.5148L9.5148 6.00008H6.00008ZM12.0001 16.0001C9.79094 16.0001 8.00008 14.2092 8.00008 12.0001C8.00008 9.79094 9.79094 8.00008 12.0001 8.00008C14.2092 8.00008 16.0001 9.79094 16.0001 12.0001C16.0001 14.2092 14.2092 16.0001 12.0001 16.0001ZM12.0001 14.0001C13.1047 14.0001 14.0001 13.1047 14.0001 12.0001C14.0001 10.8955 13.1047 10.0001 12.0001 10.0001C10.8955 10.0001 10.0001 10.8955 10.0001 12.0001C10.0001 13.1047 10.8955 14.0001 12.0001 14.0001Z"></path>
        </svg>
      </Button>
    </FlexRow>
  );
}

export default function MobileNavbar({
  sidebarVisiblityManager,
  onSave,
  OnWatch,
  hasChanges,
  isEditMode,
}) {
  const navigate = useNavigate();
  useEffect(() => {
    sidebarVisiblityManager.toFalse();
  }, [navigate]);
  return (
    <Div className="mobile-navbar">
      <FlexRow alignItems="center">
        <Button
          className="sidebar-open-btn"
          onClick={() => sidebarVisiblityManager.toTrue()}
        >
          <LeftLayoutIco width="18" height="18" />
        </Button>
        <Div>
          <Span>cicaw</Span>
        </Div>
      </FlexRow>

      <NavbarActionButtonList
        onSave={onSave}
        onWatch={OnWatch}
        hasChanges={hasChanges}
        isEditMode={isEditMode}
      />

      <UserAvatar label={"c d"} />
    </Div>
  );
}
