import React from "react";
import { app } from "../../constant";
import useLoginRequired from "../../hook/useLoginRequired";
import { useScrollTop } from "../../hook/useScroolTop";
import useRedirect from "../../hook/userRedirect";
import CustomModal from "../modal/CustomModal";
import FlexColumn from "../tags/layout/flex/public/FlexColumn";
import GradientButton from "./GradientButton";
import Div from "../tags/element/Div";
import H1 from "../tags/element/H1";
import P from "../tags/element/P";
import Hr from "../tags/element/Hr";

export function LoginRequiredModal({ onGoback }) {
  const redirect = useRedirect();

  return (
    <CustomModal
      containerClassName="login-required-modal__container"
      className="login-required-modal__item"
      hasHeader={false}
    >
      <Div style={{ height: "100%" }} className="padding-1-em">
        <FlexColumn style={{ height: "100%" }} justifyContent="between">
          <H1>{app.page.auth.text.head.title} </H1>
          <P>{app.page.auth.text.head.description}</P>
          <Hr />
          <FlexColumn flexGap="1">
            <GradientButton to={redirect.to(app.url.login)}>
              {app.text.login}
            </GradientButton>
            <GradientButton onClick={onGoback && onGoback} to={".."} gray>
              {app.text.back}
            </GradientButton>
          </FlexColumn>
        </FlexColumn>
      </Div>
    </CustomModal>
  );
}

export default function LoginRequired({ component }) {
  useScrollTop();
  const loginRequired = useLoginRequired();

  return loginRequired.isAuthenticated ? component : <LoginRequiredModal />;
}
