import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "../../components/public/card";
import GradientButton from "../../components/public/GradientButton";
import ChevronDown from "../../components/public/ico/ChevronDown";
import ChevronUp from "../../components/public/ico/ChevronUp";
import DeliveryCar from "../../components/public/ico/DeliveryCar";
import LocationIco from "../../components/public/ico/LocationIco";
import Phone from "../../components/public/ico/Phone";
import Wallet from "../../components/public/ico/Wallet";
import PaddingContainer from "../../components/public/layout/PaddingContainer";
import UserAvatar from "../../components/public/layout/UserAvatar";
import Separator from "../../components/public/Separator";
import Button from "../../components/tags/element/Button";
import Div from "../../components/tags/element/Div";
import H1 from "../../components/tags/element/H1";
import H2 from "../../components/tags/element/H2";
import H3 from "../../components/tags/element/H3";
import Li from "../../components/tags/element/Li";
import P from "../../components/tags/element/P";
import Span from "../../components/tags/element/Span";
import Ul from "../../components/tags/element/Ul";
import FlexColumn from "../../components/tags/layout/flex/public/FlexColumn";
import FlexRow from "../../components/tags/layout/flex/public/FlexRow";
import { useBoolean } from "../../hook/useBoolean";
import useIntersectionObserver from "../../hook/useIntersectionObserver";
import auditImage from "../../image/audit.png";
import securityImage from "../../image/security.png";
import supportImage from "../../image/support.png";
import "../../styles/seller-overview.css";
import { app } from "../../constant";
import { useScrollTop } from "../../hook/useScroolTop";
import { usePageAnalize } from "../../hook/usePageAnalize";
import { useMetadata } from "../../hook/useMetadata";

function CardWithObserver({ children, comeFrom = "left" }) {
  const [isObserved, setIsObserved] = useState(false);
  const observer = useIntersectionObserver(([entries]) => {
    if (entries.isIntersecting) {
      setIsObserved(true);
      observer.observer.disconnect();
    } else {
      setIsObserved(false);
    }
  });

  useEffect(() => {
    return () => observer.observer.disconnect();
  }, []);

  return (
    <div
      ref={observer.ref}
      className={
        isObserved
          ? "observer-animation-visible"
          : "observer-animation-hide " + comeFrom
      }
    >
      {children}
    </div>
  );
}

function FAQCard() {
  const [faqs, setFaqs] = useState([
    {
      question: "Comment puis-je m'inscrire en tant que vendeur sur Cicaw ?",
      answer:
        "Pour vous inscrire en tant que vendeur, cliquez sur le bouton en haut de la page d'accueil, remplissez le formulaire d'inscription et suivez les instructions pour vérifier votre compte.",
      isOpen: false,
    },
    {
      question: "Quels sont les frais pour vendre sur Cicaw ?",
      answer:
        "L'inscription sur Cicaw est gratuite. Nous prenons une commission de 5% sur chaque vente réalisée sur notre plateforme.",
      isOpen: false,
    },
    {
      question: "Comment puis-je gérer mon inventaire ?",
      answer:
        "Vous pouvez gérer votre inventaire en utilisant notre tableau de bord vendeur, qui vous permet d'ajouter, de modifier et de supprimer des produits facilement.",
      isOpen: false,
    },
    {
      question: "Que faire si j'ai besoin d'aide avec une transaction ?",
      answer:
        "Si vous avez besoin d'aide avec une transaction, notre équipe de support client est disponible 24/7. Vous pouvez les contacter via le chat en direct, par email ou par téléphone.",
      isOpen: false,
    },
    {
      question: "Comment puis-je optimiser mes ventes sur Cicaw ?",
      answer:
        "Utilisez nos outils de marketing intégrés, participez à nos promotions et assurez-vous que vos listings sont complets et attrayants. Vous pouvez également consulter nos ressources et guides pour des conseils supplémentaires.",
      isOpen: false,
    },
  ]);

  const toggleFAQ = (index) => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.isOpen = !faq.isOpen;
        }
        return faq;
      }),
    );
  };

  return (
    <Card className="faq-card">
      <Div className="faq-card__item">
        <H2>Foire Aux Questions (FAQ)</H2>
        <ul className="faq-list">
          {faqs.map((faq, index) => (
            <li key={index}>
              <FlexRow
                onClick={() => toggleFAQ(index)}
                justifyContent="between"
              >
                <H3 style={{ cursor: "pointer" }}>{faq.question}</H3>
                {faq.isOpen ? (
                  <Div className="shrink-0">
                    <ChevronUp width={30} style={{ cursor: "pointer" }} />
                  </Div>
                ) : (
                  <Div className="shrink-0">
                    <ChevronDown width={30} style={{ cursor: "pointer" }} />
                  </Div>
                )}
              </FlexRow>
              {faq.isOpen && <P>{faq.answer}</P>}
            </li>
          ))}
        </ul>
      </Div>
    </Card>
  );
}

function InformationCard() {
  return (
    <Card className="information-card">
      <FlexColumn flexGap="1">
        <FlexRow
          flexGap="1"
          className="information-card__item"
          justifyContent="between"
        >
          <Div className="information-card__section">
            <Div className="information-card__icon">
              <LocationIco width={30} />
            </Div>
            <Div className="information-card__content">
              <H3 className="information-card__title">Localisation</H3>
              <P className="information-card__text">
                {
                  "Pour être vendeur sur Cicaw, vous devez être basé dans la région de Dakar ou utiliser notre service Cicaw Express et nous confier l'intégralité de votre logistique."
                }
              </P>
            </Div>
          </Div>
          <Div className="information-card__section">
            <Div className="information-card__icon">
              <DeliveryCar width={30} />
            </Div>
            <Div className="information-card__content">
              <H3 className="information-card__title">Livraison</H3>
              <P className="information-card__text">
                Cicaw offre des options de livraison rapides et fiables pour
                assurer que vos produits parviennent à vos clients en temps
                opportun.
              </P>
            </Div>
          </Div>
        </FlexRow>
        <FlexRow
          flexGap="1"
          className="information-card__item"
          justifyContent="between"
        >
          <Div className="information-card__section">
            <Div className="information-card__icon">
              <Wallet width={30} />
            </Div>
            <Div className="information-card__content">
              <H3 className="information-card__title">Options de Paiement</H3>
              <P className="information-card__text">
                Nous acceptons diverses méthodes de paiement pour faciliter les
                transactions.
              </P>
            </Div>
          </Div>
          <Div className="information-card__section">
            <Div className="information-card__icon">
              <Phone width={30} />
            </Div>
            <Div className="information-card__content">
              <H3 className="information-card__title">Support Client</H3>
              <P className="information-card__text">
                Notre équipe de support est disponible 24/7 pour vous aider avec
                toutes vos questions et préoccupations
              </P>
            </Div>
          </Div>
        </FlexRow>
      </FlexColumn>
    </Card>
  );
}

function CTACard() {
  const navigate = useNavigate()
  return (
    <Card className="cta-card">
      <Div className="cta-card__content">
        <H2 className="cta-card__title">
          Rejoignez {"Cicaw dès aujourd'hui"} !
        </H2>
        <P className="cta-card__text">
          Profitez des avantages de notre plateforme pour vendre vos produits
          facilement et efficacement. Inscrivez-vous maintenant pour accéder à
          des outils de gestion puissants, une assistance dédiée et des
          opportunités de croissance pour votre entreprise.
        </P>
        <Button
          className="cta-card__button"
          onClick={() => navigate(app.url.signIn)}
        >
          {"S'inscrire"}
        </Button>
      </Div>
    </Card>
  );
}

function FirstCard() {
  return (
    <Card className={"seller-overview-home"}>
      <Div className="seller-overview-home__item">
        <CardWithObserver comeFrom="top">
          <H1>
            Développez votre activité et touchez des acheteurs partout au
            Sénégal avec <Span className="cicaw__name">Cicaw</Span>
          </H1>
        </CardWithObserver>
        <CardWithObserver>
          <P>
            {
              "Rejoignez Cicaw, la plateforme incontournable pour les vendeurs désirant élargir leur portée et maximiser leurs opportunités de vente."
            }
          </P>
        </CardWithObserver>
      </Div>
      <CardWithObserver comeFrom="left">
        <FlexRow justifyContent="center" flexGap="1">
          <GradientButton to={app.url.editBecomeSeller}>Vendre sur cicaw</GradientButton>
        </FlexRow>
      </CardWithObserver>
    </Card>
  );
}

function TestimonialCard() {
  return (
    <Card className="testimonial-card">
      <Div className="testimonial-card__item">
        <H2>Ce que disent nos utilisateurs</H2>
        <FlexRow
          className="testimonial-list"
          flexWrap="wrap"
          justifyContent="between"
          flexGap="1"
        >
          <Div className="testimonial">
            <FlexRow flexGap="1" alignItems="center">
              <UserAvatar size={32} label="Fatou S" />
              <Div>
                <Span>Fatou S.</Span>
              </Div>
            </FlexRow>
            <P>{`Cicaw a transformé mon entreprise en ligne. Les outils de gestion sont incroyablement utiles.`}</P>
          </Div>

          <Div className="testimonial">
            <FlexRow flexGap="1" alignItems="center">
              <UserAvatar size={32} label="Mamadou D" />
              <Div>
                <Span>Mamadou D.</Span>
              </Div>
            </FlexRow>
            <P>{`Le support client est toujours disponible et très réactif. Je me sens en sécurité avec Cicaw.`}</P>
          </Div>

          <Div className="testimonial">
            <FlexRow flexGap="1" alignItems="center">
              <UserAvatar size={32} label="Awa T" />
              <Div>
                <Span>Awa T.</Span>
              </Div>
            </FlexRow>
            <P>{`Depuis que j'ai rejoint Cicaw, mes ventes ont doublé. Je recommande vivement !`}</P>
          </Div>
        </FlexRow>
      </Div>
    </Card>
  );
}

function FeatureCard({ imageSrc, title, children, imagePosition = "left" }) {
  return (
    <Card className={`feature-card ${imagePosition}`}>
      <FlexRow
        className="feature-card__item"
        justifyContent="between"
        flexGap="1"
        alignItems="center"
      >
        {imagePosition === "left" && (
          <img
            className="big-img"
            width="300"
            src={imageSrc}
            alt={`Illustration de ${title}`}
          />
        )}
        <Div>
          <H2>{title}</H2>
          <img
            className="mobile-img"
            width="200"
            src={imageSrc}
            alt={`Illustration de ${title}`}
          />
          {children}
        </Div>
        {imagePosition === "right" && (
          <img
            className="big-img"
            width="300"
            src={imageSrc}
            alt={`Illustration de ${title}`}
          />
        )}
      </FlexRow>
    </Card>
  );
}

function AdvancedFeaturesCard() {

  return (
    <FeatureCard imageSrc={auditImage} title="Fonctionnalités Avancées">
      <P>
        {
          "Utilisez nos outils de gestion de vente, d'inventaire et d'analyse pour suivre vos performances et optimiser vos opérations commerciales."
        }
      </P>
      <Ul>
        <Li>{"Gestion de l'inventaire en temps réel"}</Li>
        <Li>Suivi des commandes et des expéditions</Li>
        <Li>Analyses de vente détaillées et rapports personnalisés</Li>
        <Li>Outils de marketing et de promotion intégrés</Li>
        <Li>Gestion des avis et des évaluations des clients</Li>
      </Ul>
    </FeatureCard>
  );
}

function SecurityCard() {
  return (
    <FeatureCard
      imageSrc={securityImage}
      title="Sécurité et Fiabilité"
      imagePosition="right"
    >
      <P>
        {
          "Vos transactions sont sécurisées avec nos protocoles de sécurité de pointe, garantissant une expérience de vente et d'achat sans souci."
        }
      </P>
      <P>
        Nous utilisons des technologies de chiffrement avancées pour protéger
        vos données sensibles. De plus, nos systèmes de surveillance en temps
        réel détectent et préviennent toute activité suspecte.
      </P>
    </FeatureCard>
  );
}

function SupportCard() {
  return (
    <FeatureCard imageSrc={supportImage} title="Support Client Dédié">
      <P>
        Notre équipe de support est disponible 24/7 pour vous aider à chaque
        étape de votre parcours de vente, assurant votre réussite sur notre
        plateforme.
      </P>
      <P>
        {
          "Que vous ayez besoin d'assistance technique, de conseils sur la gestion de vos ventes ou de réponses rapides à vos questions, notre équipe est toujours prête à vous offrir un service personnalisé et efficace."
        }
      </P>
      <Link to="/contact-us" className="contact-support-button">
        Contactez notre support
      </Link>
    </FeatureCard>
  );
}

export default function SellerOverview() {
  useScrollTop()
  usePageAnalize()
  useMetadata(`p=seller_overview`)
  useEffect(() => {
    document.body.classList.add("seller-overview");
    return () => {
      document.body.classList.remove("seller-overview");
    };
  }, []);
  const isobserved = useBoolean(false);
  const observer = useIntersectionObserver(([entries]) => {
    if (entries.isIntersecting) {
      isobserved.toTrue();
      observer.observer.disconnect();
    } else {
      isobserved.toFalse();
    }
  });

  return (
    <PaddingContainer className="padding-container-card base-container-card">
      <FirstCard />
      <Separator />

      <CardWithObserver
        className={isobserved.value ? "visible" : "hide"}
        ref={observer.ref}
      >
        <TestimonialCard />
      </CardWithObserver>

      <CardWithObserver>
        <AdvancedFeaturesCard />
      </CardWithObserver>
      <Separator />
      <CardWithObserver>
        <SecurityCard />
      </CardWithObserver>
      <Separator />
      <CardWithObserver>
        <SupportCard />
      </CardWithObserver>

      <Separator />
      <CardWithObserver comeFrom="bottom">
        <InformationCard />
      </CardWithObserver>

      <CardWithObserver comeFrom="top">
        <FAQCard />
      </CardWithObserver>
      <Separator />
      <CardWithObserver>
        <CTACard />
      </CardWithObserver>
    </PaddingContainer>
  );
}
