import { logout } from "../modules/auth";
import {
  NetworkError,
  NotFoundError,
  UnauthorizedError,
} from "../modules/errors";
import {
  formatUrlForDebug,
  generateAuthorizationHeader,
} from "../modules/utils";

export function requestOptions(
  data,
  method,
  headers = {},
  defaultHeader = true,
) {
  const authorizationHeader = generateAuthorizationHeader();
  const finalHeaders = {
    ...authorizationHeader,
    ...headers,
  };

  if (defaultHeader) {
    finalHeaders["Content-Type"] = "application/json";
  }

  const options = {
    method: method,
    headers: finalHeaders,
    body: data,
  };

  return options;
}

async function handleResponse(res) {
  if (res.status === 401) {
    logout();
    throw new UnauthorizedError("Unauthorized");
  } else if (res.status === 404) {
    throw new NotFoundError("NotFound");
  }
  if (!res.ok) {
    throw new NetworkError("Network response was not ok");
  }

  return await res.json();
}

export async function request(
  method,
  url,
  data,
  header,
  defaultHeader,
  params,
) {
  var formattedUrl = formatUrlForDebug(url);
  if (params && params.externalUrl) {
    formattedUrl = url;
  }
  const rOption = requestOptions(data, method, header, defaultHeader);
  const res = await fetch(formattedUrl, rOption);

  return await handleResponse(res);
}

export async function post(url, data, header) {
  return await request("POST", url, data, header);
}

export async function get(url, data, header, params) {
  return await request("GET", url, data, header, undefined, params);
}

export async function getIpInfo() {
  try {
    const request = await fetch("https://ipinfo.io/json");
    const jsonResponse = await request.json();
    const { city, country, region, timezone, org } = jsonResponse;
    return { city, country, region, timezone, org };
  } catch (error) {
    console.log("Error fetching ipinfo:", error);
    return null;
  }
}
