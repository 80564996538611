import React from "react";
import Div from "../element/Div";
import { concatenateClass } from "../../../tools/utils";

export default function PositionFix({
  children,
  top,
  left,
  right,
  bottom,
  className,
  ...props
}) {
  const style = {
    top: top,
    left: left,
    right: right,
    bottom: bottom,
  };
  return (
    <Div
      style={style}
      className={concatenateClass([className, "fixed"])}
      {...props}
    >
      {children}
    </Div>
  );
}
