import { app } from "../constant";

export function useVibrate() {
  const vibrate = () => {
    if (navigator.vibrate) {
      navigator.vibrate(app.vibratorTime);
    }
  };
  return vibrate;
}
