import React from 'react';
import { Link, useParams } from 'react-router-dom';
import Card from '../../../components/public/card';
import UserAvatar from '../../../components/public/layout/UserAvatar';
import Skeleton from '../../../components/public/Skeleton';
import Button from '../../../components/tags/element/Button';
import Div from '../../../components/tags/element/Div';
import H2 from '../../../components/tags/element/H2';
import Span from '../../../components/tags/element/Span';
import FlexColumn from '../../../components/tags/layout/flex/public/FlexColumn';
import FlexRow from '../../../components/tags/layout/flex/public/FlexRow';
import { useSubscribeToSeller } from '../../../hook/subscribe/seller/useSubscribeToSeller';
import Img from '../../../components/tags/element/Img';
import { formImageUrlForDebug } from '../../../modules/utils';

function LoadingCard() {
  return (
    <Card className="details-seller-card">
      <FlexRow className="details-seller-header" alignItems="center" flexGap="1">
        <FlexRow alignItems="center" className="seller-info" flexGap="1">
          <Div className="seller-avatar">
            <Skeleton width={32} height={32}/>
          </Div>
          <FlexColumn className="seller-details">
            <Div className="seller-name"><Skeleton width={100} height={12}/></Div>
            <Div className="seller-followers">
              <Span className="followers-count"><Skeleton style={{marginTop:"5px"}} width={50} height={8}/></Span>
            </Div>
          </FlexColumn>
        </FlexRow>
      </FlexRow>
    </Card>
  )
}

export default function DetailsSellerCard() {
  const { id, slug } = useParams();
  const url = `/api/seller/products/${id}/${slug}/subscribe`

  const {
    getData,
    handleSubscribe,
    isSubscribed,
    followerCount,
    followerText
  } = useSubscribeToSeller(url)

  

  if (getData.loading || getData.error) return <LoadingCard/>

  const response = getData.response

  return (
      <Card className="details-seller-card">
        
        <FlexRow className="details-seller-header">
          <Link className="details-seller-header__link" to={`/shop/${response.slug}`}>
            <FlexRow alignItems="center" className="seller-info" flexGap="1">
              <Div className="seller-avatar">
                {
                  getData.response.profile ?
                    <Img width="32" height="32" style={{borderRadius:"50%", objectFit:"cover"}} src={formImageUrlForDebug(getData.response.profile)}/>:
                  <UserAvatar color={getData.response.profile_color} size={32} label={response.name} />
                }
              </Div>
              <FlexColumn className="seller-details">
                <H2 className="seller-name">{response.name}</H2>
                <Div className="seller-followers">
                  <Span className="followers-count">{`${followerCount} ${followerText}`}</Span>
                </Div>
              </FlexColumn>
            </FlexRow>
          </Link>

          <Button
            onClick={handleSubscribe}
            className={`subscribe-button ${isSubscribed.value ? 'subscribed' : 'not-subscribed'}`}
          >
            {isSubscribed.value ? "Abonné" : "S'abonner"}
          </Button>
        </FlexRow>
      </Card>

  );
}
