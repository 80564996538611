import React from "react";
import { NavLink } from "react-router-dom";
import Div from "../../tags/element/Div";
import MapList from "../MapList";
import FlexRow from "../../tags/layout/flex/public/FlexRow";

export default function TabsNavigation({ linkProps, data }) {
  const renderItem = (item) => {
    return (
      <Div>
        <NavLink
          className={(a) => (a.isActive ? "selected" : "")}
          to={item.to}
          {...linkProps}
        >
          {item.label}
        </NavLink>
      </Div>
    );
  };

  return (
    <FlexRow className="tabs-navigation">
      <MapList data={data} renderItem={renderItem} />
    </FlexRow>
  ) 
    
}
