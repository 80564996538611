import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../../../components/modal";
import ModalHeadWithMSGLink from "../../../components/modal/ModalHeadWithMSGLink";
import ModalHelpText from "../../../components/modal/ModalHelpText";
import GradientButton from "../../../components/public/GradientButton";
import MapList from "../../../components/public/MapList";
import ModalWithStickyButtonFooter from "../../../components/public/ModalWithStickyButtonFooter";
import Close from "../../../components/public/ico/Close";
import ErrorMessageRetryButton from "../../../components/public/layout/ErrorMessageRetryButton";
import Loader from "../../../components/public/layout/Loader";
import Div from "../../../components/tags/element/Div";
import Img from "../../../components/tags/element/Img";
import Option from "../../../components/tags/element/Option";
import Select from "../../../components/tags/element/Select";
import Span from "../../../components/tags/element/Span";
import FlexColumn from "../../../components/tags/layout/flex/public/FlexColumn";
import FlexRow from "../../../components/tags/layout/flex/public/FlexRow";
import { app, toApiCarts, toDetail } from "../../../constant";
import useCartManager from "../../../hook/useCartManager";
import { useMetadata } from "../../../hook/useMetadata";
import { usePageAnalize } from "../../../hook/usePageAnalize";
import usePost from "../../../hook/usePost";
import { formImageUrlForDebug } from "../../../modules/utils";
import "../../../styles/cart.css";

export function ProductInfo({ product, finalize = false, deliveryInfo }) {
  const navigate = useNavigate();
  const imageStyle = {
    width: 50,
    height: 50,
    marginRight: "0.5em",
    borderRadius: "4px",
  };
  const titleStyle = { fontSize: ".9em", marginRight: "0.5em" };
  const imageUrl = formImageUrlForDebug(product.image_1.image);
  const cartManager = useCartManager();

  const handleClick = () => {
    !finalize && navigate(toDetail(product.id, product.slug));
  };

  return (
    <FlexRow
      onClick={handleClick}
      alignItems="center"
      className="cart-product_info"
    >
      <Img style={imageStyle} className="cart-product-img" src={imageUrl} />
      <FlexColumn justifyContent="between">
        <Div style={titleStyle} className="cart-product-title">
          <Span>{product.title}</Span>
        </Div>
        <FlexRow flexGap="1">
          {(!finalize || deliveryInfo) && (
            <Div className="cart-product-delivery-info">
              <Span>
                {deliveryInfo
                  ? deliveryInfo
                  : cartManager.text.DELIVERY_DATE +
                    product.delivery_time * 24 +
                    " h"}
              </Span>
            </Div>
          )}
        </FlexRow>
      </FlexColumn>
    </FlexRow>
  );
}

export function ProductAction({ product, pageAnalize }) {
  const cartManager = useCartManager();
  const cartItem = cartManager.getCartItem(product.id);

  const options = [];
  for (let i = 1; i <= product.stock; i++) {
    options.push(
      <Option key={i} value={i}>
        {i}
      </Option>,
    );
  }

  return (
    <FlexColumn flexShrink="0">
      <Div className="cart-product-price">
        <Span>{product.price.formatted_price} FCFA</Span>
      </Div>
      <FlexRow justifyContent="between" alignItems="center">
        <Select
          className="cart-product-select"
          onChange={(e) => {
            cartManager.changeCartItemQuantity(product.id, e.target.value);
            pageAnalize.createPageAction(
              "CART_ITEM_QUANTITY_CHANGE",
              `${e.target.value}-${product.id}`,
            );
          }}
          value={cartItem && cartItem.quantity}
        >
          {options}
        </Select>
        <Close
          onClick={() => cartManager.removeProduct(product.id)}
          className="cart-product-delete-btn"
          width={24}
          height={24}
        />
      </FlexRow>
    </FlexColumn>
  );
}

export function CardBody({ product, pageAnalize }) {
  return (
    <FlexRow
      justifyContent="between"
      flexWrap="nowrap"
      alignItems="center"
      className="cart-card__body"
    >
      <ProductInfo product={product} />
      <ProductAction pageAnalize={pageAnalize} product={product} />
    </FlexRow>
  );
}

function Container({ children }) {
  const cartManager = useCartManager();

  return (
    <Modal
      head={
        <ModalHeadWithMSGLink
          title={cartManager.text.MY_BASKET}
          msg={"Retour"}
          to={app.url.home}
        />
      }
    >
      {children}
    </Modal>
  );
}

export default function Cart() {
  useMetadata("p=cart");

  const pageAnalize = usePageAnalize();
  const cartManager = useCartManager();

  const [getData, data, loading, error] = usePost(toApiCarts());
  const cartToPost = { carts: cartManager.cart };

  const onRetryClick = () => {
    getData(cartToPost);
  };

  useEffect(() => {
    onRetryClick();
  }, [cartManager.cart]);

  useEffect(() => {
    if (data) {
      const listOfIds = data.map((obj) => obj.id);
      const filteredCarts = cartManager.cart.filter((cart) =>
        listOfIds.includes(cart.id),
      );
      cartManager.updateCart(filteredCarts);
      if (JSON.stringify(filteredCarts) !== JSON.stringify(cartManager.cart)) {
        cartManager.addGroup(filteredCarts);
      }
    }
  }, [data]);

  if (loading)
    return (
      <Container>
        <Loader />
      </Container>
    );

  if (error)
    return (
      <Container>
        <ErrorMessageRetryButton onRetry={onRetryClick} />
      </Container>
    );

  const productItem = (product) => (
    <CardBody pageAnalize={pageAnalize} key={product.id} product={product} />
  );

  return (
    <>
      <Container>
        <Div className="padding-1-em cart">
          {data.length > 0 ? (
            <>
              <MapList renderItem={productItem} data={data} />
              <ModalWithStickyButtonFooter>
                <GradientButton to={app.url.cartFinalize}>
                  {cartManager.text.FINALIZE_THE_ORDER}
                </GradientButton>
              </ModalWithStickyButtonFooter>
            </>
          ) : (
            <ModalHelpText text={cartManager.text.YOUR_BASKET_IS_EMPTY} />
          )}
        </Div>
      </Container>
    </>
  );
}
