import React, { useEffect, useRef, useState } from "react";
import { app } from "../../../constant";
import Button from "../../tags/element/Button";
import Div from "../../tags/element/Div";
import ChevronLeft from "../ico/ChevronLeft";
import ChevronRight from "../ico/ChevronRight";

export default function Slider({ children, ...props }) {
  const containerRef = useRef(null);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(false);

  const isAtScrollStart = () => containerRef.current.scrollLeft === 0;

  const isAtScrollEnd = () => {
    const container = containerRef.current;
    const scrollRight = container.scrollLeft + container.clientWidth;
    const scrollWidth = container.scrollWidth;
    return scrollRight === scrollWidth;
  };

  const updateButtonVisibility = () => {
    setShowLeftButton(!isAtScrollStart());
    setShowRightButton(!isAtScrollEnd());
  };

  const handleLeftBtnClick = () => {
    const container = containerRef.current;
    if (!isAtScrollStart()) {
      container.scrollBy({ left: -app.slider.SCROLL_STEP, behavior: 'smooth' }); // Défilement fluide
      updateButtonVisibility();
    }
  };

  const handleRightBtnClick = () => {
    const container = containerRef.current;
    container.scrollBy({ left: app.slider.SCROLL_STEP, behavior: 'smooth' }); // Défilement fluide
    updateButtonVisibility();
  };

  const handleMouseOver = () => {
    const container = containerRef.current;
    if (container.scrollWidth > container.clientWidth) {
      setShowLeftButton(!isAtScrollStart());
      setShowRightButton(!isAtScrollEnd());
    }
  };

  const handleMouseOut = () => {
    setShowLeftButton(false);
    setShowRightButton(false);
  };

  useEffect(() => {
    updateButtonVisibility();
  }, []);

  return (
    <Div onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} {...props}>
      <Div className="card__product__render-slider-control">
        {showLeftButton && (
          <Button
            className="card__product__render-control-left"
            onClick={handleLeftBtnClick}
          >
            <ChevronLeft height={30} width={30} />
          </Button>
        )}
        {showRightButton && (
          <Button
            className="card__product__render-control-right"
            onClick={handleRightBtnClick}
          >
            <ChevronRight height={30} width={30} />
          </Button>
        )}
      </Div>
      <div
        className="flex-container flex-direction-row card__product__render-slider-children-container"
        ref={containerRef}
      >
        {children}
      </div>
    </Div>
  );
}
