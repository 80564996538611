import React, { useEffect } from 'react'
import useDynamicComponent from '../hook/useDynamicComponent';

export default function DynamicComponent({ component, componentId }) {
  const dynamicComponent = useDynamicComponent();

  useEffect(() => {
    if (!dynamicComponent.getComponent(componentId)) {
      dynamicComponent.addComponent(componentId);
    }
    
    /*return () => {
      dynamicComponent.(componentId);

    }*/
  }, [componentId]);

  const isActive = dynamicComponent.isComponentActive(componentId);

  const close = () => {
    dynamicComponent.deactiveComponent(componentId);
  }

  const componentProps = dynamicComponent.getComponent(componentId)?.props || {};

  const mergedProps = { ...componentProps, close };

  return isActive ? React.cloneElement(component, mergedProps) : null;
}
