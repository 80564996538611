import React, { useEffect, useState } from "react";
import FlexColumn from "../../tags/layout/flex/public/FlexColumn";
import Div from "../../tags/element/Div";
import Select from "../../tags/element/Select";

export default function InputSelect({ children, value, onChange, ...props }) {
  const [currentValue, setCurrentValue] = useState(value);
  useEffect(() => {
    if (value) {
      setCurrentValue(value)
    }
  }, [value])
  return (
    <FlexColumn className="input-group__container">
      <Div className="input-group">
        <Select
          onChange={
            (e) => {
              setCurrentValue(e.target.value)
              onChange && onChange(e)
            }
          }
          value={currentValue}
          {...props}
        >
          {children}
        </Select>
      </Div>
    </FlexColumn>
  );
}
