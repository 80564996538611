import MarkdownIt from "markdown-it";
import React from "react";
import Div from "../../tags/element/Div";

const md = new MarkdownIt();

export default function RenderMarkdown({ value }) {
  const html = md.render(value);

  return (
    <Div
      className="render-markdown"
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
}
