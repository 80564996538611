import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { app } from "../../../../../constant";
import useFetch from "../../../../../hook/useFetch";
import { useForm } from "../../../../../hook/useForm";
import usePost from "../../../../../hook/usePost";
import { useProductImageManager } from "../../../../../hook/useProductImageManager";
import Div from "../../../../tags/element/Div";
import H1 from "../../../../tags/element/H1";
import GradientButton from "../../../GradientButton";
import Card from "../../../card";
import GoBackHeader from "../../../card/header/GoBackHeader";
import RequestManager from "../../../layout/RequestManager";
import ProductAddForm from "./ProductAddForm";
import ProductCategoryModal from "./ProductCategoryModal";
import ProductImageModal from "./image/ProductImageModal";

const getApiUrl = (type, id) => {
  return type === "add"
    ? "/api/add-product"
    : `/api/seller/products/${id}/update`;
};
export default function ProductManager({ type }) {
  const { id } = useParams();
  const addProductForm = useForm(getApiUrl(type, id));
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const [categoryModalVisible, setCategoryModalVisible] = useState(false);
  const imageManager = useProductImageManager();
  const getImages = useFetch("/api/product/images", [], false);
  const [category, seCategory] = useState(null);

  useEffect(() => {
    addProductForm.setFomProps({
      image_1: imageManager.imagesValue.images.image1,
    });
    addProductForm.setFomProps({
      image_2: imageManager.imagesValue.images.image2,
    });
    addProductForm.setFomProps({
      image_3: imageManager.imagesValue.images.image3,
    });
  }, [imageManager.imagesValue.images]);

  const handleHidImageModal = () => {
    setImageModalVisible(false);
    imageManager.changeSelectedInput(0);
  };

  const handleImageClick = async (image) => {
    setImageModalVisible(true);
    imageManager.changeSelectedInput(image);
    await getImages.fetchData();
  };

  const handleImageSelect = (imageId) => {
    imageManager.handleImageSelect(imageId);
    handleHidImageModal();
  };
  const handleHidCategotyModal = () => {
    setCategoryModalVisible(false);
  };
  const handleShowCategotyModal = () => {
    setCategoryModalVisible(true);
  };
  const handleAddCatgory = (currentCategory) => {
    addProductForm.setFomProps({ category: currentCategory.id });
    seCategory(currentCategory);
  };

  if (addProductForm.loading || addProductForm.error)
    return <RequestManager request={addProductForm} />;

  return (
    <>
      <ProductImageModal
        getImages={getImages}
        isVisible={imageModalVisible}
        onHid={handleHidImageModal}
        images={getImages.response}
        handleImageClick={handleImageSelect}
      />
      <ProductCategoryModal
        isVisible={categoryModalVisible}
        onHid={handleHidCategotyModal}
        onSubmit={handleAddCatgory}
      />
      {type === "add" ? (
        <AddProduct
          headerProps={{
            to: app.url.sellerProduct,
            h: H1,
            title: "Ajouter un produit",
            description: "Ajouter un nouveau produit.",
          }}
          formProps={{
            btnMsg: "Ajouter le produit",
            form: addProductForm,
            imageManager: imageManager,
            handleImageClick: handleImageClick,
            handleShowCategotyModal: handleShowCategotyModal,
            category,
          }}
        />
      ) : (
        <UpdateProduct
          headerProps={{
            to: app.url.sellerProduct,
            h: H1,
            title: "Modifier le produit",
            description: "Modifier ce produit.",
          }}
          formProps={{
            btnMsg: "Modifier le produit",
            form: addProductForm,
            imageManager: imageManager,
            handleImageClick: handleImageClick,
            handleShowCategotyModal: handleShowCategotyModal,
            category,
          }}
        />
      )}
    </>
  );
}

function AddProduct({ headerProps, formProps }) {
  return (
    <>
      <Card header={<GoBackHeader {...headerProps} />} />
      <ProductAddForm {...formProps} />
    </>
  );
}

function UpdateProduct({ headerProps, formProps }) {
  const { id } = useParams();
  const [send, response /*loading, error*/] = usePost(
    `/api/seller/products/${id}/delete`,
  );
  const getUpdateData = useFetch(`/api/products/${id}/update/data`);

  const navigate = useNavigate();

  const handleDeleteProduct = async () => {
    await send();
  };

  useEffect(() => {
    if (getUpdateData.response) {
      formProps.form.setFomProps({
        category: getUpdateData.response.product.category.id,
      });
    }
  }, [getUpdateData.response]);

  useEffect(() => {
    if (response && response.deleted) {
      setTimeout(() => {
        navigate("/seller/products");
      }, 1000);
    }
  }, [response]);

  useEffect(() => {
    const image1 =
      formProps.form.formData.find((data) => data.name === "image_1")?.value ||
      null;
    const image2 =
      formProps.form.formData.find((data) => data.name === "image_2")?.value ||
      null;
    const image3 =
      formProps.form.formData.find((data) => data.name === "image_3")?.value ||
      null;
    formProps.imageManager.updateImageValue("image1", image1);
    formProps.imageManager.updateImageValue("image2", image2);
    formProps.imageManager.updateImageValue("image3", image3);
  }, [formProps.form.formData]);

  if (getUpdateData.loading || getUpdateData.error)
    return <RequestManager request={getUpdateData} />;

  return (
    <>
      <Card
        className={"product-manager-header"}
        header={<GoBackHeader {...headerProps} />}
      />
      <ProductAddForm updateData={getUpdateData.response} {...formProps} />
      <Div className="padding-1-em" style={{ background: "white" }}>
        <GradientButton onClick={handleDeleteProduct} type={"button"} gray>
          Supprimer le produit
        </GradientButton>
      </Div>
    </>
  );
}
