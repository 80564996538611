import React from "react";
import Div from "../../../components/tags/element/Div";
import StarRate from "../../../components/public/ico/StarRate";
import FlexRow from "../../../components/tags/layout/flex/public/FlexRow";
import MapList from "../../../components/public/MapList";

export default function StarRateList({
  rateValue,
  setRateValue,
  info = false,
}) {
  const starsCount = 5;
  const stars = Array.from({ length: starsCount }, (_, index) => index + 1);
  const flexGap = info ? 0 : 1;
  const width = info ? 10 : 50;

  const renderItem = (item) => {
    const handleClick = () => {
      !info && setRateValue(item);
    };
    const className = rateValue >= item ? "fill" : "";
    return (
      <Div key={item} onClick={handleClick}>
        <StarRate className={className} width={width} height={width} />
      </Div>
    );
  };

  return (
    <FlexRow flexGap={flexGap} className="star-rate-list">
      <MapList data={stars} renderItem={renderItem} />
    </FlexRow>
  );
}
