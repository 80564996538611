import React from "react";
import { useDispatch } from "react-redux";
import MapList from "../../../components/public/MapList";
import Option from "../../../components/tags/element/Option";
import Select from "../../../components/tags/element/Select";
import FlexColumn from "../../../components/tags/layout/flex/public/FlexColumn";
import FlexRow from "../../../components/tags/layout/flex/public/FlexRow";
import { setBuyNowQuantity } from "../../../features/main/cartSlice";
import { ProductInfo } from "../cart/Cart";
import useCartManager from "../../../hook/useCartManager";

export default function CartItems({ isCartFinalize, data }) {
  const dispatch = useDispatch();
  const options = [];
  const cartManager = useCartManager();

  if (!isCartFinalize) {
    const product = data[0];
    for (let i = 1; i <= product.stock; i++) {
      options.push(
        <Option key={i} value={i}>
          {i}
        </Option>,
      );
    }
  }

  const renderItem = (product) => (
    <FlexRow
      justifyContent="between"
      flexWrap="nowrap"
      alignItems="center"
      className="cart-card__body"
    >
      <ProductInfo
        deliveryInfo={
          cartManager.getCartItem(product.id).quantity +
          " x " +
          product.price.price +
          " FCFA"
        }
        product={product}
        finalize
      />
    </FlexRow>
  );

  return !isCartFinalize ? (
    <FlexRow
      justifyContent="between"
      flexWrap="nowrap"
      alignItems="center"
      className="cart-card__body"
    >
      <ProductInfo product={data[0]} finalize />
      <FlexColumn flexShrink="0">
        <Select
          className="cart-product-select"
          onChange={(e) => dispatch(setBuyNowQuantity(e.target.value))}
          style={{ marginBottom: "5px" }}
        >
          {options}
        </Select>
      </FlexColumn>
    </FlexRow>
  ) : (
    <MapList data={data} renderItem={renderItem} />
  );
}
