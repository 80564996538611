import { useNavigate } from "react-router-dom";
import { toSearchQuery } from "../constant";

const useSearch = (params={ onSubmit:null, onSucces:null }) => {
  const navigate = useNavigate();

  // Function to get search history from localStorage
  const getLocalStorageSearch = () => {
    try {
      const searchHistory = localStorage.getItem("search");
      return JSON.parse(searchHistory) ?? [];
    } catch (error) {
      console.error("Error retrieving search history from localStorage:", error);
      return [];
    }
  };

  // Function to perform a search and update localStorage
  const search = (q) => {
    try {
      navigate(toSearchQuery(q));
      const history = getLocalStorageSearch();

      // Add the new search query to the beginning of the list
      const updatedHistory = [q, ...history];

      // Keep only the last 5 entries
      const limitedHistory = updatedHistory.slice(0, 5);

      localStorage.setItem("search", JSON.stringify(limitedHistory));
    } catch (error) {
      console.error("Error performing search or updating localStorage:", error);
    }
  };

  // Function to extract search query from form submission
  const getQ = (event) => {
    const formData = new FormData(event.target);
    return Object.fromEntries(formData).q;
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    if (params.onSubmit) {
      params.onSubmit(event);
    }
    const q = getQ(event);
    if (q) {
      search(q);
      if (params.onSucces) {
        params.onSucces(q);
      }
    }
  };

  // Return the handleSubmit, search functions, and search history
  return {
    handleSubmit,
    search,
    searchHistory: getLocalStorageSearch(),
  };
};

export default useSearch;
