import { configureStore } from "@reduxjs/toolkit";
import appReducer from "../features/main/appSlice";
import cartReducer from "../features/main/cartSlice";
import adminReducer from "../features/main/adminSlice";
import detailSlice from "../features/main/detailSlice";
import visitorSlice from "../features/main/visitorSlice";
import homeSlice from "../features/main/homeSlice";
import sidebarSlice from "../features/main/sidebarSlice";
import toastSlice from "../features/main/toastSlice";
import profileSlice from "../features/main/profileSlice";
import pageEditorSlice from "../features/main/pageEditorSlice";
import dynamicComponentSlice from "../features/main/dynamicComponentSlice";

export const store = configureStore({
  reducer: {
    app: appReducer,
    cart: cartReducer,
    admin: adminReducer,
    detail: detailSlice,
    visitor: visitorSlice,
    home: homeSlice,
    sidebar: sidebarSlice,
    toast: toastSlice,
    profile: profileSlice,
    pageEditor: pageEditorSlice,
    dynamicComponent: dynamicComponentSlice,
  },
});
