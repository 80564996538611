/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Form from "../../../tags/element/Form";
import Img from "../../../tags/element/Img";
import FlexColumn from "../../../tags/layout/flex/public/FlexColumn";
import GradientButton from "../../GradientButton";
import Card from "../../card";
import SimpleHeader from "../../card/header/SimpleHeader";
import InputLitsRender from "../../layout/InputLitsRender";
import { useAddImage } from "../../../../hook/useAddImage";

const SellerAddImageInput = ({ getImages }) => {
  const {
    selectedImage,
    isUploading,
    imageResponse,
    handleImageChange,
    handleSubmit
  } = useAddImage("/api/product/images/add", () => getImages.fetchData({loading:true})); // Use the custom hook and pass the URL

  return (
    <Card
      className="seller-product-image-add-image-input"
      header={
        <SimpleHeader
          title={"Ajouter une image"}
          description={"Seules les fichiers images sont valables"}
        />
      }
    >
      <Form onSubmit={(e) => handleSubmit(e)}>
        <FlexColumn flexGap="1">
          {imageResponse ? (
            <InputLitsRender onChange={handleImageChange} formData={imageResponse} />
          ) : (
            <input
              accept="image/*"
              name="image"
              type="file"
              onChange={handleImageChange}
            />
          )}
          {selectedImage && (
            <>
              <Img width={100} height={100} src={selectedImage} alt="Preview" />
              <GradientButton
                isLoading={isUploading}
                disabled={isUploading}
                type={"button"}
              >
                Ajouter l'image
              </GradientButton>
            </>
          )}
        </FlexColumn>
      </Form>
    </Card>
  );
};

export default SellerAddImageInput;
