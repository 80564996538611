import React, { useEffect, useState } from "react";
import { Link, Outlet, useParams } from "react-router-dom";
import MapList from "../../../components/public/MapList";
import Card from "../../../components/public/card";
import GoBackHeader from "../../../components/public/card/header/GoBackHeader";
import SimpleHeader from "../../../components/public/card/header/SimpleHeader";
import RequestManager from "../../../components/public/layout/RequestManager";
import DashborMimidCardInfo from "../../../components/public/manager/admin/card/DashborMimidCardInfo";
import Div from "../../../components/tags/element/Div";
import H3 from "../../../components/tags/element/H3";
import Span from "../../../components/tags/element/Span";
import FlexColumn from "../../../components/tags/layout/flex/public/FlexColumn";
import FlexRow from "../../../components/tags/layout/flex/public/FlexRow";
import useFetch from "../../../hook/useFetch";
import { useScrollTop } from "../../../hook/useScroolTop";

function KeyValueContainer({ name, value }) {
  return (
    <FlexColumn>
      <Div style={{ fontSize: ".9em", color: "#5f6368" }}>
        <Span>{name}</Span>
      </Div>
      <Div
        style={{
          marginTop: ".5em",
          fontWeight: "600",
          fontSize: ".9em",
          color: "rgb(49 49 49)",
        }}
      >
        <Span>{value}</Span>
      </Div>
    </FlexColumn>
  );
}

export default function AdminVisitorSessionItem() {
  useScrollTop();
  const { visitorId, sessionId } = useParams();
  const getSession = useFetch("/api/admin/session/" + sessionId);
  const [state, setState] = useState({});
  const goToVisitor = `/admin/visitor/${visitorId}`;

  useEffect(() => {
    if (getSession.response) {
      var totalAction = 0;
      for (let i = 0; i < getSession.response.pages.length; i++) {
        const page = getSession.response.pages[i];
        const numberOfAction = page.actions.length;
        totalAction += numberOfAction;
      }
      setState((previousState) => {
        return { ...previousState, totalAction };
      });
    }
  }, [getSession.response]);

  if (getSession.loading || getSession.error)
    return <RequestManager request={getSession} />;

  return (
    <Div style={{ maxWidth: "800px" }}>
      <Outlet />

      <GoBackHeader
        to={goToVisitor}
        title={<Div>Session - {sessionId}</Div>}
        description={
          <Link to={goToVisitor} style={{ textDecoration: "underline" }}>
            Voir le visiteur
          </Link>
        }
      />

      <FlexRow flexWrap="wrap" flexGap="1">
        <DashborMimidCardInfo
          title={"Page"}
          value={getSession.response.pages.length}
        />
        <DashborMimidCardInfo title={"Action"} value={state.totalAction} />
      </FlexRow>

      <Card header={<SimpleHeader h={H3} title={"Ip Geolocalisation"} />}>
        <FlexRow flexWrap="wrap" flexGap="1" justifyContent="between">
          <MapList
            data={getSession.response.location_infos}
            renderItem={(item) => (
              <KeyValueContainer
                name={item.info_name.name}
                value={item.info_value.value}
              />
            )}
          />
        </FlexRow>
      </Card>

      <Card header={<SimpleHeader h={H3} title={"Page"} />}>
        <FlexColumn flexGap="1">
          <MapList
            data={getSession.response.pages}
            renderItem={(item) => {
              return (
                <Link
                  to={
                    "/admin/visitor/" +
                    visitorId +
                    "/session/" +
                    sessionId +
                    "/page/" +
                    item.id
                  }
                >
                  <FlexColumn>
                    <Div>{item.url}</Div>
                    <Div className="admin-order-data-item">
                      <Div>Temps passé: {item.timestamp} s</Div>
                      <Div style={{ marginTop: ".2em" }}>{item.added_date}</Div>
                      <Div style={{ marginTop: ".2em" }}>
                        {item.actions.length} action(s)
                      </Div>
                    </Div>
                  </FlexColumn>
                </Link>
              );
            }}
          />
        </FlexColumn>
      </Card>
    </Div>
  );
}
