import React from "react";
import ProductManager from "../../../../../components/public/manager/seller/product/ProductManager";
import MaxWidth from "../../../../../components/public/layout/MaxWidth";
import { useTitle } from "../../../../../hook/useTitle";
import { titleSyntax } from "../../../../../constant";

export default function SellerUpdateProduct() {
  useTitle(titleSyntax("Modifier le produit"));

  return (
    <MaxWidth>
      <ProductManager type={"update"} />
    </MaxWidth>
  );
}
