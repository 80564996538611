import React from 'react'
import CustomModal from '../../modal/CustomModal'

export default function DynamicSelectList({close, ...props}) {
  console.log(props)

  return (
    <CustomModal onHid={close}>
      
    </CustomModal>
  )
}
