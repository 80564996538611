import React from "react";
import Option from "../tags/element/Option";
import InputSelect from "./layout/InputSelect";
import { capitalizeFirstLetter } from "../../tools/utils";

const months = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];

export default function MonthSelector({ value, ...props }) {
  return (
    <InputSelect {...props} value={capitalizeFirstLetter(value)}>
      {months.map((month, index) => (
        <Option key={index}>{month}</Option>
      ))}
    </InputSelect>
  );
}
