import React from "react";
import FlexRow from "../../../tags/layout/flex/public/FlexRow";
import FlexColumn from "../../../tags/layout/flex/public/FlexColumn";
import H2 from "../../../tags/element/H2";
import P from "../../../tags/element/P";

export default function SimpleHeader({ title, description, children, h }) {
  const Header = h ? h : H2;

  return (
    <FlexRow
      alignItems="center"
      className="card-header padding-1em"
      justifyContent="between"
    >
      <FlexColumn>
        <Header>{title}</Header>
        {description && <P className="card-description">{description}</P>}
      </FlexColumn>
      {children}
    </FlexRow>
  );
}
