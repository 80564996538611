import React from "react";
import FlexColumn from "../../../components/tags/layout/flex/public/FlexColumn";
import CartSummaryHeader from "./CartSummaryHeader";
import Separator from "../../../components/public/Separator";
import CartItems from "./CartItems";
import Hr from "../../../components/tags/element/Hr";
import CartDetails from "./CartDetails";

export default function CartSummary({ data, isCartFinalize = false }) {
  return (
    <FlexColumn className="buy-now__item-2 buy-now__item-2_sumary">
      <CartSummaryHeader isCartFinalize={isCartFinalize} />
      <Separator margin="0.5em 0" />
      <CartItems isCartFinalize={isCartFinalize} data={data.products} />
      <Hr />
      <CartDetails data={data} />
    </FlexColumn>
  );
}
