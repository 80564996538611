import React from "react";
import { Link, useParams } from "react-router-dom";
import RequestManager from "../../../components/public/layout/RequestManager";
import UserAvatar from "../../../components/public/layout/UserAvatar";
import Div from "../../../components/tags/element/Div";
import useFetch from "../../../hook/useFetch";
import P from "../../../components/tags/element/P";
import FlexRow from "../../../components/tags/layout/flex/public/FlexRow";
import Mail from "../../../components/public/ico/Mail";
import Separator from "../../../components/public/Separator";
import UserSvg from "../../../components/public/navbar/ico/UserSvg";
import LocationIco from "../../../components/public/ico/LocationIco";
import Phone from "../../../components/public/ico/Phone";
import CalendarIco from "../../../components/public/ico/CalendarIco";
import MenSymbol from "../../../components/public/ico/MenSymbol";
import MapList from "../../../components/public/MapList";
import { OrderItem } from "../../../components/public/manager/admin/renderItem";
import H2 from "../../../components/tags/element/H2";
import FlexColumn from "../../../components/tags/layout/flex/public/FlexColumn";

function UserOrderSession() {
  const { id } = useParams();
  const getUserOrders = useFetch(`/api/admin/user/${id}/orders`);

  if (getUserOrders.loading || getUserOrders.error)
    return <RequestManager request={getUserOrders} />;

  return (
    <>
      <h2>Commande effectuer</h2>
      <MapList
        data={getUserOrders.response}
        renderItem={(item) => {
          return <OrderItem item={item} />;
        }}
      />
    </>
  );
}

function UserSessionList() {
  const { id } = useParams();
  const getUserSessions = useFetch(`/api/admin/user/${id}/sessions`);

  if (getUserSessions.loading || getUserSessions.error)
    return <RequestManager request={getUserSessions} />;
  return (
    <>
      <H2>{"Session de l'utilisateur"}</H2>
      <FlexColumn flexGap="1">
        <MapList
          data={getUserSessions.response}
          renderItem={(item) => (
            <Link
              to={`http://cicaw.sn:3000/admin/visitor/${item.visitorId}/session/${item.id}`}
            >
              <Div>{item.id}</Div>
              <Div>{item.added_date}</Div>
            </Link>
          )}
        />
      </FlexColumn>
    </>
  );
}

export default function AdminUserItem() {
  const { id } = useParams();
  const getUser = useFetch(`/api/admin/user/${id}`);
  if (getUser.loading || getUser.error)
    return <RequestManager request={getUser} />;

  return (
    <Div>
      <h1>{getUser.response.full_name}</h1>
      <UserAvatar size={130} label={getUser.response.full_name} />
      <P>{getUser.response.username}</P>
      <Separator />
      <h2>Information Générale</h2>
      <ul>
        <li>
          <FlexRow alignItems="center" flexGap="1">
            <UserSvg width={32} height={32} />
            <Div>{getUser.response.full_name}</Div>
          </FlexRow>
        </li>
        <li>
          <FlexRow alignItems="center" flexGap="1">
            <MenSymbol width={32} height={32} />
            <Div>{getUser.response.sexe}</Div>
          </FlexRow>
        </li>
        <li>
          <FlexRow alignItems="center" flexGap="1">
            <Mail width={32} height={32} />
            <Div>{getUser.response.email}</Div>
          </FlexRow>
        </li>

        <li>
          <FlexRow alignItems="center" flexGap="1">
            <LocationIco width={32} height={32} />
            <Div>{getUser.response.adresse_1}</Div>
          </FlexRow>
        </li>

        <li>
          <FlexRow alignItems="center" flexGap="1">
            <LocationIco width={32} height={32} />
            <Div>{getUser.response.adresse_2}</Div>
          </FlexRow>
        </li>
        <li>
          <FlexRow alignItems="center" flexGap="1">
            <Phone width={32} height={32} />
            <Div>{getUser.response.phone_1}</Div>
          </FlexRow>
        </li>
        <li>
          <FlexRow alignItems="center" flexGap="1">
            <Phone width={32} height={32} />
            <Div>{getUser.response.phone_2}</Div>
          </FlexRow>
        </li>

        <li>
          <FlexRow alignItems="center" flexGap="1">
            <CalendarIco width={32} height={32} />
            <Div>
              {"Date de naissance"} {getUser.response.date_of_birth}
            </Div>
          </FlexRow>
        </li>

        <li>
          <FlexRow alignItems="center" flexGap="1">
            <CalendarIco width={32} height={32} />
            <Div>
              {"Date d'inscrription"} {getUser.response.date_joined}
            </Div>
          </FlexRow>
        </li>

        <li>
          <FlexRow alignItems="center" flexGap="1">
            <CalendarIco width={32} height={32} />
            <Div>
              {"Boutique"} {getUser.response.date_joined}
            </Div>
          </FlexRow>
        </li>
      </ul>
      <UserOrderSession />
      <UserSessionList />
    </Div>
  );
}
