import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toastData } from "../../../constant";
import useBodyClass from "../../../hook/useBodyClass";
import useFetch from "../../../hook/useFetch";
import { useForm } from "../../../hook/useForm";
import useToastManager from "../../../hook/useToastManager";
import NotFound from "../../../pages/public/error/NotFound";
import "../../../styles/blog-settings.css";
import Form from "../../tags/element/Form";
import H1 from "../../tags/element/H1";
import H2 from "../../tags/element/H2";
import Option from "../../tags/element/Option";
import FlexColumn from "../../tags/layout/flex/public/FlexColumn";
import GradientButton from "../GradientButton";
import Separator from "../Separator";
import Card from "../card";
import InputGroup from "../layout/InputGroup";
import InputSelect from "../layout/InputSelect";
import RequestManager from "../layout/RequestManager";
import BlogLayout from "./BlogLayout";
import RenderMarkdown from "./RenderMarkdown";
import { useScrollTop } from "../../../hook/useScroolTop";

function BlogSettingsBody({ data, getBlogSettings }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const settingsForm = useForm(
    `/api/blog/update/${id}/settings`,
    undefined,
    false,
    false,
  );
  const toastManager = useToastManager();
  useEffect(() => {
    if (settingsForm.formResponse) {
      toastManager.addItem(toastData.blogAdvancedSettingsModified());
      getBlogSettings.fetchData();
    }
  }, [settingsForm.formResponse]);

  

  return (
    <Form method="POST" onSubmit={settingsForm.handleSubmit}>
      <H1>Paramétrage du Blog</H1>
      <Card
        onClick={() => navigate("/blog/edit/" + id)}
        className={"blog-setting__preview"}
      >
        <H2>{data.title}</H2>
        <RenderMarkdown value={data.content} />
      </Card>
      <Separator />
      <FlexColumn flexGap="1">
        <FlexColumn>
          <H2>Titre</H2>
          <InputGroup
            required
            name="title"
            type="text"
            value={data.title}
            placeholder="Titre"
          />
        </FlexColumn>

        <FlexColumn>
          <H2>Résumé</H2>
          <p>
            {
              "Dans cette section, vous pouvez fournir un résumé concis de votre blog."
            }
          </p>
          <InputGroup
            name="summary"
            value={data.summary}
            type="textarea"
            placeholder="Résumé"
          />
        </FlexColumn>

        <FlexColumn>
          <H2>SEO</H2>
          <p>
            {
              "Optimisez votre blog pour les moteurs de recherche en ajoutant des métadonnées pertinentes."
            }
          </p>
          <FlexColumn>
            <InputGroup
              name="meta_title"
              value={data.meta_title}
              type="text"
              placeholder="Meta titre"
            />
            <InputGroup
              name="meta_description"
              type="textarea"
              value={data.meta_description}
              placeholder="Meta description"
            />
          </FlexColumn>
        </FlexColumn>

        <FlexColumn>
          <H2>Statut de Publication</H2>
          <InputSelect name="status" value={data.status}>
            <Option value="draft">En attente</Option>
            <Option value="published">Publier</Option>
          </InputSelect>
        </FlexColumn>

        <GradientButton disabled={settingsForm.formDisabled} type="button">
          Enregistrer les modifications
        </GradientButton>
      </FlexColumn>
    </Form>
  );
}

export default function BlogSettings() {
  const { id } = useParams();
  useScrollTop()

  useBodyClass("blog-settings");
  const getBlogSettings = useFetch(`/api/blog/update/${id}/settings`);

  if (getBlogSettings.error) {
    return <NotFound/>
  }


  if (getBlogSettings.loading) {
    return
  }

  return (
    <BlogLayout>
      {getBlogSettings.loading || getBlogSettings.error ? (
        <RequestManager request={getBlogSettings} />
      ) : (
        <BlogSettingsBody
          getBlogSettings={getBlogSettings}
          data={getBlogSettings.response}
        />
      )}
    </BlogLayout>
  );
}
