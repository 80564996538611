import { createToast } from "./modules/utils";

export const app = {
  appName: "cicaw",
  scrool: {
    defaultBehavior: "auto",
  },
  navbar: {
    inputTextPlaceholder: "Recherche cicaw",
    icoSize: {
      width: 22,
      height: 22,
    },
  },
  slider: {
    SCROLL_STEP: 500,
  },
  text: {
    login: "Se connecter",
    back: "Retour",
    continue: "Continuer",
    register: "S'inscrire",
    close: "Fermer",
  },
  debug: false,
  backend: "http://cicaw.sn",
  loginRedirect: "/",
  logoutRedirect: "/",
  vibratorTime: 2,
  url: {
    home: "/",
    profile: "/profile",
    userOrder: "/profile/orders",
    userOrderItem: "/profile/orders/:id",
    card: "/cart",
    search: "/search",
    signIn: "/sign-in",
    community: "/community",
    //previewDetails: "/preview/details/:id",
    login: "/login",
    auth: "/auth",
    details: "/details/:id/:slug",
    contactUs: "/contact-us",
    detailDescription: "/details/:id/:slug/description",
    detailRates: "/details/:id/:slug/rates",
    detailRateAdd: "/details/:id/:slug/rates/add",
    detailRateUpdate: "/details/:id/:slug/rates/update",
    buyNow: "/details/:id/:slug/buy-now",
    tag: "/tags/:name/:id",
    category: "/category/:id/:slug",
    searchQuery: "/search/query",
    cartFinalize: "/cart/checkout",
    addProduct: "/admin/product/add",
    admin: "/admin",
    adminShop: "/admin/shop",
    adminDashbord: "/admin/dashbord",
    adminUser: "/admin/user",
    adminUserItem: "/admin/user/:id",
    adminOrderItem: "/admin/order/:id",
    adminShopItem: "/admin/shop/:id",
    adminOrder: "/admin/order",
    adminVisitor: "/admin/visitor",
    adminVisitorItem: "/admin/visitor/:visitorId",
    adminVisitorSession: "/admin/visitor/:visitorId/session",
    adminVisitorSessionItem: "/admin/visitor/:visitorId/session/:sessionId",
    adminVisitorSessionPage:
      "/admin/visitor/:visitorId/session/:sessionId/page/:pageId",
    adminProductImage: "/admin/product/images",
    sellerLogin: "/seller/login",
    sellerSignIn: "/seller/SignIn",
    termOfUse: "/policies/term-of-use",
    seller: "/seller",
    sellerProduct: "/seller/products",
    sellerProductAdd: "/seller/product/add",
    sellerProductUpdate: "/seller/products/:id/update",
    sellerOrder: "/seller/orders",
    sellerOrderItem: "/seller/orders/:id",
    sellerDashbord: "/seller/dashbord",
    sellerProfile: "/seller",
    editName: "/profile/name",
    editBirthday: "/profile/birthday",
    editAdresse: "/profile/adresse",
    editEmail: "/profile/email",
    editPhoneOne: "/profile/phone-1",
    editPhoneTwo: "/profile/Phone-2",
    editGender: "/profile/gender",
    editBecomeSeller: "/profile/become-seller",
    checkoutSucces: "/checkout/succes",
    // gaaw
    gaaw: "/gaaw",
    sellerImage: "/seller/images",
  },
  page: {
    auth: {
      text: {
        head: {
          title: "Vous n'avez pas de encore de compte",
          description:
            "Pour profiter pleinement de tous les avantages et fonctionnalités de cicaw nous vous encourageons vivement à vous connecter dès maintenant.",
        },
        title: "Connexion Requise",
      },
    },
    login: {
      text: {
        head: {
          title: "Cicaw connexion",
        },
        noAccount: "J'ai pas encore de compte? ",
        loginError: "Nom d’utilisateur ou mot de passe incorrect",
      },
    },
  },
};

export function titleSyntax(title) {
  if (!title) {
    return "Cicaw";
  }
  return `Cicaw | ${title}`;
}

// Page
export function toCategory(id, title) {
  return `/category/${id}/${title}`;
}
export function toDetail(id, slug) {
  return `/details/${id}/${slug}`;
}
export function toAddProduct() {
  return `/seller/product/add`;
}
export function toEditProduct(id) {
  return `/seller/products/${id}/update`;
}
export function toDetailDescription(id, slug) {
  return `/details/${id}/${slug}/description`;
}
export function toSellerOrderDetail(id) {
  return `/seller/orders/${id}`;
}
export function toDetailRates(id, slug) {
  return `/details/${id}/${slug}/rates`;
}
export function toDetailRateAdd(id, slug) {
  return `/details/${id}/${slug}/rates/add`;
}
export function toDetailRateUpdate(id, slug) {
  return `/details/${id}/${slug}/rates/update`;
}
export function toSearchQuery(q) {
  return "/search/query?q=" + q;
}
// Api
export function toApiDetail(id, slug) {
  return "/api/products/" + id + "/" + slug;
}
export function toApiProductRateAdd(id, slug) {
  return `/api/products/${id}/${slug}/rates/add`;
}
export function toApiTopRate(id, slug) {
  return `/api/products/${id}/${slug}/top-rates`;
}
export function toApiProductRateUpdate(id, slug) {
  return `/api/products/${id}/${slug}/rates/update`;
}
export function toApiProduct(id, slug) {
  return "/api/products/" + id + "/" + slug;
}
export function toApiCarts() {
  return "/api/carts/";
}
export function toApiDetailRateDelete(id, slug) {
  return `/api/products/${id}/${slug}/rates/delete`;
}
export function toApiProductDescription(id, slug) {
  return `/api/products/${id}/${slug}/description`;
}
export function toApiOrderAdd() {
  return "/api/carts/order/add";
}
export function toApiEditName() {
  return "/api/profile/name";
}
export function toApiEditBirthday() {
  return "/api/profile/birthday";
}
export function toApiEditGender() {
  return "/api/profile/gender";
}
export function toApiEditAddress() {
  return "/api/profile/adresse";
}
export function toApiEditPhoneOne() {
  return "/api/profile/phone-1";
}
export function toApiEditPhoneTwo() {
  return "/api/profile/phone-2";
}
export function toApiEditEmail() {
  return "/api/profile/email";
}

export const toastData = {
  TOAST_DURATION_SHORT: 2, // 2 secondes
  TOAST_DURATION_MEDIUM: 4, // 4 secondes
  TOAST_DURATION_LONG: 6, // 6 secondes

  addToCart: (cartManager, data) =>
    createToast({
      content: cartManager.isExistingProduct(data.id)
        ? "Produit retiré du panier avec succès"
        : "Produit ajouté au panier avec succès",
      timeout: toastData.TOAST_DURATION_MEDIUM,
    }),

  rateForm: (type) =>
    createToast({
      content:
        type === "update"
          ? "Votre avis a été modifié avec succès"
          : "Votre avis a été ajouté avec succès",
      timeout: toastData.TOAST_DURATION_MEDIUM,
    }),

  rateDelete: () =>
    createToast({
      content: "Votre avis a été supprimé avec succès",
      timeout: toastData.TOAST_DURATION_MEDIUM,
    }),
  socialMediaNotAvailable: () =>
    createToast({
      content: "Connexion avec Facebook n'est pas disponible",
      timeout: toastData.TOAST_DURATION_SHORT,
      id: "login-with-facebook",
    }),

  blogSaveSuccess: () =>
    createToast({
      content: "Votre blog a été sauvegardé avec succès",
      timeout: toastData.TOAST_DURATION_MEDIUM,
    }),

  blogSaveError: () =>
    createToast({
      content: "Une erreur est survenue lors de la sauvegarde de votre blog",
      timeout: toastData.TOAST_DURATION_LONG,
    }),

  blogAdvancedSettingsModified: () =>
    createToast({
      content: "Les paramètres avancés du blog ont été modifiés avec succès",
      timeout: toastData.TOAST_DURATION_MEDIUM,
    }),
  
  copyLinkSucces: () =>
    createToast({
      content: "Le lien a été copié avec succès",
      timeout: toastData.TOAST_DURATION_MEDIUM,
    }),

  copyLinkError: () =>
    createToast({
      content: "Une erreur s'est produite lors de la copie du lien",
      timeout: toastData.TOAST_DURATION_MEDIUM,
    }),
  
  userLoginSuccess: () =>
    createToast({
      content: "Vous êtes connecté avec succès",
      timeout: toastData.TOAST_DURATION_MEDIUM,
    }),

  userSignupSuccess: () =>
    createToast({
      content: "Votre inscription a été réalisée avec succès",
      timeout: toastData.TOAST_DURATION_MEDIUM,
    }),
  
  PageEditorSaveError: () =>
    createToast({
      content: "Une erreur s'est produite lors du sauvegarde",
      timeout: toastData.TOAST_DURATION_MEDIUM,
    }),
  PageEditorSaveSucces: () =>
    createToast({
      content: "Page est mise à jour avec succès",
      timeout: toastData.TOAST_DURATION_MEDIUM,
    }),
  
  PageParamsUpdateSuccess: () =>
    createToast({
      content: "Les paramètres de la page ont été mis à jour avec succès",
      timeout: toastData.TOAST_DURATION_MEDIUM,
    }),

  PageDeleteSuccess: () =>
    createToast({
      content: "La page a été suprimé avec succès",
      timeout: toastData.TOAST_DURATION_MEDIUM,
    }),
  
  subscribeSuccess: () =>
    createToast({
      content: "Vous êtes abonné avec succès",
      timeout: toastData.TOAST_DURATION_MEDIUM,
      id: "subscribe-success"
    }),

  unsubscribeSuccess: () =>
    createToast({
      content: "Vous êtes désabonné avec succès",
      timeout: toastData.TOAST_DURATION_MEDIUM,
      id: "unsubscribe-success"
    }),
  
  subscribeError: () =>
    createToast({
      content: "Une erreur est survenue lors de l'abonnement ou du désabonnement",
      timeout: toastData.TOAST_DURATION_LONG,
      id: "subscribe-error"
    }),

    dynamicFormSuccesUpdate: () =>
      createToast({
        content: "Le formulaire a été mis à jour avec succès",
        timeout: toastData.TOAST_DURATION_LONG,
        id: "dynamic-form-update-succes"
      }),
    

  
}

export const shareOneSocialMedia = {
  whatssap: () => 
    `whatsapp://send?text=${encodeURIComponent(window.location.href)}`,
  facebook: () =>
    `https://www.facebook.com/share.php?u=${encodeURIComponent(window.location.href)}`,
  x: () =>
    `https://www.x.com/share?&url=${encodeURIComponent(window.location.href)}&text=""`
}


export const socialNetworkLink = {
  tiktok: "https://www.tiktok.com/@cicaw.com?is_from_webapp=1&sender_device=pc",
  instagram: "https://www.instagram.com/cicawcom/",
  youtube: "https://youtube.com/@cicawcom?si=DU8YL-dRL6dYeGzf",
  facebook: "https://www.facebook.com/profile.php?id=61559292953669",
  x: "https://x.com/CicawCom",
}