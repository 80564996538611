import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "../../../../hook/useForm";
import CustomModal from "../../../../components/modal/CustomModal";
import Div from "../../../../components/tags/element/Div";
import Card from "../../../../components/public/card";
import SimpleHeader from "../../../../components/public/card/header/SimpleHeader";
import H3 from "../../../../components/tags/element/H3";
import Separator from "../../../../components/public/Separator";
import Form from "../../../../components/tags/element/Form";
import InputGroup from "../../../../components/public/layout/InputGroup";
import AcceptTermOnContinue from "../../../../components/public/AcceptTermOnContinue";
import GradientButton from "../../../../components/public/GradientButton";
import Span from "../../../../components/tags/element/Span";
import { useGoBack } from "../../../../hook/useGoBack";
import StarRateList from "../StarRateList";
import MapList from "../../../../components/public/MapList";
import {
  toApiDetailRateDelete,
  toApiProductRateAdd,
  toApiProductRateUpdate,
  toApiTopRate,
  toDetail,
  toastData,
} from "../../../../constant";
import usePost from "../../../../hook/usePost";
import { useDispatch } from "react-redux";
import { setTopRate } from "../../../../features/main/detailSlice";
import { get } from "../../../../tools/requests";
import { usePageAnalize } from "../../../../hook/usePageAnalize";
import useToastManager from "../../../../hook/useToastManager";

function getUrl(type, id, slug) {
  if (type === "add") {
    return toApiProductRateAdd(id, slug);
  } else {
    return toApiProductRateUpdate(id, slug);
  }
}

function Layout({ children, ...props }) {
  const toastManager = useToastManager();
  const goBack = useGoBack();
  const { id, slug } = useParams();
  const [rateValue, setRateValue] = useState(1);
  const [commentValue, setCommentValue] = useState("");
  const addCommentForm = useForm(getUrl(props.type, id, slug));
  const [errors, setErrors] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    addCommentForm.setFomProps({ star: rateValue });
    addCommentForm.setFomProps({ product: id });
  }, [rateValue, id]);

  useEffect(() => {
    if (props.type === "update" && addCommentForm.response) {
      let star = addCommentForm.response.find(
        (data) => data.name === "star",
      ).value;
      let comment = addCommentForm.response.find(
        (data) => data.name === "comment",
      ).value;
      setRateValue(star);
      setCommentValue(comment);
    }
  }, [addCommentForm.response, props.type]);

  useEffect(() => {
    if (addCommentForm.formResponse) {
      if (addCommentForm.formResponse.is_valid) {
        get(toApiTopRate(id, slug))
          .then((result) => {
            dispatch(setTopRate(result));
          })
          .catch((e) => {
            console.log(e);
          });

        toastManager.addItem(toastData.rateForm(props.type));
      } else {
        setErrors(addCommentForm.formResponse.errors.__all__);
      }
    }
  }, [addCommentForm.formResponse, dispatch]);

  const renderItem = (error) => (
    <Div className="error-msg">
      <Span>{error}</Span>
    </Div>
  );

  return (
    <CustomModal to={".."} title={props.title} onHid={goBack}>
      <Div className="padding-1-em">
        <Card
          header={
            <SimpleHeader
              h={H3}
              title={"Évaluez ce produit"}
              description={
                "Cliquez sur les étoiles ci-dessous pour attribuer une note à ce produit. Votre avis nous aidera à améliorer nos produits et services."
              }
            />
          }
        >
          <StarRateList rateValue={rateValue} setRateValue={setRateValue} />
        </Card>
        <Separator />
        <Form onSubmit={addCommentForm.handleSubmit}>
          <InputGroup
            disabled={addCommentForm.formDisabled}
            value={commentValue}
            onChange={(e) => setCommentValue(e.target.value)}
            unique={true}
            name="comment"
            type="text"
            placeholder="Avis...."
          />
          <Separator />

          <MapList renderItem={renderItem} data={errors} />

          <Separator />
          <AcceptTermOnContinue />
          <Separator />
          <GradientButton
            disabled={addCommentForm.formDisabled}
            isLoading={addCommentForm.formDisabled}
            style={{ height: "25px", width: "25px" }}
            type={"button"}
          >
            Continuer
          </GradientButton>
          <Separator />
        </Form>
        {children}
      </Div>
    </CustomModal>
  );
}

export function RateFormUpdate() {
  const { id, slug } = useParams();
  const dispatch = useDispatch();
  const toastManager = useToastManager();
  usePageAnalize([id]);

  const [send, response, loading] = usePost(toApiDetailRateDelete(id, slug));
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    send();
  };

  useEffect(() => {
    if (response?.deleted) {
      navigate(toDetail(id, slug));
      get(toApiTopRate(id, slug)).then((result) => {
        dispatch(setTopRate(result));
      });
      toastManager.addItem(toastData.rateDelete());
    }
  }, [response]);

  return (
    <Layout type="update" title="Modifier votre avis">
      <Form method="post" onSubmit={handleSubmit}>
        <GradientButton
          disabled={!loading ? true : false}
          isLoading={!loading ? true : false}
          className="red-btn"
          type={"button"}
          gray
        >
          Supprimer
        </GradientButton>
      </Form>
    </Layout>
  );
}

export default function RateFormAdd() {
  usePageAnalize();

  return <Layout type="add" title="Rédiger un avis" />;
}
