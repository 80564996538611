import React from "react";
import { Link, useParams } from "react-router-dom";
import DefaultHeader from "../../../components/public/card/header/DefaultHeader";
import Card from "../../../components/public/card";
import Separator from "../../../components/public/Separator";
import ModalPaddingContainer from "../../../components/public/layout/ModalPaddingContainer";
import { toDetailRates } from "../../../constant";
import DetailsComment from "./DetailsComment";
import Div from "../../../components/tags/element/Div";
import FlexRow from "../../../components/tags/layout/flex/public/FlexRow";
import FlexColumn from "../../../components/tags/layout/flex/public/FlexColumn";
import Span from "../../../components/tags/element/Span";
import MapList from "../../../components/public/MapList";

function DetailsRateReviewBar({ number, percent }) {
  const style = { width: percent + "%" };
  return (
    <FlexRow flexGap="1">
      <Div className="details-rate-review__right__number">
        <Span>{number}</Span>
      </Div>
      <Div className="details-rate-review__right__progress-bar">
        <Span
          className="gradient-bg details-rate-review__right__progress-bar__indicator"
          style={style}
        ></Span>
      </Div>
    </FlexRow>
  );
}

function DetailsRateReviewTop({ data }) {
  return (
    <Div>
      <FlexRow flexGap="1" alignItems="center">
        <FlexColumn className="details-rate-review__left">
          <Div className="details-rate-review__left__means">
            <Span>{data.mean}</Span>
          </Div>
          <Div className="details-rate-review__left__total">
            <Span>{data.total} avis</Span>
          </Div>
        </FlexColumn>
        <FlexColumn flexGrow="1" className="details-rate-review__right">
          <DetailsRateReviewBar number={1} percent={data.star_1} />
          <DetailsRateReviewBar number={2} percent={data.star_2} />
          <DetailsRateReviewBar number={3} percent={data.star_3} />
          <DetailsRateReviewBar number={4} percent={data.star_4} />
          <DetailsRateReviewBar number={5} percent={data.star_5} />
        </FlexColumn>
      </FlexRow>
    </Div>
  );
}

export default function DetailsRateReview({ data }) {
  const { id, slug } = useParams();

  const renderItem = (comment) => (
    <Card className={"detail-comment hover-bg"}>
      <DetailsComment
        star={comment.star}
        date={comment.added_date}
        colorCode={comment.user.profile_color}
        username={comment.user.full_name}
        comment={comment.comment}
      />
    </Card>
  );

  return (
    data.total > 0 && (
      <Card
        className={"details-rate-review"}
        header={
          <DefaultHeader
            description={"Les avis sont verifiés."}
            title="Notes et avis"
            link={false}
          />
        }
      >
        <DetailsRateReviewTop data={data} />
        <Separator />
        <ModalPaddingContainer>
          <MapList data={data.rates} renderItem={renderItem} />
          <Link
            className="details-write-rewiew padding-1-em"
            to={toDetailRates(id, slug)}
          >
            Afficher tous les avis
          </Link>
        </ModalPaddingContainer>
      </Card>
    )
  );
}
