import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AcceptTermOnContinue from "../../../components/public/AcceptTermOnContinue";
import { LoginRequiredModal } from "../../../components/public/LoginRequired";
import Card from "../../../components/public/card";
import GoBackHeader from "../../../components/public/card/header/GoBackHeader";
import PaddingContainer from "../../../components/public/layout/PaddingContainer";
import RequestLoading from "../../../components/public/layout/RequestLoading";
import RequestManager from "../../../components/public/layout/RequestManager";
import Div from "../../../components/tags/element/Div";
import H1 from "../../../components/tags/element/H1";
import FlexColumn from "../../../components/tags/layout/flex/public/FlexColumn";
import FlexRow from "../../../components/tags/layout/flex/public/FlexRow";
import { titleSyntax, toApiOrderAdd } from "../../../constant";
import {
  handleContinueWithoutLogin,
  setBuyNowId,
} from "../../../features/main/cartSlice";
import { useForm } from "../../../hook/useForm";
import { usePageAnalize } from "../../../hook/usePageAnalize";
import usePost from "../../../hook/usePost";
import { useScrollTop } from "../../../hook/useScroolTop";
import { useTitle } from "../../../hook/useTitle";
import CartForm from "./CartForm";
import CartSummary from "./CartSummary";

function RequestForContinue() {
  {
    /*<PaddingContainer className="padding-container-card cover-with-bg">
      <Card header={<GoBackHeader to={-1} title={"Connectez-vous pour finaliser votre commande"} description={"Pour accéder au processus de commande, veuillez vous connecter à votre compte ou vous inscrire."} />}>

        <Div className="buy-now">
          <FlexRow flexWrap="wrap" justifyContent="between" flexGap="1" className="buy-now__container">
            <FlexColumn flexGrow="1" flexGap="1" className="buy-now__item-1">
              <GuestOrLoginButtons />
            </FlexColumn>
          </FlexRow>
        </Div>
      </Card>
  </PaddingContainer>*/
  }
  return <LoginRequiredModal />;
}

function CheckoutLayoutPermitted({ data, isCartFinalize }) {
  const addOrderForm = useForm(toApiOrderAdd());

  if (addOrderForm.loading || addOrderForm.error)
    return <RequestManager request={addOrderForm} />;

  return (
    <PaddingContainer className="padding-container-card cover-with-bg checkout-layout base-container-card">
      <Card
        header={
          <GoBackHeader
            title={"Finalisation de l'achat"}
            h={H1}
            description={<AcceptTermOnContinue />}
          />
        }
      >
        <Div className="buy-now">
          <FlexRow
            flexWrap="wrap"
            justifyContent="between"
            flexGap="1"
            className="buy-now__container"
          >
            <FlexColumn flexGrow="1" flexGap="1" className="buy-now__item-1">
              <CartForm
                data={addOrderForm.formData}
                error={addOrderForm.FormError}
                onSubmit={addOrderForm.handleSubmit}
                disabled={addOrderForm.formDisabled}
                isCartFinalize={isCartFinalize}
              />
            </FlexColumn>
            <CartSummary data={data} isCartFinalize={isCartFinalize} />
          </FlexRow>
        </Div>
      </Card>
    </PaddingContainer>
  );
}

function CheckoutLayout({ data, isCartFinalize }) {
  useTitle(titleSyntax("Finalisation"));
  useScrollTop();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.app.isAuthenticated);
  const cart = useSelector((state) => state.cart).buyNow;

  useEffect(() => {
    dispatch(handleContinueWithoutLogin(isAuthenticated));
  }, [isAuthenticated, dispatch]);

  return cart.canContinueWithoutLogin ? (
    <CheckoutLayoutPermitted data={data} isCartFinalize={isCartFinalize} />
  ) : (
    <RequestForContinue />
  );
}

const useCartCheckout = () => {
  const isAuthenticated = useSelector((state) => state.app.isAuthenticated);
  const [send, response, loading, error] = usePost(
    "/api/carts/checkout",
    false,
  );

  const postData = (data) => {
    isAuthenticated && send(data);
  };

  return { postData, response, loading, error };
};

export function CartFinalize() {
  usePageAnalize();
  const carts = useSelector((state) => state.app).carts;
  const cartCheckout = useCartCheckout();

  const send = () => {
    cartCheckout.postData({ carts });
  };

  useEffect(() => {
    if (carts.length >= 1) {
      send();
    }
  }, [carts]);

  if (cartCheckout.error || cartCheckout.loading)
    return <RequestManager request={{ ...cartCheckout, fetchData: send }} />;

  return <CheckoutLayout data={cartCheckout.response} isCartFinalize />;
}

export function BuyNow() {
  useScrollTop();
  usePageAnalize();
  const dispatch = useDispatch();
  const { id } = useParams();
  const cartCheckout = useCartCheckout();

  const carts = { carts: [{ id: Number(id), quantity: 1 }] };

  useEffect(() => {
    cartCheckout.postData(carts);
  }, [id]);

  useEffect(() => {
    dispatch(setBuyNowId(id));
  }, [dispatch, id]);

  if (cartCheckout.loading || cartCheckout.error) return <RequestLoading />;

  return <CheckoutLayout data={cartCheckout.response} />;
}
