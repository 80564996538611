import React from "react";
import ErrorPage from "../../../components/public/ErrorPage";

export default function NotFound({
  title = "La page que vous recherchez n'a pas été trouvée.",
}) {
  return (
    <ErrorPage title="Page non trouvée" errorName="404" ErrorMsg={title} />
  );
}
