import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useParams } from "react-router-dom";
import Separator from "../../../components/public/Separator";
import Skeleton from "../../../components/public/Skeleton";
import Card from "../../../components/public/card";
import SimpleHeader from "../../../components/public/card/header/SimpleHeader";
import Information from "../../../components/public/ico/Information";
import Mail from "../../../components/public/ico/Mail";
import Phone from "../../../components/public/ico/Phone";
import ModalPaddingContainer from "../../../components/public/layout/ModalPaddingContainer";
import PaddingContainer from "../../../components/public/layout/PaddingContainer";
import RequestLoading from "../../../components/public/layout/RequestLoading";
import RequestManager from "../../../components/public/layout/RequestManager";
import Div from "../../../components/tags/element/Div";
import Hr from "../../../components/tags/element/Hr";
import Li from "../../../components/tags/element/Li";
import Span from "../../../components/tags/element/Span";
import Ul from "../../../components/tags/element/Ul";
import FlexColumn from "../../../components/tags/layout/flex/public/FlexColumn";
import FlexRow from "../../../components/tags/layout/flex/public/FlexRow";
import {
  app,
  toApiProduct,
  toApiTopRate,
  toDetailRateAdd,
  toDetailRateUpdate,
} from "../../../constant";
import {
  setProductDetail,
  setProductId,
  setTopRate,
} from "../../../features/main/detailSlice";
import useFetch from "../../../hook/useFetch";
import { useMetadata } from "../../../hook/useMetadata";
import { usePageAnalize } from "../../../hook/usePageAnalize";
import { useScrollTop } from "../../../hook/useScroolTop";
import { NotFoundError } from "../../../modules/errors";
import "../../../styles/details.css";
import NotFound from "../error/NotFound";
import DetailsAddToCartComponent from "./DetailsAddToCartComponent";
import DetailsComment from "./DetailsComment";
import DetailsDescriptionCard from "./DetailsDescriptionCard";
import DetailsFeaturesListComponent from "./DetailsFeaturesListComponent";
import DetailsImageSection from "./DetailsImageSection";
import DetailsPriceComponent from "./DetailsPriceComponent";
import DetailsRateReview from "./DetailsRateReview";
import DetailsSellerCard from "./DetailsSellerCard";
import DetailsSimilarProductsCard from "./DetailsSimilarProductsCard";
import DetailsTagsCard from "./DetailsTagsCard";
import DetailsTitleComponent from "./DetailsTitleComponent";

function DetailsAssistanceLi({ title, text, ico, to }) {
  return (
    <Li className="details-assistance-li">
      <Link to={to}>
        <FlexRow flexGap="1" alignItems="center">
          {ico}
          <FlexColumn>
            <Div>
              <Span>{title}</Span>
            </Div>
            <Div className="details_assistance__value">
              <Span>{text}</Span>
            </Div>
          </FlexColumn>
        </FlexRow>
      </Link>
    </Li>
  );
}

export function DetailsAssistance() {
  const width = 22;
  const getContact = useFetch("/api/get-contact");
  const skeleton = <Skeleton height={12} width={"100%"} />;
  const fetchStatus = getContact.loading || getContact.error;

  return (
    <Card header={<SimpleHeader title={"Assistance cicaw"} />}>
      <Ul>
        <DetailsAssistanceLi
          title={"Adresse e-mail de l'assistance"}
          text={fetchStatus ? skeleton : getContact.response.email}
          ico={<Mail width={width} height={width} />}
          to={!fetchStatus && `mailto:${getContact.response.email}`}
        />
        <DetailsAssistanceLi
          title={"Numéro de téléphone"}
          text={fetchStatus ? skeleton : getContact.response.phone}
          ico={<Phone width={width} height={width} />}
          to={!fetchStatus && `tel:${getContact.response.phone}`}
        />
        <DetailsAssistanceLi
          title={"Règles de confidentialité"}
          text={""}
          ico={<Information width={width} height={width} />}
          to={app.url.termOfUse}
        />
      </Ul>
    </Card>
  );
}

function DetailsRateSection({ response }) {
  const isAuthenticated = useSelector((state) => state.app.isAuthenticated);
  const { id, slug } = useParams();

  const userRateData = response.user_rate.data;
  const userRateExists = userRateData && userRateData.length > 0;

  // Si l'utilisateur est authentifié et s'il existe une évaluation utilisateur, affichez l'avis existant
  // Sinon, affichez le lien pour rédiger un nouvel avis
  return (
    isAuthenticated &&
    (userRateExists ? (
      <Card
        header={
          <SimpleHeader
            title={"Votre avis"}
            description={"modifier votre avis aux clients"}
          />
        }
      >
        <DetailsComment
          colorCode={userRateData[0].user.profile_color}
          username={userRateData[0].user.full_name}
          star={userRateData[0].star}
          comment={userRateData[0].comment}
        />
        <Separator />
        <Link
          to={toDetailRateUpdate(id, slug)}
          className="details-write-rewiew"
        >
          Modifier votre avis
        </Link>
      </Card>
    ) : (
      <Card header={<SimpleHeader title={"Rédiger un avis"} />}>
        <Link to={toDetailRateAdd(id, slug)} className="details-write-rewiew">
          Rédiger un avis
        </Link>
      </Card>
    ))
  );
}

export default function Details() {
  const { id, slug } = useParams()
  useMetadata(`p=details&id=${id}&slug=${slug}`, [id]);
  const getProduct = useFetch(toApiProduct(id, slug), [id, slug]);
  useScrollTop([id]);
  const pageAnalize = usePageAnalize([id]);
  const dispatch = useDispatch();
  const detail = useSelector((state) => state.detail);
  const getRate = useFetch(toApiTopRate(id, slug), [id, slug]);

  useEffect(() => {
    dispatch(setProductId(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (getProduct.response) {
      dispatch(setProductDetail(getProduct.response));
    }
  }, [getProduct.response, dispatch]);

  useEffect(() => {
    if (getRate.response) {
      dispatch(setTopRate(getRate.response));
    }
  }, [getRate.response, dispatch]);

  if (getProduct.error instanceof NotFoundError) {
    return <NotFound />;
  }

  if (getProduct.loading || getProduct.error)
    return <RequestManager request={getProduct} />;

  if (getRate.loading || getRate.error)
    return <RequestManager request={getRate} />;

  if (
    Object.getOwnPropertyNames(detail.productDetail).length === 0 ||
    Object.getOwnPropertyNames(detail.topRate).length === 0
  ){

    return <RequestLoading />;
  }

  return (
    <>
      <Outlet />
      <PaddingContainer className="details padding-container-card cover-with-bg">
        <>
          <DetailsImageSection data={getProduct.response} />
          <FlexRow className="details-top-container">
            <Div className="details__item_info">
              <DetailsTitleComponent title={getProduct.response.title} />
              <DetailsPriceComponent data={getProduct.response} />
              <DetailsFeaturesListComponent id={getProduct.response.id} />
              <DetailsAddToCartComponent
                data={getProduct.response}
                pageAnalize={pageAnalize}
              />
              <Separator />
              <DetailsDescriptionCard
                description={getProduct.response.reduced_description}
              />
              <ModalPaddingContainer>
                <DetailsTagsCard tags={getProduct.response.tags} />
                <DetailsRateReview data={detail.topRate} />
                <DetailsRateSection response={detail.topRate} />
                <DetailsSellerCard/>
              </ModalPaddingContainer>
            </Div>
            <Hr className="details-separator" />
            <Div className="details__item_sugges">
              <ModalPaddingContainer>
                <DetailsAssistance />
                <DetailsSimilarProductsCard/>
              </ModalPaddingContainer>
            </Div>
          </FlexRow>
        </>
      </PaddingContainer>
    </>
  );
}
