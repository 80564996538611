import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "../src/styles/admin.css";
import "../src/styles/buynow.css";
import "../src/styles/card.css";
import "../src/styles/error.css";
import "../src/styles/login.css";
import "../src/styles/modal.css";
import "../src/styles/seller.css";
import { store } from "./app/store";
import "./index.css";
import "./styles/constant.css";
import "./styles/profile.css";
import "./styles/community.css";


import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);
