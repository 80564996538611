import { useEffect, useRef, useState } from "react";
import { adjustTextareaHeight, focusElement } from "../modules/utils";

function useAutoResizeAndFocus(
  defaultValue = "",
  params = { adjustDependance: [] },
) {
  const textareaRef = useRef(null);
  const [state, setState] = useState(defaultValue);

  useEffect(() => {
    adjustTextareaHeight(textareaRef.current);
  }, [state, ...params.adjustDependance]);

  useEffect(() => {
    focusElement(textareaRef.current);
  }, [textareaRef.current]);

  const handleChange = (e) => {
    setState(e.target.value);
    adjustTextareaHeight(e.currentTarget);
  };

  return { textareaRef, state, setState, handleChange };
}

export default useAutoResizeAndFocus;
