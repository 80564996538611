import { useState } from "react";
import { useBoolean } from "../../useBoolean";

export const useSubscribeStatus = () => {
  const isSubscribed = useBoolean(false);
  const [followerCount, setFollowerCount] = useState(0);
  const [followerText, setFollowerText] = useState("abonné");

  return {
    isSubscribed,
    followerCount,
    setFollowerCount,
    followerText,
    setFollowerText
  };
};