import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setAuthenticated } from "../features/main/appSlice";
import { hasToken } from "../modules/auth";

const useAuthSync = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleStorageChange = () => {
      dispatch(setAuthenticated(hasToken()));
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [dispatch]);
};

export default useAuthSync;
