import React from "react";
import Div from "../../../../tags/element/Div";
import FlexRow from "../../../../tags/layout/flex/public/FlexRow";

export default function DefaultDashbordCardListItem({ left, middle, right }) {
  return (
    <FlexRow justifyContent="between" flexGap="1" alignItems="center">
      <FlexRow
        flexGap="1"
        alignItems="center"
        className="default-dashbord-card-container-left"
      >
        <Div className="default-dashbord-card-container">{left}</Div>
        <Div className="default-dashbord-card-container">{middle}</Div>
      </FlexRow>
      <Div className="default-dashbord-card-container">{right}</Div>
    </FlexRow>
  );
}
