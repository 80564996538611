import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  buyNow: {
    data: {
      id: 0,
      quantity: 1,
    },
    canContinueWithoutLogin: false,
  },
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setBuyNowQuantity: (state, action) => {
      state.buyNow.data.quantity = action.payload;
    },
    setBuyNowId: (state, action) => {
      state.buyNow.data.id = action.payload;
    },
    handleContinueWithoutLogin: (state, action) => {
      state.buyNow.canContinueWithoutLogin = action.payload;
    },
  },
});

export const { setBuyNowQuantity, handleContinueWithoutLogin, setBuyNowId } =
  cartSlice.actions;
export default cartSlice.reducer;
