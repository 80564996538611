import React from "react";
import Div from "../../../../../tags/element/Div";
import Card from "../../../../card";
import Img from "../../../../../tags/element/Img";
import { formImageUrlForDebug } from "../../../../../../modules/utils";

export default function ProductImageCard({ image, onClick }) {
  const imageUrl = formImageUrlForDebug(image.image)
  //const className = currentImageSelected.selectedId === item.id ?  `${defaultClassName} selected`: defaultClassName

  return (
    <Div className={"seller-product-image-suggestion"}>
      <Card onClick={onClick}>
        <Img height={90} width={90} src={imageUrl} />
      </Card>
    </Div>
  );
}
