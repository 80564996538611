import { useEffect } from "react";
import useFetch from "./useFetch";
import { useDispatch, useSelector } from "react-redux";
import { setIpInfo } from "../features/main/visitorSlice";
import usePost from "./usePost";

const useGetIpInfo = () => {
  const getIpinfo = useFetch(
    "https://ipinfo.io/json",
    [],
    true,
    undefined,
    true,
  );
  const dispatch = useDispatch();
  const visitor = useSelector((state) => state.visitor);
  const [sendIpInfo] = usePost("/visitor/ipinfo");

  useEffect(() => {
    if (getIpinfo.response) {
      dispatch(setIpInfo(getIpinfo.response));
    }
  }, [getIpinfo.response]);

  useEffect(() => {
    const isIpinfo = Object.getOwnPropertyNames(visitor.ipInfo).length !== 0;
    if (isIpinfo && visitor.sessionId) {
      sendIpInfo({ ipinfo: visitor.ipInfo, session: visitor.sessionId });
    }
  }, [visitor.ipInfo, visitor.sessionId]);
};

export default useGetIpInfo;
