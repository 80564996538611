import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useScrollTop } from "../../../../hook/useScroolTop";
import PaddingContainer from "../../layout/PaddingContainer";
import ManagerNavbar from "../layout/ManagerNavbar";
import "../../../../styles/seller.css";

export default function SellerLayout() {
  useScrollTop();
  useEffect(() => {
    window.document.body.classList.add("seller");
    return () => window.document.body.classList.remove("seller");
  });

  return (
    <>
      <ManagerNavbar />
      <PaddingContainer className="padding-container-card seller-layout">
        <Outlet />
      </PaddingContainer>
    </>
  );
}
