import React from "react";
import FlexRow from "../../../tags/layout/flex/public/FlexRow";
import ArrowRight from "../../../public/ico/ArrowRight";
import H2 from "../../../tags/element/H2";
import P from "../../../tags/element/P";
import FlexColumn from "../../../tags/layout/flex/public/FlexColumn";
import { Link } from "react-router-dom";
import { useVibrate } from "../../../../hook/useVibrate";

function Body({ title, description, ico }) {
  return (
    <FlexRow justifyContent="between" className="card-header padding-1em">
      <FlexColumn>
        <H2 className="color-fill-black">{title}</H2>
        {description && (
          <P className="card-description color-fill-black">{description}</P>
        )}
      </FlexColumn>
      {ico && <ArrowRight className="arrow_head" width={22} height={22} />}
    </FlexRow>
  );
}

export default function DefaultHeader({ title, description, to, link = true }) {
  const vibrate = useVibrate();

  return link ? (
    <Link to={to} onClick={() => vibrate()}>
      <Body title={title} description={description} ico />
    </Link>
  ) : (
    <Body title={title} description={description} />
  );
}
