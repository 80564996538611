import { createSlice } from "@reduxjs/toolkit";

const updateComponentState = (components, componentId, isActive, props) => {
  const newComponents =  components.map((item) => {
    if (item.id === componentId) {
      const newPprops = isActive ? props: {}
      return { ...item, isActive, props: newPprops }
    } else {
      return item
    }
  });
  return newComponents
}


const initialState = {
  components: []
};

const dynamicComponentSlice = createSlice({
  name: "dynamicComponent",
  initialState,
  reducers: {
    addComponentItem: (state, action) => {
      const componentId = action.payload

      const component = {
        id: componentId,
        isActive: false,
        props: null
      }
      state.components.push(component)
    },

    deleteComponent: (state, action) => {
      const componentId = action.payload
      state.components = state.components.filter((item) => item.id !== componentId)
    },

    activeComponentItem: (state, action) => {
      const {componentId, componentProps} = action.payload
      state.components = updateComponentState(state.components, componentId, true, componentProps);
    },
    deactivateComponentItem: (state, action) => {
      const {componentId, componentProps} = action.payload
      state.components = updateComponentState(state.components, componentId, false, componentProps);
    }
  },
});

export const { addComponentItem, activeComponentItem, deactivateComponentItem } = dynamicComponentSlice.actions;
export default dynamicComponentSlice.reducer;
