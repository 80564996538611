import React from "react";
import Button from "../../../components/tags/element/Button";
import Div from "../../../components/tags/element/Div";
import Img from "../../../components/tags/element/Img";
import Span from "../../../components/tags/element/Span";
import FlexRow from "../../../components/tags/layout/flex/public/FlexRow";
import usePost from "../../../hook/usePost";
import PagnationContainer from "./pagnation/PagnationContainer";

export default function AdminProductImage() {
  const [send, response] = usePost("/api/admin/product/images/delete");

  const handleDelete = (item) => {
    send({ pk: item.id });
  };

  return (
    <PagnationContainer
      response={response}
      url={"/api/admin/product/images"}
      renderItem={(item) => {
        return (
          <FlexRow flexGap="1" className="seller-image">
            <Img src={item.image} />
            <Div className="seller-image__render-item__container">
              <Div className="seller-image-label seller-image__name">
                <Span>{item.name}</Span>
              </Div>
              <Div className="seller-image-label seller-image__size">
                <Span>{item.size} - </Span>
                <Span>{item.added_date}</Span>
              </Div>
              {item.total_used <= 0 ? (
                <Button onClick={() => handleDelete(item)} className="delete">
                  Supprimer
                </Button>
              ) : (
                <Button className="used">Image utilisé</Button>
              )}
            </Div>
          </FlexRow>
        );
      }}
      title={"Images"}
    />
  );
}
