import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPageId } from "../features/main/visitorSlice";
import { calculateDifferenceMs } from "../modules/utils";
import usePost from "./usePost";

const usePageTimer = ({ pageId }, dependance) => {
  const visitor = useSelector((state) => state.visitor);
  const [startAt, setStartAt] = useState(new Date().getTime());
  const [send] = usePost("/visitor/page/stamp");

  useEffect(() => {
    setStartAt(new Date().getTime());
  }, [...dependance]);

  const sendTimer = () => {
    send({
      s_id: visitor.sessionId,
      p_id: pageId,
      timestamp: calculateDifferenceMs(startAt) / 1000,
      v_id: visitor.visitorId,
    });
  };
  useEffect(() => {
    function sendData() {
      if (document.visibilityState === "hidden") {
        sendTimer();
      }
    }
    document.addEventListener("visibilitychange", sendData);
    return () => document.removeEventListener("visibilitychange", sendData);
  });

  useEffect(() => {
    return () => {
      pageId && sendTimer();
    };
  }, [pageId]);

  return { startAt, sendTimer };
};

export const usePageAnalize = (dependance = []) => {
  const visitor = useSelector((state) => state.visitor);
  const [pageVisitSend, pageVisitResponse] = usePost("/visitor/page");
  const [actionSend] = usePost("/visitor/page/action");
  const [page, setPage] = useState(window.location.pathname);
  const dispath = useDispatch();
  usePageTimer({ pageId: visitor.pageId }, dependance);

  useEffect(() => {
    setPage(window.location.pathname);
  }, [...dependance]);

  useEffect(() => {
    if (pageVisitResponse) {
      dispath(setPageId(pageVisitResponse.p_id));
    }
  }, [pageVisitResponse]);

  useEffect(() => {
    if (visitor.sessionId) {
      pageVisitSend({
        p: page,
        s_id: visitor.sessionId,
        v_id: visitor.visitorId,
      });
    }
  }, [visitor.sessionId, ...dependance]);

  const createPageAction = (key, value) => {
    actionSend({
      p_id: visitor.pageId,
      s_id: visitor.sessionId,
      v_id: visitor.visitorId,
      action: {
        k: key,
        v: value,
      },
    });
  };

  return { createPageAction };
};
