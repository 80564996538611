import { useEffect } from 'react';

function useIframeCss(iframeRef, css) {
  useEffect(() => {
    const handleIframeLoad = () => {
      const iframeDocument = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
      if (css && iframeDocument && iframeDocument.head) {
        const styleElement = iframeDocument.createElement('style');
        styleElement.type = 'text/css';
        styleElement.innerHTML = css;
        iframeDocument.head.appendChild(styleElement);
      } 
    };

    if (iframeRef.current) {
      iframeRef.current.addEventListener('load', handleIframeLoad);
    }

    return () => {
      if (iframeRef.current) {
        iframeRef.current.removeEventListener('load', handleIframeLoad);
      }
    };
  }, [css, iframeRef]);
}

export default useIframeCss;
