import React from "react";
import { useParams } from "react-router-dom";
import CardRender from "../../../components/public/card/body/CardRender";
import PaddingContainer from "../../../components/public/layout/PaddingContainer";
import { useMetadata } from "../../../hook/useMetadata";
import { usePageAnalize } from "../../../hook/usePageAnalize";
import { useScrollTop } from "../../../hook/useScroolTop";

export default function Category() {
  useScrollTop();
  const { id, slug } = useParams();
  useMetadata(`p=category&slug=${slug}&id=${id}`);
  usePageAnalize();

  return (
    <PaddingContainer className="padding-container-card cover-with-bg base-container-card">
      <CardRender url={"/api/cards/" + id} dependance={[id, slug]} filter />
    </PaddingContainer>
  );
}
