import React from "react";
import MapList from "../../../components/public/MapList";
import Card from "../../../components/public/card";
import GoBackHeader from "../../../components/public/card/header/GoBackHeader";
import PaddingContainer from "../../../components/public/layout/PaddingContainer";
import RequestManager from "../../../components/public/layout/RequestManager";
import ProfileCard from "../../../components/public/profile/ProfileCard";
import ProfileCardItem from "../../../components/public/profile/ProfileCardItem";
import H1 from "../../../components/tags/element/H1";
import Hr from "../../../components/tags/element/Hr";
import { app, titleSyntax } from "../../../constant";
import useFetch from "../../../hook/useFetch";
import { useLogout } from "../../../hook/useLogout";
import { usePageAnalize } from "../../../hook/usePageAnalize";
import { useScrollTop } from "../../../hook/useScroolTop";
import { useTitle } from "../../../hook/useTitle";
import { Outlet } from "react-router-dom";

export default function Profile() {
  useScrollTop();
  usePageAnalize();
  const logout = useLogout();
  const getProfile = useFetch("/api/profile");
  const title = getProfile.response ? getProfile.response.full_name : "Profile";
  useTitle(titleSyntax(title));

  if (getProfile.loading || getProfile.error)
    return <RequestManager request={getProfile} />;

  const profileSections = [
    {
      title: "Informations générales",
      description:
        "Consultez et modifiez vos informations de base. Vous pouvez mettre à jour votre nom et votre date de naissance ici.",
      items: [
        {
          label: "Nom",
          value: getProfile.response.full_name,
          to: app.url.editName,
        },
        {
          label: "Date de naissance",
          value: getProfile.response.birthday,
          to: app.url.editBirthday,
        },
        {
          label: "Sexe",
          value: getProfile.response.sexe,
          to: app.url.editGender,
        },
      ],
    },
    {
      title: "Adresses de livraison",
      description:
        "Ajoutez, modifiez ou supprimez les adresses de livraison pour vos commandes.",
      items: [
        {
          label: "Adresse 1",
          value: getProfile.response.adresse_1,
          to: app.url.editAdresse,
        },
        {
          label: "Adresse 2",
          value: getProfile.response.adresse_2,
          to: app.url.editAdresse,
        },
      ],
    },
    {
      title: "Coordonnées de contact",
      description:
        "Gérez vos numéros de téléphone et votre adresse e-mail.",
      items: [
        {
          label: "Numéro de téléphone",
          value: getProfile.response.phone_1,
          to: app.url.editPhoneOne,
        },
        {
          label: "Autre numéro de téléphone",
          value: getProfile.response.phone_2,
          to: app.url.editPhoneTwo,
        },
        {
          label: "Adresse e-mail",
          value: getProfile.response.email,
          to: app.url.editEmail,
        },
      ],
    },
    {
      title: "Historique",
      description: "Consultez l'historique de vos commandes et suivez leur statut.",
      items: [{ value: "Mes commandes", to: app.url.userOrder }],
    },
    {
      title: "Paramétre général",
      description: "Gérez vos accès et paramètres de compte.",
      items: [
        getProfile.response.is_superuser && {
          value: "Admin",
          to: app.url.adminDashbord,
        },
        getProfile.response.is_superuser && {
          value: "Blog",
          to: `/blog/edit/${2}`,
        },
        getProfile.response.is_superuser && {
          value: "Page",
          to: `/page/editor`,
        },
        getProfile.response.is_seller
          ? { value: "Espace vendeur", to: app.url.sellerProduct }
          : { value: "Devenir vendeur", to: app.url.editBecomeSeller },
        { onClick: () => logout(), value: "Me deconnecter" },
      ].filter((item) => item),
    },
  ];

  const renderItem = (section) => {
    return (
      <ProfileCard title={section.title} description={section.description}>
        {section.items.map((item, itemIndex) => (
          <React.Fragment key={itemIndex}>
            <ProfileCardItem
              label={item.label}
              value={item.value}
              to={item.to}
              onClick={item.onClick}
            />
            {itemIndex < section.items.length - 1 && <Hr />}
          </React.Fragment>
        ))}
      </ProfileCard>
    );
  };

  return (
    <>
      <Outlet />
      <PaddingContainer className="profile padding-container-card">
        <Card
          header={
            <GoBackHeader
              h={H1}
              title={"Informations de votre profil"}
              description={
                "Gérez vos informations personnelles et accédez à des options de compte."
              }
            />
          }
        />
        <MapList data={profileSections} renderItem={renderItem} />
      </PaddingContainer>
    </>
  );
}
