import React from 'react';
import { Link } from "react-router-dom";
import { useProfile } from "../../../hook/useProfile";
import Div from "../../tags/element/Div";
import P from "../../tags/element/P";
import Span from "../../tags/element/Span";
import FlexColumn from "../../tags/layout/flex/public/FlexColumn";
import FlexRow from "../../tags/layout/flex/public/FlexRow";
import UserAvatar from "../layout/UserAvatar";

export default function SidebarProfileCard() {
  const { profile } = useProfile();

  if (!profile.isProfileLoad) return;

  return (
    <Link className="sidebar-user-profile" to={"/profile"}>
      <FlexRow flexGap="1" className="sidebar-user-profile__container">
        <UserAvatar
          color={profile.userInfo.profile_color}
          size={32}
          label={profile.userInfo.full_name}
        />
        <FlexColumn>
          <P>{profile.userInfo.full_name}</P>
          <Div className="sidebar-user-profile__label">
            <Span>Voir le profile</Span>
          </Div>
        </FlexColumn>
      </FlexRow>
    </Link>
  );
}
