import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import GradientButton from "../../../../components/public/GradientButton";
import MapList from "../../../../components/public/MapList";
import Separator from "../../../../components/public/Separator";
import Card from "../../../../components/public/card";
import GoBackHeader from "../../../../components/public/card/header/GoBackHeader";
import SimpleHeader from "../../../../components/public/card/header/SimpleHeader";
import InputSelect from "../../../../components/public/layout/InputSelect";
import RequestManager from "../../../../components/public/layout/RequestManager";
import ProfileCard from "../../../../components/public/profile/ProfileCard";
import ProfileCardItem from "../../../../components/public/profile/ProfileCardItem";
import Div from "../../../../components/tags/element/Div";
import Form from "../../../../components/tags/element/Form";
import H1 from "../../../../components/tags/element/H1";
import H2 from "../../../../components/tags/element/H2";
import Hr from "../../../../components/tags/element/Hr";
import Img from "../../../../components/tags/element/Img";
import Option from "../../../../components/tags/element/Option";
import P from "../../../../components/tags/element/P";
import Span from "../../../../components/tags/element/Span";
import FlexColumn from "../../../../components/tags/layout/flex/public/FlexColumn";
import FlexRow from "../../../../components/tags/layout/flex/public/FlexRow";
import { app, titleSyntax, toDetail } from "../../../../constant";
import useFetch from "../../../../hook/useFetch";
import usePost from "../../../../hook/usePost";
import { useScrollTop } from "../../../../hook/useScroolTop";
import { useTitle } from "../../../../hook/useTitle";
import { concatenateClass } from "../../../../tools/utils";
import UserAvatar from "../../../../components/public/layout/UserAvatar";
import { formImageUrlForDebug, orderStatusName } from "../../../../modules/utils";

const imageStyle = {
  width: 50,
  height: 50,
  marginRight: "0.5em",
  borderRadius: "4px",
};
const titleStyle = { fontSize: ".9em", marginRight: "0.5em" };

function OrderHeader({ orderId }) {
  return (
    <GoBackHeader
      h={H1}
      title={`Commande #${orderId}`}
      to={app.url.sellerOrder}
    />
  );
}

function CustomerCard({ name }) {
  return (
    <Card className="card-render">
      <FlexColumn alignItems="center" flexGap="1">
        <UserAvatar label={name} size={64} />
        <P>{name}</P>
      </FlexColumn>
    </Card>
  );
}

function GeneralInfoCard({ fullName, phoneNumber }) {
  return (
    <Card className="card-render card-profile-item">
      <ProfileCard title={"Informations générales"}>
        <ProfileCardItem ico={false} label={"Nom complet"} value={fullName} />
        <Hr />
        <ProfileCardItem ico={false} label={"Numéro"} value={phoneNumber} />
        <Hr />
        <ProfileCardItem ico={false} label={"Paiment"} value="À la livraison" />
      </ProfileCard>
    </Card>
  );
}

function ShippingAddressCard({ shippingAddress }) {
  return (
    <Card
      className="card-render"
      header={<SimpleHeader title={"Adresse de livraison"} />}
    >
      {shippingAddress}
    </Card>
  );
}

function OrderDetailsCard({ cartItems, total }) {
  const navigate = useNavigate();
  const handleClick = (item) => {
    const { id, slug } = item.product;
    navigate(toDetail(id, slug));
  };
  const renderItem = (item) => (
    <FlexRow onClick={() => handleClick(item)}>
      <Img
        src={formImageUrlForDebug(item.product.image_1?.image)}
        style={imageStyle}
      />
      <FlexColumn justifyContent="between">
        <Div style={titleStyle} className="cart-product-title">
          <Span>{item.product.title}</Span>
        </Div>
        <FlexRow justifyContent="between">
          <Div className="cart-product-delivery-info">
            <Span>
              {item.quantity} x {item.product.price.price} FCFA
            </Span>
          </Div>
        </FlexRow>
      </FlexColumn>
    </FlexRow>
  );
  return (
    <Card
      className="card-render"
      header={<SimpleHeader title={"Résumé du panier"} />}
    >
      <FlexColumn flexGap="1">
        <MapList data={cartItems} renderItem={renderItem} />
        <Div className="seller-order-item__total">Total: {total}</Div>
      </FlexColumn>
    </Card>
  );
}

function OrderStatusCard({ orderStatus, refetch }) {
  const { id } = useParams();
  const [send, response, loading, error] = usePost(
    `/api/orders/${id}/status/change`,
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const entries = Object.fromEntries(formData.entries());
    send(entries);
  };
  useEffect(() => {
    if (response && response.change) {
      refetch({loading:true});
    }
  }, [response]);

  return orderStatus === "canceled" ? (
    <Div
      className={concatenateClass([
        "order-status-info delivery",
        orderStatus.toLowerCase(),
      ])}
    >
      {orderStatusName(orderStatus.toLowerCase())}
    </Div>
  ) : (
    <Card
      className="card-render"
      header={<SimpleHeader title={"Statut de la commande"} />}
    >
      <Form method="post" onSubmit={handleSubmit}>
        <InputSelect
          name="order_status"
          value={orderStatus.toLowerCase()}
          className={concatenateClass([
            "order-status-info delivery",
            orderStatus.toLowerCase(),
          ])}
        >
          <Option value="pending">En attente</Option>
          <Option value="completed">Terminée</Option>
          <Option value="canceled">Annulée</Option>
        </InputSelect>
        <Separator />
        {!loading || error ? (
          <GradientButton
            disabled={true}
            isLoading={true}
            style={{ height: "25px", width: "25px" }}
            type={"button"}
          >
            Continuer
          </GradientButton>
        ) : (
          <GradientButton type={"button"}>Modifier</GradientButton>
        )}
      </Form>
    </Card>
  );
}

export default function SellerOrderItem() {
  const { id } = useParams();
  useScrollTop();
  useTitle(titleSyntax(`Commande: ${id}`));

  const getOrder = useFetch(`/api/orders/${id}`);

  if (getOrder.loading || getOrder.error)
    return <RequestManager request={getOrder} />;

  return (
    <Card header={<OrderHeader orderId={id} />}>
      <FlexRow flexGap="1" className="seller-order-item">
        <FlexColumn flexGap="1" style={{ flexBasis: "40%", flexShrink: "0" }}>
          <CustomerCard name={getOrder.response.full_name} />
          <ShippingAddressCard
            shippingAddress={getOrder.response.shipping_address}
          />
          <GeneralInfoCard
            fullName={getOrder.response.full_name}
            phoneNumber={getOrder.response.phone}
          />
        </FlexColumn>

        <FlexColumn
          flexGap="1"
          flexGrow="1"
          style={{ flexBasis: "55%", flexShrink: "0" }}
        >
          <OrderDetailsCard
            cartItems={getOrder.response.order_items}
            total={`${getOrder.response.total} FCFA`}
          />

          <Card
            className="card-render"
            header={
              <SimpleHeader
                description={getOrder.response.added_date}
                h={H2}
                title={"Date du commande"}
              />
            }
          />
          <Card
            className="card-render"
            header={
              <SimpleHeader
                description={getOrder.response.last_update}
                h={H2}
                title={"Dernière modification"}
              />
            }
          />

          <OrderStatusCard
            refetch={getOrder.fetchData}
            orderStatus={getOrder.response.order_status}
          />
        </FlexColumn>
      </FlexRow>
      <Separator />
    </Card>
  );
}
