import React from "react";
import Option from "../tags/element/Option";
import InputSelect from "./layout/InputSelect";

export default function YearSelector({ ...props }) {
  const startYear = 1930;
  const endYear = 2024; // Année actuelle

  const years = Array.from(
    { length: endYear - startYear + 1 },
    (_, index) => startYear + index,
  );

  return (
    <InputSelect {...props}>
      {years.map((year) => (
        <Option key={year}>{year}</Option>
      ))}
    </InputSelect>
  );
}
