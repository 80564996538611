import { useState } from "react";

export const useBoolean = (defaultValue = false) => {
  const [value, setValue] = useState(defaultValue);

  const toggle = () => {
    setValue(!value);
  };

  const toFalse = () => {
    setValue(false);
  };

  const toTrue = () => {
    setValue(true);
  };
  
  const set = (value) => {
    setValue(value)
  }

  return {
    value,
    toggle,
    toFalse,
    toTrue,
    set
  };
};
