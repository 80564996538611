import React from 'react'
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import UserAvatar from '../../../../components/public/layout/UserAvatar'
import MapList from '../../../../components/public/MapList'
import Separator from '../../../../components/public/Separator'
import Div from '../../../../components/tags/element/Div'
import H2 from '../../../../components/tags/element/H2'
import Li from '../../../../components/tags/element/Li'
import Span from '../../../../components/tags/element/Span'
import Ul from '../../../../components/tags/element/Ul'
import FlexColumn from '../../../../components/tags/layout/flex/public/FlexColumn'
import FlexRow from '../../../../components/tags/layout/flex/public/FlexRow'
import useFetch from '../../../../hook/useFetch'
import "../../../../styles/page-editor.css"

function SidebarPageItem({item}) {
  const {id} = useParams()
  return (
    <Div className="sidebar-page-item">
      <Link className={item.id == id ? 'selected': ''} to={`/page/editor/${item.id}/html`}>
        <FlexRow alignItems="center">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" fill="currentColor"><path d="M9 2.00318V2H19.9978C20.5513 2 21 2.45531 21 2.9918V21.0082C21 21.556 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5501 3 20.9932V8L9 2.00318ZM5.82918 8H9V4.83086L5.82918 8ZM11 4V9C11 9.55228 10.5523 10 10 10H5V20H19V4H11Z"></path></svg>
          <Div>
            <Span>{item.url}</Span>
          </Div>
        </FlexRow>
      </Link>
    </Div>
  )
}
function PageEditorNavbarTool() {
  const navigate = useNavigate()
  const { id } = useParams()
  return (
    <FlexRow flexGap="1">
      <Div onClick={() => navigate("/page/editor/"+id+"/preview")}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" fill="currentColor"><path d="M12.0003 3C17.3924 3 21.8784 6.87976 22.8189 12C21.8784 17.1202 17.3924 21 12.0003 21C6.60812 21 2.12215 17.1202 1.18164 12C2.12215 6.87976 6.60812 3 12.0003 3ZM12.0003 19C16.2359 19 19.8603 16.052 20.7777 12C19.8603 7.94803 16.2359 5 12.0003 5C7.7646 5 4.14022 7.94803 3.22278 12C4.14022 16.052 7.7646 19 12.0003 19ZM12.0003 16.5C9.51498 16.5 7.50026 14.4853 7.50026 12C7.50026 9.51472 9.51498 7.5 12.0003 7.5C14.4855 7.5 16.5003 9.51472 16.5003 12C16.5003 14.4853 14.4855 16.5 12.0003 16.5ZM12.0003 14.5C13.381 14.5 14.5003 13.3807 14.5003 12C14.5003 10.6193 13.381 9.5 12.0003 9.5C10.6196 9.5 9.50026 10.6193 9.50026 12C9.50026 13.3807 10.6196 14.5 12.0003 14.5Z"></path></svg>
      </Div>
      <Div onClick={() => navigate(`/page/editor/${id}/settings`)}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" fill="currentColor"><path d="M8.68637 4.00008L11.293 1.39348C11.6835 1.00295 12.3167 1.00295 12.7072 1.39348L15.3138 4.00008H19.0001C19.5524 4.00008 20.0001 4.4478 20.0001 5.00008V8.68637L22.6067 11.293C22.9972 11.6835 22.9972 12.3167 22.6067 12.7072L20.0001 15.3138V19.0001C20.0001 19.5524 19.5524 20.0001 19.0001 20.0001H15.3138L12.7072 22.6067C12.3167 22.9972 11.6835 22.9972 11.293 22.6067L8.68637 20.0001H5.00008C4.4478 20.0001 4.00008 19.5524 4.00008 19.0001V15.3138L1.39348 12.7072C1.00295 12.3167 1.00295 11.6835 1.39348 11.293L4.00008 8.68637V5.00008C4.00008 4.4478 4.4478 4.00008 5.00008 4.00008H8.68637ZM6.00008 6.00008V9.5148L3.5148 12.0001L6.00008 14.4854V18.0001H9.5148L12.0001 20.4854L14.4854 18.0001H18.0001V14.4854L20.4854 12.0001L18.0001 9.5148V6.00008H14.4854L12.0001 3.5148L9.5148 6.00008H6.00008ZM12.0001 16.0001C9.79094 16.0001 8.00008 14.2092 8.00008 12.0001C8.00008 9.79094 9.79094 8.00008 12.0001 8.00008C14.2092 8.00008 16.0001 9.79094 16.0001 12.0001C16.0001 14.2092 14.2092 16.0001 12.0001 16.0001ZM12.0001 14.0001C13.1047 14.0001 14.0001 13.1047 14.0001 12.0001C14.0001 10.8955 13.1047 10.0001 12.0001 10.0001C10.8955 10.0001 10.0001 10.8955 10.0001 12.0001C10.0001 13.1047 10.8955 14.0001 12.0001 14.0001Z"></path></svg>
      </Div>

    </FlexRow>
  )
}

function PageEditorNavbarTabs() {
  const {id} = useParams()
  const location = useLocation()
  const isSelected = (segment) => location.pathname.endsWith(segment)

  return (
    <Ul>
      <Li>
        <Link to={`/page/editor/${id}/html`} className={isSelected('/html') ? 'selected' : ''}
        >
          <FlexRow alignItems="center" >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor"><path d="M12 18.1778L7.38083 16.9222L7.0517 13.3778H9.32156L9.48045 15.2222L12 15.8889L14.5195 15.2222L14.7806 12.3556H6.96091L6.32535 5.67778H17.6747L17.4477 7.88889H8.82219L9.02648 10.1444H17.2434L16.6192 16.9222L12 18.1778ZM3 2H21L19.377 20L12 22L4.62295 20L3 2ZM5.18844 4L6.48986 18.4339L12 19.9278L17.5101 18.4339L18.8116 4H5.18844Z"></path></svg>
            <Div><Span>HTML</Span></Div>
          </FlexRow>
        </Link>
      </Li>
      <Li>
        <Link to={`/page/editor/${id}/css`} className={isSelected('/css') ? 'selected' : ''}
        >
          <FlexRow alignItems="center" >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor"><path d="M2.8 14H4.83961L4.2947 16.7245L10.0393 18.8787L17.2665 16.4697L18.3604 11H3.4L3.8 9H18.7604L19.5604 5H4.6L5 3H22L19 18L10 21L2 18L2.8 14Z"></path></svg>
            <Div><Span>CSS</Span></Div>
          </FlexRow>
        </Link>
      </Li>
    </Ul>
  )
}
export default function PageEditor() {
  const {id} = useParams()
  const getPageList = useFetch("/api/page/editor/list")
  const navigate = useNavigate()

  if (getPageList.error) {
    navigate("/")
    return 
  }

  if (getPageList.loading ) return 
  
  
  const currentPage = getPageList.response?.find((page) => page.id == id)
  
  return (
    <>
    <FlexRow className="page-editor-container">
      <Div className='page-editor__sidebar'>
        <Div className="padding-1-em">
          <Link to={"/page/editor/add"} className="new-page-btn">Nouvelle page</Link>
          <Separator/>
          <hr/>
          <H2>Mes pages</H2>
          <MapList data={getPageList.response} renderItem={(item) => <SidebarPageItem item={item}/>}/>
        </Div>
      </Div>
      <Div className='page-editor__main'>
        <Div className='page-editor__navbar'>
          <FlexColumn className="page-editor__navbar-column">
            <FlexRow alignItems="center" justifyContent="between">
              <Div className="p-e-logo"><Span>cicaw</Span></Div>
              {id && currentPage && <Link to={`/p${currentPage?.url}`} className="p-e-pathname">{`/p${currentPage?.url}`}</Link>}
              <Div><UserAvatar color={"red"} label={"Che dieng"} size={32}/></Div>
            </FlexRow>
            {id &&

              <FlexRow alignItems="center" className="page-editor__navbar-items-top" justifyContent="between">
              
              <PageEditorNavbarTabs/>
              <PageEditorNavbarTool/>
            </FlexRow>
            }
          </FlexColumn>
        </Div>
        <Div className="page-editor-body">
          <Outlet/>
        </Div>
      </Div>
    </FlexRow>
    </>
  )
}
