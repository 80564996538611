import React from "react";
import { getInitials } from "../../../modules/utils";

export default function UserAvatar({ label, size, color, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size ? size : "30px"}
      height={size ? size : "30px"}
      viewBox="0 0 64 64"
      version="1.1"
      {...props}
    >
      <circle
        fill={color ? color : "#222"}
        cx={32}
        width={64}
        height={64}
        cy={32}
        r={32}
      />
      <text
        x="50%"
        y="50%"
        style={{
          color: "#222",
          lineHeight: 1,
          fontFamily:
            '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        }}
        alignmentBaseline="middle"
        textAnchor="middle"
        fontSize={28}
        fontWeight={400}
        dy=".1em"
        dominantBaseline="middle"
        fill="#fff"
      >
        {getInitials(label)}
      </text>
    </svg>
  );
}
