import { useNavigate, useSearchParams } from "react-router-dom";

const useRedirect = (param = "redirect") => {
  const nav = useNavigate();
  const [searchParams] = useSearchParams();
  const params = searchParams.get(param)
  const redirectPath = `?${param}=${window.location.pathname}`;

  const to = (path) => {
    return path + redirectPath;
  };
  const navigate = (defaultPath) => {
    nav(defaultPath ? defaultPath : params);
  };

  return {
    navigate,
    to,
    params
  };
};
export default useRedirect;
