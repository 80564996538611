import React from "react";
import Tags from "..";
import useIntersectionObserver from "../../../hook/useIntersectionObserver";
import { useBoolean } from "../../../hook/useBoolean";

export default function Img({ children, src, ...props }) {
  const isVisible = useBoolean(false);
  const isLoad = useBoolean(false);
  const hasError = useBoolean(false);

  const observer = useIntersectionObserver(([entry]) => {
    if (entry.isIntersecting) {
      isVisible.toTrue();
      observer.observer.disconnect();
    }
  });

  const handleLoad = () => {
    isLoad.toTrue();
  };

  const handleError = () => {
    hasError.toTrue();
  };

  const defaultSrc =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mN88wUAAtIB4oiqgf0AAAAASUVORK5CYII=";
  const defaultAlt = props.alt ? props.alt : "cicaw image";

  return (
    <Tags
      onLoad={handleLoad}
      ref={observer.ref}
      alt={defaultAlt}
      tag="img"
      src={!hasError.value && isLoad && isVisible.value ? src : defaultSrc}
      onError={handleError}
      {...props}
    >
      {children}
    </Tags>
  );
}
