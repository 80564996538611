export function concatenateClass(classList = []) {
  // Filtrer les classes non valides
  const validClasses = classList.filter(
    (cls) => typeof cls === "string" && cls.trim() !== "",
  );

  // Concaténer les classes valides avec un espace entre chaque classe
  return validClasses.join(" ");
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
