import React from "react";
import { Link, useParams } from "react-router-dom";
import useBodyClass from "../../../hook/useBodyClass";
import useDetachNavbar from "../../../hook/useDetachNavbar";
import useFetch from "../../../hook/useFetch";
import "../../../styles/blog-details.css";
import Div from "../../tags/element/Div";
import H1 from "../../tags/element/H1";
import P from "../../tags/element/P";
import Span from "../../tags/element/Span";
import FlexColumn from "../../tags/layout/flex/public/FlexColumn";
import FlexRow from "../../tags/layout/flex/public/FlexRow";
import Separator from "../Separator";
import Facebook from "../ico/Facebook";
import LinkIco from "../ico/LinkIco";
import TwitterX from "../ico/TwitterX";
import Whatsapp from "../ico/Whatsapp";
import CenterByMaxWidth from "../layout/CenterByMaxWidth";
import PaddingContainer from "../layout/PaddingContainer";
import RequestManager from "../layout/RequestManager";
import UserAvatar from "../layout/UserAvatar";
import RenderMarkdown from "./RenderMarkdown";
import Skeleton from "../Skeleton";
import { useScrollTop } from "../../../hook/useScroolTop";
import { useMetadata } from "../../../hook/useMetadata";
import { shareOneSocialMedia } from "../../../constant";
import useCopy from "../../../hook/useCopy";
import { usePageAnalize } from "../../../hook/usePageAnalize";

export default function BlogDetails() {
  const { id, slug } = useParams();
  useScrollTop();
  const getBlog = useFetch(`/api/blog/${id}/${slug}`, [id, slug]);
  useBodyClass("blog-details");
  useDetachNavbar(true);
  useMetadata(`p=blog_details&id=${id}`);
  usePageAnalize()
  const copy = useCopy()

  const handleCopy = () => {
    copy(window.location.href)
  }

  if (getBlog.error) {
    return <RequestManager request={getBlog} />;
  }
  return (
    <PaddingContainer className="padding-container-card base-container-card">
      <CenterByMaxWidth maxWidth={"800px"}>
        {getBlog.loading ? (
          <Div className="blog-details__header">
            <FlexColumn flexGap="1">
              <Skeleton
                style={{ maxWidth: "100px", width: "100%", height: "15px" }}
              />
              <Skeleton style={{ width: "100%", height: "30px" }} />
              <Skeleton style={{ width: "100%", height: "100px" }} />
            </FlexColumn>
          </Div>
        ) : (
          <>
            <Div className="blog-details__header">
              <Div>
                <Span className="blog-details__tag">Vente en ligne</Span>
              </Div>
              <Separator />
              <H1>{getBlog.response.title}</H1>
              <P className="blog-details__summary">
                {getBlog.response.summary}
              </P>
            </Div>
            <Separator />
            <hr />
            <Separator />
            <RenderMarkdown value={getBlog.response.content} />
            <hr />
            <FlexRow
              alignItems="center"
              justifyContent="between"
              className="blog-details-action__bar"
            >
              <FlexRow flexGap="1">
                <UserAvatar
                  label={"c d"}
                  size={40}
                  color={getBlog.response.user.profile_color}
                />
                <FlexColumn>
                  <Div className="blog-item__author">
                    {getBlog.response.user.full_name}
                  </Div>
                  <Div className="blog-item__date">
                    {getBlog.response.added_date}
                  </Div>
                </FlexColumn>
              </FlexRow>
              <FlexRow
                className="blog-details__action-bar"
                justifyContent="center"
                flexGap="2"
              >
                <FlexRow flexGap="1">

                  <Link target="_blank" to={shareOneSocialMedia.facebook()}>
                    <Facebook width={24} fill={false} />
                  </Link>

                  <Link target="_blank" to={shareOneSocialMedia.whatssap()}>
                  <Whatsapp width={24} fill={"#000"} />
                  </Link>

                  <Link target="_blank" to={shareOneSocialMedia.x()}>
                    <TwitterX width={24} />
                  </Link>

                  <Div onClick={handleCopy}>
                    <LinkIco width={24} />
                  </Div>
                
                </FlexRow>
              </FlexRow>
            </FlexRow>
          </>
        )}
      </CenterByMaxWidth>
    </PaddingContainer>
  );
}
