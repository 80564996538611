import React, { useEffect } from 'react'
import ShareModal from '../ShareModal'
import CustomModal from '../../modal/CustomModal'
import ModalHeadWithMSGLink from '../../modal/ModalHeadWithMSGLink'
import FlexRow from '../../tags/layout/flex/public/FlexRow'
import Div from '../../tags/element/Div'
import Share from '../ico/Share'
import { useLocation } from 'react-router-dom'

export default function DynamicShareModal({close, pathname}) {
  const location = useLocation()

  useEffect(() => {
    if (location.pathname !== pathname) {
      close();
    }
  }, [location]);

  return (
    <CustomModal
      className={"details-modal-features-share"}
      onHid={close}
      head={
        <ModalHeadWithMSGLink
            title={
              <FlexRow flexGap="1" alignItems="center">
                <Share width={18} height={18}/>
                <Div>Partager</Div>
              </FlexRow>
            }
            onClick={close}
            msg={"Retour"}
          />
      }>
        <Div className="padding-1-em">
          <ShareModal/>
        </Div>
    </CustomModal>
  )
}
