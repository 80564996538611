import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { app } from "../constant";

const useSaveScrool = (setter) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const handleScool = () => {
      dispatch(
        setter({
          top: window.scrollY,
          behavior: app.scrool.defaultBehavior,
        }),
      );
    };
    window.addEventListener("scroll", handleScool);
    return () => window.removeEventListener("scroll", handleScool);
  }, []);
};

export default useSaveScrool;
