import React from "react";
import FlexColumn from "../../tags/layout/flex/public/FlexColumn";
import P from "../../tags/element/P";
import Img from "../../tags/element/Img";
import Span from "../../tags/element/Span";
import Div from "../../tags/element/Div";
import { Link, useNavigate } from "react-router-dom";
import { toDetail, toEditProduct } from "../../../constant";
import { formImageUrlForDebug } from "../../../modules/utils";

export default function Product({ data }) {
  const navigate = useNavigate();
  const to =
    data.product_to === "edit"
      ? toEditProduct(data.id)
      : toDetail(data.id, data.slug);
  const imageUrl = formImageUrlForDebug(
    data.image_1 ? data.image_1.image : data.images[0].image,
  );

  const handleContextMenu = (e) => {
    navigate(to);
    e.preventDefault();
  };

  return (
    <Link to={to} onContextMenu={handleContextMenu} draggable="false">
      <FlexColumn flexWrap="wrap" className="product">
        <Div className="product-img-container">
          <Img className="product-img" src={imageUrl} draggable="false" />
        </Div>
        {data.price.is_reduce ? (
          <>
            <P className={"product-title"}>
              <Span className={"product-reduced-percent"}>
                -{data.price.discount_percentage}%
              </Span>
              <Span className={"product-price__reduced"}>
                {data.price.formatted_default_price} FCFA
              </Span>
            </P>
            <Div className={"product-price"}>
              <Span>{data.price.formatted_price} FCFA</Span>
            </Div>
          </>
        ) : (
          <>
            <P className={"product-title"}>{data.title}</P>
            <Div className={"product-price"}>
              <Span>{data.price.formatted_price} FCFA</Span>
            </Div>
          </>
        )}
      </FlexColumn>
    </Link>
  );
}
