import React from "react";
import Li from "../tags/element/Li";
import Skeleton from "./Skeleton";
import MapList from "./MapList";

export default function TagSkeleton() {
  const tagSkeletons = ["Pour vous", "Chaussure", "Pantellon", "Pain"];
  const renderItem = (tag) => (
    <Li>
      <Skeleton className="tag__suggestion__list-tag tag-skeleton">
        {tag}
      </Skeleton>
    </Li>
  );
  return <MapList data={tagSkeletons} renderItem={renderItem} />;
}
