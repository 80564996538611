import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { LoginRequiredModal } from '../LoginRequired';

export default function DynamicLoginRequiredModal({pathname, close}) {
  const location = useLocation()

  useEffect(() => {
    if (location.pathname !== pathname) {
      close();
    }

  }, [location]);

  return (
    <LoginRequiredModal onGoback={(e) =>{e.preventDefault(); close()}}/>
  )
}
