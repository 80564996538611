import React from "react";
import useFetch from "../../../hook/useFetch";
import { Link, useParams } from "react-router-dom";
import RequestManager from "../../../components/public/layout/RequestManager";
import GoBackHeader from "../../../components/public/card/header/GoBackHeader";
import Card from "../../../components/public/card";
import SimpleHeader from "../../../components/public/card/header/SimpleHeader";
import H1 from "../../../components/tags/element/H1";
import H2 from "../../../components/tags/element/H2";
import H3 from "../../../components/tags/element/H3";
import P from "../../../components/tags/element/P";
import FlexRow from "../../../components/tags/layout/flex/public/FlexRow";
import { useScrollTop } from "../../../hook/useScroolTop";
import CardRender from "../../../components/public/card/body/CardRender";

export default function AdminShopItem() {
  useScrollTop();
  const { id } = useParams();
  const getShopItem = useFetch(`/api/admin/shop/${id}`);

  if (getShopItem.loading || getShopItem.error)
    return <RequestManager request={getShopItem} />;
  const response = getShopItem.response;

  return (
    <>
      <GoBackHeader to={".."} h={H1} title={response.name} />
      <Card header={<SimpleHeader h={H2} title={"Boutique"} />}>
        <FlexRow flexGap="1" flexWrap="wrap">
          <Card header={<SimpleHeader h={H3} title={"Nom"} />}>
            <P>{response.name}</P>
          </Card>
          <Card header={<SimpleHeader h={H3} title={"Numéro"} />}>
            <P>{response.phone}</P>
          </Card>
          <Card header={<SimpleHeader h={H3} title={"Email"} />}>
            <P>{response.email}</P>
          </Card>
          <Card
            header={
              <SimpleHeader h={H3} title={"Peux ajouter des produits?"} />
            }
          >
            <P>{response.can_add_products ? "Oui" : "Non"}</P>
          </Card>
          <Card header={<SimpleHeader h={H3} title={"Categorie"} />}>
            <P>{response.category}</P>
          </Card>
          {response.website && (
            <Card header={<SimpleHeader h={H3} title={"Site web"} />}>
              <P>{response.website}</P>
            </Card>
          )}
          <Card
            header={<SimpleHeader h={H3} title={"Duré de livraison (Jour)"} />}
          >
            <P>{response.delivery_time}</P>
          </Card>
          <Card header={<SimpleHeader h={H3} title={"Description"} />}>
            <P>{response.description}</P>
          </Card>
          <Card header={<SimpleHeader h={H3} title={"Est appouvé?"} />}>
            <P>{response.is_approved ? "Oui" : "Non"}</P>
          </Card>
          <Card header={<SimpleHeader h={H3} title={"Lieu"} />}>
            <P>{response.location}</P>
          </Card>
          <Card header={<SimpleHeader h={H3} title={"Date d'ajout"} />}>
            <P>{response.added_date}</P>
          </Card>
          <Card
            header={<SimpleHeader h={H3} title={"Derniere modification"} />}
          >
            <P>{response.last_update}</P>
          </Card>
        </FlexRow>
      </Card>
      <CardRender url={"/api/admin/shop/" + id + "/products"} />

      <Card header={<SimpleHeader h={H2} title={"Utilisateur"} />}>
        <FlexRow flexGap="1" flexWrap="wrap">
          <Card header={<SimpleHeader h={H3} title={"Utlisateur"} />}>
            <Link to={"/admin/user/" + response.user.id}>
              {response.user.full_name}
            </Link>
          </Card>
        </FlexRow>
      </Card>
    </>
  );
}
