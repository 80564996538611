import React from "react";
import Div from "../../../components/tags/element/Div";
import Span from "../../../components/tags/element/Span";
import FlexColumn from "../../../components/tags/layout/flex/public/FlexColumn";
import FlexRow from "../../../components/tags/layout/flex/public/FlexRow";

export default function DetailsPriceComponent({ data }) {
  return (
    <FlexColumn className="details-price-component">
      {data.price.is_reduce && (
        <FlexRow alignItems="center" className={"product-title"}>
          <Span className={"product-reduced-percent"}>
            -{data.price.discount_percentage}%
          </Span>
          <Span className={"product-price__reduced"}>
            {data.price.formatted_default_price} FCFA
          </Span>
        </FlexRow>
      )}

      <Div className="details-price">
        <Span>{data.price.formatted_price} FCFA</Span>
      </Div>
    </FlexColumn>
  );
}
