import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pageList: [],
  currentPage: {}
};

const pageEditor = createSlice({
  name: "pageEditor",
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    setPageList: (state, action) => {
      state.pageList = action.payload
    }
  },
});

export const { setCurrentPage, setPageList } = pageEditor.actions;

export default pageEditor.reducer;
