import React from "react";
import ProductImageCard from "./ProductImageCard";
import MapList from "../../../../MapList";

export default function ProductImageList({ images, handleClick }) {
  const renderItem = (image) => (
    <ProductImageCard
      key={image.id}
      image={image}
      onClick={() => handleClick(image.id)}
    />
  );

  return <MapList data={images} renderItem={renderItem} />;
}
