import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import CustomModal from "../../../components/modal/CustomModal";
import AcceptTermOnContinue from "../../../components/public/AcceptTermOnContinue";
import Card from "../../../components/public/card";
import SimpleHeader from "../../../components/public/card/header/SimpleHeader";
import CalendarIco from "../../../components/public/ico/CalendarIco";
import LocationIco from "../../../components/public/ico/LocationIco";
import Phone from "../../../components/public/ico/Phone";
import Wallet from "../../../components/public/ico/Wallet";
import ErrorMessageRetryButton from "../../../components/public/layout/ErrorMessageRetryButton";
import Loader from "../../../components/public/layout/Loader";
import UserAvatar from "../../../components/public/layout/UserAvatar";
import MapList from "../../../components/public/MapList";
import UserSvg from "../../../components/public/navbar/ico/UserSvg";
import Separator from "../../../components/public/Separator";
import Button from "../../../components/tags/element/Button";
import Div from "../../../components/tags/element/Div";
import H2 from "../../../components/tags/element/H2";
import Img from "../../../components/tags/element/Img";
import Li from "../../../components/tags/element/Li";
import P from "../../../components/tags/element/P";
import Span from "../../../components/tags/element/Span";
import Ul from "../../../components/tags/element/Ul";
import FlexColumn from "../../../components/tags/layout/flex/public/FlexColumn";
import FlexRow from "../../../components/tags/layout/flex/public/FlexRow";
import useFetch from "../../../hook/useFetch";
import { useGoBack } from "../../../hook/useGoBack";
import usePost from "../../../hook/usePost";
import { formImageUrlForDebug, orderStatusName } from "../../../modules/utils";
import { concatenateClass } from "../../../tools/utils";

const Body = ({ getOrder }) => {
  const [cancelOrder, response, loading, error] = usePost(
    "/api/profile/orders/" + getOrder.response.id + "/delete",
    false,
  );
  const handleCancelOrder = async () => {
    await cancelOrder();
  };
  useEffect(() => {
    if (response && response.status === 1) {
      getOrder.fetchData();
    }
  }, [response]);

  return (
    <>
      <Card>
        <FlexRow flexGap="1">
          <UserAvatar label={getOrder.response.full_name} size={64} />
          <FlexColumn>
            <Div className="u-o-i__h__full_name">
              <Span>{getOrder.response.full_name}</Span>
            </Div>
            <Div className="u-o-i__h__full_date">
              <Span>{getOrder.response.shipping_address}</Span>
            </Div>
            <Div className="u-o-i__h__full_date">
              <Span>{getOrder.response.total} FCFA</Span>
            </Div>
            <Div className="u-o-i__h__full_date">
              <Span>{getOrder.response.added_date}</Span>
            </Div>
            <Div
              className={concatenateClass([
                "u-o-i-order-status",
                "order-status-info delivery",
                getOrder.response.order_status,
              ])}
            >
              <Span>{orderStatusName(getOrder.response.order_status)}</Span>
            </Div>
          </FlexColumn>
        </FlexRow>
      </Card>
      <Card
        header={
          <SimpleHeader
            title={"Information générale"}
            description={"Détails concernant la commande et l'utilisateur."}
          />
        }
      >
        <Ul>
          <Li>
            <FlexRow flexGap="1">
              <UserSvg height={22} width={22} />
              <Div>{getOrder.response.full_name}</Div>
            </FlexRow>
          </Li>
          <Li>
            <FlexRow flexGap="1">
              <Phone height={22} width={22} />
              <Div>
                <Link
                  className="u-o-i__phone"
                  to={"tel:" + getOrder.response.phone}
                >
                  {getOrder.response.phone}
                </Link>
              </Div>
            </FlexRow>
          </Li>
          <Li>
            <FlexRow flexGap="1">
              <LocationIco height={22} width={22} />
              <Div>{getOrder.response.shipping_address}</Div>
            </FlexRow>
          </Li>
          <Li>
            <FlexRow flexGap="1">
              <Wallet height={22} width={22} />
              <Div>{getOrder.response.total} FCFA</Div>
            </FlexRow>
          </Li>
          <Li>
            <FlexRow flexGap="1">
              <CalendarIco height={22} width={22} />
              <Div>Ajouté le {getOrder.response.added_date}</Div>
            </FlexRow>
          </Li>
        </Ul>
      </Card>

      <Card
        className={"user-order-item__summary"}
        header={
          <SimpleHeader
            description={"Un récapitulatif des articles de la commande."}
            title={"Résumé du panier"}
          />
        }
      >
        <Separator />
        <FlexColumn flexGap="1">
          <MapList
            data={getOrder.response.order_items}
            renderItem={(item) => {
              return (
                <FlexRow flexGap="1">
                  <Img
                    src={formImageUrlForDebug(item.product.image_1.image)}
                    height="30"
                    style={{ borderRadius: "50%" }}
                    width="30"
                  />
                  <FlexColumn>
                    <P className="p-title">{item.product.title}</P>
                    <P className="p-info">
                      {item.quantity} x {item.product.price.price} FCFA
                    </P>
                  </FlexColumn>
                </FlexRow>
              );
            }}
          />
        </FlexColumn>
      </Card>
      {loading || error || getOrder.response.order_status === "canceled" ? (
        <></>
      ) : (
        <Card
          header={
            <SimpleHeader
              h={H2}
              description={<AcceptTermOnContinue />}
              title={"Annuler la commande"}
            />
          }
        >
          <FlexRow justifyContent="center" flexWrap="wrap">
            <Button onClick={handleCancelOrder} className="delete">
              Annuler la commande
            </Button>
          </FlexRow>
          <Separator />
        </Card>
      )}
    </>
  );
};

export default function UserOrderItem() {
  const { id } = useParams();
  const getOrder = useFetch("/api/profile/orders/" + id);
  const goback = useGoBack();

  return (
    <CustomModal
      handleHeadClick={goback}
      onHid={goback}
      title={<H2>Ma commande</H2>}
      className={"user-order-modal-item"}
    >
      {getOrder.loading ? (
        <Loader />
      ) : getOrder.error ? (
        <ErrorMessageRetryButton onRetry={getOrder.fetchData} />
      ) : (
        <Body getOrder={getOrder} />
      )}
    </CustomModal>
  );
}
