import { app } from "../constant";
import { getToken, hasToken } from "./auth";

export const generateRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  let isLightColor = true;

  while (isLightColor) {
    color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 15) + 1]; // Exclut 0 pour éviter les teintes blanches
    }

    const luminance =
      (0.299 * parseInt(color.slice(1, 3), 16) +
        0.587 * parseInt(color.slice(3, 5), 16) +
        0.114 * parseInt(color.slice(5, 7), 16)) /
      255;

    isLightColor = luminance > 0.7;
  }

  return color;
};

export const loadFromLocalStorage = (key, defaultData = []) => {
  try {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : defaultData;
  } catch (error) {
    console.error("Error loading data from localStorage:", error);
    return defaultData;
  }
};

export function orderStatusName(status) {
  status = status.toLowerCase();
  if (status === "completed") {
    return "Livré";
  }
  if (status === "pending") {
    return "En attente";
  }
  if (status === "canceled") {
    return "Annulé";
  }
}

export function formatUrlForDebug(url) {
  if (app.debug) {
    return app.backend + url;
  }
  return url;
}

export function generateAuthorizationHeader() {
  return hasToken() ? { Authorization: "Token " + getToken() } : {};
}

export function formImageUrlForDebug(image) {
  if (app.debug) {
    return app.backend + image;
  }
  return image;
}

export function generateFormattedDate() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hour = String(now.getHours()).padStart(2, "0");
  const minute = String(now.getMinutes()).padStart(2, "0");
  const second = String(now.getSeconds()).padStart(2, "0");
  const millisecond = String(now.getMilliseconds()).padStart(3, "0");
  return `${year}-${month}-${day}T${hour}:${minute}:${second}.${millisecond}Z`;
}

export function formatDescriptiveDate(dateStr) {
  const date = new Date(dateStr);

  const month = [
    "janvier",
    "février",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "août",
    "septembre",
    "octobre",
    "novembre",
    "décembre",
  ];

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  const dateDescriptive = `${day} ${month[monthIndex]} ${year}`;

  return dateDescriptive;
}

export function collectedBrowserData() {
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;
  const userAgent = navigator.userAgent;
  const language = navigator.language || navigator.userLanguage;
  const deviceMemory = navigator.deviceMemory;
  const platform = navigator.platform;
  const effectiveType =
    (navigator.connection && navigator.connection.effectiveType) || undefined;
  const connectionType =
    (navigator.connection && navigator.connection.type) || undefined;
  const data = {
    sw: screenWidth,
    sh: screenHeight,
    ua: userAgent,
    lang: language,
    deviceMemory,
    platform,
    effectiveType,
    connectionType,
  };
  return data;
}

export function calculateDifferenceMs(startTime) {
  const endDate = new Date();
  const differenceMs = endDate.getTime() - startTime;

  return differenceMs;
}

export function nativeRound(num, decimalPlaces = 0) {
  var p = Math.pow(10, decimalPlaces);
  return Math.round(num * p) / p;
}

export function calculMean(list = []) {
  let total = 0;
  for (let i = 0; i < list.length; i++) {
    total = total + list[i];
  }
  let mean = total / list.length;
  return mean;
}

export function getInitials(full_name) {
  // Divise le nom complet en mots
  const words = full_name.split(" ");

  // Récupère le premier mot comme prénom
  const firstName = words[0];

  // Récupère le dernier mot comme nom de famille
  const lastName = words[words.length - 1];

  // Obtiens les initiales à partir du prénom et du nom
  let initials = "";
  if (firstName && lastName) {
    initials = `${firstName.charAt(0)}${lastName.charAt(0)}`;
  } else if (firstName) {
    initials = firstName.charAt(0);
  } else if (lastName) {
    initials = lastName.charAt(0);
  }

  return initials.toUpperCase();
}

export function debug(data) {
  if (app.debug) {
    const date = new Date();
    const dateFormat = `${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()}:`;
    console.log(dateFormat, data);
  }
}

export function generate_random(n) {
  var add = 1,
    max = 12 - add;

  if (n > max) {
    return generate_random(max) + generate_random(n - max);
  }

  max = Math.pow(10, n + add);
  var min = max / 10; // Math.pow(10, n) basically
  var number = Math.floor(Math.random() * (max - min + 1)) + min;

  return ("" + number).substring(add);
}

export function createToast(props) {
  return props;
}

export function adjustTextareaHeight(textareaElement) {
  if (textareaElement) {
    // Réinitialiser la hauteur pour obtenir la hauteur correcte du contenu
    textareaElement.style.height = "auto";
    // Ajuster la hauteur en fonction du contenu
    textareaElement.style.height = `${textareaElement.scrollHeight}px`;
  }
}

export function focusElement(element) {
  if (element) {
    element.focus();
  }
}
