/* eslint-disable react/no-unescaped-entities */
import React from "react";
import P from "../../tags/element/P";
import IcoCheck from "../ico/IcoCheck";
import H2 from "../../tags/element/H2";
import CenterByMaxWidth from "../layout/CenterByMaxWidth";
import GradientButton from "../GradientButton";
import Separator from "../Separator";
import { app } from "../../../constant";
import { useNavigate, useSearchParams } from "react-router-dom";
import useFetch from "../../../hook/useFetch";
import Loader from "../layout/Loader";

export default function SuccesfulOrder() {
  let [searchParams] = useSearchParams();
  const getResponse = useFetch(
    `/api/checkout/succes-order?uuid=${searchParams.get("uuid")}`,
  );
  const navigate = useNavigate();

  if (getResponse.loading) return <Loader />;

  if (getResponse.error) {
    navigate(app.url.home);
    return null;
  }

  return (
    <CenterByMaxWidth className={"padding-1-em"} maxWidth={500}>
      <IcoCheck className="succesful-order__ico" height={200} width={200} />
      <H2>Merci d'avoir commandée</H2>
      <Separator />
      <P>{getResponse.response.message}</P>
      <Separator />
      <GradientButton to={"/profile/orders/" + searchParams.get("uuid")}>
        Voir la commande
      </GradientButton>
      <Separator />
      <GradientButton gray to={app.url.home}>
        Retourne à la boutique
      </GradientButton>
    </CenterByMaxWidth>
  );
}
