import React from "react";
import PaddingContainer from "../layout/PaddingContainer";
import GoBackHeader from "../card/header/GoBackHeader";
import Card from "../card";
import H1 from "../../tags/element/H1";
import Div from "../../tags/element/Div";
import Form from "../../tags/element/Form";
import { app } from "../../../constant";
import GradientButton from "../GradientButton";
import Separator from "../Separator";
import { useScrollTop } from "../../../hook/useScroolTop";
import { usePageAnalize } from "../../../hook/usePageAnalize";

export default function ProfileEdit({
  children,
  childrenAfter,
  title,
  description,
  onSubmit,
  isDisabled,
  showButton = true,
}) {
  useScrollTop();
  usePageAnalize();

  return (
    <PaddingContainer
      className={"padding-container-card profile cover-with-bg"}
    >
      <Card
        header={
          <GoBackHeader
            description={description}
            h={H1}
            title={title}
            to={app.url.profile}
          />
        }
      >
        <Div className="edit-body">
          <Form method="post" onSubmit={onSubmit}>
            {children}
            <Separator />
            <Separator />
            {showButton && (
              <GradientButton
                style={{ height: "25px", width: "25px" }}
                type={"button"}
                disabled={isDisabled}
                isLoading={isDisabled ? true : false}
              >
                Continuer
              </GradientButton>
            )}
            {childrenAfter}
          </Form>
        </Div>
      </Card>
    </PaddingContainer>
  );
}
