import React from "react";
import { Link } from "react-router-dom";
import useBodyClass from "../../../hook/useBodyClass";
import useFetch from "../../../hook/useFetch";
import { useScrollTop } from "../../../hook/useScroolTop";
import "../../../styles/blog-editor.css";
import "../../../styles/blog.css";
import Div from "../../tags/element/Div";
import H1 from "../../tags/element/H1";
import H2 from "../../tags/element/H2";
import P from "../../tags/element/P";
import FlexColumn from "../../tags/layout/flex/public/FlexColumn";
import FlexRow from "../../tags/layout/flex/public/FlexRow";
import Card from "../card";
import SimpleHeader from "../card/header/SimpleHeader";
import PaddingContainer from "../layout/PaddingContainer";
import RequestManager from "../layout/RequestManager";
import UserAvatar from "../layout/UserAvatar";
import MapList from "../MapList";
import useDetachNavbar from "../../../hook/useDetachNavbar";
import { useMetadata } from "../../../hook/useMetadata";
import { usePageAnalize } from "../../../hook/usePageAnalize";

function BlogItem({ ...props }) {
  return (
    <Link to={"/blog/" + props.id + "/" + props.slug} className="blog-item">
      <Card>
        <FlexRow flexGap="1" style={{ paddingTop: "1em" }}>
          <UserAvatar
            size={22}
            label={"c d"}
            color={props.user.profile_color}
          />
          <FlexColumn>
            <Div className="blog-item__author">{props.user.full_name}</Div>
            <Div className="blog-item__date">{props.added_date}</Div>
          </FlexColumn>
        </FlexRow>
        <H2>{props.title}</H2>
        <P className="blog-item__description">{props.summary}</P>
      </Card>
    </Link>
  );
}

function BlogRenderer() {
  useBodyClass("blog");
  useScrollTop();
  usePageAnalize()
  useDetachNavbar(true);
  useMetadata(`p=blog_home`);

  const getBlogs = useFetch("/api/blog");

  if (getBlogs.loading || getBlogs.error) {
    return <RequestManager request={getBlogs} />;
  }

  return (
    <PaddingContainer className="blog-container">
      <Card
        className="blog-title-card"
        header={
          <SimpleHeader
            h={H1}
            title={
              "Explorez le Blog de Cicaw : Innovations, Conseils, et Plus Encore"
            }
          />
        }
      />
      <MapList
        data={getBlogs.response}
        renderItem={(item) => <BlogItem {...item} />}
      />
    </PaddingContainer>
  );
}

export default BlogRenderer;
