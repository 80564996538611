import React from "react";
import InputGroup from "../InputGroup";

export default function TagInput({ onChange, value }) {
  return (
    <InputGroup
      onChange={onChange}
      value={value}
      type="text"
      placeholder="Tags"
      unique
    />
  );
}
