import React from "react";
import H3 from "../../../../tags/element/H3";
import P from "../../../../tags/element/P";
import Card from "../../../card";
import SimpleHeader from "../../../card/header/SimpleHeader";

export default function DashborMimidCardInfo({ title, value }) {
  return (
    <Card header={<SimpleHeader h={H3} title={title} />}>
      <P className="card-dashbord-value">{value}</P>
    </Card>
  );
}
