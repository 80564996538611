import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ipInfo: {},
  startTime: new Date().getTime(),
  visitorId: localStorage.getItem("v_id"),
  sessionId: sessionStorage.getItem("s_id"),
  lastSession: "",
  pageId: "",
};

const visitorSlice = createSlice({
  name: "visitor",
  initialState,
  reducers: {
    setIpInfo: (state, action) => {
      state.ipInfo = action.payload;
    },
    setVisitorId: (state, action) => {
      state.visitorId = action.payload;
    },

    setPageId: (state, action) => {
      state.pageId = action.payload;
    },

    setSessionId: (state, action) => {
      state.sessionId = action.payload;
    },
  },
});

export const { setIpInfo, setVisitorId, setPageId, setSessionId } =
  visitorSlice.actions;
export default visitorSlice.reducer;
