import React from "react";
import PagnationContainer from "./pagnation/PagnationContainer";
import { UserItem } from "../../../components/public/manager/admin/renderItem";

export default function AdminUser() {
  return (
    <PagnationContainer
      url={"/api/admin/user"}
      renderItem={UserItem}
      title={"Utilisateurs"}
    />
  );
}
