import { useState } from "react";
import usePost from "../../usePost";

export const useSendSubscribe = (url) => {
  const [subscribeSend, subscribeResponse, , subscribeError] = usePost(url);
  const [prevFollowerCount, setPrevFollowerCount] = useState(0);

  return {
    subscribeSend,
    subscribeResponse,
    subscribeError,
    prevFollowerCount,
    setPrevFollowerCount
  };
};