import React from "react";
import Div from "../tags/element/Div";
import P from "../tags/element/P";

export default function ModalHelpText({ text }) {
  return (
    <Div className="modal__help__text">
      <P>{text}</P>
    </Div>
  );
}
