import React, { useEffect } from "react";
import { NavIcoList } from "../../../components/public/navbar/Navbar";
import Div from "../../../components/tags/element/Div";
import CicawLogo from "../../../components/tags/public/CicawLogo";
import { useScrollTop } from "../../../hook/useScroolTop";
import "../../../styles/gaaw.css";
import FlexRow from "../../../components/tags/layout/flex/public/FlexRow";
import ChevronLeft from "../../../components/public/ico/ChevronLeft";
import H1 from "../../../components/tags/element/H1";
import { Link } from "react-router-dom";
import Span from "../../../components/tags/element/Span";

export default function Gaaw() {
  useScrollTop();

  useEffect(() => {
    document.body.classList.add("bg-white");
    document.body.classList.add("gaaw");
    return () => {
      document.body.classList.remove("bg-white");
      document.body.classList.remove("gaaw");
    };
  }, []);

  const videoRef = React.useRef(null);

  const toggleSound = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
    }
  };

  return (
    <Div className="gaaw-container">
      <Div className="gaaw-body">
        <Div className="gaaw-video-container">
          <video
            onClick={toggleSound}
            ref={videoRef}
            autoPlay
            loop
            muted
            playsInline
          >
            <source
              src="https://videos.pexels.com/video-files/4057322/4057322-uhd_1440_2732_25fps.mp4"
              type="video/mp4"
            />
            <track
              kind="captions"
              src="captions_en.vtt"
              label="English"
              default
            />
          </video>
        </Div>

        <Div className="gaaw-body__head-container">
          <Link to={".."}>
            <FlexRow alignItems="center">
              <ChevronLeft height={26} width={26} />
              <H1>
                Gaaw <Span className="beta-badge">Beta</Span>
              </H1>
            </FlexRow>
          </Link>
        </Div>
        <Div className="gaaw-body__action-container">
          <Div className="test"></Div>
          <Div className="test"></Div>
          <Div className="test"></Div>
          <Div className="test"></Div>
        </Div>
        <Div className="gaaw-body__description-container"></Div>
      </Div>
      <Div className="gaaw-navbar">
        <CicawLogo />
        <NavIcoList />
      </Div>
    </Div>
  );
}
