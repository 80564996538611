import React from "react";
import CustomModal from "../../../components/modal/CustomModal";
import SuccesfulOrder from "../../../components/public/cart/SuccesfulOrder";

export default function CheckoutSucces() {
  return (
    <CustomModal
      title={"Commande réussie"}
      isVisible={true}
      bodyTo={"/"}
      className="succesful-order"
    >
      <SuccesfulOrder />
    </CustomModal>
  );
}
