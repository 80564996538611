import { useState } from "react";
import { post } from "../tools/requests";

const usePost = (url, defaultLoading = true) => {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(defaultLoading);
  const [error, setError] = useState(null);

  const init = () => {
    setResponse(null);
    setLoading(true);
    setError(null);
  };

  const send = async (data, option = { url: url }) => {
    try {
      init();
      const result = await post(option.url, JSON.stringify(data));
      setResponse(result);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return [send, response, loading, error];
};

export default usePost;
