import { post } from "../tools/requests";

export async function submitPostForm(
  event,
  url,
  data = {},
  setFormDisabled,
  header,
) {
  event.preventDefault();
  setFormDisabled && setFormDisabled(true);
  const formData = new FormData(event.target);
  const entries = Object.fromEntries(formData.entries());

  const dictData = {
    ...entries,
    ...data,
  };
  const result = await post(url, JSON.stringify(dictData), header);
  setFormDisabled && setFormDisabled(false);
  return await result;
}
