import { useState, useEffect } from "react";
import { get } from "../tools/requests";

const useFetch = (
  url,
  dependance = [],
  autoSend = true,
  params = { loading: true },
  externalUrl = false,
) => {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(params.loading);
  const [error, setError] = useState(null);
  const fetchData = async (
    data = { loading: loading, response: response, newUrl: null },
  ) => {
    const init = () => {
      setResponse(data.response);
      setLoading(data.loading);
      setError(false);
    };

    try {
      init();
      const result = await get(data.newUrl || url, undefined, undefined, {
        externalUrl: externalUrl,
      });
      setResponse(result);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    autoSend && fetchData({loading:true});
  }, dependance);

  return { response, loading, error, fetchData };
};

export default useFetch;
