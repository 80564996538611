import React from "react";
import PaddingContainer from "./PaddingContainer";
import Loader from "./Loader";

export default function RequestLoading() {
  return (
    <PaddingContainer className="padding-container-card cover-with-bg">
      <Loader />
    </PaddingContainer>
  );
}
