import React, { useEffect } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import MapList from "../../../components/public/MapList";
import DashbordIco from "../../../components/public/ico/DashbordIco";
import ProductIco from "../../../components/public/ico/ProductIco";
import ShopIco from "../../../components/public/ico/ShopIco";
import StarRate from "../../../components/public/ico/StarRate";
import UserSvg from "../../../components/public/navbar/ico/UserSvg";
import Div from "../../../components/tags/element/Div";
import Li from "../../../components/tags/element/Li";
import Span from "../../../components/tags/element/Span";
import Ul from "../../../components/tags/element/Ul";
import FlexRow from "../../../components/tags/layout/flex/public/FlexRow";
import "../../../styles/admin.css";
import useFetch from "../../../hook/useFetch";
import RequestManager from "../../../components/public/layout/RequestManager";
import { app } from "../../../constant";

const tabs = [
  {
    to: "/admin/dashbord",
    ico: <DashbordIco />,
    label: "Dashbord",
  },
  {
    to: "/admin/user",
    ico: <UserSvg />,
    label: "Utilisateur",
  },
  {
    to: "/admin/shop",
    ico: <ShopIco />,
    label: "Boutique",
  },
  {
    to: "/admin/product",
    ico: <ProductIco />,
    label: "Produit",
  },
  {
    to: "/admin/rates",
    ico: <StarRate />,
    label: "Avis",
  },
  {
    to: "/admin/cards",
    label: "Card",
  },
];

export default function Admin() {
  const navigate = useNavigate();
  const getProfile = useFetch("/api/profile");

  useEffect(() => {
    document.body.classList.add("admin");
    return () => document.body.classList.remove("admin");
  }, []);
  useEffect(() => {
    if (window.location.pathname === "/admin") {
      navigate("/admin/dashbord");
    }
  }, [navigate]);

  useEffect(() => {
    if (getProfile.response && !getProfile.response.is_superuser) {
      navigate(app.loginRedirect);
    }
  }, [getProfile.response]);
  if (getProfile.loading || getProfile.error)
    return <RequestManager request={getProfile} />;

  const renderItem = (item) => {
    return (
      <Li>
        <NavLink
          to={item.to}
          className={({ isActive }) => {
            return isActive ? "selected" : "";
          }}
        >
          <FlexRow flexGap="1" alignItems="center">
            <Div>{item.ico}</Div>
            <Div>
              <Span>{item.label}</Span>
            </Div>
          </FlexRow>
        </NavLink>
      </Li>
    );
  };

  return (
    getProfile.response.is_superuser && (
      <Div>
        <Div className="admin-navbar">
          <Div>
            <Span className="cicaw__logo">cicaw</Span>
            <Span className="cicaw__logo-admin">admin</Span>
          </Div>
        </Div>
        <FlexRow className="admin-container">
          <Div className="admin__left">
            <Ul>
              <MapList data={tabs} renderItem={renderItem} />
            </Ul>
          </Div>
          <Div className="admin__right">
            <Div className="padding-1-em">
              <Outlet />
            </Div>
          </Div>
        </FlexRow>
      </Div>
    )
  );
}
