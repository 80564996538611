import { useRef, useState } from "react";
import { request } from "../tools/requests";


export const useAddImage = (url, onSucces, inputName="image") => {
  const [file, setFile] = useState(null);
  const [isImageSelected, setIsImageSelected] = useState(false); // Nouvel état pour suivre la sélection de l'image
  const [selectedImage, setSelectedImage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);
  const [fileName, setFileName] = useState("");
  const fileInputRef = useRef(null);


  const reset = () => {
    setFile(null);
    setSelectedImage(null);
    setIsImageSelected(false); // Désélectionner l'image
    setFileName("");

    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleImageChange = (event) => {
    const inputFile = event.target.files[0];
    setFile(inputFile);
    if (inputFile) {
      setIsImageSelected(true)
      setFileName(inputFile.name);
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(inputFile);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      setError("Please select an image before submitting.");
      return;
    }

    const formData = new FormData();
    formData.append(inputName, file);
    try {
      setIsUploading(true);
      setError(null);

      const response = await request("POST", url, formData, {}, false);
      if (response.is_valid) {
        onSucces?.();
        reset(); // Réinitialiser après succès
      }
    } catch (error) {
      setError("There was an error uploading the image. Please try again.");
    } finally {
      setIsUploading(false);
    }
  };

  return {
    selectedImage,
    isImageSelected,
    isUploading,
    error,
    fileName,
    fileInputRef,
    handleImageChange,
    handleSubmit,
    reset,
  };
};
