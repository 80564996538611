import { useDispatch, useSelector } from "react-redux";
import {
  hideFeedbackModal,
  showFeedbackModal,
} from "../features/main/appSlice";

const useFeedback = () => {
  const app = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const show = () => {
    dispatch(showFeedbackModal());
  };
  const hide = () => {
    dispatch(hideFeedbackModal());
  };

  return {
    isModalVisible: app.feedbackModalVisible,
    show,
    hide,
  };
};

export default useFeedback;
