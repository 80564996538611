import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AcceptTermOnContinue from "../../../components/public/AcceptTermOnContinue";
import Separator from "../../../components/public/Separator";
import Card from "../../../components/public/card";
import SimpleHeader from "../../../components/public/card/header/SimpleHeader";
import InputLitsRender from "../../../components/public/layout/InputLitsRender";
import RequestLoading from "../../../components/public/layout/RequestLoading";
import RequestManager from "../../../components/public/layout/RequestManager";
import ProfileEdit from "../../../components/public/profile/ProfileEdit";
import Button from "../../../components/tags/element/Button";
import Div from "../../../components/tags/element/Div";
import H2 from "../../../components/tags/element/H2";
import FlexColumn from "../../../components/tags/layout/flex/public/FlexColumn";
import useFetch from "../../../hook/useFetch";
import { useForm } from "../../../hook/useForm";
import usePost from "../../../hook/usePost";
import { useScrollTop } from "../../../hook/useScroolTop";

function AddBecomeSeller({ getState }) {
  useScrollTop();
  const getBecomeSeller = useForm("/api/profile/become-seller");

  useEffect(() => {
    if (getBecomeSeller.formResponse) {
      if (getBecomeSeller.formResponse.is_valid) {
        getState.fetchData();
      }
    }
  }, [getBecomeSeller.formResponse]);

  if (getBecomeSeller.loading || getBecomeSeller.error)
    return <RequestManager request={getBecomeSeller} />;

  return (
    <ProfileEdit
      isDisabled={getBecomeSeller.formDisabled}
      onSubmit={getBecomeSeller.handleSubmit}
      description={<AcceptTermOnContinue />}
      title={"Devenir vendeur sur cicaw"}
    >
      <FlexColumn flexGap="1">
        <InputLitsRender formData={getBecomeSeller.formData} />
      </FlexColumn>
      <Separator />
    </ProfileEdit>
  );
}

function UpdateBecomeSeller() {
  useScrollTop();
  const [isEditMode, setIsEditMode] = useState(false);
  const { loading, error, formData, handleSubmit, formDisabled, formResponse } =
    useForm("/api/profile/become-seller/update");
  const [sendDelete, responseDelete /*loadingDelete, errorDelete*/] = usePost(
    "/api/profile/become-seller/delete",
  );
  const navigate = useNavigate();
  const handleDelete = async () => {
    await sendDelete();
  };

  useEffect(() => {
    if (responseDelete) {
      if (responseDelete.deleted) {
        navigate("/profile");
      } else {
        // some Error
      }
    }
  }, [responseDelete, navigate]);

  useEffect(() => {
    if (formResponse) {
      if (formResponse.is_valid) {
        setIsEditMode(false);
      }
    }
  }, [formResponse]);

  if (loading || error) return <RequestLoading />;

  return (
    <>
      <ProfileEdit
        showButton={isEditMode}
        isDisabled={formDisabled}
        onSubmit={handleSubmit}
        description={<AcceptTermOnContinue />}
        title={"Modifier votre demande"}
        childrenAfter={
          isEditMode && (
            <Button
              onClick={handleDelete}
              className="update-become-seller__edit-btn__delete"
              type="button"
            >
              Annuler la demande
            </Button>
          )
        }
      >
        {!isEditMode && (
          <Card
            header={<SimpleHeader h={H2} title={"Note"} />}
            className={"update-become-seller__info"}
          >
            <Div>
              Votre demande pour devenir vendeur sur cicaw est actuellement en
              attente de traitement. Vous avez maintenant la possibilité de
              modifier ou de annuler cette demande.
            </Div>
            <Button
              onClick={() => setIsEditMode(true)}
              className="update-become-seller__edit-btn"
              type="button"
            >
              Modifier la demande
            </Button>
          </Card>
        )}
        <Separator />
        <FlexColumn flexGap="1">
          <InputLitsRender formData={formData} formDisabled={!isEditMode} />
        </FlexColumn>
        <Separator />
      </ProfileEdit>
    </>
  );
}

export default function EditBecomeSeller() {
  const getState = useFetch("/api/profile/become-seller/state");
  const navigate = useNavigate();

  if (getState.loading || getState.error)
    return <RequestManager request={getState} />;

  if (getState.response.is_seller) {
    navigate("/seller");
  }

  return getState.response.created === true ? (
    <UpdateBecomeSeller />
  ) : (
    <AddBecomeSeller getState={getState} />
  );
}
