import React from "react";
import { Link } from "react-router-dom";
import H3 from "../../../../tags/element/H3";
import Span from "../../../../tags/element/Span";
import FlexColumn from "../../../../tags/layout/flex/public/FlexColumn";
import FlexRow from "../../../../tags/layout/flex/public/FlexRow";
import MapList from "../../../MapList";
import Card from "../../../card";
import SimpleHeader from "../../../card/header/SimpleHeader";
import ChevronLeft from "../../../ico/ChevronLeft";
import ChevronRight from "../../../ico/ChevronRight";
import DefaultDashbordCardListItem from "./DefaultDashbordCardListItem";

function CardHeaderWithArrow({ label, to, goBack }) {
  return (
    <SimpleHeader
      h={H3}
      title={
        <Link to={to}>
          <FlexRow justifyContent="between">
            {goBack && <ChevronLeft width={16} />}
            <Span>{label}</Span>
            {!goBack && <ChevronRight width={16} />}
          </FlexRow>
        </Link>
      }
    />
  );
}

function DashborMimidCardForward({ label, to, goBack, children }) {
  return (
    <Card
      header={<CardHeaderWithArrow label={label} to={to} goBack={goBack} />}
    >
      {children}
    </Card>
  );
}

function DashbordCardList({ data, renderItem }) {
  return (
    <FlexColumn flexGap="1">
      <MapList data={data} renderItem={renderItem} />
    </FlexColumn>
  );
}

export default function DefaultDashbordCard({
  data,
  label,
  renderItem,
  to,
  goBack = false,
  children,
}) {
  const defaultRenderItem = (item) => {
    return <DefaultDashbordCardListItem left={item.id} middle={item.value} />;
  };

  return (
    <DashborMimidCardForward to={to} label={label} goBack={goBack}>
      <DashbordCardList
        data={data}
        renderItem={renderItem ? renderItem : defaultRenderItem}
      />
      {children}
    </DashborMimidCardForward>
  );
}
