import React from "react";
import GoBackHeader from "../../../components/public/card/header/GoBackHeader";
import DefaultDashbordCard from "../../../components/public/manager/admin/card/DefaultDashbordCard";
import { sessionDataItem } from "../../../components/public/manager/admin/renderItem";
import Div from "../../../components/tags/element/Div";
import { useScrollTop } from "../../../hook/useScroolTop";

export default function AdminVisitorSession() {
  useScrollTop();

  return (
    <>
      <Div style={{ maxWidth: "800px" }}>
        <GoBackHeader
          title={
            "Visiteur - bdcc2900ecc72c9b5cb258272d59f6e30beeab00 (Session)"
          }
        />
        <DefaultDashbordCard
          goBack
          to={".."}
          data={[]}
          label={"Session"}
          renderItem={sessionDataItem}
        />
      </Div>
    </>
  );
}
