import React from "react";
import Li from "../tags/element/Li";
import { Link } from "react-router-dom";

export default function Tag({ data }) {
  const { name, id } = data;
  const linkTo = "/tags/" + name + "/" + id;

  return (
    <Li>
      <Link to={linkTo} className="tag__suggestion__list-tag">
        {name}
      </Link>
    </Li>
  );
}
