import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loadFromLocalStorage } from "../modules/utils";
import { addGroupCart } from "../features/main/appSlice";

export function useLoadCard() {
  const dispatch = useDispatch();

  useEffect(() => {
    const cartLoaded = loadFromLocalStorage("cart");
    const cart = cartLoaded ? loadFromLocalStorage("cart") : [];
    dispatch(addGroupCart(cart));
  }, [dispatch]);
}
