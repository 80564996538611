import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import HomeCardSection from "../../../components/public/home/HomeCardSection";
import TagSection from "../../../components/public/home/TagSection";
import PaddingContainer from "../../../components/public/layout/PaddingContainer";
import { setScrool } from "../../../features/main/homeSlice";
import { usePageAnalize } from "../../../hook/usePageAnalize";
import useSaveScrool from "../../../hook/useSaveScrool";
import { useScroll } from "../../../hook/useScrool";

export default function Home() {
  const scroolState = useSelector((state) => state.home.scrool);
  useSaveScrool(setScrool);
  useScroll(scroolState);
  usePageAnalize();

  return (
    <>
      <Outlet />
      <PaddingContainer className="padding-container-card">
        <TagSection />
        <HomeCardSection />
      </PaddingContainer>
    </>
  );
}
