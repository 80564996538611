import React from "react";
import Modal from ".";
import ModalHead from "./ModalHead";
import HeadLeft from "./HeadLeft";
import Div from "../tags/element/Div";

function Header({ onClick, title, to }) {
  return (
    <ModalHead>
      <HeadLeft onClick={onClick} title={title} to={to} />
    </ModalHead>
  );
}

export default function CustomModal({
  title,
  to,
  children,
  isVisible = true,
  className,
  head,
  bodyTo,
  onHid,
  containerClassName,
  handleHeadClick,
  hasHeader = true,
  ...props
}) {
  const header = head ? (
    head
  ) : (
    <Header title={title} onClick={handleHeadClick} to={to} />
  );

  return (
    isVisible && (
      <Modal
        containerClassName={containerClassName}
        className={className}
        bodyClickNavigateTo={bodyTo ? bodyTo : false}
        modalContainerClick={onHid}
        {...props}
      >
        {hasHeader && header}
        <Div className="modal_body">{children}</Div>
      </Modal>
    )
  );
}
