import React from "react";
import AuthBody from "../../../components/public/AuthBody";
import GradientButton from "../../../components/public/GradientButton";
import InputLitsRender from "../../../components/public/layout/InputLitsRender";
import RequestLoading from "../../../components/public/layout/RequestLoading";
import FlexColumn from "../../../components/tags/layout/flex/public/FlexColumn";
import { app } from "../../../constant";
import { useForm } from "../../../hook/useForm";
import { useMetadata } from "../../../hook/useMetadata";
import { usePageAnalize } from "../../../hook/usePageAnalize";
import "../../../styles/profile.css";
import AcceptTermOnContinue from "../../../components/public/AcceptTermOnContinue";

export default function SignIn() {
  usePageAnalize();
  useMetadata("p=sign_in");
  const signInFormHandler = useForm("/api/sign-in/");

  if (signInFormHandler.loading || signInFormHandler.error)
    return <RequestLoading />;

  return (
    <AuthBody
      title={"Bienvenue sur cicaw"}
      minTitle={"Inscription"}
      description={"J'ai déja un compte? "}
      anoterAction={"Se connecter"}
      anoterActionTo={app.url.login}
      onSubmit={signInFormHandler.handleSubmit}
    >
      <FlexColumn flexGap="1">
        <InputLitsRender
          formData={signInFormHandler.formData}
          formDisabled={signInFormHandler.formDisabled}
        />
        <AcceptTermOnContinue />
        <GradientButton
          disabled={signInFormHandler.formDisabled}
          type={"button"}
        >
          Continuer
        </GradientButton>
      </FlexColumn>
    </AuthBody>
  );
}
