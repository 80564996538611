import React from "react";
import FlexRow from "../../../components/tags/layout/flex/public/FlexRow";
import H2 from "../../../components/tags/element/H2";
import { Link } from "react-router-dom";

export default function CartSummaryHeader({ isCartFinalize }) {
  return (
    <FlexRow
      alignItems="center"
      justifyContent="between"
      className="buy-now__item-2_sumary-header"
    >
      <H2 style={{ marginTop: "0" }}>Résumé du panier</H2>
      {isCartFinalize && <Link to={"/cart"}>Modifer</Link>}
    </FlexRow>
  );
}
