import React from "react";
import { Link, useParams } from "react-router-dom";
import MapList from "../../../components/public/MapList";
import Separator from "../../../components/public/Separator";
import Card from "../../../components/public/card";
import GoBackHeader from "../../../components/public/card/header/GoBackHeader";
import SimpleHeader from "../../../components/public/card/header/SimpleHeader";
import RequestManager from "../../../components/public/layout/RequestManager";
import UserAvatar from "../../../components/public/layout/UserAvatar";
import Div from "../../../components/tags/element/Div";
import H1 from "../../../components/tags/element/H1";
import H2 from "../../../components/tags/element/H2";
import H3 from "../../../components/tags/element/H3";
import Hr from "../../../components/tags/element/Hr";
import Img from "../../../components/tags/element/Img";
import P from "../../../components/tags/element/P";
import FlexColumn from "../../../components/tags/layout/flex/public/FlexColumn";
import FlexRow from "../../../components/tags/layout/flex/public/FlexRow";
import useFetch from "../../../hook/useFetch";
import { useScrollTop } from "../../../hook/useScroolTop";

export default function AdminOrderItem() {
  useScrollTop();
  const { id } = useParams();
  const getOrderItem = useFetch(`/api/admin/order/${id}`);

  if (getOrderItem.loading || getOrderItem.error)
    return <RequestManager request={getOrderItem} />;
  const response = getOrderItem.response;
  const renderOrderItems = (item) => {
    return (
      <>
        <Link to={"/"}>
          <FlexRow flexGap="1">
            <Img
              src={item.product.image_1.image}
              width="50"
              height="50"
              style={{ borderRadius: "50%" }}
            />
            <FlexColumn>
              <P>{item.product.title}</P>
              <FlexRow flexGap="1" alignItems="center">
                <P className="cart-product-delivery-info">
                  {item.product.price.price} x {item.quantity}
                </P>
                <P style={{ fontSize: ".9em", fontWeight: "600" }}>
                  {item.total} FCFA
                </P>
              </FlexRow>
              <Div>
                <Link
                  to={`/admin/shop/${item.product.shop}`}
                  style={{ textDecoration: "underline" }}
                >
                  Voir la boutique
                </Link>
              </Div>
            </FlexColumn>
          </FlexRow>
        </Link>
        <Hr />
      </>
    );
  };

  return (
    <>
      <GoBackHeader h={H1} to={".."} title={response.str} />
      <Card header={<SimpleHeader h={H2} title={"Information générale"} />}>
        <FlexRow flexGap="1" flexWrap="wrap">
          <Card>
            <FlexColumn flexGap="1" alignItems="center">
              <UserAvatar label={response.full_name} size={60} />
              <P>{response.full_name}</P>
            </FlexColumn>
          </Card>

          <Card header={<SimpleHeader h={H3} title={"Adresse de livraison"} />}>
            <Div>{response.shipping_address}</Div>
          </Card>

          <Card header={<SimpleHeader h={H3} title={"Date d'ajout"} />}>
            <Div>{response.added_date}</Div>
          </Card>

          <Card
            header={<SimpleHeader h={H3} title={"Dernière modification"} />}
          >
            <Div>{response.last_update}</Div>
          </Card>

          <Card header={<SimpleHeader h={H3} title={"Numéro de téléphone"} />}>
            <Div>{response.phone}</Div>
          </Card>

          <Card header={<SimpleHeader h={H3} title={"Total"} />}>
            <Div>{response.total} FCFA</Div>
          </Card>

          <Card header={<SimpleHeader h={H3} title={"Status"} />}>
            <Div>{response.order_status}</Div>
          </Card>
        </FlexRow>
      </Card>
      <Card header={<SimpleHeader h={H2} title={"Panier"} />}>
        <FlexColumn flexGap="1">
          <MapList data={response.order_items} renderItem={renderOrderItems} />
        </FlexColumn>
        <Separator />
        <Div>
          <strong>Total: {response.total} FCFA</strong>
        </Div>
      </Card>
    </>
  );
}
