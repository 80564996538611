import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  content: {
    tags: [],
    cards: [],
    cardRecommendations: [],
  },
  scrool: {
    top: 0,
    left: 0,
    behavior: "smooth",
  },
};

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    dumpTag: (state, action) => {
      const tags = action.payload;
      state.content.tags = tags;
    },
    dumpCards: (state, action) => {
      const cards = action.payload;
      state.content.cards = cards;
    },
    dumpCardRecommendations: (state, action) => {
      const cards = action.payload;
      state.content.cardRecommendations = cards;
    },
    setScrool: (state, action) => {
      state.scrool = action.payload;
    },
  },
});

export const { dumpTag, dumpCards, dumpCardRecommendations, setScrool } =
  homeSlice.actions;
export default homeSlice.reducer;
