import React, { useEffect } from 'react'
import CustomModal from '../../modal/CustomModal'
import Div from '../../tags/element/Div'
import ModalHeadWithMSGLink from '../../modal/ModalHeadWithMSGLink'
import useFetch from '../../../hook/useFetch'
import Loader from '../layout/Loader'
import ErrorMessageRetryButton from '../layout/ErrorMessageRetryButton'

export default function DynamicTextContainer({text, title, close, url}) {
  const getData = useFetch(url, [url], false)

  useEffect(() => {
    if (url) {
      getData.fetchData({loading:true})
    }
  }, [url])

  return (
    <CustomModal onHid={close} head={<ModalHeadWithMSGLink msg={"fermer"} title={title ?? "Information"} onClick={close}/>} className={"dynamic-modal-height"} containerClassName={'dynamic-modal-container'}>
      <Div className="padding-1-em">
        {
         url ? (
          getData.loading ? (<Loader modal />):
          getData.error ? (<ErrorMessageRetryButton onRetry={() => getData.fetchData({loading:true})} />):
          getData.response.data
         ):
         text 
        }
      </Div>
    </CustomModal>
  )
}
