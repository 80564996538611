import React from 'react'
import { cardItem } from '../../../components/public/manager/admin/renderItem'
import Separator from '../../../components/public/Separator'
import Div from '../../../components/tags/element/Div'
import H1 from '../../../components/tags/element/H1'
import PagnationContainer from './pagnation/PagnationContainer'


export default function AdminCards() {
  
  return (
    <Div>
      <H1>Cards</H1>
      <Separator/>
      <PagnationContainer
      url={"/api/admin/cards"}
      renderItem={cardItem}
      title={"Cards"}
    />
    </Div>
  )
}
