import { useDispatch, useSelector } from "react-redux";
import useFetch from "./useFetch";
import { useEffect } from "react";
import { dumpTag } from "../features/main/homeSlice";

const useGetTagSession = () => {
  const dispatch = useDispatch();
  const content = useSelector((state) => state.home.content);
  const getTags = useFetch("/api/tags", [], false, { loading: false });

  useEffect(() => {
    if (content.tags.length === 0) {
      getTags.fetchData({ loading: false });
    }
  }, []);

  useEffect(() => {
    if (getTags.response) {
      dispatch(dumpTag(getTags.response));
    }
  }, [getTags.response]);

  return {
    tags: content.tags,
    isEmpty: content.tags.length === 0,
    loading: getTags.loading,
    error: getTags.error,
  };
};

export default useGetTagSession;
