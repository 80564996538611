import React, { useEffect, useState } from "react";
import Separator from "../../../../components/public/Separator";
import ChevronLeft from "../../../../components/public/ico/ChevronLeft";
import ChevronRight from "../../../../components/public/ico/ChevronRight";
import DefaultDashbordCard from "../../../../components/public/manager/admin/card/DefaultDashbordCard";
import Button from "../../../../components/tags/element/Button";
import Div from "../../../../components/tags/element/Div";
import Hr from "../../../../components/tags/element/Hr";
import FlexRow from "../../../../components/tags/layout/flex/public/FlexRow";
import useFetch from "../../../../hook/useFetch";

export default function PagnationContainer({
  url,
  renderItem,
  title,
  response,
}) {
  const [state, setState] = useState({});
  const request = useFetch(url);
  const loading = request.loading || request.error;

  useEffect(() => {
    if (response && response.status === 1) {
      request.fetchData({
        newUrl: "/api/admin/product/images?page=" + state.currentPage,
        response: request.response,
      });
    }
  }, [response]);

  useEffect(() => {
    if (request.response) {
      const next = request.response.next;
      const previous = request.response.previous;
      const currentPage = request.response.current_page;
      const totalPages = request.response.total_pages;
      setState({ next, previous, currentPage, totalPages });
    }
  }, [request.response]);

  return (
    <Div style={{ maxWidth: "800px" }}>
      <DefaultDashbordCard
        goBack
        to={"/admin/dashbord"}
        data={loading ? [] : request.response.results}
        label={title}
        renderItem={renderItem}
      >
        <Separator />
        <Hr />
        <FlexRow alignItems="center" justifyContent="flex-end" flexGap="1">
          {state.previous && (
            <Button
              onClick={() =>
                request.fetchData({
                  newUrl: state.previous,
                  response: request.response,
                })
              }
            >
              <ChevronLeft width={22} />
            </Button>
          )}
          <Div>
            {state.currentPage}/{state.totalPages}
          </Div>
          {state.next && (
            <Button
              onClick={() =>
                request.fetchData({
                  newUrl: state.next,
                  response: request.response,
                })
              }
            >
              <ChevronRight width={22} />
            </Button>
          )}
        </FlexRow>
      </DefaultDashbordCard>
    </Div>
  );
}
