import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import DynamicForm from "../components/public/dynamicComponent/DynamicForm";
import DynamicLoginRequiredModal from "../components/public/dynamicComponent/DynamicLoginRequiredModal";
import DynamicSelectList from "../components/public/dynamicComponent/DynamicSelectList";
import Feedback from "../components/public/Feedback";
import BottomToast from "../components/public/layout/BottomToast";
import { setTitle } from "../hook/useTitle";
import useToastManager from "../hook/useToastManager";
import DynamicComponent from "./DynamicComponent";
import DynamicShareModal from "../components/public/dynamicComponent/DynamicShareModal";
import DynamicTextContainer from "../components/public/dynamicComponent/DynamicTextContainer";



export default function PageContainer() {
  const toastManager = useToastManager();
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname === "/") {
      setTitle("Cicaw | Site de vente en ligne au sénégal");
    }
  }, [navigate]);

  return (
    <>
      <Outlet />
      <BottomToast item={toastManager.currentItem} />
      <Feedback />
      <DynamicComponent component={<DynamicForm/>} componentId={"dynamic-form"}/>
      <DynamicComponent component={<DynamicSelectList/>} componentId={"dynamic-select-list"}/>
      <DynamicComponent component={<DynamicLoginRequiredModal/>} componentId={"login-required-modal"}/>
      <DynamicComponent component={<DynamicShareModal/>} componentId={"share-modal"}/>
      <DynamicComponent component={<DynamicTextContainer/>} componentId={"modal-text-info"}/>
    </>
  )
}